import Controller, { OnClose } from '@philips-emr/tws-core/lib/client';
import BaseController from '../../BaseController';

@Controller({ fragment: 'politica-de-cookies-modal', component: 'modal-politica-cookies' })
class PoliticaDeCookiesController extends BaseController {

  @OnClose({ target: 'modal-politica-cookies' })
  onCloseModalAviso(): void {
    this.historyService.back();
  }

}

export default PoliticaDeCookiesController;