import { Inject } from '@philips/odin';
import { DeckAPI } from '@philips-emr/tws-components-api';
import { HttpService } from '@philips-emr/tws-core/lib/services';
import Controller, { OnClose } from "@philips-emr/tws-core/lib/client";
import BaseController from '../../BaseController';
import { Notificacao } from '../../../interfaces/notificacao';
import { Endpoint } from '../../../constants/endpoints';

@Controller({ fragment: 'lista-notificacao-modal', component: 'lista-notificacao-modal-layout' })
class ListaNotificacaoModalController extends BaseController {

  @Inject('deck-notificacoes')
  deckNotificacoes: DeckAPI;

  @OnClose({ target: ['lista-notificacao-modal-component'] })
  onClose(): void {
    this.historyService.back();
    this.checkNotification();
  }

  checkNotification(): void {
    const params = this.deckNotificacoes.getData() as Notificacao[];
    const notificacoes = params && params.filter(notificacao => notificacao.qtLeitura == "0");

    //@ts-ignore body deveria aceitar receber um array de dados
    HttpService.post(Endpoint.POST_CHECK_NOTIFICACAO, {}, { body: notificacoes });
  }
}

export default ListaNotificacaoModalController;