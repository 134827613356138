import HpmsTermServiceApi from '@philips-emr/hpms-components/src/api/hpms-term-service-api';
import { FetchAPI } from '@philips-emr/tws-components-api';
import Controller, { OnClose, OnMount } from '@philips-emr/tws-core/lib/client';
import EventListener from '@philips-emr/tws-core/lib/client/event-listener';
import { AuthenticationService, HttpService } from '@philips-emr/tws-core/lib/services';
import { Inject } from '@philips/odin';
import { Route } from "../../../constants/routes";
import BaseController from '../../BaseController';
import JWTToken from '@philips-emr/tws-core/lib/services/auth/JWTToken';
import TokenConstants from '../../../constants/token-constants';
import { Endpoint } from '../../../constants/endpoints';

@Controller({ fragment: 'assign-aviso-de-privacidade', component: 'assign-aviso-de-privacidade-modal' })
class AssignAvisoDePrivacidadeModalController extends BaseController {

  @Inject('fetch-hpms-aviso')
  fetchTermoUso: FetchAPI;

  @Inject('hpms-aviso-service')
  checkTerm: HpmsTermServiceApi;

  @OnMount({ target: ['assign-aviso-de-privacidade-modal'] })
  onMount(): void {
    const { nrSeqAviso } = this.historyService.getCurrentRouteParams();
    this.context.set({ nrSeqAviso });
    const nrSeqValid = nrSeqAviso !== 'undefined';
    this.checkTerm.setEnabled(nrSeqValid);
    if (nrSeqValid) {
      this.fetchTermoUso.forceFetch();
    }
  }

  @OnClose({ target: ['assign-aviso-de-privacidade-modal'] })
  onclose(): void {
    AuthenticationService.logout().then(() => {
      this.historyService.push(Route.LOGIN);
    });
  }

  //@ts-ignore
  @EventListener({ type: 'onSubmitTermoUso', target: ['hpms-aviso-service'] })
  onSubmitTermoUso(): void {
    const { nrSeqAviso } = this.historyService.getCurrentRouteParams();
    const token = AuthenticationService.getJWTToken() as JWTToken;
    const nrSeqBeneficiario = token.claims[TokenConstants.NR_SEQ_BENEFICIARIO] as string;

    if (nrSeqAviso !== 'undefined') {
      HttpService.put(Endpoint.CONFIRMAR_LEITURA_AVISO_DE_PRIVACIDADE, { nrSeqAviso }).then(() => {
        this.historyService.push(Route.DASHBOARD, { nrSeqBeneficiario });
      });
    }
  }

}

export default AssignAvisoDePrivacidadeModalController;
