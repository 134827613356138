import { Inject } from '@philips/odin';

import Controller, { OnAfterFetch, OnChange, OnClose, OnMount, OnSubmit } from "@philips-emr/tws-core/lib/client";
import EventListener from '@philips-emr/tws-core/lib/client/event-listener';
import BaseController from "../../BaseController";
import { AutocompleteAPI, FetchAPI, FieldAPI, FormAPI, PanelAPI } from "@philips-emr/tws-components-api";
import { ChangeEvent, FetchEvent, MountEvent, SubmitEvent } from "@philips-emr/tws-core/lib/component/types";
import { HttpService } from '@philips-emr/tws-core/lib/services';
import { Endpoint } from '../../../constants/endpoints';
import { Route } from '../../../constants/routes';
import Utils from '../../../utils/utils';
import ToastService from "../../../services/toast-service";


interface CategoriaChamado {
  nrSequencia: number;
  dsCategoria: string;
}

interface TipoChamado {
  nrSequencia: number;
  dsTipoChamado: string;
}

interface Guia {
  guia: string;
}

interface Chamado {
  categoria: CategoriaChamado;
  tipoChamado: TipoChamado;
  guia: Guia;
  anexoObrigatorio: string;
  nrSeqChamado: number;
}
@Controller({ fragment: 'chamado-abertura' })
class NovoChamadoController extends BaseController {

  @Inject('fetch-tipo-chamado')
  private fetchTipoChamado: FetchAPI;

  @Inject('panel-modal')
  private panelModal: PanelAPI;

  @Inject('form-chamado')
  private formChamado: FormAPI;

  @Inject('fetch-guia')
  private fetchGuia: FetchAPI;

  @Inject('field-termo')
  private fieldTermo: FieldAPI;

  @Inject('field-Anexos')
  private fieldAnexo: FieldAPI;

  @Inject('autocomplete-Tipo')
  private autocompleteTipoChamado: AutocompleteAPI;

  @OnSubmit({ target: 'form-chamado' })
  onSubmitChamado(event: SubmitEvent): void {
    const formApi = event.target as unknown as FormAPI;
    const data = formApi.getRecord();
    const { categoria, tipoChamado } = data as unknown as Chamado;
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    const body = {
      ...data,
      nrSeqCategoria: categoria.nrSequencia,
      nrSeqTipoChamado: tipoChamado.nrSequencia
    };
    HttpService.post(Endpoint.POST_ABRIR_CHAMADO, { nrSeqBeneficiario }, { body })
      .then(response => {
        const mensagem = response.body as string;
        ToastService.safety(mensagem);
        this.historyService.push(Route.CHAMADOS, { nrSeqBeneficiario });
        return false;
      })
      .catch(response => {
        Utils.catchHandler(response);
        return true;
      });
  }

  @OnChange({ target: 'field-categoria-chamado' })
  onChangeCategoria(event: ChangeEvent<CategoriaChamado>): void {
    const nrSeqCategoria = event.value.nrSequencia;
    const fetchParams = this.fetchTipoChamado.getParameters();
    this.fetchTipoChamado.setParameters({ ...fetchParams, nrSeqCategoria });
    this.fetchTipoChamado.forceFetch();
    this.autocompleteTipoChamado.setValue({ undefined });
  }

  @OnChange({ target: 'field-Tipo-Chamado' })
  onChangeTipoChamado(event: ChangeEvent<TipoChamado>): void {
    const nrSeqTipoChamado = event.value.nrSequencia;
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    const { categoria } = this.formChamado.getRecord() as unknown as Chamado;
    const fetchParams = this.fetchGuia.getParameters();
    this.fetchGuia.setParameters({ ...fetchParams, nrSeqTipoChamado, nrSeqBeneficiario, nrSeqCategoria: categoria.nrSequencia });
    this.fetchGuia.forceFetch();

    if (nrSeqTipoChamado) {
      this.fieldTermo.setVisible(true);
    }
  }

  @OnAfterFetch({ target: 'fetch-guia' })
  onAfterFetchGuia(event: FetchEvent): void {
    const { anexoObrigatorio } = event.data as Chamado;
    const required = [{ name: 'required', options: {} }] as unknown as string[];

    if (anexoObrigatorio) {
      this.fieldAnexo.setValidators(required);
    }
    else {
      this.fieldAnexo.setValidators([]);
    }

    this.fieldAnexo.validate();
  }

  //@ts-ignore
  @EventListener({ type: 'onClickChamado', target: ['hpms-term-checkbox'] })
  onClickLink(): void {
    this.panelModal.setVisible(true);
  }

  @OnClose({ target: 'modal-chamado-termo' })
  onCloseModal(): void {
    this.panelModal.setVisible(false);
  }

  @OnMount({ target: 'fetch-modal' })
  onMountFetch(event: MountEvent): void {
    const fetchAPI = event.target as FetchAPI;
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    const { tipoChamado } = this.formChamado.getRecord() as unknown as Chamado;
    const nrSeqTipoChamado = tipoChamado.nrSequencia;
    fetchAPI.setParameters({ nrSeqBeneficiario, nrSeqTipoChamado });
    fetchAPI.forceFetch();
  }

} export default NovoChamadoController;
