function isCpfValid(value: string): boolean {
  if (!value) return true;

  let sum = 0,
    remainder;

  value = value.replace(/\.|-/gi, '').trim();

  if (value.split('').every(element => element === value[0])) {
    return false;
  }

  for (let i = 1; i <= 9; ++i) {
    sum = sum + parseInt(value.substring(i - 1, i)) * (11 - i);
  }
  remainder = (sum * 10) % 11;

  if ((remainder == 10) || (remainder == 11)) {
    remainder = 0;
  }
  if (remainder != parseInt(value.substring(9, 10))) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; ++i) {
    sum = sum + parseInt(value.substring(i - 1, i)) * (12 - i);
  }
  remainder = (sum * 10) % 11;

  if ((remainder == 10) || (remainder == 11)) {
    remainder = 0;
  }
  if (remainder != parseInt(value.substring(10, 11))) {
    return false;
  }

  return true;

}
export default isCpfValid;