import Card from '@philips-emr/tws-components/lib/components/card/card';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import { string, date } from '@philips-emr/tws-core/lib/component/prop-types';
import React, { PureComponent } from 'react';
import { ReactComponent as CheckSVG } from '../../assets/images/global/hpms-check.svg';
import './hpms-cpt-card.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY })
class HpmsCptCard extends PureComponent { 

  static propTypes = {
    dsCobertura: string,
    dtValidade: date,
    ieStatus: string
  }

  renderHeader() {
    const { dtValidade } = this.props; 
    return (
      <div className='c-hpms-cpt-card__header'>
        <div className='c-hpms-cpt-card__header-left'>{format('date', dtValidade)}</div>
      </div>
    );
  }

  renderContent() {
    const { dsCobertura } = this.props;
    return (
      <div className='c-hpms-cpt-card__content'>{dsCobertura}</div>
    );
  }

  renderFooter() { 
    const { ieStatus } = this.props;
    return (
      <div className='c-hpms-cpt-card__footer'>
        <div className='c-hpms-cpt-card__footer-left'>
          {this.getSvg()}{'\u00A0'}{msg('beneficiary.cpt-status.' + ieStatus)}
        </div>
      </div> 
    );
  }

  render() {
    return (
      <Card width="12" className='c-hpms-cpt-card'>
        <div className={`c-hpms-cpt-card__wrapper c-hpms-cpt-card__border-${this.getColor()}`}>
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderFooter()}
        </div>
      </Card>
    );
  }

  getColor() {
    const { ieStatus } = this.props;
    
    switch (ieStatus) {
      case 'S':
        return 'green';
      default:
        return 'gray';
    }
  }

  getSvg() {
    const { ieStatus } = this.props;
    switch (ieStatus) {
      case 'S':
        return <CheckSVG className={`c-hpms-cpt-card__color-${this.getColor()}`} />;
      default:
        return <CheckSVG className={`c-hpms-cpt-card__color-${this.getColor()}`} />;
    }
  }

}

export default HpmsCptCard;