import { Inject } from '@philips/odin';
import Controller, { OnAfterFetch, OnMount } from "@philips-emr/tws-core/lib/client";
import BaseController from "../../BaseController";
import { FetchAPI, FormAPI, PanelAPI } from "@philips-emr/tws-components-api";
import { ClickEvent } from '@philips-emr/tws-core/lib/component/types';
import { FileService } from '@philips-emr/tws-core/lib/services';
import { Endpoint } from '../../../constants/endpoints';
import { HttpMethod } from '@philips-emr/tws-core/lib/services/http-service';
import ToastService from '../../../services/toast-service';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import EventListener from '@philips-emr/tws-core/lib/client/event-listener';

interface Anexos {
  dsAnexo: string;
  nmArquivo: string;
  nrSeqAnexo: number;
}

@Controller({ fragment: 'chamado-detalhe' })
class ChamadoDetalheController extends BaseController {

  @Inject('form-call')
  private formDetalhe: FormAPI;

  @Inject('panel-chamado-anexos')
  panelAnexos: PanelAPI;

  @OnMount({ target: 'fetch-detalhe' })
  onMountFetchCall(event: FetchAPI): void {
    const fetchAPI = event.target as unknown as FetchAPI;
    const { nrSeqBeneficiario, nrSeqChamado } = this.historyService.getCurrentRouteParams();
    fetchAPI.setParameters({ nrSeqBeneficiario, nrSeqChamado });
    fetchAPI.forceFetch();
  }

  @OnMount({ target: 'panel-detalhe'})
  OnMountPanelDetal(): void{
    const { nrSeqBeneficiario, nrSeqChamado } = this.historyService.getCurrentRouteParams();
    this.context.set({ nrSeqBeneficiario, nrSeqChamado });
  }

  @OnMount({ target: 'fetch-history' })
  OnMountFetchHist(event: FetchAPI): void {
    const fetchAPI = event.target as unknown as FetchAPI;
    const { nrSeqBeneficiario, nrSeqChamado } = this.historyService.getCurrentRouteParams();
    fetchAPI.setParameters({ nrSeqBeneficiario, nrSeqChamado });
    fetchAPI.forceFetch();
  }

  @OnAfterFetch({ target: 'fetch-detalhe' })
  OnAfterFetchCall(): void {
    this.formDetalhe.setEditing(false);
  }

  @OnAfterFetch({ target: ['fetch-chamado-anexo'] })
  onAfterFetchRequestAttachments(event: FetchAPI): void {
    const data = event.data as Anexos;

    if (Object.keys(data).length == 0) {
      this.panelAnexos.setVisible(false);
      return;
    }
  }

  //@ts-ignore
  @EventListener({ type: 'onOpenAttachment', target: ['hpms-attachments-list'] })
  onDownloadClick(event: ClickEvent): void {
    const value = event.value;
    const { nrSeqBeneficiario, nrSeqChamado } = this.historyService.getCurrentRouteParams();
    FileService.download(
      Endpoint.DOWNLOAD_CHAMADO_ANEXO,
      value.nmArquivo,
      { nrSeqAnexo: value.nrSeqAnexo, nrSeqBeneficiario, nrSeqChamado },
      HttpMethod.GET
    ).catch(() => ToastService.caution(msg('$expression.$508286')));
  }

} export default ChamadoDetalheController;