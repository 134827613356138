import { AwesomeEvent, ComponentAPI } from '@philips-emr/tws-core';
import { ClickEventListener } from '@philips-emr/tws-core/lib/component/types';


class HpmsTermosCardApi extends ComponentAPI {

  @AwesomeEvent
  onClick = new ClickEventListener();

}

export default HpmsTermosCardApi;