import { HttpService, AuthenticationService } from '@philips-emr/tws-core/lib/services';
import { RequestResponse } from '@philips-emr/tws-core/lib/services/http-service';
import { Endpoint } from '../constants/endpoints';
import { Route } from '../constants/routes';
import HistoryService from '@philips-emr/tws-core/lib/services/history-service';
import { Value } from '@philips-emr/tws-core';

interface OAuthResponse {
  access_token: string;
  expires_in: number;
  refresh_expires: number;
  refresh_token: string;
  token_type: string;
}

class Audit {

  addRouteListener(): Function {
    return HistoryService.addListener((path: string) => {

      //@ts-ignore
      return this.logTrocaDeRota(path).then(response => {
        if (!response) {
          return Promise.resolve();
        }
        const oAuth = response.body as OAuthResponse;
        return AuthenticationService.updateJWTToken(oAuth);
      });

    });
  }

  logTrocaDeRota(path: string): Promise<RequestResponse> {
    const rotas = {
      [this.parseRoute(Route.DASHBOARD)]: "Dashboard",
      [this.parseRoute(Route.PERSON_DATA_DETAILS)]: "Dados pessoais",
      [this.parseRoute(Route.BENEFICIARIO_DETALHES)]: "Dados do contrato",
      [this.parseRoute(Route.BENEFICIARIO_CARENCIAS)]: "Carências",
      [this.parseRoute(Route.BENEFICIARIO_CPT)]: "Cobertura parcial temporária",
      [this.parseRoute(Route.SOLICITAR_CANCELAMENTO)]: "Solicitação de rescisão",
      [this.parseRoute(Route.CONTRATO_SOLICITACAO_RESCISAO)]: "Histórico de cancelamentos",
      [this.parseRoute(Route.CARTAO_CONSULTAR)]: "Consultar segunda via do cartão",
      [this.parseRoute(Route.BOLETOS_POR_EMAIL)]: "Boletos por e-mail",
      [this.parseRoute(Route.MENSALIDADES)]: "Mensalidades",
      [this.parseRoute(Route.INFORME_DE_RENDIMENTOS)]: "Informe de rendimentos",
      [this.parseRoute(Route.CONSULTA_AUTORIZACAO)]: "Consulta de autorizações",
      [this.parseRoute(Route.DETALHES_AUTORIZACAO)]: "Autorização",
      [this.parseRoute(Route.DETALHES_AUTORIZACAO_TISS)]: "Autorização",
      [this.parseRoute(Route.HISTORICO_MEDICINA_PREVENTIVA)]: "Medicina preventiva",
      [this.parseRoute(Route.HISTORICO_CONSENTIMENTOS)]: "Históricos de Consentimentos",
      [this.parseRoute(Route.PROTOCOLO_ATENDIMENTO_HISTORICO)]: "Protocolos de atendimento",
      [this.parseRoute(Route.CONSULTAR_REQUISICOES)]: "Consulta de requisições",
      [this.parseRoute(Route.DETALHES_REQUISICAO)]: "Requisição",
      [this.parseRoute(Route.DETALHES_REQUISICAO_TISS)]: "Requisição",
      [this.parseRoute(Route.DETALHES_GUIA_REQUISICAO_COMPLETO)]: "Autorização",
      [this.parseRoute(Route.MENSAGEM)]: "Mensagens",
      [this.parseRoute(Route.NOTIFICACAO)]: "Notificações",
      [this.parseRoute(Route.MEUS_DADOS)]: "Meus dados",
    };

    const nmFuncao = rotas[this.parseCurrentRoute(path)];

    if (!nmFuncao) {
      //@ts-ignore
      return Promise.resolve();
    }

    const body = {
      rota: path,
      nmFuncao,
      'refresh_token': localStorage.getItem('refresh_token') as string
    };

    return Audit.logAcessoFuncao(body);
  }

  static logAcessoFuncao(requestBody: Record<string, Value>): Promise<RequestResponse> {
    return HttpService.post(Endpoint.POST_ACESSO_FUNCAO, {}, { body: requestBody });
  }

  parseCurrentRoute(route: string): string {
    const regex = /\d+/g;
    return route.replace(regex, '');
  }

  parseRoute(route: string): string {
    const regex = /:\w+/g;
    return route.replace(regex, '');
  }

}

export default Audit;