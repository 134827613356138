import HpmsInfoBeneficiarioApi from '@philips-emr/hpms-components/src/api/hpms-info-beneficiario-api';
import HpmsMensagemApi from '@philips-emr/hpms-components/src/api/hpms-mensagens-api';
import { FetchAPI, TextAPI } from '@philips-emr/tws-components-api';
import Controller, { OnAfterFetch, OnMount, OnSelect } from '@philips-emr/tws-core/lib/client';
import EventListener from '@philips-emr/tws-core/lib/client/event-listener';
import { ClickEvent, FetchEvent, MountEvent, SelectEvent } from '@philips-emr/tws-core/lib/component/types';
import { AuthenticationService, HttpService } from '@philips-emr/tws-core/lib/services';
import JWTToken from '@philips-emr/tws-core/lib/services/auth/JWTToken';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import { Inject } from '@philips/odin';
import { Endpoint } from '../../../constants/endpoints';
import CardMensalidade from '../../../constants/hpms-monthlyfee-interface';
import { Route } from '../../../constants/routes';
import TokenConstants from '../../../constants/token-constants';
import obterLinks from '../../../services/external-links-service';
import monthlyFeesPopoverService from '../../../services/monthly-fees-popover-service';
import ReportService from '../../../services/report-service';
import ToastService from "../../../services/toast-service";
import Utils from '../../../utils/utils';
import BaseController from '../../BaseController';
import { Data } from './../../../interfaces/data';
import './dashboard-controller.scss';

interface PersonData {
  nmPessoaUsuario: string;
  dtUltimoAcesso: string;
}

interface Mensalidade {
  nrSeqMensalidade: string;
}
@Controller({ fragment: 'dashboard', component: 'dashboard-layout' })
class BeneficiaryController extends BaseController {

  @Inject('fecth-dados-operadoras')
  fecthDadosOperadoras: FetchAPI;

  @Inject('title-text')
  title: TextAPI;

  @Inject('hpms-info-beneficiario')
  hpmsInfoBeneficiario: HpmsInfoBeneficiarioApi;

  reportService: ReportService;

  @OnSelect({ target: ['hpms-monthly-fee-card'] })
  onSelectMenuCardMensalidade(e: SelectEvent<CardMensalidade>): void {
    const { item, nrSeqMensalidade, nrTitulo } = e.value;

    this.context.set({ nrSeqMensalidade });

    if (item === 'nota_fiscal') {
      const params = {
        nrSeqBeneficiario: e.value.nrSeqBeneficiario,
        nrSeqMensalidade: e.value.nrSeqMensalidade,
      };

      obterLinks(this, Endpoint.GET_LINKS_NFS_MENSALIDADES, params, Route.NFS_MENSALIDADES_DASHBOARD, "$expression.$688160");
    }

    if (item === 'observacoes') {
      this.historyService.push(Route.OBSERVACOES_MENSALIDADE, { nrSeqMensalidade });
    }

    if (item === 'segunda_via') {
      const repService = new ReportService('6', this);
      this.emitirRelatorio(repService, { NR_TITULO: nrTitulo }, Route.SEGUNDA_VIA_MENSALIDADE_DASHBOARD, { nrSeqMensalidade });
    }

    if (item === 'detalhamento') {
      const repService = new ReportService('10', this);
      this.emitirRelatorio(repService, { NR_SEQ_MENSALIDADE: nrSeqMensalidade }, Route.DETALHAMENTO_MENSALIDADE_DASHBOARD, { nrSeqMensalidade });
    }
  }

  emitirRelatorio(repService: ReportService, param: {}, rota: string, parametrosRota = {}): void {
    repService.getRelatoriosByCdGrupo()
      .then(reps => {
        if (reps.length <= 0) {
          ToastService.caution('$expression.$736330');
          return;
        }

        repService.abreListaRelatorios(param, rota, '', parametrosRota);
      });
  }

  @OnAfterFetch({ target: ['fetch_mensalidade'] })
  onAfterFetchMensalidade(e: FetchEvent): void {
    if (!(e.data as Mensalidade).nrSeqMensalidade) {
      this.hpmsInfoBeneficiario.setWidth(8);
    }
  }

  @OnMount({ target: ['hpms-info-beneficiario'] })
  onMountInfoBeneficiarioCard(): void {
    const token = AuthenticationService.getJWTToken() as JWTToken;
    const cdPessoaFisica = token && token.claims[TokenConstants.CD_PESSOA_FISICA];

    HttpService.get(Endpoint.GET_PESSOA_FISICA_FOTO, { cdPessoaFisica }).then(response => {
      const pessoaData = response.body as Data;
      this.hpmsInfoBeneficiario.setImage(pessoaData.foto as string);
    });
  }

  //@ts-ignore
  @EventListener({ type: 'onVerMais', target: ['ultimas-autorizacoes'] })
  onVerMaisAutorizacoes(): void {
    this.historyService.push(Route.CONSULTA_AUTORIZACAO);
  }

  //@ts-ignore
  @EventListener({ type: 'onVisualizarAutorizacao', target: ['ultimas-autorizacoes'] })
  onVisualizarAutorizacao(event: ClickEvent): void {
    this.historyService.push(Route.DETALHES_AUTORIZACAO, { nrSeqGuia: event.value });
  }

  //@ts-ignore
  @EventListener({ type: 'onShowMoreClick', target: ['hpms-info-beneficiario'] })
  infoBeneficiarioOnShowMoreClick(): void {
    const token = AuthenticationService.getJWTToken() as JWTToken;
    const cdPessoaFisica: string = token && token.claims[TokenConstants.CD_PESSOA_FISICA] as string;
    this.historyService.push(Route.PERSON_DATA_DETAILS, { cdPessoaFisica });
  }

  //@ts-ignore
  @EventListener({ type: 'onShowMoreClick', target: ['hpms-plan-info'] })
  onShowMoreClickHpmsInfoPlan(): void {
    this.historyService.push(Route.BENEFICIARIO_DETALHES);
  }

  //@ts-ignore
  @EventListener({ type: 'onAnsClick', target: ['hpms-grantor-info'] })
  infoBeneficiarioOnAnsClick(event: ClickEvent): void {
    const link: string = event.value as string;
    window.open(link);
  }

  //@ts-ignore
  @EventListener({ type: 'onNucleoFiscClick', target: ['hpms-grantor-info'] })
  infoBeneficiarioOnNucleoFiscClick(event: ClickEvent): void {
    const link: string = event.value as string;
    window.open(link);
  }


  @OnAfterFetch({ target: ['person-data-fetch'] })
  onAfterPersonDataFetch(): void {
    this.hpmsInfoBeneficiario.setLoading(false);
    this.fecthDadosOperadoras.forceFetch();
  }

  @OnAfterFetch({ target: ['fetch-user-data'] })
  onAfterUserDataFetch(event: FetchEvent): void {
    const data = event.data as PersonData;

    if (data.dtUltimoAcesso) {
      this.title.setValue(`${msg('beneficiary.hello')} ${data.nmPessoaUsuario}, ${msg('beneficiary.your-last-access')} ${format('date', data.dtUltimoAcesso, { type: 'timestamp' })}`);
    } else {
      this.title.setValue(`${msg('beneficiary.hello')} ${data.nmPessoaUsuario}, ${msg('beneficiary.welcome')}`);
    }
  }

  @OnSelect({ target: 'hpms-monthly-fee-card' })
  onSelectMonthlyFee(e: SelectEvent<Data>): void {
    if (e.value && e.value.item) {
      const item: string = e.value.item as string;
      monthlyFeesPopoverService(item, this, {}, Route.DASHBOARD_RELATORIOS);
    }
  }

  //@ts-ignore
  @EventListener({ type: 'onMoreClick', target: ['component-hpms-mensagens'] })
  onHpmsMensagensClick(): void {
    const beneficiaryId = this.context.get('beneficiaryId') as string;
    this.historyService.push(Route.MENSAGEM, { beneficiaryId });
  }

  @OnMount({ target: ['component-hpms-mensagens'] })
  onMount(event: MountEvent): void {
    const mensagem = event.target as HpmsMensagemApi;
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    mensagem.setSeqBeneficiario(nrSeqBeneficiario as number);
  }

  //@ts-ignore
  @EventListener({ type: 'onRolProcedimentosClick', target: ['hpms-plan-info'] })
  onRolProcedimentosClick(): void {
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();

    HttpService.get(Endpoint.GET_ROL_PROCEDIMENTOS, { nrSeqBeneficiario }).then(response => {
      const link = response.body as string;
      window.open(link);
    }).catch(response => Utils.catchHandler(response));
  }

  //@ts-ignore
  @EventListener({ type: 'onTabelaPrecoClick', target: ['hpms-plan-info'] })
  onTabelaPrecoClick(): void {
    this.reportService = new ReportService('9', this, Endpoint.GET_RELATORIOS_POR_GRUPO_AUTORIZACAO);
    this.reportService.getRelatoriosByCdGrupo()
      .then(response => {
        if (response.length === 0) {
          ToastService.caution(msg('$expression.$736330'));
        } else {
          this.reportService.abreListaRelatorios({}, Route.TABELA_DE_PRECO);
        }
      });
  }

  //@ts-ignore
  @EventListener({ type: 'onExtratoUtilizacaoClick', target: ['hpms-plan-info'] })
  onExtratoUtilizacaoClick(): void {
    this.reportService = new ReportService('8', this, Endpoint.GET_RELATORIOS_POR_GRUPO_AUTORIZACAO);
    this.reportService.getRelatoriosByCdGrupo()
      .then(response => {
        if (response.length === 0) {
          ToastService.caution(msg('$expression.$736330'));
        } else {
          this.historyService.push(Route.EXTRATO_UTILIZACAO);
        }
      });
  }
}

export default BeneficiaryController;
