import { Report } from "@philips-emr/hpms-components/src/components/hpms-report-list/hpms-report-list-api";
import { HttpService, SessionStorageService } from "@philips-emr/tws-core/lib/services";
import { ResponseBodyMapperType } from "@philips-emr/tws-core/lib/services/http-service";
import { Endpoint } from "../constants/endpoints";
import BaseController from "../controllers/BaseController";

class ReportService {

  cdGrupo: string;

  reps: Report[];

  owner: BaseController;

  nrSeqBeneficiario: string | number;

  endpointLista: string;

  parametrosLista: {};

  constructor(cdGrupo: string, owner: BaseController, endpointLista: string = '') {
    this.cdGrupo = cdGrupo;
    this.owner = owner;
    this.endpointLista = endpointLista;
    this.nrSeqBeneficiario = owner.context.get("nrSeqBeneficiario") as string | number;

    if (!this.nrSeqBeneficiario) {
      this.nrSeqBeneficiario = owner.historyService.getCurrentRouteParams()['nrSeqBeneficiario'];
    }
  }

  abreListaRelatorios(relatorioParametros: object, relatorioModalRoute: string, titleExpression: string = '', parametrosRota = {}): void {
    this.getRelatoriosByCdGrupo()
      .then(reps => {
        if (reps.length > 0) {
          if (reps.length === 1) {
            this.gerarRelatorio(reps[0].cdRelatorio, reps[0].cdClassifRelat, relatorioParametros);
            return;
          }
          this.abreModal(relatorioParametros, relatorioModalRoute, parametrosRota, titleExpression);
          this.changeCursor(false);
        }
      })
      .catch(() => this.changeCursor(false));
  }

  getRelatoriosByCdGrupo(requestParams: {} = {}): Promise<Report[]> {
    if (this.reps) {
      return Promise.resolve(this.reps);
    }

    if (requestParams) {
      this.parametrosLista = requestParams;
    }

    const params = { ...this.parametrosLista, cdGrupo: this.cdGrupo, nrSeqBeneficiario: this.nrSeqBeneficiario };

    const endpoint = this.endpointLista === '' ? Endpoint.GET_RELATORIOS_POR_GRUPO : this.endpointLista;

    return HttpService.get(endpoint, params)
      .then(resp => {
        this.reps = resp.body as Report[];
        return this.reps;
      });
  }

  changeCursor(isProgress: boolean): void {
    document.body.style.cursor = isProgress ? 'wait' : 'default';
  }

  abrirEmNovaJanela(url: string): void {
    var win = window.open(url, '_blank');
    win && win.focus();
  }

  abreModal(relatorioParametros: object, relatorioModalRoute: string, parametrosRota: {}, titleExpression: string): void {
    SessionStorageService.setItem('dados_relatorios', {
      relatorioParametros,
      nrSeqBeneficiario: this.nrSeqBeneficiario,
      cdGrupo: this.cdGrupo,
      parametrosLista: this.parametrosLista,
      endpointLista: this.endpointLista,
      titleExpression: titleExpression !== '' ? titleExpression : 'hpms-fragments.reports',
    });

    this.owner.historyService.push(relatorioModalRoute, parametrosRota);
  }

  async gerarRelatorio(cdRelatorio: string, tipoRelatorio: string, parametrosRelatorio: object): Promise<void> {
    this.changeCursor(true);

    const body = {
      code: cdRelatorio,
      parameters: parametrosRelatorio,
      type: tipoRelatorio
    };

    const responseBodyMapperType: ResponseBodyMapperType = ResponseBodyMapperType.BLOB;

    const requestParams = { cdGrupo: this.cdGrupo, nrSeqBeneficiario: this.nrSeqBeneficiario };

    return HttpService.post(Endpoint.POST_CONTEUDO_RELATORIO, requestParams, { body, responseBodyMapperType })
      .then(response => {
        const file = new Blob([response.body as Blob], { type: 'application/pdf' });
        const url = URL.createObjectURL(file);
        this.abrirEmNovaJanela(url);
      })
      .finally(() => this.changeCursor(false));
  }
}

export default ReportService;
