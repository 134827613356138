import { Validation } from "@philips-emr/tws-core/lib/services/model-service";
import { Value } from "@philips-emr/tws-core/lib/registry/component-info";

export const BOOLEAN_REQUIRED = 'booleanRequired';
export const EMAIL_VALIDATOR = 'emailValidator';
export const EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;

export function booleanRequired(value: Value): Validation[] {
  const valid = Boolean(value);

  return [{
    message: valid ? "" : 'tws.validator.required',
    name: 'booleanRequired',
    valid,
  }];
}

export function emailValidator(value: Value): Validation[] {
  const valid = !!value && Boolean((value as string).match(EMAIL));

  return [{
    message: !valid ? 'beneficiary.novo-usuario.email-validator' : '',
    name: EMAIL_VALIDATOR,
    valid,
  }];
}