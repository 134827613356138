import Controller, { OnClose, OnMount } from '@philips-emr/tws-core/lib/client';
import BaseController from '../../BaseController';
import { FetchAPI } from '@philips-emr/tws-components-api';
import { Inject } from '@philips/odin';

@Controller({ fragment: 'aviso-de-privacidade', component: 'modal-aviso-privacidade' })
class AvisoDePrivacidadeController extends BaseController {

  @Inject('fetch-aviso-card')
  fetchCard: FetchAPI;

  @Inject('lista-aviso-de-privacidade-fetch')
  fetchDesktop: FetchAPI;

  @OnMount({ target: ['modal-aviso-privacidade'] })
  onMount(): void {
    const { nrSeqAviso } = this.historyService.getCurrentRouteParams();
    if (nrSeqAviso) {
      this.context.set({ nrSeqAviso });
      this.fetchCard && this.fetchCard.forceFetch();
      this.fetchDesktop && this.fetchDesktop.forceFetch();
    }
  }

  @OnClose({ target: 'modal-aviso-privacidade' })
  onCloseModalAviso(): void {
    this.historyService.back();
  }

}

export default AvisoDePrivacidadeController;