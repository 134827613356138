import { AwesomeEvent, ComponentAPI } from '@philips-emr/tws-core';
import { ClickEventListener, SelectEventListener } from '@philips-emr/tws-core/lib/component/types';


class HpmsMonthlyFeeCardApi extends ComponentAPI {

  @AwesomeEvent
  onClick = new ClickEventListener();

  @AwesomeEvent
  onSelect = new SelectEventListener();

}

export default HpmsMonthlyFeeCardApi;