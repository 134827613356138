import Card from '@philips-emr/tws-components/lib/components/card/card';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { number, string } from '@philips-emr/tws-core/lib/component/prop-types';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import React, { PureComponent } from 'react';
import { ReactComponent as CancelSVG } from '../../assets/images/global/hpms-cancel.svg';
import { ReactComponent as CheckSVG } from '../../assets/images/global/hpms-check.svg';
import { ReactComponent as WarningSVG } from '../../assets/images/global/hpms-warning.svg';
import './hpms-contract-termination-card.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY })
class HpmsContractTerminationCard extends PureComponent {

  static propTypes = {
    nrProtocoloAtendimento: string,
    nmBeneficiario: string,
    dtSolicitacao: string,
    dsStatus: string,
    ieStatus: number
  }

  renderContent() {
    const { nrProtocoloAtendimento, nmBeneficiario, dtSolicitacao } = this.props;
    return (
      <div className='c-hpms-contract-termination-card__content'>
        <div className='c-hpms-contract-termination-card__content-item'>
          <div>{nrProtocoloAtendimento}</div>
          <div>{msg('beneficiario.protocolos-atendimento')}</div>
        </div>
        <div className='c-hpms-contract-termination-card__content-item'>
          <div>{nmBeneficiario}</div>
          <div>{msg('beneficiary.request.termination.form-request.beneficiary')}</div>
        </div>
        <div className='c-hpms-contract-termination-card__content-item'>
          <div>{format('date', dtSolicitacao)}</div>
          <div>{msg('hpms-components.solicitation-date')}</div>
        </div>
      </div>
    );
  }

  renderFooter() {
    const { dsStatus } = this.props;
    return (
      <div className='c-hpms-contract-termination-card__footer'>{this.getSvg()}{dsStatus}</div>
    );
  }

  render() {
    return (
      <Card width="12" className='c-hpms-contract-termination-card'>
        <div className={`c-hpms-contract-termination-card__wrapper c-hpms-contract-termination-card__border-${this.getColor()}`}>
          {this.renderContent()}
          {this.renderFooter()}
        </div>
      </Card>
    );
  }

  getColor() {
    const { ieStatus } = this.props;
    switch (ieStatus) {
      case 1:
        return 'orange';
      case 2:
        return 'gray';
      case 3:
        return 'green';
      default:
        return 'red';
    }
  }

  getSvg() {
    const { ieStatus } = this.props;
    switch (ieStatus) {
      case 1:
        return <WarningSVG className={`c-hpms-contract-termination-card__color-${this.getColor()}`} />;
      case 2:
      case 3:
        return <CheckSVG className={`c-hpms-contract-termination-card__color-${this.getColor()}`} />;
      default:
        return <CancelSVG className={`c-hpms-contract-termination-card__color-${this.getColor()}`} />;
    }
  }
}

export default HpmsContractTerminationCard;