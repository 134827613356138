import Controller, { OnClose, OnMount } from '@philips-emr/tws-core/lib/client';
import { FetchAPI } from '@philips-emr/tws-components-api';
import BaseController from '../../BaseController';
import { Inject } from '@philips/odin';
import HpmsAditivoServiceApi from '@philips-emr/hpms-components/src/api/hpms-aditivo-service-api';
import EventListener from '@philips-emr/tws-core/lib/client/event-listener';
import { Route } from "../../../constants/routes";
import { HttpService } from '@philips-emr/tws-core/lib/services';
import { Endpoint } from './../../../constants/endpoints';

@Controller({ fragment: 'consentimento-aditivo-contratual-detalhe', component: 'modal-consentimento-aditivo-contratual-detalhe' })
class ConsentimentoAditivoContratualController extends BaseController {

  @Inject('fetch-hpms-consetimento')
  fetchConsentimento: FetchAPI;

  @Inject('hpms-aditivo-service')
  checkTerm: HpmsAditivoServiceApi;
  
  state = {
    onSubmitAditivo: false
  }

  @OnMount({ target: 'modal-consentimento-aditivo-contratual-detalhe' })
  onMountModalConsetimentoDetalhe(): void {
   
    const { nrSeqTermo, nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    this.context.set({ nrSeqTermo, nrSeqBeneficiario });
    this.fetchConsentimento.forceFetch();
  }

  @OnClose({target: 'modal-consentimento-aditivo-contratual-detalhe' })
  onClose(): void {
    this.historyService.push(Route.HISTORICO_CONSENTIMENTOS);
  }

  //@ts-ignore
  @EventListener({ type: 'onCancelAditivo', target: ['hpms-aditivo-service']})
  onCancelAditivo(): void {
    const { nrSeqTermo, nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();

    const data = {
      nrSeqSegTermo : nrSeqTermo,
      ieAcao : 'R'
    }

    if (nrSeqTermo !== 'undefined') {
      HttpService.post(Endpoint.REFUSE_ADITIVO_CONTRATUAL, { nrSeqBeneficiario }, { body: data }).then(() => {
        this.fetchConsentimento.forceFetch();
        this.historyService.push(Route.HISTORICO_CONSENTIMENTOS);
      });
    }
  }
  
}

export default ConsentimentoAditivoContratualController;