import { ContextMenu, Option, Text } from '@philips-emr/tws-components/lib/components';
import Card from '@philips-emr/tws-components/lib/components/card/card';
import Link from '@philips-emr/tws-components/lib/components/link/link';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { array, eventListener, string } from '@philips-emr/tws-core/lib/component/prop-types';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import React, { PureComponent } from 'react';
import { ReactComponent as ErrorSVG } from '../../../assets/images/global/hpms-cancel.svg';
import { ReactComponent as InfoSVG } from '../../../assets/images/global/hpms-check.svg';
import { ReactComponent as WarnSVG } from '../../../assets/images/global/hpms-warning.svg';
import HpmsLatestAuthorizationsApi from './hpms-latest-authorizations-api';
import './hpms-latest-authorizations.scss';
import { AuthenticationService } from '@philips-emr/tws-core/lib/services';





@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: HpmsLatestAuthorizationsApi })
class HpmsLatestAuthorizations extends PureComponent {

  constructor(props) {
    super(props);
  }

  static propTypes = {
    uuid: string,
    data: array,
    onVisualizarAutorizacao: eventListener,
    onVerMais: eventListener,
  };

  renderIcon(icon) {
    switch (Number(icon)) {
      case 1:
        return <InfoSVG className="svg-color-green" />;
      case 2:
        return <WarnSVG className="svg-color-orange" />;
      case 3:
        return <ErrorSVG className="svg-color-red" />;
    }
  }

  renderItems() {
    const { data } = this.props;

    let items = [];
    data && data.forEach((element, index, array) => {

      let itemClass = 'c-hpms-latest-authorizations__item ';

      if (index != array.length - 1) {
        itemClass += ' c-hpms-latest-authorizations__item-border-bottom';
      }

      items.push(
        <div className={itemClass} key={`${index}`}>

          <div className="c-hpms-latest-authorizations__item-left-section">

            <div className="c-hpms-latest-authorizations__item-field">
              <div className="c-hpms-latest-authorizations__item-field-title">{msg('authorization.form')}</div>
              <div>{element.cdGuia}</div>
            </div>

            <div className="c-hpms-latest-authorizations__item-field">
              <div className="c-hpms-latest-authorizations__item-field-title">{msg('authorization.type')}</div>
              <div>{element.dsTipoGuia}</div>
            </div>

            <div className="c-hpms-latest-authorizations__item-field hidden-on-mobile">
              <div className="c-hpms-latest-authorizations__item-field-title">{msg('authorization.encounter-date')}</div>
              <div>{format('date', element.dtSolicitacao)}</div>
            </div>

            <div className="c-hpms-latest-authorizations__item-separator hidden-on-mobile"></div>

            <div className="c-hpms-latest-authorizations__item-field">
              <div className="c-hpms-latest-authorizations__item-field-title">{msg('authorization.doctor')}</div>
              <div>{element.nmMedicoSolic}</div>
            </div>


            <div className="c-hpms-latest-authorizations__item-field hidden-on-mobile">
              <div className="c-hpms-latest-authorizations__item-field-title">{msg('authorization.provider')}</div>
              <div>{element.nmPrestador}</div>
            </div>

            <div className="c-hpms-latest-authorizations__item-field c-hpms-latest-authorizations__item-field-custom">
              <div>{this.renderIcon(element.ieStatus)}</div><div className="c-hpms-latest-authorizations__item-field-status">{format('domain', element.ieStatus, { name: 'domain-status-guia' })}</div>
            </div>

          </div>
          <div className="c-hpms-latest-authorizations__item-right-section">
            <ContextMenu placement="left">
              <Option onClick={() => this.onVisualizarAutorizacao(element.nrSequencia)} value={msg('hpms-components.visualizar-autorizacao')} />
            </ContextMenu>
          </div>
        </div>
      );

    });

    return items;
  }

  onVisualizarAutorizacao(nrSequencia) {
    const { onVisualizarAutorizacao } = this.props;

    onVisualizarAutorizacao && onVisualizarAutorizacao({ value: nrSequencia });
  }

  onVerMais() {
    const { onVerMais } = this.props;

    onVerMais && onVerMais();
  }

  render() {

    if (AuthenticationService.getJWTToken().claims['ext_acesso_autorizacao'] === 'N') {
      return null;
    }

    return (
      <Card width={12} className="c-hpms-latest-authorizations">
        <div className="c-hpms-latest-authorizations__card-interior">

          <div className="c-hpms-latest-authorizations__title-section">
            <Text>{msg('hpms-components.latest-authorizations')}</Text>
          </div>

          <div className="c-hpms-latest-authorizations__content-section">
            {this.renderItems()}
          </div>

          <div className="c-hpms-latest-authorizations__footer-section">
            <div className="c-hpms-latest-authorizations__footer">
              <Link onClick={() => this.onVerMais()} value="hpms-components.show-more" />
            </div>
          </div>

        </div>
      </Card>
    );
  }
}

export default HpmsLatestAuthorizations;
