import BaseController from "../controllers/BaseController";
import ReportService from "./report-service";

interface ReportMap {
  [key: string]: string;
}

function monthlyFeesPopoverService(identifier: string, owner: BaseController, param: object, modalRoute: string): void {
  const popoverReportMap: ReportMap = {
    'duplicate': '6',
    'detail': '7',
  };

  if (popoverReportMap[identifier]){
    const reportService = new ReportService(popoverReportMap[identifier], owner);
    reportService.abreListaRelatorios(param, modalRoute);
  }
}

export default monthlyFeesPopoverService