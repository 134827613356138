import { ButtonAPI, FormAPI, ImageAPI, PanelAPI, TextAPI } from '@philips-emr/tws-components-api';
import Controller, { OnClick, OnKeyDown, OnMount } from '@philips-emr/tws-core/lib/client';
import { AuthenticationService, HttpService } from '@philips-emr/tws-core/lib/services';
import JWTToken from '@philips-emr/tws-core/lib/services/auth/JWTToken';
import { HttpError } from '@philips-emr/tws-core/lib/services/http-service';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { Inject } from '@philips/odin';
import { Endpoint } from '../../../constants/endpoints';
import { Route } from '../../../constants/routes';
import TokenConstants from '../../../constants/token-constants';
import ConfigurationService from '../../../services/configuration-service';
import BaseController from '../../BaseController';

interface Resp {
  [key: string]: string;
}

@Controller({ fragment: 'login', component: 'login-layout' })
class LoginControler extends BaseController {

  @Inject('errorMessage')
  errorMessageField: TextAPI;

  @Inject('errorMessage-sub')
  errorMessageSub: TextAPI;

  @Inject('login-image-header')
  loginImageHeader: ImageAPI;

  @Inject('header-panel')
  headerPanel: PanelAPI;

  @Inject('panel-loader')
  panelLoader: PanelAPI;

  @Inject('form-login')
  formLogin: FormAPI;

  @Inject('loginBtn')
  loginBtn: ButtonAPI;

  @OnKeyDown({ target: ['userField', 'passwordField'] })
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === "Enter") {
      this.onClick();
    }
  }

  @OnMount({ target: ['login-layout'] })
  onMount(): void {
    ConfigurationService.getConfigurationData().then(layoutData => {
      this.headerPanel.setBackground(`linear-gradient(279deg, ${layoutData.dsFirstColor}, ${layoutData.dsSecondColor})`);
      this.headerPanel.setClassName('u-theme-background');
      this.loginImageHeader.setSrc(layoutData.dsUrlLogoHeaderLogin);
      this.loginBtn.setBackground(layoutData.dsButtonColor);
    })

    if (AuthenticationService.isAuthenticated()) {
      this.handleLoginSuccess(AuthenticationService.getJWTToken());
    }
  }

  @OnClick({ target: ['loginBtn'] })
  onClick(): void {
    const { username, password } = this.formLogin.getRecord();
    this.errorMessageField.setValue('');
    this.errorMessageSub.setValue('');

    if (username && password) {
      const loginData = {
        username: String(username),
        password: String(password)
      };

      this.panelLoader.setVisible(true);
      this.loginBtn.setEnabled(false);

      AuthenticationService.login(loginData)
        .then(token => this.handleLoginSuccess(token))
        .catch(error => {
          this.panelLoader.setVisible(false);
          this.loginBtn.setEnabled(true);
          this.handleLoginError(error)
        });
    } else {
      this.errorMessageField.setValue(msg('$expression.$964755'))
    }
  }

  handleLoginSuccess(token: JWTToken | null): void {
    if (token === null) {
      return;
    }

    this.onLoginSuccessAsyncCalls();

    //TODO: replace beneficiaryId to nrSeqBeneficiario
    const beneficiaryId: string = token.claims[TokenConstants.NR_SEQ_BENEFICIARIO] as string;
    const params = { beneficiaryId, nrSeqBeneficiario: beneficiaryId };

    this.historyService.push(Route.DASHBOARD, params);

  }

  handleLoginError(error: HttpError): void {
    if (error.response) {
      error.response.json()
        .then((resp: Resp) => {
          const primeiraExp = resp[Object.keys(resp)[0]];
          const segundaExp = resp[Object.keys(resp)[1]];

          if (primeiraExp && !segundaExp) {
            if (resp[870103]) {
              //If this code is present, the password has expired
              let { username } = this.formLogin.getRecord();
              username = username as string;
              this.context.set({ username });
              this.formLogin.setRecord({});

              this.historyService.push(Route.ALTERAR_SENHA_LOGIN);

            } else {
              this.errorMessageField.setValue(primeiraExp);
            }
          } else if (primeiraExp && segundaExp) {
            this.errorMessageField.setValue(primeiraExp);
            this.errorMessageSub.setValue(segundaExp);
          } else {
            this.errorMessageField.setValue('beneficiary.messages.server-error');
          }
        })
        .catch(() => this.errorMessageField.setValue('beneficiary.messages.server-error'))
    } else {
      this.errorMessageField.setValue('$expression.$306657');
    }
  }

  onLoginSuccessAsyncCalls(): void {
    // Este método pode ser utilizado para fazer chamadas assíncronas ao backend. 
    // Onde não seja necessário escutar o retorno para dar continuidade no processo de login
    HttpService.post(Endpoint.POST_NOTIFICACAO_ATUALIZAR_CADASTRO);
  }

  @OnClick({ target: ['button-new-user'] })
  onClickNewUser(): void {
    this.historyService.push('/login/new-user');
  }

  @OnClick({ target: ['accessibility_login'] })
  onAccessibilityClick(): void {
    this.historyService.push(Route.ACESSIBILIDADE_LOGIN);
  }
}
export default LoginControler;
