import HpmsMensagemCardApi from '@philips-emr/hpms-components/src/api/hpms-mensagem-card-api';
import { FilterAPI, ToastAPI } from "@philips-emr/tws-components-api";
import Types from '@philips-emr/tws-constants/lib/type';
import Controller, { OnClose, OnMount  } from "@philips-emr/tws-core/lib/client";
import EventListener from '@philips-emr/tws-core/lib/client/event-listener';
import { ClickEvent, MountEvent } from "@philips-emr/tws-core/lib/component/types";
import { FileService } from "@philips-emr/tws-core/lib/services";
import { HttpMethod } from "@philips-emr/tws-core/lib/services/http-service";
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { Inject } from '@philips/odin';
import { Endpoint } from "../../../constants/endpoints";
import ToastService from "../../../services/toast-service";
import BaseController from '../../BaseController';

@Controller({ fragment: 'lista-mensagem-modal', component: 'lista-mensagem-modal-layout' })
class ListaMensagemModalController extends BaseController {

  @Inject('toast-message')
  toastMessage: ToastAPI;

  @OnMount({ target: 'filter-mensagens' })
  onFilterMount(e: MountEvent): void {

    const filter = e.target as FilterAPI;
    const restored = filter.restoreState(true);

    if (restored) {
      return;
    }
    const currentDate = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(currentDate.getMonth() - 1);

    const record = {
      'dtInicio': lastMonth.toISOString(),
      'dtFim': currentDate.toISOString()
    };

    filter.setData(record);
    filter.submit();
  }

  @OnClose({ target: ['lista-mensagem-modal-component'] })
  onClose(): void {
    this.historyService.back();
  }

  //@ts-ignore
  @EventListener({ type: 'onOpenAttachment', target: ['hpms-mensagem-card'] })
  onHpmsMensagensClick(event: ClickEvent): void {
    const value = event.value;
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    FileService.download(Endpoint.DOWNLOAD_MENSAGEM_ANEXO, value.nmArquivo, { nrSeqAnexo: value.nrSequencia, nrSeqBeneficiario }, HttpMethod.GET)
      .catch(() => ToastService.showMessage(this.toastMessage, Types.CAUTION, msg('$expression.$508286')));
  }

  @OnMount({ target: ['hpms-mensagem-card'] })
  onMount(event: MountEvent): void {
    const mensagemCard = event.target as HpmsMensagemCardApi;
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    mensagemCard.setSeqBeneficiario(nrSeqBeneficiario as number);
  }
}

export default ListaMensagemModalController;