import { ComponentAPI, AwesomeEvent } from '@philips-emr/tws-core';
import { ClickEventListener } from '@philips-emr/tws-core/lib/component/types';


interface AditivoContratual {
  nrSeqSegTermo: number;
  nrSeqSegurado:  number;
  ieObrigatorio: string;
  termo: string;
  tipoConfirmacao: string;
}

class HpmsAditivoServiceApi extends ComponentAPI {

  @AwesomeEvent
  onSubmitAditivo  = new ClickEventListener();

  @AwesomeEvent
  onCancelAditivo = new ClickEventListener();

  @AwesomeEvent
  onSubmitAditivoReq = new ClickEventListener();


  @AwesomeEvent
  onDetailClick = new ClickEventListener();

  @AwesomeEvent
  onDetailClickCard = new ClickEventListener();

  setEnabled(enabled: boolean): void {
    this.setState({ enabled });
  }

  isObrigatorio(): boolean {
    return this.state.ieObrigatorio == 'S';
  }

  nrSeqBeneficiary(): boolean {
    return this.state.nrSeqBeneficarioReturn;
  }

  nrSeqTermoClose(): [] {
    return this.state.nrSeqTermoClose;
  }

  returnListAditivo(): [] {
    return this.state.listAditivo;
  }

  setData(value: AditivoContratual): void {
    this.setState({ data: value });
  }

  getData(): AditivoContratual {
    return this.state.data;
  }

  setFirstFetch(value: boolean): void {
    this.setState({ firstFetch: value });
  }

  getFirstFetch(): boolean {
    return this.state.firstFetch;
  }

}

export default HpmsAditivoServiceApi;
