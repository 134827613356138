import { HPMSExpansibleRow } from '@philips-emr/hpms-components';
import { FilterAPI } from '@philips-emr/tws-components-api';
import Controller, { OnClick, OnDynamicContentRender, OnMount, OnUnmount } from '@philips-emr/tws-core/lib/client';
import { MountEvent, RenderEvent } from '@philips-emr/tws-core/lib/component/types';
import React from 'react';
import { Endpoint } from '../../../constants/endpoints';
import { Route } from '../../../constants/routes';
import BaseController from '../../BaseController';

interface Value {
  nrSeqProtocolo: number;
  nrProtocolo: number;
}

interface Data {
  value: Value;
}

@Controller({ fragment: 'encounter-protocol-history', component: 'encounter-protocol-layout' })
class EncounterProtocolHistoryController extends BaseController {

  @OnClick({ target: 'deck-encounter-protocol' })
  onCardClick(event: Data): void {
    const { nrSeqProtocolo, nrProtocolo } = event.value;
    this.context.set({ nrSeqProtocolo, nrProtocolo });
    this.historyService.push(Route.PROTOCOLO_ATENDIMENTO_DETALHES, { nrSeqProtocolo });
  }

  @OnMount({ target: 'encounter-protocol-filter' })
  onFilterMount(e: MountEvent): void {

    const filter = e.target as FilterAPI;

    const restored = filter.restoreState(true);

    if (restored) {
      return;
    }

    const currentDate = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(currentDate.getMonth() - 1);

    const record = {
      'dtInicio': lastMonth.toISOString(),
      'dtFim': currentDate.toISOString()
    };

    filter.setData(record);
    filter.submit();
  }

  @OnUnmount({ target: 'encounter-protocol-filter' })
  onFilterUnmount(e: MountEvent): void {

    const filter = e.target as FilterAPI;

    filter.persistState();
  }

  @OnDynamicContentRender({ target: ['encounter-protocol-layout-dataTable'] })
  onRender(event: RenderEvent): void {

    const { entry, data, render, type } = event;

    const params = { nrSeqProtocolo: '', description: '', ...data }
    const nrSeqBeneficiario = this.context.get('nrSeqBeneficiario');
    const nrSeqProtocolo = params.nrSeqProtocolo;
    const endpoint = Endpoint.GET_ENCOUNTER_PROTOCOL_DETAIL_CARD;
    const urlParams = { nrSeqBeneficiario, nrSeqProtocolo };

    const divStyle = {
      transition: '0.10s ease-in-out',
      cursor: 'pointer',
    };

    if (type == 'row') {
      render(() => <HPMSExpansibleRow params={urlParams} endpoint={endpoint}></HPMSExpansibleRow>);
    }

    if (entry === 'expandColumn') {

      const renderLink: Function = () => {
        return (
          <div onClick={(e) => this.onExpandClick(e.target as HTMLDivElement)} className="c-menu-option__arrow" style={divStyle}></div>
        );
      }

      render(renderLink);
    }
  }

  onExpandClick = (dv: HTMLDivElement) => {
    if (dv.style.transform) {
      dv.style.transform = '';
    } else {
      dv.style.transform = 'rotate(90deg)';
    }
  }
}

export default EncounterProtocolHistoryController;