import React, { PureComponent } from "react";
import { AwesomeComponent, ComponentType } from "@philips-emr/tws-core";
import { string } from "@philips-emr/tws-core/lib/component/prop-types";
import { Text } from "@philips-emr/tws-components/lib/components";
import './hpms-observacao-mensalidade.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY })
class HpmsObservacaoMensalidade extends PureComponent {

  static propTypes = {
    dsMensagemQuitacao: string,
    dsObservacao: string,
  }

  render() {
    const { dsMensagemQuitacao, dsObservacao } = this.props;
    return (
      <div className='c-hpms-observacao-mensalidade_wrapper'>
        <div className='c-hpms-observacao-mensalidade' >
          <Text value='$expression.$827325' size='md' />
          <Text value={dsMensagemQuitacao} className='c-hpms-observacao-mensalidade__descricao' />
        </div>
        <div className='c-hpms-observacao-mensalidade'>
          <Text value='$expression.$667998' size='md' />
          <Text value={dsObservacao} className='c-hpms-observacao-mensalidade__descricao' />
        </div>
      </ div>
    );
  }
}

export default HpmsObservacaoMensalidade;