import { Link } from '@philips-emr/tws-components';
import { DeckAPI, FilterAPI, MapperAPI } from '@philips-emr/tws-components-api';
import Controller, { OnAfterFetch, OnClick, OnDynamicContentRender, OnMount, OnUnmount } from '@philips-emr/tws-core/lib/client';
import EventListener from '@philips-emr/tws-core/lib/client/event-listener';
import { ClickEvent, MountEvent, RenderEvent } from '@philips-emr/tws-core/lib/component/types';
import { HttpService } from '@philips-emr/tws-core/lib/services';
import { Inject } from '@philips/odin';
import React from 'react';
import RequestItemsComponent from '../../../components/request/request-list-component';
import { Endpoint } from "../../../constants/endpoints";
import { Route } from '../../../constants/routes';
import BaseController from '../../BaseController';

const DETAIL_COLUMN_NAME = 'detailColumn';

interface Value {
  id: number;
}

interface Data {
  nrSeqRequisicao: number;
  nrSequencia: number;
  value: Value;
  countGuias: number;
}

@Controller({ fragment: 'request-list', component: 'request-list-layout' })
class RequestListController extends BaseController {

  @Inject('deck-requests')
  deckRequests: DeckAPI;

  @OnClick({ target: 'deck-requests' })
  onCardClick(event: Data): void {
    const { id } = event.value;
    this.onDetailClick(id);
  }

  @OnAfterFetch({ target: ['request-list-fetch'] })
  onAfterFetch(): void {
    this.deckRequests && this.deckRequests.setDone(true);
  }

  @OnMount({ target: 'mapper-card' })
  onMapperMount(e: MountEvent): void {
    const api = e.target as MapperAPI;
    api.addCustomMapping('nrSeqBeneficiario', () => this.historyService.getCurrentRouteParams().nrSeqBeneficiario as string);
  }

  @OnMount({ target: 'request-list-filter' })
  onFilterMount(e: MountEvent): void {

    const filter = e.target as FilterAPI;

    const restored = filter.restoreState(true);

    if (restored) {
      return;
    }

    const currentDate = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(currentDate.getMonth() - 1);

    const record = {
      'dtInicio': lastMonth.toISOString(),
      'dtFinal': currentDate.toISOString()
    };

    filter.setData(record);
    filter.submit();
  }

  @OnUnmount({ target: 'request-list-filter' })
  onFilterUnmount(e: MountEvent): void {

    const filter = e.target as FilterAPI;

    filter.persistState();
  }

  @OnDynamicContentRender({ target: ['request-list-datatable'] })
  onDynamicContentRender(event: RenderEvent): void {
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    const { entry, data, render, type } = event;

    const { nrSequencia, countGuias } = (data as Data);

    if (entry === DETAIL_COLUMN_NAME) {

      const renderLink: Function = () => {
        return (
          <Link icon='/static/assets/view-details.svg' className='hpms-custom-icon-size' onClick={() => this.onDetailClick(nrSequencia)}/>
        );
      }
      render(renderLink);
    } else if (type == 'row') {
      const urlParams = { nrSeqRequisicao: nrSequencia, nrSeqBeneficiario };

      if (!countGuias || countGuias <= 0) {
        render(null);
        return;
      }

      const renderRowContent: Function = () => {
        return (
          <div className='c-request-list-component__list-wrapper'>
            <RequestItemsComponent onDetalhesClick={(event: ClickEvent) => this.requestItemsComponentDetailClick(event)} params={urlParams} />
          </div>
        );
      }
      render(renderRowContent);
    } else if (entry === 'expandColumn') {
      render(() => true);
    }
  }

  requestItemsComponentDetailClick(event: ClickEvent): void {
    const { nrSeqGuia, nrSeqRequisicao } = event.value;
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    this.context.set({ nrSeqGuia, nrSeqRequisicao });

    const autorizacao = HttpService.get(Endpoint.CHECK_GUIA_TISS, { nrSeqGuia, nrSeqBeneficiario }).then(response => response.body as boolean);
    const requisicao = HttpService.get(Endpoint.CHECK_REQUISICAO_TISS, { nrSeqBeneficiario, nrSeqRequisicao }).then(response => response.body as boolean);

    Promise.all([autorizacao, requisicao]).then((values) => {

      let rota;

      if (values[0]) {
        rota = Route.DETALHES_REQUISICAO_TISS;
      } else {
        rota = Route.DETALHES_REQUISICAO;
      }

      if (values[1]) {
        rota = rota.concat(Route.DETALHES_GUIA_REQUISICAO_TISS);
      } else {
        rota = rota.concat(Route.DETALHES_GUIA_REQUISICAO);
      }

      this.historyService.push(rota, { nrSeqBeneficiario, nrSeqRequisicao, nrSeqGuia });
    });
  }

  //@ts-ignore
  @EventListener({ type: 'onDetalhesClick', target: ['hpms-requisicao-autorizacao-card'] })
  onHpmsCardDetailsClick(event: ClickEvent): void {
    this.onDetailClick(event.value);
  }

  //@ts-ignore
  @EventListener({ type: 'onGuiaDetalhesClick', target: ['hpms-requisicao-autorizacao-card'] })
  onGuiaDetalhesClick(event: ClickEvent): void {
    this.requestItemsComponentDetailClick(event);
  }

  onDetailClick = (nrSeqRequisicao: number) => {
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    this.context.set({ nrSeqRequisicao });

    HttpService.get(Endpoint.CHECK_REQUISICAO_TISS, { nrSeqBeneficiario, nrSeqRequisicao }).then(response => {

      const isGuiaTiss = response.body as boolean;

      if (isGuiaTiss) {
        this.historyService.push(Route.DETALHES_REQUISICAO_TISS, { nrSeqRequisicao });
      } else {
        this.historyService.push(Route.DETALHES_REQUISICAO, { nrSeqRequisicao });
      }
    });
  }

}

export default RequestListController;
