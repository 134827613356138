import { ButtonAPI, FieldAPI, FormAPI, PanelAPI, RepeaterAPI, ToastAPI } from '@philips-emr/tws-components-api';
import { Value } from '@philips-emr/tws-core';
import Controller, { OnChange, OnClick, OnClose, OnMount, OnUnmount } from '@philips-emr/tws-core/lib/client';
import { ChangeEvent } from '@philips-emr/tws-core/lib/component/types';
import { AuthenticationService, HttpService } from '@philips-emr/tws-core/lib/services';
import { RequestResponse } from '@philips-emr/tws-core/lib/services/http-service';
import { Inject } from '@philips/odin';
import { Endpoint } from '../../../constants/endpoints';
import Response from '../../../constants/message';
import { Route } from '../../../constants/routes';
import TokenConstants from '../../../constants/token-constants';
import pollingService from '../../../services/polling/polling-service';
import { showToastMessage } from '../../../utils/user-response-utils';
import { confirmationFieldValidator, CONFIRMATION_FIELD_VALID, passwordValidator, PASSWORD_VALIDATOR, senhaNovaDiferenteDaAtual, SENHA_NOVA_DIFERENTE_ATUAL } from '../../../utils/user-util';
import BaseController from '../../BaseController';

@Controller({ fragment: 'alterar-senha', component: 'modal-alt-senha' })
class AlterarSenha extends BaseController {

  @Inject('panel-loader')
  panelLoader: PanelAPI;

  @Inject('field-senha-atual')
  fieldSenhaAtual: FieldAPI;

  @Inject('field-nova-senha')
  fieldNovaSenha: FieldAPI;

  @Inject('field-nova-senha-confirm')
  fieldNovaSenhaConfirm: FieldAPI;

  @Inject('button-alt-senha')
  buttonAltSenha: ButtonAPI;

  @Inject('form-alt-senha')
  formAltSenha: FormAPI;

  @Inject('toast-general-msg')
  toastGeneralMessage: ToastAPI;

  @Inject('repeater-toast')
  repeater: RepeaterAPI;

  @Inject('form-alt-senha')
  form: FormAPI;

  alteracaoCompleta: boolean = false;

  @OnMount({ target: ['modal-alt-senha'] })
  onMountModalAltSenha(): void {

    if (this.historyService.getCurrentRoute() === Route.ALTERAR_SENHA_LOGIN && !this.context.get("username")) {
      //If the user tried to F5 the modal while changing password in the login
      this.historyService.push(Route.LOGIN);
    }

    this.fieldNovaSenha.addLocalValidator(PASSWORD_VALIDATOR, passwordValidator);
    this.fieldNovaSenhaConfirm.addLocalValidator(CONFIRMATION_FIELD_VALID, (value) => {
      const confirmationValue = this.formAltSenha.getRecord()['novaSenha'] || '';
      return confirmationFieldValidator(value, confirmationValue);
    });
    this.fieldNovaSenha.addLocalValidator(SENHA_NOVA_DIFERENTE_ATUAL, (value) => {
      const senhaAtual = this.formAltSenha.getRecord()['senhaAtual'] || '';
      return senhaNovaDiferenteDaAtual(value, senhaAtual);
    });
    this.buttonAltSenha.setEnabled(false);
  }

  @OnChange({ target: ['form-alt-senha'] })
  onChangeFormAltSenha({ value }: ChangeEvent<Record<string, Value>>): void {
    this.buttonAltSenha.setEnabled(this.isDataValid(value));
  }

  isDataValid(value: Record<string, Value>): boolean {
    return this.fieldNovaSenha.isValid() &&
      this.fieldSenhaAtual.isValid() &&
      this.fieldNovaSenhaConfirm.isValid() &&
      Boolean(value['senhaAtual']) &&
      Boolean(value['novaSenha']) &&
      Boolean(value['novaSenhaConfirm']) &&
      (value['novaSenha'] === value['novaSenhaConfirm']);
  }

  @OnClick({ target: ['button-alt-senha'] })
  onClickAltSenha(): void {
    this.panelLoader.setVisible(true);
    this.buttonAltSenha.setEnabled(false);
    this.formAltSenha.setEditing(false);

    if (this.context.get("username")) {
      this.renovarSenha();
    } else {
      this.alterarSenha();
    }
  }

  alterarSenha(): void {
    const body = this.formAltSenha.getRecord();
    const nrSeqBeneficiario = this.getNrSeqBeneficiario();
    HttpService.post(Endpoint.POST_ALTERAR_SENHA, { nrSeqBeneficiario }, { body })
      .then(resp => {
        this.handleSuccess(resp);
      }).catch(resp => {
        this.handleFailure(resp);
      });
  }

  renovarSenha(): void {
    const body = this.formAltSenha.getRecord();
    body.login = this.context.get('username');
    HttpService.post(Endpoint.POST_RENOVAR_SENHA, {}, { body })
      .then(resp => {
        this.handleSuccess(resp);
      }).catch(resp => {
        this.handleFailure(resp);
      });
  }

  handleSuccess(resp: RequestResponse<unknown>): void {
    this.panelLoader.setVisible(false);
    this.handleResponse(resp);
  }

  handleFailure(resp: RequestResponse<unknown>): void {
    this.formAltSenha.setEditing(true);
    this.panelLoader.setVisible(false);
    this.buttonAltSenha.setEnabled(this.isDataValid(this.form.getRecord()));
    showToastMessage((resp.body as Response), this.toastGeneralMessage, this.getRepeaterApi);
  }

  getRepeaterApi = (): RepeaterAPI => {
    return this.repeater;
  }

  @OnClose({ target: ['modal-alt-senha'] })
  onclose(): void {
    if (this.isAlteracaoPeloLogin()) {
      AuthenticationService.logout();

      this.historyService.push(Route.LOGIN);

      return;
    }

    this.historyService.back();
  }

  @OnUnmount({ target: ['modal-alt-senha'] })
  onModalUnMount(): void {
    if (this.isAlteracaoPeloLogin() && !this.alteracaoCompleta) {
      AuthenticationService.logout();
    }
  }

  handleResponse(resp: RequestResponse<unknown>): void {
    this.alteracaoCompleta = true;
    showToastMessage((resp.body as Response), this.toastGeneralMessage, this.getRepeaterApi);
    this.closeModalTimer();
  }

  closeModalTimer(): void {
    pollingService.createPolling('close-modal-alterar-senha', this.closeAltSenhaModal, 5000);
  }

  closeAltSenhaModal = () => {
    if (this.isAlteracaoPeloLogin()) {
      AuthenticationService.requestRefreshToken()
        .then(() => {
          const nrSeqBeneficiario = this.getNrSeqBeneficiario();
          this.historyService.push(Route.DASHBOARD, { nrSeqBeneficiario, beneficiaryId: nrSeqBeneficiario });
        });
    } else {
      this.historyService.back();
    }
    pollingService.removePolling('close-modal-alterar-senha');
  }

  getNrSeqBeneficiario(): number | string {
    let { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();

    if (!nrSeqBeneficiario) {
      nrSeqBeneficiario = this.context.get('nrSeqBeneficiario') as number;
    }

    return nrSeqBeneficiario;
  }

  deveRedirecionar(): boolean {
    return !this.historyService.getCurrentRoute().includes(Route.LOGIN) && this.deveAlterarSenha();
  }

  isAlteracaoPeloLogin(): boolean {
    return this.historyService.getCurrentRoute().includes(Route.LOGIN) && this.deveAlterarSenha();
  }

  deveAlterarSenha(): boolean {
    const token = AuthenticationService.getJWTToken();
    if (token == null) {
      return false;
    }

    return token.claims[TokenConstants.ALTERAR_SENHA] as boolean;
  }

}

export default AlterarSenha;