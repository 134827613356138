import { ContextMenu, Option } from '@philips-emr/tws-components';
import Card from '@philips-emr/tws-components/lib/components/card/card';
import Field from '@philips-emr/tws-components/lib/components/field/field';
import Text from '@philips-emr/tws-components/lib/components/text/text';
import { Alignment, Placement } from '@philips-emr/tws-constants';
import { eventListener, object } from '@philips-emr/tws-core/lib/component/prop-types';
import { HttpService } from '@philips-emr/tws-core/lib/services';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import React, { Component } from 'react';
import { Endpoint } from '../../constants/endpoints';
import './request-list-component.scss';

class RequestItemsComponent extends Component {

  static propTypes = {
    params: object,
    onDetalhesClick: eventListener
  }

  state = {
    data: null
  };

  componentDidMount() {
    const { params } = this.props;

    HttpService.get(Endpoint.REQUISICOES_GUIAS, params).then(data => this.setState({ data: data.body }));
  }

  onDetalhes(nrSeqGuia) {
    const { onDetalhesClick, params } = this.props;

    onDetalhesClick && onDetalhesClick({ value: { nrSeqGuia, nrSeqRequisicao: params.nrSeqRequisicao } });
  }

  render() {
    const { data } = this.state;

    if (!data) return null;
    return (
      <>
        {
          data.map(data => {
            data.dtAutorizacao = format('date', data.dtAutorizacao, { type: 'shortDate' });
            return (
              <Card className="c-request-list-component__card" width={12} key={data.nrSequencia} >
                <div className="c-request-list-component__wrapper">
                  <div className="c-request-list-component__card_content_left">
                    <Field width={12} data={data} entry={'nrSequencia'} label={msg('hpms-components.guia')}>
                      <Text alignment={Alignment.LEFT} />
                    </Field>
                    <Field width={12} data={data} entry={'dtAutorizacao'} label={msg('authorization.authorization-date')}>
                      <Text alignment={Alignment.LEFT} />
                    </Field>
                    <Field width={12} data={data} entry={'nmPrestador'} label={msg('hpms-components.prestador')}>
                      <Text alignment={Alignment.LEFT} />
                    </Field>
                  </div>
                  <div className="c-request-list-component__card_content_rigth">
                    <ContextMenu placement={Placement.LEFT}>
                      <Option value={msg('hpms-components.details')} onClick={() => this.onDetalhes(data.nrSequencia)} />
                    </ContextMenu>
                  </div>
                </div>
              </Card>
            )
          })
        }
      </>
    );
  }

}
export default RequestItemsComponent;
