import HpmsReportListApi, { Report } from '@philips-emr/hpms-components/src/components/hpms-report-list/hpms-report-list-api';
import { ModalAPI } from '@philips-emr/tws-components-api';
import Controller, { OnClick, OnClose, OnMount } from '@philips-emr/tws-core/lib/client';
import { ClickEvent, MountEvent } from '@philips-emr/tws-core/lib/component/types';
import { SessionStorageService } from '@philips-emr/tws-core/lib/services';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { Inject } from '@philips/odin';
import ReportService from '../../../services/report-service';
import BaseController from '../../BaseController';

@Controller({ fragment: 'hpms-reports-modal', component: 'modal-reports' })
class HpmsReportsModalController extends BaseController {

  @Inject('hpms-report-list')
  repList: HpmsReportListApi;

  params = {};

  reportService: ReportService;

  @OnMount({ target: ['modal-reports'] })
  onMount(e: MountEvent): void {
    const {
      relatorioParametros,
      nrSeqBeneficiario,
      cdGrupo,
      parametrosLista,
      endpointLista,
      titleExpression } = SessionStorageService.getItem('dados_relatorios');

    this.context.set({ nrSeqBeneficiario });

    const $this = e.target as ModalAPI;
    $this.setTitle(msg(titleExpression))

    this.params = relatorioParametros;
    this.reportService = new ReportService(cdGrupo, this, endpointLista);
    this.reportService.getRelatoriosByCdGrupo(parametrosLista)
      .then(reps => {
        this.repList.setData(reps);
      });
  }

  @OnClose({ target: ['modal-reports'] })
  onclose(): void {
    SessionStorageService.removeItem('dados_relatorios');
    this.historyService.back();
  }

  @OnClick({ target: ['hpms-report-list'] })
  onRepClick(e: ClickEvent): void {
    this.repList.setLoading(true);

    const relat = e.value as Report;
    this.reportService.gerarRelatorio(relat.cdRelatorio, relat.cdClassifRelat, this.params)
      .then(() => this.repList.setLoading(false))
      .catch(() => this.repList.setLoading(false))
  }

}
export default HpmsReportsModalController;
