import HpmsLoaderApi from "@philips-emr/hpms-components/src/api/hpms-loader-api";
import { ButtonAPI, FieldAPI, FormAPI, RecaptchaAPI, RepeaterAPI, ToastAPI } from "@philips-emr/tws-components-api";
import { Value } from '@philips-emr/tws-core';
import Controller, { OnChange, OnClick, OnClose, OnMount } from "@philips-emr/tws-core/lib/client";
import { ChangeEvent } from "@philips-emr/tws-core/lib/component/types";
import { HttpService } from "@philips-emr/tws-core/lib/services";
import { Inject } from '@philips/odin';
import { Endpoint } from "../../../constants/endpoints";
import Response from "../../../constants/message";
import { Route } from "../../../constants/routes";
import { showToastMessage } from "../../../utils/user-response-utils";
import BaseController from "../../BaseController";
import { dtNascimentoValidator, DT_NASCIMENTO_VALIDATOR } from "../../../utils/user-util";

@Controller({ fragment: 'reset-password', component: 'modal-reset-password' })
class ResetPassword extends BaseController {

  @Inject('recaptcha')
  recaptcha: RecaptchaAPI;

  @Inject('button-confirm')
  confirmBtn: ButtonAPI;

  @Inject('form-content')
  form: FormAPI;

  @Inject('field-carteira')
  fieldCarteira: FieldAPI;

  @Inject('field-dt-nascimento')
  fieldDtNascimento: FieldAPI;

  @Inject('toast-general-msg')
  toastGeneralMessage: ToastAPI;

  @Inject('repeater-toast')
  repeater: RepeaterAPI;

  @Inject('hpms-loader')
  loader: HpmsLoaderApi;

  @OnMount({ target: ['modal-reset-password'] })
  onModalMount(): void {
    this.confirmBtn.setEnabled(false);
    this.fieldDtNascimento.addLocalValidator(DT_NASCIMENTO_VALIDATOR, dtNascimentoValidator);
  }

  @OnClick({ target: ['button-confirm'] })
  onConfirmClick(): void {
    if (this.recaptcha.getValue()) {
      this.callResetPasswordEndPoint();
    }
  }

  @OnClose({ target: ['modal-reset-password'] })
  onClose(): void {
    this.historyService.back();
  }

  @OnChange({ target: ['form-content'] })
  onFormChange({ value }: ChangeEvent<Record<string, Value>>): void {
    this.confirmBtn.setEnabled(this.isDataValid(value));
  }

  isDataValid(value: Record<string, Value>): boolean {
    return Boolean(value['dtNascimento'] && value['cdCarteira'] && value['recaptcha'] && this.form.isValid());
  }

  callResetPasswordEndPoint(): void {
    const { cdCarteira, dtNascimento } = this.form.getRecord();
    const body = { cdCarteira, dtNascimento }
    this.confirmBtn.setEnabled(false);
    this.loader.setVisible(true);
    HttpService.post(Endpoint.RECUPERAR_SENHA, {}, { body })
      .then(() => {
        this.loader.setVisible(false);
        this.historyService.push(Route.RECUPERAR_SENHA_SUCESSO);
      })
      .catch(resp => {
        this.loader.setVisible(false);
        this.confirmBtn.setEnabled(this.isDataValid(this.form.getRecord()));
        const response = resp.body as Response;
        showToastMessage(response, this.toastGeneralMessage, this.getRepeaterApi);
      });
  }

  getRepeaterApi = (): RepeaterAPI => {
    return this.repeater;
  }

}

export default ResetPassword;