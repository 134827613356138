import Controller, { OnClose, OnMount } from '@philips-emr/tws-core/lib/client';
import BaseController from '../../BaseController';
import { Inject } from '@philips/odin';
import { FetchAPI } from '@philips-emr/tws-components-api';

@Controller({ fragment: 'person-data-change-details', component: 'layout-person-data-change-details' })
class ChangeRequestDetailsController extends BaseController {

  @Inject('fetch-person-data-details')
  fetchEncounterProtocolDetails: FetchAPI;

  @OnClose({ target: ['modal-data-change-details'] })
  onClose(): void {
    this.historyService.back();
  }
  @OnMount({ target: ['modal-data-change-details'] })
  onMount(): void {
    const { nrSeqBeneficiario, nrSeqProtocolo} = this.historyService.getCurrentRouteParams();
    this.context.set({ nrSeqBeneficiario, nrSeqProtocolo});
    this.fetchEncounterProtocolDetails.forceFetch();
  }


}
export default ChangeRequestDetailsController;