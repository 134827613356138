import Card from '@philips-emr/tws-components/lib/components/card/card';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { eventListener, string } from '@philips-emr/tws-core/lib/component/prop-types';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import { trigger } from '@philips-emr/tws-utils';
import React, { PureComponent } from 'react';
import { ReactComponent as CancelSVG } from '../../assets/images/global/hpms-cancel.svg';
import { ReactComponent as CheckSVG } from '../../assets/images/global/hpms-check.svg';
import { ReactComponent as WarningSVG } from '../../assets/images/global/hpms-warning.svg';
import './hpms-authorization-card.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY })
class HpmsAuthorizationCard extends PureComponent {

  static propTypes = {
    onClick: eventListener,
    ieTipoGuia: string,
    cdGuia: string,
    dtSolicitacao: string,
    ieStatus: string
  }

  renderHeader() {
    const { ieTipoGuia } = this.props;
    return (
      <div className='c-hpms-authorization-card__header'>{msg('authorization.form-type.' + ieTipoGuia)}</div>
    );
  }

  renderContent() {
    const { cdGuia } = this.props;
    return (
      <div className='c-hpms-authorization-card__content'>{cdGuia}</div>
    );
  }

  renderFooter() {
    const { dtSolicitacao, ieStatus } = this.props;
    return (
      <div className='c-hpms-authorization-card__footer'>
        <div className='c-hpms-authorization-card__footer-left'>{this.getSvg()} {msg('authorization.form-status.' + ieStatus)}</div>
        <div className='c-hpms-authorization-card__footer-right'>{format('date', dtSolicitacao)}</div>
      </div>
    );
  }

  render() {
    const { onClick } = this.props;
    return (
      <Card width="12" className='c-hpms-authorization-card' onClick={trigger(onClick)}>
        <div className={`c-hpms-authorization-card__wrapper c-hpms-authorization-card__border-${this.getColor()}`}>
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderFooter()}
        </div>
      </Card>
    );
  }

  getColor() {
    const { ieStatus } = this.props;
    switch (ieStatus) {
      case '1':
        return 'green';
      case '2':
        return 'orange';
      default:
        return 'red';
    }
  }

  getSvg() {
    const { ieStatus } = this.props;
    switch (ieStatus) {
      case '1':
        return <CheckSVG className={`c-hpms-authorization-card__color-${this.getColor()}`} />;
      case '2':
        return <WarningSVG className={`c-hpms-authorization-card__color-${this.getColor()}`} />;
      default:
        return <CancelSVG className={`c-hpms-authorization-card__color-${this.getColor()}`} />;
    }
  }
}

export default HpmsAuthorizationCard;