import './beneficiary-controller';
import './acessibilidade-controller';
import './assign-term-uso-modal/assign-term-uso-modal-controller';
import './notificacao/notificacao-controller';
import './notificacao/lista-notificacao-modal-controller';
import './mensagem/lista-mensagem-modal-controller';
import './meus-dados/meus-dados';
import './meus-dados/alterar-senha';
import './meus-dados/termos-uso-usuario-controller';
import './cartao/cartao-solicitar-controller';
import './cartao/cartao-confirmar-controller';
import './cartao/cartao-consultar-controller';
import './extrato-utilizacao-modal-controller';
import './cancelamento/cancelamentos-steps-controller';
import './cancelamento/cancelamentos-steps-modal-controller';
import './assign-aviso-de-privacidade-modal/assign-aviso-de-privacidade-modal-controller';
import './consentimentos/history-consents-list-controller';
import './consentimentos/consentimento-aditivo-contratual-detalhe-controller';
import './chamado/novo-chamado-controller';
import './chamado/lista-chamado-controller';
import './chamado/detalhe-chamado-controller';
