import { FetchAPI, ModalAPI } from '@philips-emr/tws-components-api';
import Controller, { OnAfterFetch, OnClose, OnMount } from '@philips-emr/tws-core/lib/client';
import { FetchEvent } from '@philips-emr/tws-core/lib/component/types';
import { Inject } from '@philips/odin';
import { Route } from '../../../constants/routes';
import BaseController from '../../BaseController';

interface Value {
  nmTermo: string;
}

@Controller({ fragment: 'termo-responsabilidade-modal', component: 'layout-modal-termo' })
class TermoResponsabilidadeModalController extends BaseController {

  @Inject('fetch-termo')
  fetchTermo: FetchAPI;

  @Inject('modal-termo-responsabilidade')
  modalTermoResponsabilidade: ModalAPI;

  @OnClose({ target: ['modal-termo-responsabilidade'] })
  onClose(): void {
    const nrSeqBeneficiario = this.context.get('nrSeqBeneficiario') as string;
    const cdPessoaFisica = this.context.get('cdPessoaFisica') as string;
    this.historyService.push(Route.PERSON_DATA_DETAILS, { nrSeqBeneficiario, cdPessoaFisica }, { scrollToTop: false });
  }

  @OnMount({ target: ['modal-termo-responsabilidade'] })
  onMount(): void {
    const { nrSeqTermo } = this.historyService.getCurrentRouteParams();
    this.context.set({ nrSeqTermo });
    this.fetchTermo.forceFetch();
  }

  @OnAfterFetch({ target: ['fetch-termo'] })
  onAfterFetch(response: FetchEvent): void {
    const data = response.data as Value;
    if (data) {
      this.modalTermoResponsabilidade.setTitle(data.nmTermo);
    }
  }
}

export default TermoResponsabilidadeModalController;