class Polling {
  private timerHandlerID: number;
  private functionHandler: Function;
  private timeout: number;

  constructor(
    functionHandler: Function,
    timeout: number = 0
  ) {
    this.functionHandler = functionHandler;
    this.timeout = timeout;
    this.startPolling();
  }

  public startPolling(): void {
    this.timerHandlerID = window.setInterval(() => this.functionHandler(), this.timeout);
  }

  public stopPolling(): void {
    clearInterval(this.timerHandlerID);
  }

  public getTimeout(): number {
    return this.timeout;
  }

  public setTimeout(timeout: number): void {
    this.timeout = timeout;
  }
}

export default Polling;