import { ComponentAPI, AwesomeEvent } from '@philips-emr/tws-core';
import { ClickEventListener } from '@philips-emr/tws-core/lib/component/types';


class HpmsTermCheckApi extends ComponentAPI {

  @AwesomeEvent
  onClickTermo = new ClickEventListener();

  @AwesomeEvent
  onClickAviso = new ClickEventListener();

  @AwesomeEvent
  onClickChamado = new ClickEventListener();


  setEnabled(enabled: boolean): void {
    this.setState({ enabled });
  }

}

export default HpmsTermCheckApi;
