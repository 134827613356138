function employerIdentificationNumberValidator(originalNumber: string): boolean {
  const number = originalNumber.replace(/[^\d]+/g, '');

  if (number == '' || number.length != 14) {
    return false;
  }
  
  const isTheSameNumber = number.split('').every(digit => digit === number[0]);
  if (isTheSameNumber) {
    return false;
  }

  const length = number.length - 2;
  const digit = number.substring(length);
  const checkDigitOne = checkDigit(length, number.substring(0, length));
  if (checkDigitOne != parseInt(digit.charAt(0))) {
    return false;
  }

  const checkDigitTwo = checkDigit(length + 1, number.substring(0, length));

  if (checkDigitTwo != parseInt(digit.charAt(1))) {
    return false;
  }

  return true;
}

function checkDigit(length: number, number: string): number {
  let sum = 0;
  let pos = length - 7;
  for (let i = length; i >= 1; i--) {
    sum += parseInt(number.charAt(length - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  return (sum % 11 < 2 ? 0 : 11 - sum % 11);
}

export default employerIdentificationNumberValidator;