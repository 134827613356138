import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { string } from '@philips-emr/tws-core/lib/component/prop-types';
import React, { PureComponent } from 'react';
import './hpms-alteracao-cadastro.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY })
class HpmsAlteracaoCadastro extends PureComponent {

  static propTypes = {
    dsMotivoRejeicao: string,
    descricao: string
  }

  renderContent() {
    const { dsMotivoRejeicao, descricao } = this.props;

    return (
      <div className='c-hpms-encounter-protocol__content'>
        <div className='c-hpms-encounter-protocol__content-data'>{dsMotivoRejeicao}</div>
        <div className='c-hpms-encounter-protocol__content-data'>{descricao}</div>
      </div>
    );
  }


  render() {
    return (
      <div className='u-grid-col-12 c-hpms-encounter-protocol'>{this.renderContent()}</div>
    );
  }
}

export default HpmsAlteracaoCadastro;