import { FieldAPI, PanelAPI, LinkAPI } from '@philips-emr/tws-components-api';
import Controller, { OnAfterFetch, OnClick, OnMount } from '@philips-emr/tws-core/lib/client';
import { FetchEvent } from '@philips-emr/tws-core/lib/component/types';
import HpmsIconApi from '@philips-emr/hpms-components/src/api/hpms-icon-api';
import { phoneNumberFormatter } from '@philips-emr/hpms-components';
import { Inject } from '@philips/odin';
import { Route } from '../../../constants/routes';
import ReportService from '../../../services/report-service';
import BaseController from '../../BaseController';

interface DadosContratuaisBeneficiario {
  nrContrato: string;
  dsSubestipulante: string;
  nrCartaoNacSus: string;
  dsEstipulante: string;
  dsPagador: string;
  nmPessoaFisica: string;
  cdUsuarioPlano: string;
  nrContratoColetivo: string;
  dsTitular: string;
  dtValidadeCarteira: string;
  dsTitularDependente: string;
  nrSeqPlano: string;
  nrSeqContrato: string;
  cdOperadoraEmpresa: string;
  dtContratacao: string;
  dtInclusaoOperadora: string;
  dtNascimento: string;
  dtLimiteUtilizacao: string;
  dsEmailAns: string;
}

interface DadosOperadora {
  dsEmailAns: string;
  nrTelefoneAns: string;
  nrDddTelefoneOperadora: string;
  nrTelefoneOperadora: string;
}
interface CompRisco extends Record<string, string | CompRiscoBenef[]> {
  compRisco: CompRiscoBenef[];
}
interface CompRiscoBenef {
  cdAnsCongenere: string;
  dsCongenere: string;
}

import './beneficiary-data-details-controller.scss';

@Controller({ fragment: 'beneficiary-data-details', component: 'beneficiary-data-layout' })
class DadosContratuaisBeneficiarioDetailsController extends BaseController {

  @Inject('compartilhamento-risco-panel')
  compartRiscoPanel: PanelAPI;

  @Inject('sub-estipulante-field')
  subEstipulanteCampo: FieldAPI;

  @Inject('titular-field')
  dsTitularCampo: FieldAPI;

  @Inject('dt_limite_utilizacao-field')
  dataLimiteUtilizacaoCampo: FieldAPI;

  @Inject('contrato-coletivo-field')
  nrContratoColetivoCampo: FieldAPI;

  @Inject('panel-relatorio')
  panelRelatorio: PanelAPI;

  @Inject('hpms-icon-ans-email')
  ansIconEmail: HpmsIconApi;

  @Inject('hpms-icon-ans-telefone')
  ansIconTelefone: HpmsIconApi;

  @Inject('hpms-icon-operadora-telefone')
  operadoraTelefoneIcon: HpmsIconApi;

  @Inject('beneficiary-website-link')
  webSiteBeneficiario: LinkAPI;

  @Inject('supervision-cores-link')
  supervisionCoresLink: LinkAPI

  reportService: ReportService;

  @OnAfterFetch({ target: ['fetch-operadora'] })
  onAfterFetchDadosOperadora(event: FetchEvent): void {
    const dadosOperadora = event.data as DadosOperadora;
    const { nrDddTelefoneOperadora, nrTelefoneOperadora } = dadosOperadora;
    this.ansIconEmail.setLabel(dadosOperadora.dsEmailAns);
    this.ansIconTelefone.setLabel(phoneNumberFormatter(dadosOperadora.nrTelefoneAns, true));
    if (nrDddTelefoneOperadora == undefined  || nrDddTelefoneOperadora == "080") {
      this.operadoraTelefoneIcon.setLabel(`${phoneNumberFormatter(`${nrDddTelefoneOperadora}${nrTelefoneOperadora}`, true)}`);
    } else {
      this.operadoraTelefoneIcon.setLabel(`${phoneNumberFormatter(`${nrDddTelefoneOperadora}${nrTelefoneOperadora}`, false)}`);
    }
    
  }


  @OnAfterFetch({ target: ['informacoes-contratuais-fetch'] })
  onAfterFetchDadosContratuais(event: FetchEvent): void {
    const dadosContratuaisBeneficiario = event.data as DadosContratuaisBeneficiario;

    if (dadosContratuaisBeneficiario.dsSubestipulante) {
      this.subEstipulanteCampo.setVisible(true);
    }

    if (dadosContratuaisBeneficiario.dtLimiteUtilizacao) {
      this.dataLimiteUtilizacaoCampo.setVisible(true);
    }

    if (dadosContratuaisBeneficiario.dsTitular) {
      this.dsTitularCampo.setVisible(true);
    }

    if (dadosContratuaisBeneficiario.nrContratoColetivo) {
      this.nrContratoColetivoCampo.setVisible(true);
    }
  }

  @OnAfterFetch({ target: ['compartilhamento-risco-fetch'] })
  onAfterFetchCompartilhamentoRisco(event: FetchEvent): void {
    const compRisco = event.data as CompRisco;
    if (Number(compRisco.length) == 0) {
      this.compartRiscoPanel.setVisible(false);
    }
  }

  @OnMount({ target: ['beneficiary-data-layout'] })
  onMount(): void {
    this.reportService = new ReportService('4', this);
    this.reportService.getRelatoriosByCdGrupo()
      .then(reps => {
        this.panelRelatorio.setVisible(reps.length > 0);
      });
  }

  @OnClick({ target: ['button-imprimir'] })
  onClickImprimir(): void {
    const rota = this.historyService.getCurrentRoute().concat(Route.RELATORIOS).replace(/\/\//g, '/');
    this.reportService && this.reportService.abreListaRelatorios({ }, rota);
  }

  @OnClick({ target: ['beneficiary-website-link'] })
  onClickBeneficiaryWebsiteLink(): void {
    this.openExternalLink(this.webSiteBeneficiario);
  }

  @OnClick({ target: ['supervision-cores-link'] })
  onClickSupervisionCoresLink(): void {
    this.openExternalLink(this.supervisionCoresLink);
  }

  openExternalLink(link: LinkAPI): void {
    window.open(link.getValue());
  }
}

export default DadosContratuaisBeneficiarioDetailsController;
