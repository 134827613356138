import { FormAPI, PanelAPI } from '@philips-emr/tws-components-api';
import Controller, { OnClick, OnMount } from '@philips-emr/tws-core/lib/client';
import { HttpService } from '@philips-emr/tws-core/lib/services';
import { Inject } from '@philips/odin';
import { Route } from '../../../constants/routes';
import { getTelefoneCompleto } from '../../../utils/telefone-utils';
import BaseController from '../../BaseController';

interface Data {
  [key: string]: string | number | object | undefined;
  nrDddTelefone: string | undefined;
  nrDdiTelefone: string | undefined;
  nrTelefone: string | undefined;
  nrTelefoneCelular: string | undefined;
  nrDdiCelular: string | undefined;
  nrDddCelular: string | undefined;
}

@Controller({ fragment: 'meus-dados', component: 'layout-meus-dados' })
class MeusDados extends BaseController {

  @Inject('form-meus-dados')
  form: FormAPI;

  @Inject('panel-telefone')
  panelTelefone: PanelAPI;

  @Inject('panel-celular')
  panelCelular: PanelAPI;

  @OnClick({ target: ['button-alt-senha'] })
  onClickAltSenha(): void {
    this.historyService.push(Route.ALTERAR_SENHA);
  }

  @OnMount({ target: ['form-meus-dados'] })
  onFormMount(): void {
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    const cdPessoaFisica = this.context.get('cdPessoaFisica');
    HttpService.get('/beneficiary-service/resources/pessoas/:cdPessoaFisica/beneficiarios/:nrSeqBeneficiario/detalhes', { cdPessoaFisica, nrSeqBeneficiario })
      .then(resp => {
        const body = resp.body as Data;
        const telefone = getTelefoneCompleto(body.nrDdiTelefone, body.nrDddTelefone, body.nrTelefone);
        const celular = getTelefoneCompleto(body.nrDdiCelular, body.nrDddTelefone, body.nrTelefoneCelular);
        this.panelTelefone.setVisible(!!telefone);
        this.panelCelular.setVisible(!!celular);
        const data = { ...body, telefone, celular };
        this.form.setData(data);
      })
  }

}

export default MeusDados;