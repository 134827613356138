import Controller from '@philips-emr/tws-core/lib/client';
import BaseController from '../../BaseController';



@Controller({ fragment: 'lista-termos-de-uso', component: 'lista-termos-de-uso-layout' })
class ListaTermosDeUsoController extends BaseController {

  



}

export default ListaTermosDeUsoController;