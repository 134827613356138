import React from 'react';
import { HPMSExpansibleRow } from '@philips-emr/hpms-components';
import Controller, { OnDynamicContentRender, OnMount } from '@philips-emr/tws-core/lib/client';
import { HttpService, SessionStorageService } from "@philips-emr/tws-core/lib/services";
import { MountEvent, RenderEvent } from '@philips-emr/tws-core/lib/component/types';
import BaseController from '../../BaseController';
import { Endpoint } from '../../../constants/endpoints';
import { Route } from '../../../constants/routes';
import { Link } from '@philips-emr/tws-components';
import { TipoAnexoTiss } from '../anexos-tiss/anexos-tiss-controller';
import { Inject } from '@philips/odin';
import { DataTableAPI, DataTableColumnAPI, PanelAPI } from '@philips-emr/tws-components-api';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';

interface RequisicaoItens {
  nrSeqItem: number;
  ieTipoAnexo: TipoAnexoTiss;
  ieTipoItem: string;
  ieTipoGuia: string;
}

const GUIA_DE_TRATAMENTO_ODONTOLOGICO = '11';

@Controller({ fragment: 'request-items', component: 'panel-request-items' })
class RequestItemsProceduresController extends BaseController {

  @Inject('panel-itens')
  panelItens: PanelAPI;
  @Inject('panel-itens-odonto')
  panelItensOdonto: PanelAPI;
  @Inject('requestItemsDataTable')
  requestItemsDataTable: DataTableAPI;
  @Inject('requestItemsDataTableOdonto')
  requestItemsDataTableOdonto: DataTableAPI;

  @OnMount({ target: ['panel-request-items'] })
  onMountItens(): void {
    const { nrSeqBeneficiario, nrSeqRequisicao } = this.historyService.getCurrentRouteParams();
    this.context.set({ nrSeqBeneficiario, nrSeqRequisicao });
    HttpService.get(Endpoint.GET_ITENS_REQUISICAO, { nrSeqBeneficiario, nrSeqRequisicao })
      .then(({ body }) => {
        if (Array.isArray(body)) {
          const data = body;
          const { ieTipoGuia } = body[0] as RequisicaoItens;
          if (ieTipoGuia === GUIA_DE_TRATAMENTO_ODONTOLOGICO) {
            this.panelItensOdonto.setVisible(true);
            this.panelItens.setVisible(false);
            this.requestItemsDataTableOdonto.setData(data);
            return;
          }
          this.panelItens.setVisible(true);
          this.requestItemsDataTable.setData(data);
          this.panelItensOdonto.setVisible(false);
        }
      });
  }

  @OnMount({ target: ['data-table-column-odonto'] })
  onMountColunaOdonto(event: MountEvent): void {
    const api = event.target as DataTableColumnAPI;
    api.setValue(`${msg('authorization.Region')} / ${msg('authorization.Tooth')} / ${msg('authorization.Tooth-faces')}`);
  }

  @OnDynamicContentRender({ target: ['requestItemsDataTable', 'requestItemsDataTableOdonto'] })
  onRender(event: RenderEvent): void {
    const { entry, data, render, type } = event;
    const { nrSeqRequisicao, nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    const { nrSeqItem, ieTipoItem, ieTipoGuia } = data as RequisicaoItens;

    const registro = data as RequisicaoItens;
    const param = { nrSeqRequisicao, nrSeqItem, nrSeqBeneficiario, ieTipoItem, ieTipoGuia };

    const divStyle = {
      transition: '0.10s ease-in-out',
      cursor: 'pointer'
    };

    if (type == 'row') {
      const renderRowContent: Function = () => {

        const endpoint = Endpoint.GET_GLOSAS_OCORRENCIAS_ITEMS_REQUISICOES_AUTORIZACAO;
        return <HPMSExpansibleRow endpoint={endpoint} params={param}></HPMSExpansibleRow>
      }
      render(renderRowContent);
    }

    if (entry === 'ieTipoAnexo') {
      const { ieTipoAnexo } = data as RequisicaoItens;

      const renderIcon: Function = () => {
        return (
          [TipoAnexoTiss.OPME , TipoAnexoTiss.QUIMIOTERAPIA].includes(ieTipoAnexo) ?
            <Link icon="/static/assets/view-details.svg" value='' className='hpms-custom-icon-size' onClick={() => this.onIconClick(nrSeqItem, ieTipoAnexo)} /> :
            <div></div>
        );
      }

      render(renderIcon);
    } else if (entry === 'expandColumn') {
      const renderLink: Function = () => {
        return (
          <div onClick={(e) => this.onExpandClick(e.target as HTMLDivElement)} className="c-menu-option__arrow" style={divStyle}></div>
        );
      }

      render(renderLink);
    } else if (entry === 'detalhesMobile') {
      render(() => <Link icon="/static/assets/view-details.svg" value=' ' className='hpms-custom-icon-size' onClick={() => this.onDetalhesClick(nrSeqItem, registro, param)} />);
    }
  }

  onIconClick = (seq: number, tipo: string) => {
    let currentRoute = this.historyService.getCurrentRoute();
    if (currentRoute.endsWith("/")) {
      currentRoute = currentRoute.slice(0, -1);
    }

    if (tipo == TipoAnexoTiss.QUIMIOTERAPIA) {
      this.historyService.push(currentRoute.concat(Route.TISS_MATERIAL_QUIMIOTERAPIA), { nrSeqMat: seq });
    } else if (tipo == TipoAnexoTiss.OPME) {
      this.historyService.push(currentRoute.concat(Route.TISS_MATERIAL_OPME), { nrSeqMat: seq });
    }
  }

  onExpandClick = (dv: HTMLDivElement) => {
    if (dv.style.transform) {
      dv.style.transform = '';
    } else {
      dv.style.transform = 'rotate(90deg)';
    }
  }

  onDetalhesClick = (seq: number, registro: RequisicaoItens, param: {}) => {
    SessionStorageService.setItem('requisicao_autorizacao_item_detalhe', {
      data: registro,
      endpoint: Endpoint.GET_GLOSAS_OCORRENCIAS_ITEMS_REQUISICOES_AUTORIZACAO,
      parametro: param
    });

    let currentRoute = this.historyService.getCurrentRoute();
    if (currentRoute.endsWith("/")) {
      currentRoute = currentRoute.slice(0, -1);
    }

    this.historyService.push(currentRoute.concat(Route.AUTORIZACAO_ITEM_DETALHE), { nrSeqItem: seq }, { scrollToTop: false });
  }
}

export default RequestItemsProceduresController;