import Controller, { OnClose, OnMount } from '@philips-emr/tws-core/lib/client';
import { FetchAPI } from '@philips-emr/tws-components-api';
import { SessionStorageService } from '@philips-emr/tws-core/lib/services';
import BaseController from '../../BaseController';
import { Inject } from '@philips/odin';
import HpmsAditivoServiceApi from '@philips-emr/hpms-components/src/api/hpms-aditivo-service-api';
import EventListener from '@philips-emr/tws-core/lib/client/event-listener';
import { AuthenticationService, HttpService } from '@philips-emr/tws-core/lib/services';
import { Route } from "../../../constants/routes";
import { Endpoint } from './../../../constants/endpoints';

interface AditivoContratual {
  nrSeqSegTermo: number;
  nrSeqSegurado:  number;
  ieObrigatorio: string;
  termo: string;
  tipoConfirmacao: string;
}
@Controller({ fragment: 'aditivo-contratual-detalhe', component: 'modal-aditivo-contratual-detalhe' })
class AditivoContratualController extends BaseController {

  @Inject('fetch-hpms-term-uso')
  fetchTermoUso: FetchAPI;

  @Inject('hpms-aditivo-service')
  checkTerm: HpmsAditivoServiceApi;

  @Inject('fetch-aditivo-detalhe')
  fetchAditivoDetalhe: FetchAPI;
  
  state = {
    onSubmitAditivo: false,
    listAditivo: []
  }

  @OnMount({ target: 'modal-aditivo-contratual-detalhe' })
  onMountModalAditivoContratualDetalhe(): void {
    this.context.set({ isClose : false});
    this.context.set({ listAditivos : false});
    const { nrSeqTermo, nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    this.context.set({ nrSeqTermo, nrSeqBeneficiario });
  }

  @OnClose({target: 'modal-aditivo-contratual-detalhe' })
  onClose(): void {

    if (this.checkTerm.isObrigatorio() && this.state.onSubmitAditivo) {

      SessionStorageService.setItem('resetFetch', {
        reset: true
      });
      AuthenticationService.logout().then(() => {
        this.historyService.push(Route.LOGIN);
      });
    } 
    
    if (this.checkTerm.isObrigatorio() && !this.state.onSubmitAditivo) {

      SessionStorageService.setItem('resetFetch', {
        reset: true
      });
      AuthenticationService.logout().then(() => {
        this.historyService.push(Route.LOGIN);
      });
    }
    
    if (!this.checkTerm.isObrigatorio() && this.state.onSubmitAditivo) {
      this.checkExistAditivos()
    }

    if (!this.checkTerm.isObrigatorio() && !this.state.onSubmitAditivo) {
      this.checkExistAditivos()
    }
  }

  //@ts-ignore
  @EventListener({ type: 'onSubmitAditivoReq', target: ['hpms-aditivo-service']})
  onSubmitAditivoReq(): void {
    const { nrSeqBeneficiario, nrSeqTermo } = this.historyService.getCurrentRouteParams();
    const checkData = this.checkTerm.getData() as AditivoContratual;
    const data = {
      nrSeqSegurado : checkData.nrSeqSegurado,
      nrSeqTermo : checkData.nrSeqSegTermo,
      ieStatus : 'C',
      nrSeqSeguradoAceite :  nrSeqBeneficiario
    }

    if (nrSeqTermo !== 'undefined') {
      HttpService.post(Endpoint.CHECK_ADITIVO_CONTRATUAL, { nrSeqBeneficiario }, { body: data }).then(() => {
        this.state.onSubmitAditivo = true;
        this.checkExistAditivos();
        
      });
    }
  }

  //@ts-ignore
  @EventListener({ type: 'onSubmitAditivo', target: ['hpms-aditivo-service']})
  onSubmitAditivo(): void {
    const { nrSeqBeneficiario, nrSeqTermo } = this.historyService.getCurrentRouteParams();
    const checkData = this.checkTerm.getData() as AditivoContratual;

    const data = {
      nrSeqSegurado : checkData.nrSeqSegurado,
      nrSeqTermo : checkData.nrSeqSegTermo,
      ieStatus : 'C',
      nrSeqSeguradoAceite :  nrSeqBeneficiario
    }

    if (nrSeqTermo !== 'undefined') {
      HttpService.post(Endpoint.CHECK_ADITIVO_CONTRATUAL, { nrSeqBeneficiario }, { body: data }).then(() => {
        this.state.onSubmitAditivo = true;
        this.checkExistAditivos();
        
      });
    }
  }
  
  //@ts-ignore
  @EventListener({ type: 'onCancelAditivo', target: ['hpms-aditivo-service']})
  onCancelAditivo(): void {
    const { nrSeqBeneficiario, nrSeqTermo } = this.historyService.getCurrentRouteParams();
    const checkData = this.checkTerm.getData() as AditivoContratual;
    const data = {
      nrSeqSegurado : checkData.nrSeqSegurado,
      nrSeqTermo : checkData.nrSeqSegTermo,
      ieStatus : 'R',
      nrSeqSeguradoAceite :  nrSeqBeneficiario
    }

    if (nrSeqTermo !== 'undefined') {
      HttpService.post(Endpoint.CHECK_ADITIVO_CONTRATUAL, { nrSeqBeneficiario }, { body: data }).then(() => {
        this.state.onSubmitAditivo = true;
        this.checkExistAditivos();
      });
    }
  }

  checkExistAditivos(): void {
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    const value = this.context.get('listAditivos') as [AditivoContratual];
    const isClose = this.context.get('isClose') as [AditivoContratual];
    if(!value && !isClose) {
      HttpService.get(Endpoint.ADITIVO_CONTRATUAL, {nrSeqBeneficiario}).then(respAviso => {
        const array = (respAviso.body as [AditivoContratual])
        if (array.length > 0) {
          const listNrSeqTermo = array;
          const condition = this.context.get('listAditivos') as boolean;
          if (condition) {
            listNrSeqTermo.splice(0, 1);
          } 
          this.scrollToModalTop();
          this.checkTerm.setData(listNrSeqTermo[0]);
          this.state.onSubmitAditivo = false;
          listNrSeqTermo.splice(0, 1);
          var lists = listNrSeqTermo;
          this.context.set({listNrSeqTermo: lists});
          this.context.set({ listAditivos : lists as [AditivoContratual]});
        } else {
          this.checkTerm.setFirstFetch(true);
          this.context.set({ listNrSeqTermo : ''});
          SessionStorageService.setItem('resetFetch', {
            reset: false
          });
          this.historyService.push(Route.DASHBOARD);
        }
      });
    } else {
      if (value.length > 1  && value.length > 0 && !isClose) {
        const listNrSeqTermo = value;
        this.scrollToModalTop();
        this.checkTerm.setData(listNrSeqTermo[0]);
        this.state.onSubmitAditivo = false;
        listNrSeqTermo.splice(0, 1);
        var lists = listNrSeqTermo;
        this.context.set({listNrSeqTermo: lists});
        this.context.set({ listAditivos : lists as [AditivoContratual]});
      } else if (value.length == 1 && value.length > 0 && !isClose) {
        this.scrollToModalTop();
        this.checkTerm.setData(value[0]);
        this.state.onSubmitAditivo = false;

        this.context.set({ listAditivos : true});
        value.splice(0, 1);
      } else {
        this.state.onSubmitAditivo = false;
        this.checkTerm.setFirstFetch(true);
        this.context.set({ listNrSeqTermo : ''});
        SessionStorageService.setItem('resetFetch', {
          reset: false
        });
        this.historyService.push(Route.DASHBOARD);
      } 
    } 
  }

  scrollToModalTop(): void {
    let element = document.activeElement;
    while (element && element.classList && !element.classList.contains('c-modal')) {
      element = element.parentElement;
    }
    if (element) {
      element.scrollTop = 0;
    }
  }
}

export default AditivoContratualController;