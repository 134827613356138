import { FileService, HttpService } from "@philips-emr/tws-core/lib/services";
import DefaultFileHandler from "@philips-emr/tws-core/lib/services/file/default-file-handler";
import FileHandlerConfiguration from "@philips-emr/tws-core/lib/services/file/file-handler-configuration";
import { LayoutData } from "../app/types";
import { Endpoint } from "../constants/endpoints";

class ConfigurationService {

  static getConfigurationData(): Promise<LayoutData> {
    return HttpService.get(Endpoint.CONFIGURATION).then(response => response.body as LayoutData);
  }

  static setFileuploaderConfig(): void {

    const config = new FileHandlerConfiguration();
    config.uploadUrl = Endpoint.UPLOAD_URL;
    config.previewUrl = Endpoint.PREVIEW_URL;
    config.removeUrl = Endpoint.REMOVE_URL;

    const handler = new DefaultFileHandler();
    handler.setConfiguration(config);

    FileService.setFileHandler(handler);
  }
}

export default ConfigurationService;