import Card from '@philips-emr/tws-components/lib/components/card/card';
import Link from '@philips-emr/tws-components/lib/components/link/link';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { eventListener } from '@philips-emr/tws-core/lib/component/prop-types';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import React, { PureComponent } from 'react';
import HpmsPlanInfoApi from '../../../api/hpms-plan-info-api';
import { ReactComponent as LinkWebSVG } from '../../../assets/images/global/hpms-link-web.svg';
import { ReactComponent as PrintSVG } from '../../../assets/images/global/hpms-print.svg';
import './hpms-plan-info.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: HpmsPlanInfoApi })
class HpmsPlanInfo extends PureComponent {

  static propTypes = {
    onRolProcedimentosClick: eventListener,
    onTabelaPrecoClick: eventListener,
    onExtratoUtilizacaoClick: eventListener,
    onShowMoreClick: eventListener,
  };

  onShowMore() {
    const { onShowMoreClick } = this.props;

    onShowMoreClick && onShowMoreClick();
  }

  onRolProcedimentosClick() {
    const { onRolProcedimentosClick } = this.props;

    onRolProcedimentosClick && onRolProcedimentosClick();
  }

  onTabelaPrecoClick() {
    const { onTabelaPrecoClick } = this.props;

    onTabelaPrecoClick && onTabelaPrecoClick();
  }

  onExtratoUtilizacaoClick() {
    const { onExtratoUtilizacaoClick } = this.props;

    onExtratoUtilizacaoClick && onExtratoUtilizacaoClick();
  }

  renderFooter() {
    return (
      <div className="c-hpms-plan-info__footer">
        <Link onClick={() => this.onShowMore()} value="hpms-components.show-more" />
      </div>
    );
  }

  render() {

    return (
      <Card width={4} className="c-hpms-plan-info">
        <div className="c-hpms-plan-info__card-interior">

          <div className="c-hpms-plan-info__title">{msg('hpms-components.plan-data')}</div>

          <div className="c-hpms-plan-info__item">
            <div className="c-hpms-plan-info__image svg-hpms"><LinkWebSVG /></div>
            <div className="c-hpms-plan-info__link"><Link onClick={() => this.onRolProcedimentosClick()} className="c-hpms-plan-info__item" value={msg('hpms-components.procedure-list')} /></div>
          </div>
          <div className="c-hpms-plan-info__item">
            <div className="c-hpms-plan-info__image svg-hpms"><PrintSVG /></div>
            <div className="c-hpms-plan-info__link"><Link onClick={() => this.onTabelaPrecoClick()} className="c-hpms-plan-info__item" value={msg('hpms-components.price-table')} /></div>
          </div>
          <div className="c-hpms-plan-info__item">
            <div className="c-hpms-plan-info__image svg-hpms"><PrintSVG /></div>
            <div className="c-hpms-plan-info__link"><Link onClick={() => this.onExtratoUtilizacaoClick()} className="c-hpms-plan-info__item" value={msg('hpms-components.usage-statement')} /></div>
          </div>

        </div>
        {this.renderFooter()}
      </Card>
    );
  }
}

export default HpmsPlanInfo;
