export enum Endpoint {
  CARTAO_STATUS = '/beneficiary-service/resources/cartao/:nrSeqBeneficiario/status',
  AUTHENTICATION = '/beneficiary-service/resources/authentication',
  BENEFICIARY_REQUEST_TERMINATION = '/beneficiary-service/resources/termination-request',
  CONFIGURATION = '/beneficiary-service/resources/configuracao',
  CONFIRMACAO_NOVO_USUARIO = "/beneficiary-service/resources/confirmar-usuario/:hash",
  INVOICE_MAILING_REQUEST = '/beneficiary-service/resources/invoice-mailing-requests',
  NOVO_USUARIO = '/beneficiary-service/resources/novo-usuario',
  PREV_MED_SPONTANEOUS_DEMAND = '/beneficiary-service/resources/prev-med-spontaneous-demand',
  RECUPERAR_SENHA = "/beneficiary-service/resources/recuperar-senha",
  REQUISICOES_GUIAS = '/beneficiary-service/resources/beneficiarios/:nrSeqBeneficiario/requisicao-autorizacao/:nrSeqRequisicao/guias',
  REVOKE = '/beneficiary-service/resources/logout',
  CHECK_TERMOS_USO = '/beneficiary-service/resources/termos-de-uso/check',
  TERMOS_USO = '/beneficiary-service/resources/termos-de-uso',
  AVISO_DE_PRIVACIDADE = '/beneficiary-service/resources/aviso-de-privacidade',
  ADITIVO_CONTRATUAL = '/beneficiary-service/resources/aditivo-contratual/:nrSeqBeneficiario/lista',
  CHECK_AVISO_DE_PRIVACIDADE = '/beneficiary-service/resources/aviso-de-privacidade/check',
  UPDATE_TERMOS_USO = '/beneficiary-service/resources/termos-de-uso/:nrSeqTermo',
  CHECK_ADITIVO_CONTRATUAL = '/beneficiary-service/resources/aditivo-contratual/:nrSeqBeneficiario/check',
  REFUSE_ADITIVO_CONTRATUAL = '/beneficiary-service/resources/aditivo-contratual/:nrSeqBeneficiario/recusa',
  CONFIRMAR_LEITURA_AVISO_DE_PRIVACIDADE = '/beneficiary-service/resources/aviso-de-privacidade/confirmar-leitura',
  DOWNLOAD_MENSAGEM_ANEXO = '/beneficiary-service/resources/mensagens/:nrSeqBeneficiario/download',
  DOWNLOAD_REQUISICAO_AUTORIZACAO_ANEXO = '/beneficiary-service/resources/beneficiarios/:nrSeqBeneficiario/requisicao-autorizacao/:nrSeqRequisicao/anexos/:nrSeqAnexo/download',
  DOWNLOAD_AUTORIZACAO_ANEXO = '/beneficiary-service/resources/autorizacoes/:nrSeqBeneficiario/autorizacao/:nrSeqGuia/anexos/download',
  CHECK_GUIA_TISS = '/beneficiary-service/resources/autorizacoes/:nrSeqBeneficiario/autorizacao/:nrSeqGuia/possui-tiss',
  CHECK_REQUISICAO_TISS = '/beneficiary-service/resources/beneficiarios/:nrSeqBeneficiario/requisicao-autorizacao/:nrSeqRequisicao/possui-tiss',
  UPLOAD_URL = '/beneficiary-service/resources/files/upload',
  PREVIEW_URL = '/beneficiary-service/resources/preview-files?uri=',
  REMOVE_URL = '/beneficiary-service/resources/files/remove',
  CHECK_TIPO_ANEXOS = '/beneficiary-service/resources/anexos/check-tipo-anexos',
  DOWNLOAD_CHAMADO_ANEXO = '/beneficiary-service/resources/beneficiario/:nrSeqBeneficiario/chamado/:nrSeqChamado/anexos/:nrSeqAnexo/download',

  //Requests GET
  GET_BENEFICIARY_CARD = '/beneficiary-service/resources/beneficiaries/:nrSeqBeneficiario/card',
  GET_ENCOUNTER_PROTOCOL_DETAIL_CARD = '/beneficiary-service/resources/beneficiarios/:nrSeqBeneficiario/protocolo-atendimento/:nrSeqProtocolo',
  GET_ENDERECO = '/beneficiary-service/resources/logradouro/endereco',
  GET_PERSON_BY_BENEFICIARY_ID = '/beneficiary-service/resources/usuarios/info',
  GET_PESSOA_FISICA_FOTO = '/beneficiary-service/resources/pessoa-fisica/:cdPessoaFisica/foto',
  GET_PLAN_DETAILS = '/beneficiary-service/resources/plans/:planId',
  GET_NOTIFICACAO = '/beneficiary-service/resources/notificacoes',
  GET_COUNT_MENSAGENS = '/beneficiary-service/resources/mensagens/:nrSeqBeneficiario/total-msg-nao-lidas',
  GET_DEVE_TROCAR_SENHA = '/beneficiary-service/resources/beneficiario/:nrSeqBeneficiario/regra-alteracao-senha',
  GET_ROL_PROCEDIMENTOS = '/beneficiary-service/resources/beneficiarios/:nrSeqBeneficiario/informacoes-plano/rol-procedimentos',
  GET_GLOSAS_OCORR_BY_PROCEDIMENTO = '/beneficiary-service/resources/autorizacoes/:nrSeqBeneficiario/autorizacao/:nrSeqGuia/procedimento/:nrSeqProc/glosas-ocorrencias',
  GET_GLOSAS_OCORR_BY_MATERIAL = '/beneficiary-service/resources/autorizacoes/:nrSeqBeneficiario/autorizacao/:nrSeqGuia/material/:nrSeqMat/glosas-ocorrencias',
  GET_RELATORIOS_POR_GRUPO = '/beneficiary-service/resources/beneficiarios/:nrSeqBeneficiario/grupos-relatorios/:cdGrupo/relatorios',
  GET_RELATORIOS_POR_GRUPO_GUIA = '/beneficiary-service/resources/beneficiarios/:nrSeqBeneficiario/grupos-relatorios/:cdGrupo/relatorios-guia',
  GET_GLOSAS_OCORRENCIAS_ITEMS_REQUISICOES_AUTORIZACAO = '/beneficiary-service/resources/beneficiarios/:nrSeqBeneficiario/requisicao-autorizacao/:nrSeqRequisicao/guia-glosas-ocorrencias/:nrSeqItem',
  GET_RELATORIOS_POR_GRUPO_AUTORIZACAO = '/beneficiary-service/resources/beneficiarios/:nrSeqBeneficiario/grupos-relatorios/:cdGrupo/relatorios-autorizacao',
  GET_RELATORIOS_POR_GRUPO_REQUISICAO = '/beneficiary-service/resources/beneficiarios/:nrSeqBeneficiario/grupos-relatorios/:cdGrupo/relatorios-requisicao',
  GET_MENSALIDADES = '/beneficiary-service/resources/beneficiarios/:nrSeqBeneficiario/financeiro/mensalidades',
  GET_LINKS_NFS_MENSALIDADES = '/beneficiary-service/resources/beneficiarios/:nrSeqBeneficiario/financeiro/mensalidades/:nrSeqMensalidade/notas-fiscais',
  GET_PARAMETROS_DINAMICOS = '/beneficiary-service/resources/beneficiarios/:nrSeqBeneficiario/relatorios-dinamicos/:nrSeqRelatorio',
  GET_MOTIVO_REJEICAO = '/beneficiary-service/resources/pessoas/:nrSeqBeneficiario/solicitacoes-alteracao-campo/detalhes/:nrSeqProtocolo',
  GET_INCOME_TAX = '/beneficiary-service/resources/financeiro/rendimentos/:nrSeqBeneficiario/lotes',
  GET_SITUACAO_CLINICA = '/beneficiary-service/resources/beneficiario/:nrSeqBeneficiario/:origem/:nrSeq/situacao-clinica',
  GET_ITENS_GUIA = '/beneficiary-service/resources/autorizacoes/:nrSeqBeneficiario/autorizacao/:nrSeqGuia/itens',
  GET_ITENS_REQUISICAO = '/beneficiary-service/resources/beneficiarios/:nrSeqBeneficiario/requisicao-autorizacao/:nrSeqRequisicao/itens',
  GET_DETALHE_CHAMADO = '/beneficiary-service/resources/beneficiario/:nrSeqBeneficiario/chamado/:nrSeqChamado/detalhe',

  //Requests POST
  POST_CONFIRMAR_LEITURA_NOTIFICACAO = '/beneficiary-service/resources/notificacoes/confirmar-leitura',
  POST_NOTIFICACAO_ATUALIZAR_CADASTRO = '/beneficiary-service/resources/notificacoes/notificacao-atualizar-cadastro',
  POST_NOVA_SENHA = "/beneficiary-service/resources/recuperar-senha/:hash",
  POST_SOLIC_ALT_CADASTRO = '/beneficiary-service/resources/pessoas/:cdPessoaFisica/solicitacao-alteracao-cadastral',
  POST_SOLICITAR_PARTICIPACAO = '/beneficiary-service/resources/medicina-preventiva/solicitar',
  POST_ALTERAR_SENHA = '/beneficiary-service/resources/beneficiario/:nrSeqBeneficiario/alterar-senha',
  POST_RENOVAR_SENHA = '/beneficiary-service/resources/renovar-senha',
  POST_CARTAO_CONFIRMAR = '/beneficiary-service/resources/cartao/:nrSeqBeneficiario/confirmar',
  POST_CARTAO_SOLICITAR = '/beneficiary-service/resources/cartao/:nrSeqBeneficiario/solicitar',
  POST_CONTEUDO_RELATORIO = '/beneficiary-service/resources/beneficiarios/:nrSeqBeneficiario/grupos-relatorios/:cdGrupo/relatorios/gerar',
  POST_CANCELAMENTO_CONFIRMAR = '/beneficiary-service/resources/beneficiarios/:nrSeqBeneficiario/cancelamento/confirmar',
  POST_BOLETOS_POR_EMAIL = '/beneficiary-service/resources/beneficiarios/:nrSeqBeneficiario/boletos/salvar',
  POST_ABRIR_CHAMADO = '/beneficiary-service/resources/beneficiario/:nrSeqBeneficiario/chamado',

  //Requests AUDIT
  POST_ACESSO_FUNCAO = '/beneficiary-service/resources/audit/acesso-a-funcao',
}
