import Controller, { OnClose } from '@philips-emr/tws-core/lib/client';
import BaseController from '../BaseController';

@Controller({ fragment: 'acessibilidade', component: 'layout-acessibilidade' })
class AcessibilidadeController extends BaseController {

  @OnClose({ target: ['modal-acessibilidade'] })
  onClose(): void {
    this.historyService.back();
  }
}

export default AcessibilidadeController;