import { AutocompleteAPI, FormAPI, PanelAPI } from "@philips-emr/tws-components-api";
import { Value } from "@philips-emr/tws-core";
import Controller, { OnAfterFetch, OnClick, OnMount } from "@philips-emr/tws-core/lib/client";
import { FetchEvent } from "@philips-emr/tws-core/lib/component/types";
import { HttpService } from "@philips-emr/tws-core/lib/services";
import { Inject } from '@philips/odin';
import { Endpoint } from "../../../constants/endpoints";
import { Route } from '../../../constants/routes';
import ToastService from "../../../services/toast-service";
import Utils from "../../../utils/utils";
import BaseController from '../../BaseController';

interface Retorno {
  motivos: Record<string, Value>[];
}

@Controller({ fragment: 'cartao-solicitar', component: 'layout-cartao-solicitar' })
class CartaoSolicitarController extends BaseController {

  @Inject('autocomplete-motivos')
  autocompleteMotivos: AutocompleteAPI;

  @Inject('form-solicitar')
  formSolicitar: FormAPI;

  @Inject('panel-loader')
  panelLoader: PanelAPI;

  @Inject('panel-button')
  panelButton: PanelAPI;

  @OnAfterFetch({ target: ['fetch-dados'] })
  onAfterFetch(event: FetchEvent): void {
    const data = event.data as Retorno;
    this.autocompleteMotivos.setState({ data: data.motivos });
  }

  @OnMount({ target: ['layout-cartao-solicitar'] })
  onMount(): void {
    this.checkStatus().then((route) => {
      if (route) {
        this.historyService.push(route);
      }
    });
  }

  @OnClick({ target: ['button-solicitar'] })
  onSolicitarClick(): void {
    this.checkStatus().then((route) => {
      if (route) {
        this.historyService.push(route);
      } else {
        this.solicitar();
      }
    });
  }

  solicitar(): void {
    const { valid } = this.formSolicitar.state;

    if (!valid) {
      this.formSolicitar.setState({ forceValidate: true });
      return;
    }
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();

    this.panelButton.setVisible(false);
    this.panelLoader.setVisible(true);

    HttpService.post(Endpoint.POST_CARTAO_SOLICITAR, { nrSeqBeneficiario }, { body: this.formSolicitar.getRecord() })
      .then(response => {
        const mensagem = response.body as string;
        ToastService.safety(mensagem);
        this.panelLoader.setVisible(false);
        this.formSolicitar.setEditing(false);
      })
      .catch(response => {
        Utils.catchHandler(response);
        this.panelButton.setVisible(true);
        this.panelLoader.setVisible(false);
      });
  }

  checkStatus(): Promise<Route | undefined> {
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();

    const status: Promise<Route | undefined> = HttpService.get(Endpoint.CARTAO_STATUS, { nrSeqBeneficiario }).then(response => {
      const status = response.body as string;
      switch (status) {
        case 'A':
          return Route.CARTAO_CONSULTAR;
        case 'CR':
          return Route.CARTAO_CONFIRMAR;
        default:
          return undefined;
      }
    });

    return status;
  }
}

export default CartaoSolicitarController;