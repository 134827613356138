import { FieldListAPI, FieldsetAPI, PanelAPI } from "@philips-emr/tws-components-api";
import Controller, { OnAfterFetch, OnMount } from '@philips-emr/tws-core/lib/client';
import { FetchEvent, MountEvent } from '@philips-emr/tws-core/lib/component/types';
import { Inject } from '@philips/odin';
import BaseController from '../../BaseController';
import { HttpService } from '@philips-emr/tws-core/lib/services';
import { Value } from '@philips-emr/tws-core';
import { Endpoint } from '../../../constants/endpoints';

export enum TipoAnexoTiss {
  OPME = 'OP',
  QUIMIOTERAPIA = 'QU',
  RADIOTERAPIA = 'RA',
  DIAGNOSTICO_TRATAMENTO_BENEFICIARIO = 'DT',
  DIAGNOSTICO_DOENCA_BENEFICIARIO = 'DD',
  SITUACAO_INICIAL_ODONTOLOGIA = 'SO'
}

@Controller({ fragment: 'anexos-tiss', component: 'layout-anexos-tiss' })
class AnexosTissController extends BaseController {

  @Inject('panel-quimio')
  panelQuimio: PanelAPI;

  @Inject('panel-diagnostico-quimio')
  panelDiagnosticoQuimio: PanelAPI;

  @Inject('panel-opme')
  panelOpme: PanelAPI;

  @Inject('panel-radio')
  panelRadio: PanelAPI;

  @Inject('panel-situacao-inicial')
  panelSituacaoInicial: PanelAPI;

  @Inject('panel-diagnostico-radio')
  panelDiagnosticoRadio: PanelAPI;

  @Inject('fieldset-cid-quimio')
  fieldsetCidQuimio: FieldsetAPI;

  @Inject('fieldset-cid-radio')
  fieldsetCidRadio: FieldsetAPI;

  @OnAfterFetch({ target: ['fetch-quimio-dado'] })
  onAfterFetchQuDado(event: FetchEvent): void {
    if (!event.data) {
      this.panelQuimio.setVisible(false);
    }
  }

  @OnAfterFetch({ target: ['fetch-quimio-diagnostico'] })
  onAfterFetchQuDiag(event: FetchEvent): void {
    if (!event.data) {
      this.panelDiagnosticoQuimio.setVisible(false);
    }
  }

  @OnAfterFetch({ target: ['fetch-quimio-cid'] })
  onAfterFetchQuCid(event: FetchEvent): void {
    if (!event.data) {
      this.fieldsetCidQuimio.setVisible(false);
    }
  }

  @OnAfterFetch({ target: ['fetch-opme-dado'] })
  onAfterFetchOpDado(event: FetchEvent): void {
    if (!event.data) {
      this.panelOpme.setVisible(false);
    }
  }

  @OnAfterFetch({ target: ['fetch-radio-dado'] })
  onAfterFetchRaDado(event: FetchEvent): void {
    if (!event.data) {
      this.panelRadio.setVisible(false);
    }
  }

  @OnAfterFetch({ target: ['fetch-radio-diagnostico'] })
  onAfterFetchRaDiag(event: FetchEvent): void {
    if (!event.data) {
      this.panelDiagnosticoRadio.setVisible(false);
    }
  }

  @OnAfterFetch({ target: ['fetch-radio-cid'] })
  onAfterFetchRaCid(event: FetchEvent): void {
    if (!event.data) {
      this.fieldsetCidRadio.setVisible(false);
    }
  }

  @OnAfterFetch({ target: ['fetch-situacao-inicial-dado'] })
  onAfterFetchSituacaoInicialDado(event: FetchEvent): void {
    if (!event.data) {
      this.panelSituacaoInicial.setVisible(false);
    }
  }

  @OnMount({ target: ['field-list-situacao-clinica'] })
  onMountListFieldSituacaoClinica(event: MountEvent): void {
    const api = event.target as FieldListAPI;
    const { nrSeqBeneficiario, origem, nrSeq } = this.context.getBulkData();
    void HttpService.get<Record<string, Value>[]>(Endpoint.GET_SITUACAO_CLINICA, { nrSeqBeneficiario, origem, nrSeq })
      .then(({ body }) => {
        if (body) {
          api.setValue(body);
        }
      });
  }
}

export default AnexosTissController;
