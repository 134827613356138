import { FormAPI } from '@philips-emr/tws-components-api';
import Controller, { OnBeforeSubmit } from '@philips-emr/tws-core/lib/client';
import { SubmitEvent } from '@philips-emr/tws-core/lib/component/types';
import { HttpService } from '@philips-emr/tws-core/lib/services';
import { Inject } from '@philips/odin';
import { Endpoint } from '../../constants/endpoints';
import ToastService from "../../services/toast-service";
import Utils from '../../utils/utils';
import BaseController from '../BaseController';

@Controller({ fragment: 'solicitar-medicina-preventiva', component: 'layout-solicitar-medicina-preventiva' })
class SolicitarMedicinaPreventivaController extends BaseController {

  @Inject('form-solicitar')
  formSolicitar: FormAPI;

  @OnBeforeSubmit({ target: ['form-solicitar'] })
  OnBeforeSubmit(event: SubmitEvent): void {

    const { abort } = event;
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();

    const canSubmit: Promise<boolean> = HttpService.post(Endpoint.POST_SOLICITAR_PARTICIPACAO, { nrSeqBeneficiario }, { body: event.record })
      .then(response => {
        const data = response.body as Mensagem;
        ToastService.safety(data[Object.keys(data)[0]]);
        return false;
      })
      .catch(response => {
        Utils.catchHandler(response);
        return true;
      });

    abort(canSubmit);
  }
}
export default SolicitarMedicinaPreventivaController;