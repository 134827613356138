import Card from '@philips-emr/tws-components/lib/components/card/card';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { eventListener, number, string } from '@philips-emr/tws-core/lib/component/prop-types';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import { trigger } from '@philips-emr/tws-utils';
import React, { PureComponent } from 'react';
import { ReactComponent as CancelSVG } from '../../assets/images/global/hpms-cancel.svg';
import { ReactComponent as CheckSVG } from '../../assets/images/global/hpms-check.svg';
import { ReactComponent as TimeSVG } from '../../assets/images/global/hpms-time.svg';
import './hpms-protocolo-atendimento-card.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY })
class HpmsProtocoloAtendimentoCard extends PureComponent {

  static propTypes = {
    onClick: eventListener,
    nrProtocolo: string,
    dtProtocolo: string,
    ieStatus: number
  }

  renderHeader() {
    const { dtProtocolo } = this.props;

    return (
      <div className='c-hpms-protocolo-atendimento-card__header'>
        <div className='c-hpms-protocolo-atendimento-card__header-left'>{format('date', dtProtocolo, { type: 'timestamp' })}</div>
      </div>
    );
  }

  renderContent() {
    const { nrProtocolo } = this.props;
    return (
      <div className='c-hpms-protocolo-atendimento-card_content-wrapper'>
        <div className='c-hpms-protocolo-atendimento-card__content'>{nrProtocolo}</div>
        <div className='c-label' >{msg('beneficiario.protocolo')}</div>
      </div>
    );
  }

  renderFooter() {
    const { ieStatus } = this.props;

    const msgStatus = ieStatus ? msg('beneficiario.protocolo-atendimento-status.' + ieStatus) : '';
    return (
      <div className='c-hpms-protocolo-atendimento-card__footer'>
        <div className='c-hpms-protocolo-atendimento-card__footer-left'>
          {this.getSvg()}{'\u00A0'}{msgStatus}
        </div>
      </div>
    );
  }

  render() {
    const { onClick } = this.props;
    return (
      <Card width="12" className='c-hpms-protocolo-atendimento-card' onClick={trigger(onClick)}>
        <div className={`c-hpms-protocolo-atendimento-card__wrapper c-hpms-protocolo-atendimento-card__border-${this.getColor()}`}>
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderFooter()}
        </div>
      </Card>
    );
  }

  getColor() {
    const { ieStatus } = this.props;

    switch (ieStatus) {
      case 1:
      case 2:
        return 'gray';
      case 3:
        return 'green';
      case 4:
        return 'red';
      default:
        return 'gray';
    }
  }

  getSvg() {
    const { ieStatus } = this.props;

    switch (ieStatus) {
      case 1:
        return <TimeSVG width="24" height="24" />;
      case 2:
      case 3:
        return <CheckSVG className={`c-hpms-protocolo-atendimento-card__color-${this.getColor()}`} />;
      case 4:
        return <CancelSVG className={`c-hpms-protocolo-atendimento-card__color-${this.getColor()}`} />;
      default:
        return '';
    }
  }
}

export default HpmsProtocoloAtendimentoCard;