import { ContextMenu, Text, Loader } from '@philips-emr/tws-components/lib/components';
import Card from '@philips-emr/tws-components/lib/components/card/card';
import { Placement } from '@philips-emr/tws-constants';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { eventListener, number, string, bool } from '@philips-emr/tws-core/lib/component/prop-types';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import { classNames } from '@philips-emr/tws-utils';
import React, { PureComponent } from 'react';
import { isFunction } from 'util';
import { ReactComponent as CancelSVG } from '../../assets/images/global/hpms-cancel.svg';
import { ReactComponent as CheckSVG } from '../../assets/images/global/hpms-check.svg';
import { ReactComponent as TimeSVG } from '../../assets/images/global/hpms-time.svg';
import { ReactComponent as WarningSVG } from '../../assets/images/global/hpms-warning.svg';
import HpmsExpression from '../../constants/expression';
import { getContextMenu, getRegrasContextMenu } from '../../util/monthly-fee-popover-utils';
import HpmsMonthlyFeeCardApi from '../hpms-monthlyfee-card/hpms-monthlyfee-card-api';
import './hpms-monthlyfee-card-dashboard.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: HpmsMonthlyFeeCardApi })
class HpmsMonthlyFeeCardDashboard extends PureComponent {

  static propTypes = {
    uuid: string,
    onClick: eventListener,
    onSelect: eventListener,
    width: number,
    loading: bool,
    ieVisualizaMensalidadeTotal: string,
    ieVisualizaNotaFiscal: string,
    ieGeraBoleto: string,
    nrNotaFiscal: string,
    nrSeqRegra: string,
    dsLinkNf: string,
    dsSituacao: string,
    ieSituacao: string,
    nrSeqMensalidade: number,
    vlMensalidade: number,
    vlMensalidadeTotal: number,
    vlCoparticipacao: number,
    vlCoparticipacaoTotal: number,
    nrTitulo: number,
    dtMesanoReferencia: string,
    dtVencimento: string,
    ieVencido: string,
    nmPagador: string,
    nrSeqBeneficiario: number,
  };

  static defaultProps = {
    width: 8,
  };

  state = {
    regraMensalidade: {},
  };

  obterRegraMensalidade = () => {
    const { nrSeqBeneficiario, nrSeqMensalidade, nrSeqRegra } = this.props;
    getRegrasContextMenu(nrSeqBeneficiario, nrSeqMensalidade, nrSeqRegra)
      .then(resp => {
        const regraMensalidade = resp.body;
        this.setState({ regraMensalidade });
      });
  }

  onSelect = (item) => {
    const { onSelect } = this.props;
    isFunction(onSelect) && onSelect({ value: { ...this.props, item } });
  }

  getColor() {
    const { ieSituacao, ieVisualizaMensalidadeTotal } = this.props;
    const isStatusVisible = ieVisualizaMensalidadeTotal !== 'C';
    return classNames({
      'red': this.isVencido() && ieSituacao === '1' && isStatusVisible,
      'green': ['2', '4'].indexOf(ieSituacao) >= 0 && isStatusVisible,
      'grey': ['3', '5', '6'].indexOf(ieSituacao) >= 0 || !isStatusVisible,
      'orange': !this.isVencido() && ieSituacao === '1' && isStatusVisible,
    });
  }

  onClick() {
    const { onClick } = this.props;
    isFunction(onClick) && onClick();
  }

  getValorMensalidade() {
    const { vlMensalidade, vlMensalidadeTotal, ieVisualizaMensalidadeTotal } = this.props;
    if (ieVisualizaMensalidadeTotal === 'B') return vlMensalidade;
    if (ieVisualizaMensalidadeTotal === 'T') return vlMensalidadeTotal;
  }

  getValorCoparticipacao() {
    const { vlCoparticipacao, vlCoparticipacaoTotal, ieVisualizaMensalidadeTotal } = this.props;
    if (ieVisualizaMensalidadeTotal === 'T') return vlCoparticipacaoTotal;

    return vlCoparticipacao;
  }

  getValor() {
    const { ieVisualizaMensalidadeTotal } = this.props;
    return ieVisualizaMensalidadeTotal === 'C' ? this.getValorCoparticipacao() : this.getValorMensalidade();
  }

  getWrapperColorClass() {
    return `c-hpms-monthlyfee-card-dashboard__border-${this.getColor()}`;
  }

  getMainContentColorClass() {
    const { ieVisualizaMensalidadeTotal } = this.props;
    if (ieVisualizaMensalidadeTotal !== 'C') {
      return `c-hpms-monthlyfee-card-dashboard__content-main-color-${this.getColor()}`;
    }
    return '';
  }

  getIconComponent(className, type) {
    const icons = {
      'warning': <WarningSVG className={className} />,
      'time-grey': <TimeSVG className={className} />,
      'cancel-red': <CancelSVG className={className} />,
      'check': <CheckSVG className={className} />,
    }
    return icons[type];
  }

  getIconType() {
    const { ieSituacao } = this.props;
    return classNames({
      'check': ['2', '4'].indexOf(ieSituacao) >= 0,
      'time-grey': ['3', '5', '6'].indexOf(ieSituacao) >= 0,
      'cancel-red': this.isVencido() && ieSituacao === '1',
      'warning': !this.isVencido() && ieSituacao === '1',
    });
  }

  getIcon() {
    const classPrefix = 'c-hpms-monthlyfee-card-dashboard__content-status-image-';
    let iconType = this.getIconType();
    return this.getIconComponent(classPrefix.concat(iconType), iconType);
  }

  isVencido() {
    const { ieVencido } = this.props
    return ieVencido === 'S';
  }

  getStatusExpression() {
    const { ieSituacao } = this.props;
    let statusExpression = `${HpmsExpression.MONTHLY_FEE_STATUS}.${ieSituacao === '1' && this.isVencido() ? 'late' : ieSituacao}`;
    return msg(statusExpression);
  }

  renderNotaFiscal() {
    const { ieVisualizaNotaFiscal, nrNotaFiscal } = this.props;
    if (ieVisualizaNotaFiscal === 'S') {
      return (
        <div className='c-hpms-monthlyfee-card-dashboard__content-info'>
          <div className='c-label'>{`${msg('$expression.$688160')}: `}</div>
          <Text className='c-hpms-monthlyfee-card-dashboard__content-info-value' value={nrNotaFiscal} />
        </div>
      );
    }
  }

  renderTitulo() {
    const { nrTitulo } = this.props;
    return (
      <div className='c-hpms-monthlyfee-card-dashboard__content-info'>
        <div className='c-label'>{`${msg('$expression.$737368')}: `}</div>
        <Text className='c-hpms-monthlyfee-card-dashboard__content-info-value' value={`${nrTitulo}`} />
      </div>
    );
  }

  renderPagador() {
    const { nmPagador } = this.props;
    return (
      < div className='c-hpms-monthlyfee-card-dashboard__content-info' >
        <div className='c-label'>{`${msg('$expression.$927601')}: `}</div>
        <Text className='c-hpms-monthlyfee-card-dashboard__content-info-value' value={nmPagador} />
      </div >
    );
  }

  renderValidade() {
    const { dtVencimento } = this.props;
    return (
      <div className='c-hpms-monthlyfee-card-dashboard__title-section'>
        <Text value={format('date', dtVencimento, { type: 'smallDate' })} bold={true} />
        <div className='c-label'>{msg(HpmsExpression.DUE_DATE)}</div>
      </div>
    );
  }

  renderActions() {
    const { ieSituacao, dsLinkNf } = this.props;
    const { regraMensalidade } = this.state;
    return (
      < ContextMenu onOpen={this.obterRegraMensalidade} placement={Placement.LEFT}>
        {getContextMenu(regraMensalidade, ieSituacao, dsLinkNf, this.onSelect)}
      </ContextMenu >
    );
  }

  renderHeader() {
    return (
      <div className='c-hpms-monthlyfee-card-dashboard__header-section'>
        <div className='c-hpms-monthlyfee-card-dashboard__left-header-section'>
          {this.renderValidade()}
        </div>
        <div className='c-hpms-monthlyfee-card-dashboard__right-header-section'>
          {this.renderActions()}
        </div>
      </div>
    )
  }

  renderStatus() {
    const { ieVisualizaMensalidadeTotal } = this.props;

    if (ieVisualizaMensalidadeTotal !== 'C') {
      return (
        <div className='c-hpms-monthlyfee-card-dashboard__content-secondary'>
          <div className='c-hpms-monthlyfee-card-dashboard__content-status-image'>{this.getIcon()}</div>
          <div className='c-hpms-monthlyfee-card-dashboard__content-status-text'>{this.getStatusExpression()}</div>
        </div>
      );
    }
  }

  renderInfo() {
    const { ieVisualizaMensalidadeTotal } = this.props;

    if (ieVisualizaMensalidadeTotal !== 'C') {
      return (
        <>
          {this.renderTitulo()}
          {this.renderNotaFiscal()}
        </>
      );
    }

    return this.renderPagador()
  }

  renderContent() {
    const { ieVisualizaMensalidadeTotal } = this.props;
    return (
      <div className='c-hpms-monthlyfee-card-dashboard__content-section'>
        <div className='c-hpms-monthlyfee-card-dashboard__content-valor-separator'>
          <div className='c-hpms-monthlyfee-card-dashboard__content-valor'>
            <div className={this.getMainContentColorClass()}>
              <Text value={`${format('currency', this.getValor())}`} size='lg' bold={true} />
            </div>
            {ieVisualizaMensalidadeTotal !== 'C' ? this.renderStatus() : <div className='c-label'>{msg('$expression.$286241')}</div>}
          </div>
        </div>
        <div className='c-hpms-monthlyfee-card-dashboard__content-info-separator'>
          {this.renderInfo()}
        </div>
      </div>
    );
  }

  render() {
    const { width, nrSeqMensalidade, loading } = this.props;
    const borderColorClass = this.getWrapperColorClass();

    if (!loading && !nrSeqMensalidade) {
      return null;
    }

    return (
      <Card width={width} className='c-hpms-monthlyfee-card-dashboard'>
        <div className={`c-hpms-monthlyfee-card-dashboard__wrapper ${borderColorClass}`}>
          {loading
            ? <div className='c-hpms-monthlyfee-card-dashboard__wrapper-link'><Loader size={32} centered={true} /> </ div>
            : <>
              {this.renderHeader()}
              {this.renderContent()}
            </>
          }
        </div>
      </Card>
    );
  }
}

export default HpmsMonthlyFeeCardDashboard;