import { Checkbox, Link } from '@philips-emr/tws-components/lib/components';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { bool, eventListener, string } from '@philips-emr/tws-core/lib/component/prop-types';
import { getLostProps, propagateProps } from '@philips-emr/tws-utils';
import React, { PureComponent } from 'react';
import './hpms-term-checkbox.scss';
import HpmsTermCheckApi from '../../api/hpms-term-checkbox-api';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';

@AwesomeComponent({ type: ComponentType.DATA_ENTRY, api: HpmsTermCheckApi })
class HpmsTermCheckbox extends PureComponent {

  static propTypes = {
    routeAviso: string,
    routeTermo: string,
    onChange: eventListener,
    onClickTermo: eventListener,
    onClickAviso: eventListener,
    onClickChamado: eventListener,
    enabled: bool,
    value: bool,
    chamado: bool,
  }

  getCheckbox() {
    const { value } = this.props;
    const lostProps = getLostProps(HpmsTermCheckbox, this.props);
    return propagateProps(<Checkbox label={'beneficiary.messages.i-agree-terms'} value={value} />, { ...this.props, ...lostProps });
  }

  getLinks() {
    const { routeTermo, onClickTermo, onClickAviso, chamado, onClickChamado } = this.props;
    if (chamado){
      return <Link value={'$expression.$967777'} onClick={onClickChamado} />
    }
    return (
      <>
        <Link value={'beneficiary.messages.terms-of-service'} route={routeTermo} onClick={onClickTermo} />
        {' '}{msg('beneficiary.e')}{' '}
        <Link value={'beneficiary.aviso-de-privacidade'} onClick={onClickAviso} />
      </>
    );
  }

  renderComponents() {
    return (
      <div className='c-hpms-term-checkbox__wrapper'>
        <div className='c-hpms-term-checkbox__checkbox'>
          {this.getCheckbox()}
        </div>
        <div className='c-hpms-term-checkbox__link'>
          {this.getLinks()}
        </div>
      </div>
    );
  }

  render() {
    return this.renderComponents();
  }

}

export default HpmsTermCheckbox;