import HpmsTermCheckApi from '@philips-emr/hpms-components/src/api/hpms-term-checkbox-api';
import { Text } from '@philips-emr/tws-components';
import { FieldAPI, FormAPI, InputAPI, PanelAPI, RepeaterAPI, StepAPI, StepsAPI, ToastAPI } from '@philips-emr/tws-components-api';
import { Value } from '@philips-emr/tws-core';
import Controller, { OnChange, OnClick, OnClose, OnDynamicContentRender, OnMount, OnSubmit } from '@philips-emr/tws-core/lib/client';
import { ChangeEvent, RenderEvent } from '@philips-emr/tws-core/lib/component/types';
import { HttpService } from '@philips-emr/tws-core/lib/services';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { Inject } from '@philips/odin';
import React from 'react';
import { Endpoint } from '../../../constants/endpoints';
import Response from '../../../constants/message';
import pollingService from '../../../services/polling/polling-service';
import isCpfValid from '../../../utils/cpf-utils';
import { showToastMessage } from '../../../utils/user-response-utils';
import { confirmationFieldValidator, CONFIRMATION_FIELD_VALID, emailValidator, EMAIL_VALIDATOR, passwordValidator, PASSWORD_VALIDATOR, DT_NASCIMENTO_VALIDATOR, dtNascimentoValidator } from '../../../utils/user-util';
import BaseController from '../../BaseController';
import EventListener from '@philips-emr/tws-core/lib/client/event-listener';
import { Route } from '../../../constants/routes';

interface AccountField {
  value: Value;
  fieldApi: FieldAPI;
}

interface TermoDeUso {
  nrSeqTermo: number;
}

@Controller({ fragment: 'new-user-steps', component: 'panel-modal-new-user' })
class NewUserController extends BaseController {

  @Inject('form-beneficiary-info')
  formStepOne: FormAPI;

  @Inject('form-account-info')
  formAccountInfo: FormAPI;

  @Inject('cpf-rg-number')
  cpfRgInput: InputAPI;

  @Inject('birth-date-field')
  dtNascimentoField: FieldAPI;

  @Inject('email-confirmation-field')
  emailConfirmationField: FieldAPI;

  @Inject('password-confirmation-field')
  passwordConfirmationField: FieldAPI;

  @Inject('password-field')
  passwordField: FieldAPI;

  @Inject('email-field')
  emailField: FieldAPI;

  @Inject('toast-general-msg')
  toastGeneralMessage: ToastAPI;

  @Inject('steps-new-user')
  steps: StepsAPI;

  @Inject('step-beneficiary-info')
  stepInfoBenef: StepAPI;

  @Inject('step-account-info')
  stepInfoConta: StepAPI;

  @Inject('repeater-toast')
  repeater: RepeaterAPI;

  @Inject('panel-loader')
  panelLoader: PanelAPI;

  @Inject('hpms-term-checkbox')
  checkboxTermo: HpmsTermCheckApi;

  termoDeUso: number;
  avisoDePrivacidade: number;

  @OnDynamicContentRender({ target: ['steps-new-user'] })
  onRenderResultSteps(event: RenderEvent): void {
    const { cardNumber } = this.formStepOne.getRecord();
    if (event.entry === 'step-beneficiary-info') {
      event.render(() => <Text>{`${msg('$expression.$284645')}: ${cardNumber}`}</Text>);
    }
  }

  @OnSubmit({ target: ['steps-new-user'] })
  onStepsSubmit(): void {
    const { cardNumber, cpfRgNumber, birthDate } = this.formStepOne.getRecord();
    const { email, emailConfirmation, password, passwordConfirmation, recaptcha } = this.formAccountInfo.getRecord();
    const cpf = this.isCpfValid(String(cpfRgNumber)) ? cpfRgNumber : '';
    const rg = cpf === '' ? cpfRgNumber : '';
    const body = {
      password,
      passwordConfirmation,
      email,
      emailConfirmation,
      carteira: cardNumber,
      nrSeqTermo: this.termoDeUso,
      nrSeqAvisoDePrivacidade: this.avisoDePrivacidade,
      nrRg: rg,
      nrCpf: cpf,
      dtNascimento: birthDate,
      recaptcha: recaptcha,
    };

    this.disableForms(true);
    this.panelLoader.setVisible(true);
    HttpService.post(Endpoint.NOVO_USUARIO, {}, { body })
      .then(resp => {
        this.setStepDone('step-account-info', false);
        this.panelLoader.setVisible(false);
        const response = (resp.body as Response);
        showToastMessage(response, this.toastGeneralMessage, this.getRepeaterApi);
        this.closeModalTimer();
        this.scrollToModalTop();
      }).catch((resp) => {
        this.disableForms(false);
        this.panelLoader.setVisible(false);
        this.setStepsDone();
        const response = resp.body as Response;
        showToastMessage(response, this.toastGeneralMessage, this.getRepeaterApi);
        this.scrollToModalTop();
        if (response.topic === 'INFO_BENEFICIARIO') {
          this.steps.editStep('step-beneficiary-info');
        }
      });
  }

  closeModalTimer(): void {
    pollingService.createPolling('close-modal', this.closeModal, 5000);
  }

  closeModal = () => {
    if (this.historyService.getCurrentRoute().includes("new-user")) {
      this.historyService.back();
    }
    pollingService.removePolling('close-modal');
  }

  getRepeaterApi = (): RepeaterAPI => {
    return this.repeater;
  }

  @OnChange({ target: ['form-beneficiary-info'] })
  onInfoBeneficiarioChange({ value }: ChangeEvent<Record<string, Value>>): void {
    this.setInputFormatter(value['cpfRgNumber']);
    this.setStepsDone();
  }

  @OnChange({ target: ['form-account-info'] })
  onAccountInfoChange({ value }: ChangeEvent<Record<string, Value>>): void {
    const fieldsToValidate = [
      { value: value['email'], fieldApi: this.emailConfirmationField },
      { value: value['password'], fieldApi: this.passwordConfirmationField },
    ];
    this.setFieldsVisible(fieldsToValidate);
    this.setStepsDone();
  }

  @OnClose({ target: ['modal-new-user'] })
  onClose(): void {
    this.historyService.back();
  }

  @OnClick({ target: ['link-signin'] })
  onSignInClick(): void {
    this.historyService.push('/login');
  }

  @OnMount({ target: ['form-account-info'] })
  onFormMount(): void {
    this.setTermoUso();
    this.emailConfirmationField.addLocalValidator(CONFIRMATION_FIELD_VALID, (value) => {
      const confirmationValue = this.formAccountInfo.getRecord()['email'];
      return confirmationFieldValidator(value, confirmationValue);
    });
    this.passwordConfirmationField.addLocalValidator(CONFIRMATION_FIELD_VALID, (value) => {
      const confirmationValue = this.formAccountInfo.getRecord()['password'];
      return confirmationFieldValidator(value, confirmationValue);
    });
    this.passwordField.addLocalValidator(PASSWORD_VALIDATOR, passwordValidator);
    this.emailField.addLocalValidator(EMAIL_VALIDATOR, emailValidator);
    this.dtNascimentoField.addLocalValidator(DT_NASCIMENTO_VALIDATOR, dtNascimentoValidator);
  }

  disableForms(disable: boolean): void {
    this.formStepOne.setLocked(disable);
    this.formStepOne.setEditing(!disable);
    this.formAccountInfo.setLocked(disable);
    this.formAccountInfo.setEditing(!disable);
    this.setStepDone('step-account-info', !disable);
  }

  isAccountInfoStepDone(formData: Record<string, Value>): boolean {
    return this.isConfirmationFieldValid(formData['email'], formData['emailConfirmation']) &&
      this.isConfirmationFieldValid(formData['password'], formData['passwordConfirmation']) &&
      Boolean(formData['recaptcha']) &&
      Boolean(formData['invoiceTermOfResponsibility']) &&
      Boolean(this.termoDeUso) &&
      this.passwordField.isValid() &&
      this.passwordConfirmationField.isValid();
  }

  setFieldsVisible(fields: AccountField[]): void {
    fields.forEach(field => {
      const isVisible = Boolean(field.value) && String(field.value).trim().length > 0;
      field.fieldApi.setVisible(isVisible);
    });
  }

  setStepDone(stepId: string, isDone: boolean): void {
    this.steps.setStepDone(stepId, isDone);
  }

  isCpfValid(value: string): boolean {
    return value.length == 11 ? isCpfValid(value) : false;
  }

  setInputFormatter(value: Value): void {
    if (this.isCpfValid(String(value))) {
      this.cpfRgInput.removeFormatterByProperty("value");
      this.cpfRgInput.setFormatterByProperty('value', 'person-id');
    } else {
      this.cpfRgInput.removeFormatterByProperty("value");
      this.cpfRgInput.setFormatterByProperty('value', 'alphaNumeric');
    }
  }

  isConfirmationFieldValid(value: Value, confirmationValue: Value): boolean {
    return Boolean(value) && Boolean(confirmationValue) && confirmationValue === value;
  }

  scrollToModalTop(): void {
    let element = document.activeElement;
    while (element && element.classList && !element.classList.contains('c-modal')) {
      element = element.parentElement;
    }
    if (element) {
      element.scrollTop = 0;
    }
  }

  setStepsDone(): void {
    const stepOneData = this.formStepOne.getRecord();
    const stepTwoData = this.formAccountInfo.getRecord();

    const isStepOneDone = Boolean(stepOneData['cardNumber']) &&
      Boolean(stepOneData['cpfRgNumber']) &&
      (Boolean(stepOneData['birthDate']) && String(stepOneData['birthDate']).length >= 8) &&
      this.dtNascimentoField.isValid();

    this.setStepDone('step-beneficiary-info', isStepOneDone);

    const isAccountInfoStepDone = this.isAccountInfoStepDone(stepTwoData)
    if (isStepOneDone && !isAccountInfoStepDone) {
      this.setStepDone('step-account-info', false);
    }
    this.setStepDone('step-account-info', isStepOneDone && isAccountInfoStepDone);
  }

  setTermoUso(): void {
    this.checkboxTermo.setEnabled(false);
    HttpService.get(Endpoint.TERMOS_USO).then(respTermo => {
      HttpService.get(Endpoint.AVISO_DE_PRIVACIDADE).then(respAviso => {
        this.termoDeUso = (respTermo.body as TermoDeUso).nrSeqTermo;
        this.avisoDePrivacidade = (respAviso.body as TermoDeUso).nrSeqTermo;
        this.checkboxTermo.setEnabled(!!this.termoDeUso && !!this.avisoDePrivacidade);
        this.context.set({ nrSeqTermo: this.termoDeUso, nrSeqAviso: this.avisoDePrivacidade });
      });
    });
  }

  //@ts-ignore
  @EventListener({ type: 'onClickAviso', target: ['hpms-term-checkbox'] })
  onClickAvisoCheckbox(): void {
    this.context.set({ nrSeqAviso: this.avisoDePrivacidade });
    this.historyService.push(Route.AVISO_DE_PRIVACIDADE_NOVO_USUARIO, { nrSeqAviso: this.avisoDePrivacidade });
  }

}
export default NewUserController;