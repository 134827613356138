import Image from '@philips-emr/tws-components/lib/components/image/image';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { string } from '@philips-emr/tws-core/lib/component/prop-types';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import React, { PureComponent } from 'react';
import HpmsIconApi from '../../api/hpms-icon-api';
import './hpms-icon.scss';


@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: HpmsIconApi })
class HpmsIcon extends PureComponent {

  constructor(props) {
    super(props);
  }

  static propTypes = {
    uuid: string,
    icon: string,
    alt: string,
    label: string,
    href: string,
    width: string,
  };

  static defaultProps = {
    width: '32px',
  }


  renderIcon() {
    const { icon, alt, width } = this.props;

    return (
      <div className="c-hpms-icon__icon">
        <Image src={icon} alt={alt} maxWidth={width} />
      </div>
    )
  }

  renderLabel() {
    const { label, href } = this.props;

    if (!href) {
      return (
        <div className="c-hpms-icon__label">
          {msg(label)}
        </div>
      );
    }

    return (
      <div className="c-hpms-icon__label">
        <a className="c-hpms-icon__anchor" href={href}>
          {msg(label)}
        </a>
      </div>
    );
  }


  render() {
    return (
      <div className="c-hpms-icon">
        {this.renderIcon()}
        {this.renderLabel()}
      </div>
    );
  }
}

export default HpmsIcon;
