import { registerFormatter, registerValidator } from '@philips-emr/tws-core/lib/services/model-service';
import { createAlphanumericFormatter } from '@philips-emr/tws-model/lib/generators/';
import EmployerIdentificationNumberFormatter from './employer-identification-number-formatter';
import PreventFutureDateFormatter from './prevent-future-date-formatter';
import { booleanRequired, BOOLEAN_REQUIRED, emailValidator, EMAIL_VALIDATOR } from './validator/validators';

registerFormatter("alphaNumeric", createAlphanumericFormatter({ name: 'alphaNumeric', mask: /^[a-zA-Z0-9]*$/, }));
registerFormatter('employee-identification-number', EmployerIdentificationNumberFormatter);
registerFormatter('prevent-future-date', PreventFutureDateFormatter);
registerFormatter("alphaNumericCaracters", createAlphanumericFormatter({ name: 'alphaNumericCaracters', mask: /^[a-zA-Z0-9-/]*$/, }));
registerValidator(EMAIL_VALIDATOR, emailValidator);
registerValidator(BOOLEAN_REQUIRED, booleanRequired);