import { ClickEvent } from '@philips-emr/tws-core/lib/component/types';
import EventListener from '@philips-emr/tws-core/lib/client/event-listener';
import BaseController from "../../BaseController";
import Controller, { OnMount, OnClick } from "@philips-emr/tws-core/lib/client";
import { Inject } from '@philips/odin';
import { PanelAPI, ImageAPI, TextAPI } from "@philips-emr/tws-components-api";
import ConfigurationService from "../../../services/configuration-service";
import HpmsSocialNetworksApi from '@philips-emr/hpms-components/src/api/hpms-social-networks-api';
import { AuthenticationService } from '@philips-emr/tws-core/lib/services';
import { Route } from '../../../constants/routes';
@Controller({ fragment: "footer", component: "footer-layout" })
class FooterController extends BaseController {

  private THEME_DARK = "2";

  @Inject('footer-image')
  footerImage: ImageAPI;

  @Inject('footer-panel')
  footerPanel: PanelAPI;

  @Inject('powered-philips-panel')
  poweredPhilipsPanel: PanelAPI;

  @Inject('address-text')
  addressText: TextAPI;

  @Inject('email-text')
  emailText: TextAPI;

  @Inject('phone-text')
  phoneText: TextAPI;

  @Inject('hpms-social-networks')
  hpmsSocialNetworks: HpmsSocialNetworksApi;

  @Inject('panel-privacidade')
  panelPrivacidade: PanelAPI;

  @OnMount({ target: ['footer-layout'] })
  onMount(): void {
    ConfigurationService.getConfigurationData().then(layoutData => {
      this.footerImage.setSrc(layoutData.dsUrlLogoFooter);
      this.footerPanel.setClassName('u-theme-background');

      this.footerPanel.setLightTheme(layoutData.ieFontColor === this.THEME_DARK);
      this.poweredPhilipsPanel.setLightTheme(layoutData.ieFontColor === this.THEME_DARK);

      const gradientColor = `linear-gradient(279deg, ${layoutData.dsFirstColor}, ${layoutData.dsSecondColor})`;
      this.footerPanel.setBackground(gradientColor);
      this.poweredPhilipsPanel.setBackground(gradientColor);
      this.addressText.setValue(layoutData.dsEndereco);
      this.emailText.setValue(`E-mail: ${layoutData.dsEmailRodape}`);
      this.phoneText.setValue(`Telefone: ${layoutData.nrFoneContato}`);
      this.hpmsSocialNetworks.setDark(!this.footerPanel.isLightTheme());
    });
  }

  @OnMount({ target: 'panel-privacidade' })
  onMountPanelPrivacidade(): void {
    if (AuthenticationService.isAuthenticated()) {
      this.panelPrivacidade.setVisible(true);
    }
  }

  //@ts-ignore
  @EventListener({ type: 'onSocialNetworkClick', target: ['hpms-social-networks'] })
  onSocialNetworkClick(event: ClickEvent): void {
    const link: string = event.value as string;
    window.open(link);
  }

  @OnClick({ target: 'termos-de-uso-link' })
  onClickTermosDeUso(): void {
    this.historyService.push(Route.LISTA_TERMOS_DE_USO);
  }

  @OnClick({ target: 'aviso-de-privacidade-link' })
  onClickAviso(): void {
    this.historyService.push(Route.LISTA_AVISO_DE_PRIVACIDADE);
  }

  @OnClick({ target: 'politica-de-cookies-link' })
  onClickCookie(): void {
    this.historyService.push(Route.POLITICA_DE_COOKIES);
  }

}

export default FooterController;
