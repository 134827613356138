import { ComponentAPI, AwesomeEvent } from '@philips-emr/tws-core';
import { ClickEventListener } from '@philips-emr/tws-core/lib/component/types';

class HpmsGrantorInfoApi extends ComponentAPI {
  
  @AwesomeEvent
  onAnsClick = new ClickEventListener();

  @AwesomeEvent
  onNucleoFiscClick = new ClickEventListener();

}

export default HpmsGrantorInfoApi;
