import React, { PureComponent } from "react";
import { AwesomeComponent, ComponentType } from "@philips-emr/tws-core";
import { eventListener, object, bool } from '@philips-emr/tws-core/lib/component/prop-types';

import { Button } from "@philips-emr/tws-components/lib/components";

import './hpms-aditivo-service.scss';
import HpmsAditivoServiceApi from "../../api/hpms-aditivo-service-api";
import { ButtonType, Size } from "@philips-emr/tws-constants";

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: HpmsAditivoServiceApi })
class HpmsAditivoService extends PureComponent {

  constructor(props) {
    super(props);
  }

  static DefaultProps = {
    useInDetails: false,
    data: {},
    firstFetch: false,
  }

  static propTypes = {
    data: object,
    useInDetails: bool,
    enabled: bool,
    firstFetch: bool,
    onSubmitAditivo: eventListener,
    onCancelAditivo: eventListener,
    onSubmitAditivoReq: eventListener,
  }

  state = {
    check: false,
    ieObrigatorio: null,
    nrSeqBeneficarioReturn: null,
    nrSeqTermoClose: [],
    data: [],
    firstFetch: false
  }

  getTermo = () => {
    const { data }  = this.props;
    if (data && data.nrSeqSegurado) {
      this.setState({nrSeqBeneficarioReturn: data.nrSeqSegurado})
    }
    if (data && data.nrSeqSegTermo) {
      this.setState({nrSeqTermoClose: data.nrSeqSegTermo})
    }
    
    return data ? data.termo : null;
  }

  onSubmitAditivo() {
    const { onSubmitAditivo } = this.props;

    onSubmitAditivo && onSubmitAditivo();
  }

  onSubmitAditivoReq() {
    const { onSubmitAditivoReq } = this.props;

    onSubmitAditivoReq && onSubmitAditivoReq();
  }

  onCancelAditivo() {
    const { onCancelAditivo } = this.props;
    onCancelAditivo && onCancelAditivo();
  }

  onCheck() {
    this.setState({ check: !this.state.check });
  }


  getIeObrigatorio = () => {
    const { data }  = this.props;
    if (data && data.ieObrigatorio) {
      this.setState({ieObrigatorio: data.ieObrigatorio})
    }
    return data ? data.ieObrigatorio == "S" ? true : false : null;
  }

  getTitle() {
    const { data }  = this.props;

    if (data && this.props.useInDetails) { 
      return data ? data.tipoTermo : null;
    } else {
      return data ? data.tipoConfirmacao : null;
    }
  }

  inShowDetails() {

    const { data }  = this.props;
    if (data && this.props.useInDetails && data.ieRevogar == "S" ) {
      return true;
    } else {
      return false;
    }
  }

  render() {

    return (
      <div className="c-aditivo-service">
        <h2 className="title">
          {this.getTitle()}
        </h2>
        <div className="c-hpms-aditivo-service" dangerouslySetInnerHTML={{ __html: this.getTermo() }}>
        </div>
   
        <div className="c-aditivo-service__check">
          
          {this.getIeObrigatorio() && !this.props.useInDetails &&
            (
              <div className="c-aditivo-service__check-button">
                <Button size={Size.XLARGE.value}
                  value='$expression.$596276'
                  type={ButtonType.SUBMIT.value}
                  onClick={() => this.onSubmitAditivoReq()} />
              </div>
            )
          }
          {!this.getIeObrigatorio() && !this.props.useInDetails &&
            
            (
              <div className="c-aditivo-service__check-button">
                <div className="btns">
                  <Button size={Size.XLARGE.value}
                    value='$expression.$701236'
                    theme="outline"
                    type={ButtonType.SUBMIT.value}
                    onClick={() => this.onCancelAditivo()} />
                </div>
                <div className="btns">
                  <Button size={Size.XLARGE.value}
                    value='$expression.$596276'
                    type={ButtonType.SUBMIT.value}
                    onClick={() => this.onSubmitAditivo()}/>
                </div>
              
              </div>
              
            )
          }

          {this.inShowDetails() && 
          (
            <div className="c-aditivo-service__check-button">
              <div className="btns">
                <Button size={Size.XLARGE.value}
                  value='$expression.$701236'
                  theme="outline"
                  type={ButtonType.SUBMIT.value}
                  onClick={() => this.onCancelAditivo()} />
              </div>
            
            </div>  
          )
          }
        </div>
      </div>
    );
  }
}
export default HpmsAditivoService;
