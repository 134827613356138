import Controller, { OnClose } from "@philips-emr/tws-core/lib/client";
import { Route } from "../../../constants/routes";
import BaseController from '../../BaseController';

@Controller({ fragment: 'cancelamento-steps-termo', component: 'modal-cancelamento-termo' })
class CancelamentoStepsController extends BaseController {

  @OnClose({ target: ['modal-cancelamento-termo'] })
  onclose(): void {
    this.historyService.push(Route.SOLICITAR_CANCELAMENTO);
  }
}

export default CancelamentoStepsController;