import Avatar from '@philips-emr/tws-components/lib/components/avatar/avatar';
import Card from '@philips-emr/tws-components/lib/components/card/card';
import Link from '@philips-emr/tws-components/lib/components/link/link';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { bool, eventListener, object, string, number } from '@philips-emr/tws-core/lib/component/prop-types';
import { AuthenticationService } from '@philips-emr/tws-core/lib/services';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import React, { PureComponent } from 'react';
import HpmsInfoBeneficiarioApi from '../../../api/hpms-info-beneficiario-api';
import AvatarDefault from '../../../assets/images/global/hpms-user.svg';
import call from '../../../assets/images/global/TODO-hpms-phone.png';
import { phoneNumberFormatter } from '../../../util';
import HpmsIcon from '../../hpms-icon/hpms-icon';
import './hpms-info-beneficiario.scss';
import { getBreakpoint } from '@philips-emr/tws-utils';
import { Breakpoint } from '@philips-emr/tws-constants';



@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: HpmsInfoBeneficiarioApi })
class HpmsInfoBeneficiario extends PureComponent {

  constructor(props) {
    super(props);
  }

  state = {
    loading: true,
  }

  static defaultProps = {
    foto: '',
    extend: true,
    width: 4,
  };

  static propTypes = {
    uuid: string,
    nmBeneficiario: string,
    cdUsuarioPlano: string,
    dsTitularidade: string,
    nrDddTelefone: string,
    nrDddCelular: string,
    nrTelefone: string,
    nrTelefoneCelular: string,
    nrCpf: string,
    nrIdentidade: string,
    dtNascimento: string,
    dsEmail: string,
    dsEndereco: string,
    nrEndereco: number,
    foto: string,
    extend: bool,
    dsMunicipio: object,
    onShowMoreClick: eventListener,
    width: number,
  };

  onShowMore() {
    const { onShowMoreClick } = this.props;

    onShowMoreClick && onShowMoreClick();
  }

  renderPhoneNumber() {
    const { nrDddTelefone, nrDddCelular, nrTelefone, nrTelefoneCelular } = this.props;

    const celularFormatado = nrTelefoneCelular && phoneNumberFormatter(`${nrDddCelular} ${nrTelefoneCelular}`, false) || '';
    const telefoneFormatado = nrTelefone && phoneNumberFormatter(`${nrDddTelefone}`, false) || '';
    return (
      celularFormatado ? <div className="c-hpms-info-beneficiario__item"><HpmsIcon icon={call} label={celularFormatado} href={`tel:${celularFormatado}`} /></div> :
        telefoneFormatado ? <div className="c-hpms-info-beneficiario__item"><HpmsIcon icon={call} label={telefoneFormatado} href={`tel:${telefoneFormatado}`} /></div> :
          <div className="c-hpms-info-beneficiario__item"><HpmsIcon icon={call} label={msg('$expression.$309956')} /></div>
    );
  }

  ifRenderTwoColumn() {
    const { extend, width } = this.props;
    const isMobile = getBreakpoint() === Breakpoint.SM || getBreakpoint() === Breakpoint.XSM;

    return ((extend && (AuthenticationService.getJWTToken().claims['ext_fin_mensalidade'] === 'N')) || width >= 8) && !isMobile;
  }

  resolveAddress(arrString, separator = ',') {
    const f = arrString.filter(word => !!word);
    if (!f.length) {
      return '';
    }
    return f.reduce((previous, current) => `${previous}${separator} ${current}`);
  }

  renderBody() {
    const { dsTitularidade, dtNascimento, dsEmail, nrCpf, nrIdentidade, dsEndereco, nrEndereco, dsMunicipio } = this.props;

    const ifTwoColumn = this.ifRenderTwoColumn();
    const address = this.resolveAddress([dsEndereco, nrEndereco, dsMunicipio && dsMunicipio.dsMunicipio]);
    return (
      <div className="c-hpms-info-beneficiario__card-interior-body">
        <div className="c-hpms-info-beneficiario__card-interior-body-left">
          <div className="c-hpms-info-beneficiario__item">{dsTitularidade}</div>
          <div className="c-hpms-info-beneficiario__item">{`${msg('hpms-components.data-nascimento')}: ${dtNascimento && format('date', dtNascimento, { type: 'shortDate' }) || ''}`}</div>
          <div className="c-hpms-info-beneficiario__item">{dsEmail || ''}</div>
          {this.renderPhoneNumber()}
        </div>
        {ifTwoColumn && (<div className="c-hpms-info-beneficiario__card-interior-body-rigth">
          <div className="c-hpms-info-beneficiario__item">{`${msg('hpms-components.cpf')}: ${nrCpf || ''}`}</div>
          <div className="c-hpms-info-beneficiario__item">{`${msg('hpms-components.rg')}: ${nrIdentidade || ''}`}</div>
          <div className="c-hpms-info-beneficiario__item">{`${msg('hpms-components.endereco')}: ${address}`}</div>
        </div>)
        }
      </div>

    );
  }

  renderFooter() {
    return (
      <div className="c-hpms-info-beneficiario__footer">
        <Link onClick={() => this.onShowMore()} value="hpms-components.show-more" />
      </div>
    );
  }

  render() {
    const { nmBeneficiario, cdUsuarioPlano, foto } = this.props;
    const { loading } = this.state;
    const widthCard = this.ifRenderTwoColumn() ? 8 : 4;

    return (
      <Card width={widthCard} className="c-hpms-info-beneficiario">
        <div className="c-hpms-info-beneficiario__card-interior">
          <div className="c-hpms-info-beneficiario__card-interior-header">
            <div className="c-hpms-info-beneficiario__card-interior-image">
              <Avatar title={nmBeneficiario} subtitle={cdUsuarioPlano} image={foto || AvatarDefault}></Avatar>
            </div>
          </div>

          {!loading && this.renderBody()}
        </div >
        {this.renderFooter()}
      </Card>
    );
  }
}

export default HpmsInfoBeneficiario;
