import { ToastAPI } from "@philips-emr/tws-components-api";
import { Data } from '@philips-emr/tws-components-api/lib/api/repeater/repeater-api';
import { Type } from '@philips-emr/tws-constants';
import Response from "../constants/message";
import ToastService from "../services/toast-service";

export function showToastMessage(resp: Response, toastAPI: ToastAPI, getRepeaterAPI: Function): void {
  const type = (resp.type || '').toLocaleLowerCase() as Type;
  const repeaterData: Data[] = resp.messages.map(m => {
    return { message: m, className: resp.type === 'CAUTION' ? 'beneficiary-text-black' : '' };
  });

  ToastService.showMessage(toastAPI, type, '', 5000);
  getRepeaterAPI().setData(repeaterData);
}