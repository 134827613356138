import { FetchAPI, FieldAPI, FormAPI, PanelAPI, TextAPI, ToastAPI } from '@philips-emr/tws-components-api';
import Controller, { OnAfterFetch, OnBeforeSubmit, OnCancel, OnClick, OnMount } from '@philips-emr/tws-core/lib/client';
import { FetchEvent } from '@philips-emr/tws-core/lib/component/types';
import { HttpService } from '@philips-emr/tws-core/lib/services';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { Inject } from "@philips/odin";
import { Endpoint } from '../../../constants/endpoints';
import ToastService from '../../../services/toast-service';
import Utils from '../../../utils/utils';
import BaseController from '../../BaseController';

interface InvoiceRequestObject {
  email: string;
  mensagem: string;
  nrSeqTermo: number;
}

@Controller({ fragment: 'invoice-request', component: 'invoice-request-layout' })
class InvoiceRequestController extends BaseController {

  @Inject('invoice-request-form')
  form: FormAPI;

  @Inject('panel-formulario')
  panelFormulario: PanelAPI;

  @Inject('panel-mensagem')
  panelMensagem: PanelAPI;

  @Inject('field-termo')
  fieldTermo: FieldAPI;

  @Inject('toast-yes-no')
  toastYesNo: ToastAPI;

  @Inject('invoice-request-fetch')
  invoiceRequestFetch: FetchAPI;

  @Inject('text-email')
  textEmail: TextAPI;

  data?: InvoiceRequestObject;
  isAlteracao: boolean = false;

  @OnAfterFetch({ target: 'invoice-request-fetch' })
  onAfterFetch(event: FetchEvent): void {
    this.data = event.data as InvoiceRequestObject;
    const { mensagem, nrSeqTermo } = this.data;

    this.panelFormulario.setVisible(!mensagem);
    this.panelMensagem.setVisible(!!mensagem);
    this.fieldTermo.setVisible(!!nrSeqTermo);

    this.context.set({ nrSeqTermo });
  }

  @OnMount({ target: ['form-0'] })
  onMountTextEmail(): void {
    if (this.data && !!this.data.mensagem && this.data.email) {
      this.isAlteracao = true;
      this.textEmail.setValue(`${msg('$expression.$969819')} ${this.data.email}`);
    }
  }

  @OnClick({ target: ['link-alterar-email'] })
  onClickAlterarEmail(): void {
    this.panelFormulario.setVisible(!this.panelFormulario.isVisible());
    this.panelMensagem.setVisible(!this.panelMensagem.isVisible());
    this.fieldTermo.setVisible(!this.fieldTermo.isVisible());
  }

  @OnCancel({ target: ['invoice-request-form']})
  onCancelForm(): void {
    if (this.isAlteracao) {
      this.onClickAlterarEmail();
    }
  }

  @OnBeforeSubmit({ target: 'invoice-request-form' })
  onBeforeSubmit(): void {
    this.toastYesNo.open();
  }

  @OnClick({ target: ['button-toast-no'] })
  onbuttonToastNoClick(): void {
    this.toastYesNo.close();
  }

  @OnClick({ target: ['button-toast-yes'] })
  onbuttonToastYesClick(): void {
    this.toastYesNo.close();
    this.form.setLoading(true);

    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    const body = this.form.getRecord();

    HttpService.post(Endpoint.POST_BOLETOS_POR_EMAIL, { nrSeqBeneficiario }, { body })
      .then(response => {
        this.form.setLoading(false);
        this.form.setEditing(false);
        ToastService.safety(response.body as string);
      })
      .catch(response => {
        this.form.setLoading(false);
        Utils.catchHandler(response);
      });
  }
}

export default InvoiceRequestController;
