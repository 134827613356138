import { HPMSExpansibleRow } from '@philips-emr/hpms-components';
import { Link } from '@philips-emr/tws-components';
import { DataTableAPI, DataTableColumnAPI, FetchAPI, PanelAPI } from "@philips-emr/tws-components-api";
import { Breakpoint } from '@philips-emr/tws-constants';
import Controller, { OnAfterFetch, OnDynamicContentRender, OnMount } from '@philips-emr/tws-core/lib/client';
import EventListener from '@philips-emr/tws-core/lib/client/event-listener';
import { ClickEvent, FetchEvent, MountEvent, RenderEvent } from '@philips-emr/tws-core/lib/component/types';
import { FileService, HttpService, SessionStorageService } from "@philips-emr/tws-core/lib/services";
import { HttpMethod } from "@philips-emr/tws-core/lib/services/http-service";
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { getBreakpoint } from '@philips-emr/tws-utils';
import { Inject } from '@philips/odin';
import React from "react";
import { Endpoint } from "../../../constants/endpoints";
import { Route } from '../../../constants/routes';
import ToastService from "../../../services/toast-service";
import BaseController from '../../BaseController';
import { TipoAnexoTiss } from '../anexos-tiss/anexos-tiss-controller';

interface Registro {
  ieTipoAnexo: string;
  ieTipoItem: TipoItem;
  nrSeqItem: number;
  ieTipoGuia: string;
}

enum TipoItem {
  PROCEDIMENTO = 'P',
  MATERIAL = 'M'
}

const GUIA_DE_TRATAMENTO_ODONTOLOGICO = '11';

@Controller({ fragment: 'authorization-form', component: 'authorizationFormLayout' })
class AuthorizationFormController extends BaseController {

  @Inject('panel-anexos')
  panelAnexos: PanelAPI;
  @Inject('panel-glosas-ocorrencias')
  panelGlosasOcorrencias: PanelAPI;
  @Inject('fetch-authorization-attachments')
  fetchAuthorizationAttachments: FetchAPI;
  @Inject('fetch-glosas-ocorrencias')
  fetchGlosasOcorrencias: FetchAPI;
  @Inject('panel-itens')
  panelItens: PanelAPI;
  @Inject('panel-itens-odonto')
  panelItensOdonto: PanelAPI;
  @Inject('data-table-itens')
  dataTableItens: DataTableAPI;
  @Inject('data-table-itens-odonto')
  dataTableItensOdonto: DataTableAPI;

  @OnMount({ target: ['authorizationFormLayout'] })
  onMount(): void {
    const { nrSeqBeneficiario, nrSeqGuia } = this.historyService.getCurrentRouteParams();
    this.context.set({ nrSeqBeneficiario, nrSeqGuia });
    this.fetchAuthorizationAttachments.forceFetch();
    this.fetchGlosasOcorrencias.forceFetch();
  }

  //@ts-ignore
  @EventListener({ type: 'onOpenAttachment', target: ['hpms-attachments-list'] })
  onDownloadClick(event: ClickEvent): void {
    const value = event.value;
    const { nrSeqBeneficiario, nrSeqGuia } = this.historyService.getCurrentRouteParams();
    FileService.download(Endpoint.DOWNLOAD_AUTORIZACAO_ANEXO, value.nmArquivo, { nrSeqAnexo: value.nrSequencia, nrSeqBeneficiario, nrSeqGuia }, HttpMethod.GET)
      .catch(() => ToastService.caution(msg('$expression.$508286')));
  }

  @OnAfterFetch({ target: ['fetch-authorization-attachments'] })
  onAfterFetchAA(event: FetchEvent): void {
    if (Object.keys(event.data as []).length == 0) {
      this.panelAnexos.setVisible(false);
    }
  }

  @OnAfterFetch({ target: ['fetch-glosas-ocorrencias'] })
  onAfterFetchGO(event: FetchEvent): void {
    if (Object.keys(event.data as []).length == 0) {
      this.panelGlosasOcorrencias.setVisible(false);
    }
  }

  @OnMount({ target: ['panel-detalhes-itens'] })
  onMountItens(): void {
    const { nrSeqBeneficiario, nrSeqGuia } = this.context.getBulkData();
    HttpService.get(Endpoint.GET_ITENS_GUIA, { nrSeqBeneficiario, nrSeqGuia })
      .then(({ body }) => {
        if (Array.isArray(body)) {
          const data = body[0];
          const { ieTipoGuia } = data as Registro;
          if (ieTipoGuia === GUIA_DE_TRATAMENTO_ODONTOLOGICO) {
            this.panelItensOdonto.setVisible(true);
            this.panelItens.setVisible(false);
            this.dataTableItensOdonto.setData(body);
            return;
          }
          this.panelItensOdonto.setVisible(false);
          this.panelItens.setVisible(true);
          this.dataTableItens.setData(body);
        }
      });
  }

  @OnMount({ target: ['data-table-column-odonto'] })
  onMountColunaOdonto(event: MountEvent): void {
    const api = event.target as DataTableColumnAPI;
    api.setValue(`${msg('authorization.Region')} / ${msg('authorization.Tooth')} / ${msg('authorization.Tooth-faces')}`);
  }

  @OnDynamicContentRender({ target: ['data-table-itens', 'data-table-itens-odonto'] })
  onDynamicContentRender(event: RenderEvent): void {
    const { nrSeqBeneficiario, nrSeqGuia } = this.historyService.getCurrentRouteParams();
    const { entry, render, data, type } = event;

    const registro = data as Registro;

    const { nrSeqItem, ieTipoItem, ieTipoAnexo, ieTipoGuia } = registro;
    const endpoint = ieTipoItem === TipoItem.PROCEDIMENTO && Endpoint.GET_GLOSAS_OCORR_BY_PROCEDIMENTO || ieTipoItem === TipoItem.MATERIAL && Endpoint.GET_GLOSAS_OCORR_BY_MATERIAL;
    const nrSeqProc = ieTipoItem === TipoItem.PROCEDIMENTO && nrSeqItem;
    const nrSeqMat = ieTipoItem === TipoItem.MATERIAL && nrSeqItem;
    const param = { ...(nrSeqProc && { nrSeqProc }), ...(nrSeqMat && { nrSeqMat }), ieTipoGuia };

    const divStyle = {
      transition: '0.10s ease-in-out',
      cursor: 'pointer'
    };

    if (entry === 'expandColumn') {
      render(() => <div onClick={(e) => this.onExpandClick(e.target as HTMLDivElement)} className="c-menu-option__arrow" style={divStyle}></div>);
    } else if (entry === 'ieTipoAnexo') {
      if ([TipoAnexoTiss.OPME, TipoAnexoTiss.QUIMIOTERAPIA].includes(ieTipoAnexo as TipoAnexoTiss)) {
        render(() => <Link icon="/static/assets/view-details.svg" value=' ' className='hpms-custom-icon-size' onClick={() => this.onIconClick(nrSeqItem, ieTipoAnexo)} />);
      } else {
        render(() => <div />);
      }
    } else if (entry === 'detalhesMobile') {
      render(() => <Link icon="/static/assets/view-details.svg" value=' ' className='hpms-custom-icon-size' onClick={() => this.onDetalhesClick(nrSeqItem, registro, endpoint, param)} />);
    }

    if (type == 'row') {
      const breakpoint = getBreakpoint();
      if (breakpoint !== Breakpoint.XSM && breakpoint !== Breakpoint.SM) {
        render(() => <HPMSExpansibleRow params={{ nrSeqBeneficiario, nrSeqGuia, ...param }} endpoint={endpoint}></HPMSExpansibleRow>);
      }
    }
  }

  onExpandClick = (dv: HTMLDivElement) => {
    if (dv.style.transform) {
      dv.style.transform = '';
    } else {
      dv.style.transform = 'rotate(90deg)';
    }
  }

  onIconClick = (seq: number, tipo: string) => {
    if (tipo == TipoAnexoTiss.QUIMIOTERAPIA) {
      this.historyService.push(this.historyService.getCurrentRoute().concat(Route.TISS_MATERIAL_QUIMIOTERAPIA), { nrSeqMat: seq }, { scrollToTop: false });
    } else if (tipo == TipoAnexoTiss.OPME) {
      this.historyService.push(this.historyService.getCurrentRoute().concat(Route.TISS_MATERIAL_OPME), { nrSeqMat: seq }, { scrollToTop: false });
    }
  }

  onDetalhesClick = (seq: number, registro: Registro, endpoint: string | false, param: {}) => {
    SessionStorageService.setItem('autorizacao_item_detalhe', {
      data: registro,
      endpoint,
      parametro: param
    });
    this.historyService.push(this.historyService.getCurrentRoute().concat(Route.AUTORIZACAO_ITEM_DETALHE), { nrSeqItem: seq }, { scrollToTop: false });
  }

}
export default AuthorizationFormController;