import Controller, { OnClick } from '@philips-emr/tws-core/lib/client';
import BaseController from '../../BaseController';

@Controller({ fragment: 'beneficiary-data', component: 'beneficiary-data-layout' })
class BeneficiaryDataController extends BaseController {

  @OnClick({ target: ['print-report-link'] })
  onClose(): void {
    alert('TODO: implement report generation');
  } 
  
}

export default BeneficiaryDataController;
