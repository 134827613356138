import Card from '@philips-emr/tws-components/lib/components/card/card';
import Link from '@philips-emr/tws-components/lib/components/link/link';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { eventListener, number, object, string } from '@philips-emr/tws-core/lib/component/prop-types';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import React, { PureComponent } from 'react';
import HpmsGrantorInfoApi from '../../../api/hpms-grantor-info-api';
import call from '../../../assets/images/global/TODO-hpms-phone.png';
import HpmsMessage from '../../../assets/images/icons/hpms-message.svg';
import phoneNumberFormatter from '../../../util/phone-utils';
import HpmsIcon from '../../hpms-icon/hpms-icon';
import './hpms-grantor-info.scss';




@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: HpmsGrantorInfoApi })
class HpmsGrantorInfo extends PureComponent {

  constructor(props) {
    super(props);
  }

  static defaultProps = {
    width: 6,
    data: {
      nmOperadora: '',
      cdCgc: '',
      nrDddTelefoneOperadora: '',
      nrTelefoneOperadora: '',
      dsSiteAns: '',
      dsLinkNucleoFisc: '',
      nrTelefoneAns: '',
      dsEmailAns: ''
    }
  }

  static propTypes = {
    uuid: string,
    width: number,
    data: object,
    onAnsClick: eventListener,
    onNucleoFiscClick: eventListener,
  };

  onAns(link) {
    const { onAnsClick } = this.props;

    onAnsClick && onAnsClick({ value: link });
  }

  onNucleoFisc(link) {
    const { onNucleoFiscClick } = this.props;

    onNucleoFiscClick && onNucleoFiscClick({ value: link });
  }

  renderContent() {
    const { data } = this.props;

    return (
      <div className="c-hpms-grantor-info__card-wrap">

        <div className="c-hpms-grantor-info-card__grantor">
          <div className="c-hpms-grantor-info__card-content">
            <div className="c-hpms-grantor-info__card-title c-hpms-grantor-info__card-title c-hpms-grantor-info__card-separator">{data.nmOperadora}</div>
            <div className="c-hpms-grantor-info__card-first-cellspacing">
              <div className="c-hpms-grantor-info__card-sub-title">
                {`${msg('hpms-components.cnpj')}`}
              </div>
              <div>
                {format('employee-identification-number', data.cdCgc)}
              </div>
            </div>

            <div className="c-hpms-grantor-info__card-dual-content">
              <div className="c-hpms-grantor-info__card-left-width c-hpms-grantor-info__card-cellspacing">
                <div className="c-hpms-grantor-info__card-sub-title">{msg('beneficiary.contact')}</div>
                <div>{data.nmContatoOperadora}</div>
              </div>
              <div className="c-hpms-grantor-info__card-left-width c-hpms-grantor-info__card-cellspacing">
                {(data.nrDddTelefoneOperadora !== "080" && data.nrDddTelefoneOperadora !== undefined) && <HpmsIcon icon={call} label={phoneNumberFormatter(`${data.nrDddTelefoneOperadora}${data.nrTelefoneOperadora}`, false)} href={`tel:${phoneNumberFormatter(`${data.nrDddTelefoneOperadora}${data.nrTelefoneOperadora}`, false)}`} />}
                {(data.nrDddTelefoneOperadora == "080" || data.nrDddTelefoneOperadora == undefined) && <HpmsIcon icon={call} label={phoneNumberFormatter(`${data.nrDddTelefoneOperadora}${data.nrTelefoneOperadora}`, true)} href={`tel:${phoneNumberFormatter(`${data.nrDddTelefoneOperadora}${data.nrTelefoneOperadora}`, true)}`} />}
              </div>
            </div>
          </div>
        </div>

        <div className="c-hpms-grantor-info-card__ans">
          <div className="c-hpms-grantor-info__card-content">
            <div className="c-hpms-grantor-info__card-title c-hpms-grantor-info__card-title c-hpms-grantor-info__card-separator">{msg('beneficiary.ans-information')}</div>
            <div className="c-hpms-grantor-info__card-sub-title c-hpms-grantor-info__card-first-cellspacing">{msg('beneficiary.ans-ans')}</div>
            <div><Link href={data.dsSiteAns} onClick={() => this.onAns(data.dsSiteAns)} value={data.dsSiteAns} /></div>

            <div className="c-hpms-grantor-info__card-sub-title c-hpms-grantor-info__card-cellspacing">{msg('beneficiary.supervision-cores')}</div>
            <div><Link href={data.dsLinkNucleoFisc} onClick={() => this.onNucleoFisc(data.dsLinkNucleoFisc)} value={data.dsLinkNucleoFisc} /></div>

            <div className="c-hpms-grantor-info__card-dual-content">
              <div className="c-hpms-grantor-info__card-content-image c-hpms-grantor-info__card-left-width c-hpms-grantor-info__card-cellspacing">
                {data.nrTelefoneAns && <HpmsIcon icon={call} alt={'Call'} label={`${phoneNumberFormatter(data.nrTelefoneAns, true)}`} href={`tel:${data.nrTelefoneAns}`} />}
              </div>
              <div className="c-hpms-grantor-info__card-content-image c-hpms-grantor-info__card-cellspacing">
                {data.dsEmailAns && <HpmsIcon icon={HpmsMessage} alt={'icone-mensagem'} label={data.dsEmailAns} />}
              </div>
            </div>
          </div>
        </div>

      </div >
    );
  }

  render() {
    const { width } = this.props;

    return (
      <Card width={width}>
        <div className="c-hpms-grantor-info">
          {this.renderContent()}
        </div>
      </Card>
    );
  }
}

export default HpmsGrantorInfo;
