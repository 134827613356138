import Controller, { OnClose, OnClick } from "@philips-emr/tws-core/lib/client";
import BaseController from "../../BaseController";
import { Route } from "../../../constants/routes";

@Controller({ fragment: 'recuperacao-senha-sucesso', component: 'layout-confirmation-success' })
class RecuperarSenhaSucesso extends BaseController {

  @OnClose({ target: ['modal-recuperacao-senha'] })
  onClose(): void {
    this.historyService.push(Route.LOGIN);
  }

  @OnClick({ target: ['button-login']})
  onBtnClick(): void {
    this.historyService.push(Route.LOGIN);
  }

}

export default RecuperarSenhaSucesso;