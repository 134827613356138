import Controller, { OnAfterFetch } from '@philips-emr/tws-core/lib/client';
import { Inject } from '@philips/odin';
import BaseController from '../../BaseController';
import { FetchEvent } from '@philips-emr/tws-core/lib/component/types';
import { Data } from '@philips-emr/tws-components-api/lib/api/repeater/repeater-api';
import { RepeaterAPI } from '@philips-emr/tws-components-api';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';

interface DenialsIncidentsList {
  [key: string]: DenialsIncidentsObject | undefined;
}

interface DenialsIncidentsObject {
  code: number;
  type: string;
  description: string;
}

@Controller({ fragment: 'request-denials-incidents', component: 'denialsIncidentsLayout' })
class RequestDenialsIncidentsController extends BaseController {

  @Inject('repeater-denials-incidents')
  cptDenialsIncidents: RepeaterAPI;

  denialsIncidentsList?: DenialsIncidentsList;

  @OnAfterFetch({ target: ['denialsIncidentsFetch'] })
  onAfterFetch(event: FetchEvent): void {

    this.denialsIncidentsList = event.data as DenialsIncidentsList;

    const denialsIncidentsObjects: Data[] = [];

    Object.keys(this.denialsIncidentsList).forEach(
      key => {
        let displayObj: Data = this.resolveDisplayText(this.denialsIncidentsList && this.denialsIncidentsList[key]);
        if (displayObj.denialsIncidentsDisplay) {
          denialsIncidentsObjects.push(displayObj);
        }
      }
    );
    this.cptDenialsIncidents.setData(denialsIncidentsObjects);
  }

  resolveDisplayText(denialsIncidentsObject: DenialsIncidentsObject | undefined): Data {

    const type = denialsIncidentsObject && denialsIncidentsObject.type && msg('authorization.denials-incidents-type.' + denialsIncidentsObject.type);
    const code = denialsIncidentsObject && denialsIncidentsObject.code;
    const description = denialsIncidentsObject && denialsIncidentsObject.description;

    let displayObj: Data = { denialsIncidentsDisplay: code ? ` ${type}: ${code} - ${description} ` : `` };

    return displayObj;
  }
}

export default RequestDenialsIncidentsController;