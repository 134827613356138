import Controller, { OnClick } from '@philips-emr/tws-core/lib/client';
import { Route } from '../../constants/routes';
import BaseController from '../BaseController';

@Controller({ fragment: 'logout-por-inatividade', component: 'layout-0' })
class LogoutPorInatividadeController extends BaseController {

  @OnClick({ target: 'button-retornar-tela-inicial' })
  onClickButtonRetornarTela(): void {
    this.historyService.push(Route.LOGIN);
  }

}

export default LogoutPorInatividadeController;