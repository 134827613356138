import Controller, { OnDynamicContentRender, OnClick } from '@philips-emr/tws-core/lib/client';
import { RenderEvent, ClickEvent } from '@philips-emr/tws-core/lib/component/types';
import BaseController from '../../BaseController';
import { Data } from '../../../interfaces/data';
import React from 'react';
import { Link } from '@philips-emr/tws-components';
import { Route } from '../../../constants/routes';

@Controller({ fragment: 'lista-aviso-de-privacidade', component: 'lista-aviso-de-privacidade-layout' })
class ListaAvisoDePrivacidadeController extends BaseController {

  @OnDynamicContentRender({ target: ['lista-aviso-de-privacidade-datatable'] })
  onDynamicContentRender(event: RenderEvent): void {
    const { entry, data, render } = event;

    const nrSeqAviso = (data as Data).nrSeqTermo as number;

    if (entry === 'detailColumn') {

      const renderLink: Function = () => {
        return (
          <Link icon='/static/assets/view-details.svg' className='hpms-custom-icon-size' onClick={() => this.onDetailClick(nrSeqAviso)} value=' ' />
        );
      }

      render(renderLink);
    }
  }

  onDetailClick = (nrSeqAviso: number) => {
    this.historyService.push(Route.AVISO_DE_PRIVACIDADE_DETALHE, { nrSeqAviso });
  }

  @OnClick({ target: 'deck-aviso' })
  onClickCardDeckTermos(event: ClickEvent): void {
    const { nrSeqTermo } = event.value;

    this.onDetailClick(nrSeqTermo);
  }

}

export default ListaAvisoDePrivacidadeController;