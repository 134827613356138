import Controller, { OnClick, OnDynamicContentRender, OnMount } from '@philips-emr/tws-core/lib/client';
import { RenderEvent, MountEvent } from '@philips-emr/tws-core/lib/component/types';
import BaseController from '../../BaseController';
import React from 'react';
import { MapperAPI } from '@philips-emr/tws-components-api';
import { HPMSExpansibleRow } from '@philips-emr/hpms-components';
import { Route } from '../../../constants/routes';
import { Endpoint } from '../../../constants/endpoints';
interface ChangeRequestObject {
  tasyFieldChangeRequestId: number;
  tasyChangeRequestId: number;
  username: string;
  loginTypeRequest: string;
  status: string;
  attributeName: string;
  oldValue: string;
  newValue: string;
  createdAt: string;
}

type Value = string | number | boolean | unknown;

interface Data {
  [key: string]: Value;
}

interface ChangeRequest {
  value: ChangeRequestValue;
}

interface ChangeRequestValue {
  ieStatus: string;
  nrSeqProtocolo: number;
  nrProtocolo: number;
  dsMotivoRejeicao: string;
  descricao: string;
  cdPessoaFisica: string;
}



@Controller({ fragment: 'person-data-change-requests', component: 'personDataChangeRequestsLayout' })
class ChangeRequestsController extends BaseController {

  changeRequestData?: ChangeRequestObject;

  @OnMount({ target: ['change-request-mapper'] })
  onMapperMount(e: MountEvent): void {
    const api = e.target as MapperAPI;

    api.addCustomMapping('readableAttributeName', this.generateGreatName);
  }

  generateGreatName(data: Data): Value {

    const resolvedAttributeName = new Map()
      .set('NM_PESSOA_FISICA', 'Nome')
      .set('DT_NASCIMENTO', 'Data de nascimento')
      .set('IE_ESTADO_CIVIL', 'Estado civil')
      .set('NR_CPF', 'CPF')
      .set('NR_CARTAO_NAC_SUS', 'Cartão Nacional de Saúde')
      .set('DS_EMAIL', 'E-mail')
      .set('NR_IDENTIDADE', 'RG')
      .set('DT_EMISSAO_CI', 'Data de emissão RG')
      .set('SG_EMISSORA_CI', 'Estado emissor RG')
      .set('NR_SEQ_PAIS', 'País emissor RG')
      .set('DS_ORGAO_EMISSOR_CI', 'Órgão emissor RG')
      .set('NR_REG_GERAL_ESTRANG', 'RG estrangeiro')
      .set('IE_SEXO', 'Sexo')
      .set('CD_CEP', 'CEP')
      .set('DS_ENDERECO', 'Endereço')
      .set('NR_ENDERECO', 'Número')
      .set('DS_COMPLEMENTO', 'Complemento')
      .set('DS_BAIRRO', 'Bairro')
      .set('DS_MUNICIPIO', 'Cidade')
      .set('SG_ESTADO', 'Estado')
      .set('NR_DDI_TELEFONE', 'DDI telefone')
      .set('NR_DDD_TELEFONE', 'DDD telefone')
      .set('NR_TELEFONE', 'Telefone')
      .set('NR_TELEFONE_CELULAR', 'Celular')
      .set('CD_DECLARACAO_NASC_VIVO', 'Declaração nascido vivo')
      .set('NM_CONTATO', 'Nome da mãe')
      .set('NM_PAI', 'Nome do pai');

    //TODO: Change this when MapperFunction get its right output variable type;
    return resolvedAttributeName.get(data.attributeName) as unknown as Data;
  }

  @OnDynamicContentRender({ target: ['personDataChangeRequestsDatatable'] })
  onRender(event: RenderEvent): void {

    const { entry, data, render, type } = event;
    const params = { tasyFieldChangeRequestId: '', rejectReason: '', notesDescription: '', ieStatus: '', nrSeqProtocolo: '', ...data }
    const endpoint = Endpoint.GET_MOTIVO_REJEICAO;
    const nrSeqProtocolo = params.nrSeqProtocolo;
    const nrSeqBeneficiario = this.context.get('nrSeqBeneficiario');
    const urlParams = { nrSeqBeneficiario, nrSeqProtocolo };


    const divStyle = {
      transition: '0.10s ease-in-out',
      cursor: 'pointer',
    };

    if (type == 'row' && params.ieStatus == 'R') {
      render(() => <HPMSExpansibleRow params={urlParams} endpoint={endpoint}></HPMSExpansibleRow>);
    }

    if (entry === 'expandColumn' && params.ieStatus == 'R') {

      const renderLink: Function = () => {
        return (
          <div onClick={(e) => this.onExpandClick(e.target as HTMLDivElement)} className="c-menu-option__arrow" style={divStyle}></div>
        );
      }

      render(renderLink);
    }
  }

  onExpandClick = (dv: HTMLDivElement) => {
    if (dv.style.transform) {
      dv.style.transform = '';
    } else {
      dv.style.transform = 'rotate(90deg)';
    }
  }

  @OnClick({ target: 'deck-data-change-request' })
  onCardClick(event: ChangeRequest): void {
    const { ieStatus,nrSeqProtocolo,cdPessoaFisica } = event.value;
   
    if (ieStatus == 'R') {
      this.context.set({ nrSeqProtocolo,cdPessoaFisica});
      this.historyService.push(Route.ALTERACAO_CADASTRO, { nrSeqProtocolo,cdPessoaFisica});

    }
  }
}

export default ChangeRequestsController;
