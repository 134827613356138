export enum Route {
  ACESSIBILIDADE = '/beneficiary/:nrSeqBeneficiario/acessibilidade',
  ACESSIBILIDADE_LOGIN = '/login/acessibilidade',
  CARTAO_SOLICITAR = '/beneficiary/:nrSeqBeneficiario/cartao/solicitar',
  CARTAO_CONSULTAR = '/beneficiary/:nrSeqBeneficiario/cartao/consultar',
  CARTAO_CONFIRMAR = '/beneficiary/:nrSeqBeneficiario/cartao/confirmar',
  CHAMADOS = '/beneficiary/:nrSeqBeneficiario/chamado',
  CHAMADO_DETALHE = '/beneficiary/:nrSeqBeneficiario/chamado/:nrSeqChamado/detalhe',
  LOGIN = '/login',
  LOGOUT_POR_INATIVIDADE = '/logout-por-inatividade',
  NOTIFICACAO = '/beneficiary/:nrSeqBeneficiario/notificacao/',
  MENSAGEM = '/beneficiary/:nrSeqBeneficiario/mensagem/',
  ASSIGN_TERMO_DE_USO = '/termo-de-uso/:nrSeqTermo',
  ASSIGN_AVISO_DE_PRIVACIDADE = '/aviso-de-privacidade/:nrSeqAviso',
  LISTA_TERMOS_DE_USO = '/beneficiary/:nrSeqBeneficiario/lista-termos-de-uso',
  LISTA_AVISO_DE_PRIVACIDADE = '/beneficiary/:nrSeqBeneficiario/lista-aviso-de-privacidade',
  POLITICA_DE_COOKIES = '/beneficiary/:nrSeqBeneficiario/politica-de-cookies',
  DETALHES_TERMO_DE_USO_MODAL = '/beneficiary/:nrSeqBeneficiario/lista-termos-de-uso/termo/:nrSeqTermo',
  AVISO_DE_PRIVACIDADE_DETALHE = '/beneficiary/:nrSeqBeneficiario/lista-aviso-de-privacidade/aviso/:nrSeqAviso',
  BENEFICIARIO_DETALHES = '/beneficiary/:nrSeqBeneficiario/contrato/info/detalhes',
  BENEFICIARIO_CARENCIAS = '/beneficiary/:nrSeqBeneficiario/contrato/info/carencias',
  BENEFICIARIO_CPT = '/beneficiary/:nrSeqBeneficiario/contrato/info/cpt',
  AVISO_DE_PRIVACIDADE_NOVO_USUARIO = '/login/new-user/aviso-de-privacidade/:nrSeqAviso',
  BENEFICIARY_REQUEST_TERMINATION_TERM = '/beneficiary/:nrSeqBeneficiario/contrato/solicitar-cancelamento/term',
  SOLICITAR_CANCELAMENTO = '/beneficiary/:nrSeqBeneficiario/contrato/solicitar-cancelamento',
  BOLETOS_POR_EMAIL = '/beneficiary/:nrSeqBeneficiario/financeiro/boletos-por-email',
  DETALHES_AUTORIZACAO_TISS = '/beneficiary/:nrSeqBeneficiario/autorizacoes/consulta-autorizacao/tiss/:nrSeqGuia',
  DETALHES_AUTORIZACAO = '/beneficiary/:nrSeqBeneficiario/autorizacoes/consulta-autorizacao/:nrSeqGuia',
  CONSULTA_AUTORIZACAO = '/beneficiary/:nrSeqBeneficiario/autorizacoes/consulta-autorizacao',
  LISTA_ADITIVO_CONTRATUAL = '/beneficiary/:nrSeqBeneficiario/lista-aditivo-contratual',
  ASSIGN_ADITIVO_CONTRATUAL = '/aditivo-contratual/:nrSeqBeneficiario/termo/:nrSeqSegTermo',
  HISTORICO_MEDICINA_PREVENTIVA = '/beneficiary/:nrSeqBeneficiario/medicina-preventiva/historico',
  HISTORICO_CONSENTIMENTOS = '/beneficiary/:nrSeqBeneficiario/aditivo-contratual/historico',
  TISS_MATERIAL_OPME = '/material/:nrSeqMat/opme',
  TISS_MATERIAL_QUIMIOTERAPIA = '/material/:nrSeqMat/quimioterapia',
  AUTORIZACAO_ITEM_DETALHE = '/item/:nrSeqItem',
  DETAILS_ADITIVO_CONTRATUAL = '/aditivo-contratual/:nrSeqBeneficiario/details/:nrSeqTermo',
  DASHBOARD = '/beneficiary/:nrSeqBeneficiario/dashboard',
  EXTRATO_UTILIZACAO = '/beneficiary/:nrSeqBeneficiario/dashboard/extrato-utilizacao',
  TABELA_DE_PRECO = '/beneficiary/:nrSeqBeneficiario/dashboard/tabela-de-preco',
  DASHBOARD_RELATORIOS = '/beneficiary/:nrSeqBeneficiario/dashboard/relatorios',
  PERSON_DATA_DETAILS = '/beneficiary/:nrSeqBeneficiario/contrato/dados-pessoais/detalhes/:cdPessoaFisica',
  PERSON_DATA_DETAILS_TERMO = '/beneficiary/:nrSeqBeneficiario/contrato/dados-pessoais/detalhes/:cdPessoaFisica/termo-responsabilidade/:nrSeqTermo',
  ALTERACAO_CADASTRO = '/beneficiary/:nrSeqBeneficiario/contrato/dados-pessoais/detalhes/:cdPessoaFisica/motivo/:nrSeqProtocolo',
  DETALHES_REQUISICAO_TISS = '/beneficiary/:nrSeqBeneficiario/requisicoes/consultar-requisicoes/tiss/:nrSeqRequisicao',
  DETALHES_REQUISICAO = '/beneficiary/:nrSeqBeneficiario/requisicoes/consultar-requisicoes/:nrSeqRequisicao',
  CONSULTAR_REQUISICOES = '/beneficiary/:nrSeqBeneficiario/requisicoes/consultar-requisicoes',
  DETALHES_GUIA_REQUISICAO_COMPLETO = '/beneficiary/:nrSeqBeneficiario/requisicoes/consultar-requisicoes/:nrSeqRequisicao/consulta-autorizacao/:nrSeqGuia',
  DETALHES_GUIA_REQUISICAO = '/consulta-autorizacao/:nrSeqGuia',
  DETALHES_GUIA_REQUISICAO_TISS = '/consulta-autorizacao/tiss/:nrSeqGuia',
  REQUEST_OPME_MATERIAL = '/beneficiary/:nrSeqBeneficiario/requisicoes/consultar-requisicoes/:nrSeqRequisicao/opme/:opmeId/materials/:requestMaterialId',
  REQUEST_CHEMOTHERAPY_MATERIAL = '/beneficiary/:nrSeqBeneficiario/requisicoes/consultar-requisicoes/:nrSeqRequisicao/chemotherapy/:chemotherapyId/materials/:requestMaterialId',
  TERMINATION_MODAL = '/beneficiary/:nrSeqBeneficiario/contract-terminations/:beneficiaryTerminationRequestId/details',
  CONTRATO_SOLICITACAO_RESCISAO = '/beneficiary/:nrSeqBeneficiario/contrato/solicitacao-rescisao',
  PROTOCOLO_ATENDIMENTO_HISTORICO = '/beneficiary/:nrSeqBeneficiario/servicos/protocolo-atendimento-historico',
  PROTOCOLO_ATENDIMENTO_DETALHES = '/beneficiary/:nrSeqBeneficiario/servicos/protocolo-atendimento-historico/:nrSeqProtocolo/detalhes',
  INCOME_TAX_REPORTS = '/beneficiary/:nrSeqBeneficiario/financeiro/informe-rendimentos/relatorios',
  INFORME_DE_RENDIMENTOS = '/beneficiary/:nrSeqBeneficiario/financeiro/informe-rendimentos',
  MONTHLY_FEES_REPORTS = '/beneficiary/:nrSeqBeneficiario/financeiro/mensalidades/relatorios',
  MENSALIDADES = '/beneficiary/:nrSeqBeneficiario/financeiro/mensalidades',
  DASHBOARD_REPORTS = '/beneficiary/:nrSeqBeneficiario/dashboard/reports',
  CONFIRMACAO_USUARIO_FAIL = '/falha',
  CONFIRMACAO_USUARIO_SUCCESS = '/sucesso',
  CARENCIA_RELATORIOS = '/beneficiary/:nrSeqBeneficiario/contrato/info/carencias/relatorios',
  DADOS_BENEFICIARIO_RELATORIOS = '/beneficiary/:nrSeqBeneficiario/contrato/info/detalhes/relatorios',
  RECUPERAR_SENHA = '/login/recuperar-senha',
  RECUPERAR_SENHA_SUCESSO = '/login/recuperar-senha/sucesso',
  NOVA_SENHA_FALHA = '/nova-senha/:hash/falha',
  NOVA_SENHA_SUCESSO = '/nova-senha/:hash/sucesso',
  ALTERAR_SENHA = '/beneficiary/:nrSeqBeneficiario/meus-dados/alterar-senha',
  MEUS_DADOS = '/beneficiary/:nrSeqBeneficiario/meus-dados',
  ALTERAR_SENHA_LOGIN = '/login/alterar-senha',
  RELATORIOS = '/relatorios',

  NFS_MENSALIDADES_DASHBOARD = '/beneficiary/:nrSeqBeneficiario/dashboard/mensalidades/:nrSeqMensalidade/notas-fiscais',
  OBSERVACOES_MENSALIDADE = '/beneficiary/:nrSeqBeneficiario/dashboard/mensalidades/:nrSeqMensalidade/observacoes',

  NFS_MENSALIDADES_LISTA = '/beneficiary/:nrSeqBeneficiario/financeiro/mensalidades/:nrSeqMensalidade/notas-fiscais',
  OBSERVACOES_MENSALIDADE_LISTA = '/beneficiary/:nrSeqBeneficiario/financeiro/mensalidades/:nrSeqMensalidade/observacoes',

  SEGUNDA_VIA_MENSALIDADE_DASHBOARD = '/beneficiary/:nrSeqBeneficiario/dashboard/mensalidades/:nrSeqMensalidade/segunda-via',
  DETALHAMENTO_MENSALIDADE_DASHBOARD = '/beneficiary/:nrSeqBeneficiario/dashboard/mensalidades/:nrSeqMensalidade/detalhamento',

 
}
