import './beneficiary';
import './beneficiary/beneficiary-terminations/index';
import './authorization/anexos-tiss/index';
import './authorization/authorization-list/index';
import './authorization/requests/index';
import './person/index';
import './person/change-requests';
import './commons/new-user/index';
import './commons/login/index';
import './commons/footer/index';
import './beneficiary/encounter-protocols';
import './beneficiary/cpts';
import './beneficiary/carencia';
import './beneficiary/beneficiary-data-details';
import './beneficiary/invoice-request';
import './financial/monthly-fees';
import './beneficiary/beneficiary-data';
import './beneficiary/dashboard';
import './commons/new-user';
import './commons/reset-password';
import './commons/geral';
import './commons/hpms-reports-modal';
import './financial/income-tax';
import './medicina-preventiva';
import './commons/hpms-modal-links';
import './beneficiary/informacoes-adicionais';
import './commons/logout-por-inatividade-controller';