import { FetchAPI, ModalAPI } from '@philips-emr/tws-components-api';
import Controller, { OnClose, OnMount } from "@philips-emr/tws-core/lib/client";
import { MountEvent } from '@philips-emr/tws-core/lib/component/types';
import { SessionStorageService } from '@philips-emr/tws-core/lib/services';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { Inject } from '@philips/odin';
import BaseController from "../../BaseController";

@Controller({ fragment: 'modal-links', component: 'modal-links' })
class HpmsModalLinksController extends BaseController {

  @Inject('fetch-links')
  fetchLinks: FetchAPI;

  @OnMount({ target: ['modal-links'] })
  onMount(e: MountEvent): void {
    const { fetchEndPoint, fetchParams, title } = SessionStorageService.getItem('dados_links');

    const $this = e.target as ModalAPI;
    $this.setTitle(msg(title));

    this.fetchLinks.setEndpoint(fetchEndPoint);
    this.fetchLinks.setParameters(fetchParams);
    this.fetchLinks.forceFetch();
  }

  @OnClose({ target: ['modal-links'] })
  onCloseModal(): void {
    SessionStorageService.removeItem('dados_links');
    this.historyService.back();
  }

}

export default HpmsModalLinksController;