import Card from '@philips-emr/tws-components/lib/components/card/card';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { eventListener, string } from '@philips-emr/tws-core/lib/component/prop-types';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import React, { PureComponent } from 'react';
import { ReactComponent as CancelSVG } from '../../assets/images/global/hpms-cancel.svg';
import { ReactComponent as CheckSVG } from '../../assets/images/global/hpms-check.svg';
import HpmsAditivoServiceApi from "../../api/hpms-aditivo-service-api";
import './hpms-aditivo-contratual-card.scss';
import { number } from 'prop-types';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: HpmsAditivoServiceApi })
class HpmsAditivoContratualCard extends PureComponent {



  static propTypes = {
    onClick: eventListener,
    dtAceiteRecusa: string,
    dsStatus: string,
    tipoTermo: string,
    nameBeneficiario: string,
    nrSeqTermo: number,
    nrSeqSeguradoAceite: number,
    onDetailClickCard: eventListener
  }

  onDetails(nrSeqTermo, nrSeqSeguradoAceite) {
    
    const { onDetailClickCard } = this.props;

    onDetailClickCard && onDetailClickCard({ value: { nrSeqTermo, nrSeqSeguradoAceite } });

  }

  renderHeader() {
    const { dtAceiteRecusa } = this.props;

    return (
      <div className='c-hpms-aditivo-contratual-card__header'>
        <div className='c-hpms-aditivo-contratual-card__header-left'>{format('date', dtAceiteRecusa, { type: 'date' })}</div>
      </div>
    );
  }

  renderContent() {
    const { tipoTermo, nameBeneficiario } = this.props;

    return (
      <div className='c-hpms-aditivo-contratual-card_content-wrapper'>
        <div className='c-hpms-aditivo-contratual-card__content'>{tipoTermo}</div>
        <div className='c-hpms-aditivo-contratual-card__content_name'>{nameBeneficiario}</div>
      </div>
    );
  }

  renderFooter() {
    const { dsStatus } = this.props;

    const msgStatus = dsStatus ? msg(dsStatus) : '';
    return (
      <div className='c-hpms-aditivo-contratual-card__footer'>
        <div className='c-hpms-aditivo-contratual-card__footer-left'>
          {this.getSvg()}{'\u00A0'}{msgStatus}
        </div>
      </div>
    );
  }

  render() {

    const { nrSeqTermo, nrSeqSeguradoAceite }  = this.props;
    return (
      <Card width="12" className='c-hpms-aditivo-contratual-card' onClick={() => this.onDetails(nrSeqTermo, nrSeqSeguradoAceite)}>
        <div className={`c-hpms-aditivo-contratual-card__wrapper c-hpms-aditivo-contratual-card__border-${this.getColor()}`}>
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderFooter()}

        </div>
      </Card>
    );
  }

  getColor() {
    const { dsStatus } = this.props;

    switch (dsStatus) {

      case 'Revogado':
        return 'orange';
      case 'Aceito':
        return 'green';

    }
  }

  getSvg() {
    const { dsStatus } = this.props;

    switch (dsStatus) {
      case 'Aceito':
        return <CheckSVG className={`c-hpms-aditivo-contratual-card__color-${this.getColor()}`} />;
      case 'Revogado':
        return <CancelSVG className={`c-hpms-aditivo-contratual-card__color-${this.getColor()}`} />;
      default:
        return '';
    }
  }
}

export default HpmsAditivoContratualCard;