import Card from '@philips-emr/tws-components/lib/components/card/card';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { eventListener, string } from '@philips-emr/tws-core/lib/component/prop-types';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import { trigger } from '@philips-emr/tws-utils';
import React, { PureComponent } from 'react';
import { ReactComponent as CancelSVG } from '../../assets/images/global/hpms-cancel.svg';
import { ReactComponent as CheckSVG } from '../../assets/images/global/hpms-check.svg';
import { ReactComponent as TimeSVG } from '../../assets/images/global/hpms-time.svg';

import './hpms-medicina-preventiva-card.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY })
class HpmsMedicinaPreventivaCard extends PureComponent {

  static propTypes = {
    onClick: eventListener,
    dtSolicitacao: string,
    ieStatus: string,
    dsTipo: string,
    dsItem: string,
    dsObservacao: string,
  }

  renderHeader() {
    const { dtSolicitacao } = this.props;

    return (
      <div className='c-hpms-medicina-preventiva-card__header'>
        <div className='c-hpms-medicina-preventiva-card__header-left'>{format('date', dtSolicitacao, { type: 'date' })}</div>
      </div>
    );
  }

  renderContent() {
    const { dsTipo } = this.props;
    const { dsItem } = this.props;

    return (
      <div className='c-hpms-medicina-preventiva-card_content-wrapper'>
        <div className='c-hpms-medicina-preventiva-card__content'>{dsItem}</div>
        <div className='c-label'>{dsTipo}</div>
      </div>
    );
  }
  renderObservacao() {
    const { dsObservacao } = this.props;
    return (

      <div className='c-hpms-medicina-preventiva-card__obs'>{dsObservacao}</div>
    );
  }
  renderFooter() {
    const { ieStatus } = this.props;

    const msgStatus = ieStatus ? msg('historico.med.prev-status.' + ieStatus) : '';
    return (
      <div className='c-hpms-medicina-preventiva-card__footer'>
        <div className='c-hpms-medicina-preventiva-card__footer-left'>
          {this.getSvg()}{'\u00A0'}{msgStatus}
        </div>
      </div>
    );
  }

  render() {
    const { onClick } = this.props;
    return (
      <Card width="12" className='c-hpms-medicina-preventiva-card' onClick={trigger(onClick)}>
        <div className={`c-hpms-medicina-preventiva-card__wrapper c-hpms-medicina-preventiva-card__border-${this.getColor()}`}>
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderObservacao()}
          {this.renderFooter()}

        </div>
      </Card>
    );
  }

  getColor() {
    const { ieStatus } = this.props;

    switch (ieStatus) {

      case 'P':
        return 'gray';
      case 'A':
        return 'green';
      case 'R':
        return 'red';
      case 'C':
        return 'orange';
      case 'N':
        return 'yellow';
      case 'T':
        return 'blue';

    }
  }

  getSvg() {
    const { ieStatus } = this.props;

    switch (ieStatus) {
      case 'P':
        return <TimeSVG width="24" height="24" />;
      case 'A':
        return <CheckSVG className={`c-hpms-medicina-preventiva-card__color-${this.getColor()}`} />;
      case 'R':
        return <CancelSVG className={`c-hpms-medicina-preventiva-card__color-${this.getColor()}`} />;
      case 'C':
        return <CancelSVG className={`c-hpms-medicina-preventiva-card__color-${this.getColor()}`} />;
      case 'N':
        return <CheckSVG className={`c-hpms-medicina-preventiva-card__color-${this.getColor()}`} />;
      case 'T':
        return <CheckSVG className={`c-hpms-medicina-preventiva-card__color-${this.getColor()}`} />;
      default:
        return '';
    }
  }
}

export default HpmsMedicinaPreventivaCard;