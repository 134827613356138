import Controller, { OnMount } from '@philips-emr/tws-core/lib/client';
import BaseController from '../../BaseController';

@Controller({ fragment: 'authorization-details', component: 'authorizationDetailsLayout' })
class AuthorizationDetailsController extends BaseController {

  @OnMount({ target: ['authorizationDetailsLayout'] })
  onMount(): void {
    const { nrSeqBeneficiario, nrSeqGuia } = this.historyService.getCurrentRouteParams();
    this.context.set({ nrSeqBeneficiario, nrSeqGuia, origem: 'autorizacao', nrSeq: nrSeqGuia });
  }

}

export default AuthorizationDetailsController;
