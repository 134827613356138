import { ComponentAPI } from '@philips-emr/tws-core';

class HpmsNoResultApi extends ComponentAPI {

  setVisible(visible: boolean): void {
    this.setState({ visible });
  }
}

export default HpmsNoResultApi;
