import React, { PureComponent } from 'react';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { string, number, bool } from '@philips-emr/tws-core/lib/component/prop-types';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import excerpts from '../../util/excerpts'

/** Component Dependencies */
import './hpms-notificacao-card.scss';

/** Icons */
import notificacaoVista from '../../assets/images/icons/check-circle-solid.svg';
import notificacaoNaoVista from '../../assets/images/icons/check-circle-solid-gray.svg';

/** External Components */
import { Card, Image } from '@philips-emr/tws-components/lib/components';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY })
class HpmsNotificacaoCard extends PureComponent {

  static defaultProps = {
    iconeStatusTamanho: '20px',
    limiteCaracteresTitulo: 20,
    limiteCaracteresDescricaoIcone: 25,
    limiteCaracteresDescricao: 32,
    mostrarTitulo: false
  }

  static propTypes = {
    nmUsuarioTasy: string,
    nmTitulo: string,
    dsTitulo: string,
    dsTexto: string,
    nrSequencia: number,
    dtCriacao: string,
    qtLeitura: number,
    limiteCaracteresTitulo: number,
    limiteCaracteresDescricaoIcone: number,
    limiteCaracteresDescricao: number,
    mostrarTitulo: bool,
    iconeStatusTamanho: string.group('size'),
    ieImportante: string
  }

  getIconeNotificacao() {
    if (this.props.qtLeitura > 0) {
      return notificacaoVista;
    } else {
      return notificacaoNaoVista;
    }
  }

  renderBody() {
    const { iconeStatusTamanho, dtCriacao, dsTitulo, dsTexto, qtLeitura, limiteCaracteresTitulo, limiteCaracteresDescricaoIcone, limiteCaracteresDescricao,
      mostrarTitulo, ieImportante } = this.props;
    return (
      !mostrarTitulo ? (
        <div className="c-hpms-notificacao-card__wrapper" >
          <div className="c-hpms-notificacao__body-sections">
            <div className="c-hpms-notificacao__body-status">
              <Image width={iconeStatusTamanho} src={this.getIconeNotificacao()}></Image>
            </div>
            <div className="c-hpms-notificacao__body-desc">
              <div>{format('date', dtCriacao)} | {excerpts(dsTitulo, limiteCaracteresTitulo, '...')}</div>
              <div className="c-hpms-notificacao__body-desc_info">
                <div>{excerpts(dsTexto, (parseInt(qtLeitura) == 0 ? limiteCaracteresDescricaoIcone : limiteCaracteresDescricao), '...')}</div>
                {parseInt(qtLeitura) == 0 &&
                  <div className="b-novo_notificacao floating">Novo</div>
                }

                { ieImportante == 'S' && parseInt(qtLeitura) != 0 && <div className="b-importante_notificacao floating">Importante</div> }
              </div>
            </div>
          </div>
        </div>
      ) :
        (
          <div className="c-hpms-notificacao-card__wrapper-modal">
            <div className="c-hpms-notificacao__body-desc_info-titulo c-hpms-notificacao-imagem-modal">
              <Image width={iconeStatusTamanho} src={this.getIconeNotificacao()}></Image>
            </div>
            <div className="c-hpms-notificacao__body-desc_info-titulo">
              <div className="c-hpms-notificacao__body-sections-modal">
                <div className="c-hpms-notificacao__body-desc_info-titulo">
                  <div>{format('date', dtCriacao)}</div>
                </div>
                {parseInt(qtLeitura) == 0 &&
                  <div className="c-hpms-notificacao__body-status">
                    <div className="b-novo_notificacao">Novo</div>
                  </div>
                }
                { ieImportante == 'S' && parseInt(qtLeitura) != 0 && <div className="c-hpms-notificacao__body-status"> <div className="b-importante_notificacao_dash">Importante</div></div> }
              </div>
              <div className="c-hpms-notificacao__body-sections-modal">
                <div className="c-hpms-notificacao__body-desc_info-titulo">
                  <div className="c-hpms-notificacao_titulo"> {excerpts(dsTitulo, limiteCaracteresTitulo, '...')}</div>
                </div>
              </div>
              <div className="c-hpms-notificacao__body-sections-modal">
                <div className="c-hpms-notificacao__body-desc_info-titulo">
                  <div>{excerpts(dsTexto, limiteCaracteresDescricao, '...')}</div>
                </div>
              </div>
            </div>
          </div>
        )
    );
  }


  render() {
    return (
      <Card width={12} className="c-card-noborderside">
        {this.renderBody()}
      </Card>
    );
  }
}

export default HpmsNotificacaoCard;
