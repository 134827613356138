import { Accordion, Image } from '@philips-emr/tws-components/lib/components';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { eventListener, number, string } from '@philips-emr/tws-core/lib/component/prop-types';
import { HttpService } from '@philips-emr/tws-core/lib/services';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import React, { PureComponent } from 'react';
import HpmsMensagemCardApi from '../../api/hpms-mensagem-card-api';
import mensagemNaoVista from '../../assets/images/icons/check-circle-solid-gray.svg';
import mensagemVista from '../../assets/images/icons/check-circle-solid.svg';
import { Endpoint } from '../../constants/endpoints';
import excerpts from '../../util/excerpts';
import HPMSAttachmentsList from '../hpms-attachments-list/hpms-attachments-list';
import './hpms-mensagem-card.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: HpmsMensagemCardApi })
class HpmsMensagemCard extends PureComponent {

  state = {};

  static propTypes = {
    dsTitulo: string,
    dsTexto: string,
    nrSequencia: number,
    dtLiberacao: string,
    ieLeitura: string,
    ieImportante: string,
    onOpenAttachment: eventListener
  }

  getIconeNotificacao() {
    if ((this.state.ieLeitura || this.props.ieLeitura) == 'S') {
      return mensagemVista;
    } else {
      return mensagemNaoVista;
    }
  }

  getHeader() {
    const { dtLiberacao, dsTitulo, ieLeitura, ieImportante } = this.props;
    return (
      <div className='c-hpms-mensagem-card__card__header-accordion'>
        <div className='c-hpms-mensagem-card__card__header-accordion-content'>
          <Image width='20px' src={this.getIconeNotificacao()}></Image>
          <div className='c-hpms-mensagem-card__card__header-accordion-display'>
            <div className='c-hpms-mensagem-card__card__header-accordion-date c-hpms-mensagem-card__card__header-accordion-text'>
              {format('date', dtLiberacao)}
            </div>
            <div className='c-hpms-mensagem-card__card__header-accordion-text'>{excerpts(dsTitulo, 50, '...')}</div>
          </div>
        </div>
        <div>
          {(this.state.ieLeitura || ieLeitura) != 'S' && <div className="b-novo_notificacao">Novo</div>}
          { ieImportante == 'S' && ieLeitura == 'S' && <div className="b-importante_notificacao_dash">Importante</div> }
        </div>
      </div>
    );
  }

  setMensagemLida() {
    const { nrSequencia } = this.props;

    HttpService.post(Endpoint.POST_GERAR_LEITURA, { nrSeqComunicado: nrSequencia, nrSeqBeneficiario: this.state.nrSeqBeneficiario })
      .then(() => { this.setState({ ieLeitura: 'S' }); });

    this.getListaAnexos();
  }

  getListaAnexos() {
    const { nrSequencia, onOpenAttachment } = this.props;
    HttpService.get(Endpoint.GET_MENSAGEM_ANEXOS, { nrSeqComunicado: nrSequencia, nrSeqBeneficiario: this.state.nrSeqBeneficiario }).then(response => {
      this.setState({
        anexos: (<HPMSAttachmentsList data={response.body} onOpenAttachment={onOpenAttachment} />)
      });
    });
  }

  getAnexos() {
    return (
      <div>
        <div className='c-hpms-mensagem-card__card__content-accordion-title'>Anexos</div>
        <div>
          {this.state.anexos}
        </div>
      </div>
    );
  }

  getContent() {
    const { dsTexto } = this.props;
    return (
      <div>
        <div>
          <div>{dsTexto}</div>
        </div>
        <div>
          {this.getAnexos()}
        </div>
      </div>
    );
  }

  render() {

    return (
      <Accordion width="12" onOpen={() => this.setMensagemLida()}>

        <div slot="header">
          {this.getHeader()}
        </div>

        <div slot="content">
          {this.getContent()}
        </div>

      </Accordion>
    );
  }
}

export default HpmsMensagemCard;
