import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, RouteComponentProps, withRouter } from 'react-router-dom';

import { init } from '@philips-emr/tws-core/lib/services/history-service';
import * as ConfigurationService from '@philips-emr/tws-core/lib/services/configuration-service';
import '@philips-emr/tws-components';

import App from './app'
import { getApplicationContext } from './app/environment';

import './index.scss';

const RouteredApp = withRouter(App);

ConfigurationService.init({ contextName: getApplicationContext() });

function RootApp(): ReactElement {
  return (
    <BrowserRouter basename={getApplicationContext()} >
      <Route path="/" render={({ history, location }: RouteComponentProps) => {
        init({ history, location });

        return <RouteredApp />;
      }} />
    </BrowserRouter>
  );
}

setTimeout(() => {
  ReactDOM.render(<RootApp />, document.getElementById('root'));
})