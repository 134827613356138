import Controller, { OnClose } from "@philips-emr/tws-core/lib/client";
import BaseController from "../../BaseController";

@Controller({ fragment: 'termo-uso-usuario', component: 'modal-temos-resp' })
class TermoUsoUsuario extends BaseController {

  @OnClose({ target: ['modal-temos-resp'] })
  onCloseClick(): void {
    this.historyService.back();
  }
}

export default TermoUsoUsuario;