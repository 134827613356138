import Card from '@philips-emr/tws-components/lib/components/card/card';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { number, string, eventListener } from '@philips-emr/tws-core/lib/component/prop-types';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import React, { PureComponent } from 'react';
import { ReactComponent as CancelSVG } from '../../assets/images/global/hpms-cancel.svg';
import { ReactComponent as CheckSVG } from '../../assets/images/global/hpms-check.svg';
import './hpms-termos-card.scss';
import { trigger } from '@philips-emr/tws-utils';
import HpmsTermosCardApi from './hpms-termos-card-api';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: HpmsTermosCardApi })
class HpmsTermosCard extends PureComponent {

  static propTypes = {
    onClick: eventListener,
    nrSeqTermo: number,
    termo: string,
    dtConfirmacao: string,
    status: string,
  }

  renderHeader() {
    const { dtConfirmacao } = this.props;
    return (
      <div className='c-hpms-termos-card__header'>
        <div className='c-hpms-termos-card__header-left'>{format('date', dtConfirmacao)}</div>
      </div>
    );
  }

  renderContent() {
    const { termo } = this.props;
    return (
      <div className='c-hpms-termos-card__content'>{termo}</div>
    );
  }

  renderFooter() {
    const { status } = this.props;

    const msgStatus = status ? msg('beneficiario.termos-card.status.' + status) : '';
    return (
      <div className='c-hpms-termos-card__footer'>
        <div className='c-hpms-cpt-card__footer-left'>
          {this.getSvg()}{'\u00A0'}{msgStatus}
        </div>
      </div>
    );
  }

  render() {
    const { onClick } = this.props;
    return (
      <Card onClick={trigger(onClick)} width="12" className='c-hpms-termos-card'>
        <div className={`c-hpms-termos-card__wrapper c-hpms-termos-card__border-${this.getColor()}`}>
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderFooter()}
        </div>
      </Card>
    );
  }

  getColor() {
    const { status } = this.props;

    switch (status) {
      case 'I':
        return 'gray';
      case 'A':
        return 'green';
      default:
        return 'gray';
    }
  }

  getSvg() {
    const { status } = this.props;

    switch (status) {
      case 'I':
        return <CancelSVG className={`c-hpms-termos-card__color-${this.getColor()}`} />;
      case 'A':
        return <CheckSVG className={`c-hpms-termos-card__color-${this.getColor()}`} />;
      default:
        return '';
    }
  }

}

export default HpmsTermosCard;