import { Link } from '@philips-emr/tws-components';
import { FilterAPI } from '@philips-emr/tws-components-api';
import Controller, { OnClick, OnDynamicContentRender, OnMount, OnUnmount } from '@philips-emr/tws-core/lib/client';
import { MountEvent, RenderEvent } from '@philips-emr/tws-core/lib/component/types';
import { HttpService } from '@philips-emr/tws-core/lib/services';
import React from 'react';
import { Endpoint } from '../../../constants/endpoints';
import { Route } from '../../../constants/routes';
import BaseController from '../../BaseController';

const DETAIL_COLUMN_NAME = 'detailColumn';

interface Value {
  nrSequencia: number;
}

interface Data {
  nrSequencia: number;
  value: Value;
}

@Controller({ fragment: 'authorization-list', component: 'authorization-list-layout' })
class AuthorizationListController extends BaseController {

  @OnClick({ target: 'deck-authorizations' })
  onCardClick(event: Data): void {
    const { nrSequencia } = event.value;
    this.onDetailClick(nrSequencia);
  }

  @OnMount({ target: 'authorization-list-filter' })
  onFilterMount(e: MountEvent): void {

    const filter = e.target as FilterAPI;
    const restored = filter.restoreState(true);

    if (restored) {
      return;
    }

    const currentDate = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(currentDate.getMonth() - 1);

    const record = {
      'dtInicio': lastMonth.toISOString(),
      'dtFim': currentDate.toISOString()
    };

    filter.setData(record);
    filter.submit();
  }

  @OnUnmount({ target: 'authorization-list-filter' })
  onFilterUnmount(e: MountEvent): void {

    const filter = e.target as FilterAPI;

    filter.persistState();
  }

  @OnDynamicContentRender({ target: ['authorization-list-datatable'] })
  onDynamicContentRender(event: RenderEvent): void {
    const { entry, data, render } = event;

    const nrSeqGuia = (data as Data).nrSequencia;

    if (entry === DETAIL_COLUMN_NAME) {

      const renderLink: Function = () => {
        return (
          <Link icon='/static/assets/view-details.svg' className='hpms-custom-icon-size' onClick={() => this.onDetailClick(nrSeqGuia)} value=' '/>
        );
      }

      render(renderLink);
    }
  }

  onDetailClick = (nrSeqGuia: number) => {
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();

    this.context.set({ nrSeqGuia });

    HttpService.get(Endpoint.CHECK_GUIA_TISS, { nrSeqGuia, nrSeqBeneficiario }).then(response => {

      const isGuiaTiss = response.body as boolean;

      if (isGuiaTiss) {
        this.historyService.push(Route.DETALHES_AUTORIZACAO_TISS, { nrSeqGuia });
      } else {
        this.historyService.push(Route.DETALHES_AUTORIZACAO, { nrSeqGuia });
      }
    });
  }

}

export default AuthorizationListController;