import { registerFormatter, Validation, Formatter } from '@philips-emr/tws-core/lib/services/model-service';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { oneOf } from '@philips-emr/tws-core/lib/component/prop-types';
import DOMAINS from '../domains';

interface DomainFormatterOptions {
  name: string;
}

function format(value: string | number, options: DomainFormatterOptions): string {
  const { name } = options;

  const prefix = DOMAINS[name];

  return value ? msg(`${prefix}.${value}`) : ``;
}

format.prototype.optionTypes = {
  name: oneOf(Object.keys(DOMAINS))
};

function getPattern(): string | RegExp {
  throw new Error("Method not implemented.");
}

function validate(value: string | number): Validation[] {
  return [{ valid: !!value }] as Validation[];
}

function parse(value: string | number): string | number {
  return value;
}

const formatterFunctions = {
  format,
  getPattern,
  parse,
  validate,
}
registerFormatter('domain', formatterFunctions as Formatter<string | number>);

export default registerFormatter;