import Controller, { OnClose } from "@philips-emr/tws-core/lib/client";
import BaseController from "../../BaseController";

@Controller({ fragment: 'termos-servico', component: 'modal-temos-resp' })
class TermosServico extends BaseController {

  @OnClose({ target: ['modal-temos-resp'] })
  onCloseClick(): void {
    this.historyService.back();
  }
}

export default TermosServico;