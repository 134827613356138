import Controller, { OnClose } from "@philips-emr/tws-core/lib/client";
import BaseController from "../../BaseController";

@Controller({ fragment: 'modal-obs-mensalidade', component: 'modal-obs-mensalidade' })
class ObservacoesMensalidadesController extends BaseController {

  @OnClose({ target: ['modal-obs-mensalidade']})
  onCloseModal(): void {
    this.historyService.back();  
  }
}

export default ObservacoesMensalidadesController;