export default {
  // HpmsMonthlyFeeCard
  VIEW: 'beneficiary.view',
  CURRENCY_SYMBOL: 'beneficiary.currency-symbol',
  DUE_DATE: 'beneficiary.due-date',
  MONTHLY_FEE_STATUS: 'beneficiary.monthly-fee-status',
  MONTHLY_FEE_CONTEXT_MENU_PREFIX: 'beneficiary.monthly-fee-context-menu-item',

  // HpmsContractTermination
  PROTOCOL: 'beneficiary.protocol.contract-termination',
  REQUEST_DATE: 'beneficiary.request-date.contract-termination',
  DETAILS: 'beneficiary.details.contract-termination',

};
