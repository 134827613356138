import { ComponentAPI, AwesomeEvent } from '@philips-emr/tws-core';

import { ClickEventListener } from '@philips-emr/tws-core/lib/component/types/clickable';

class HpmsButtonCardApi extends ComponentAPI {

  @AwesomeEvent
  onActionClick = new ClickEventListener();

  setTitle(titleExpression: string): void {
    this.setState({ titleExpression });
  }
  
  getTitle(): string {
    return this.state.titleExpression;
  }

  setSubTitle(subTitleExpression: string): void {
    this.setState({ subTitleExpression });
  }

  getSubTitle(): string {
    return this.state.subTitleExpression;
  }

  setMainContent(mainContent: string): void {
    this.setState({ mainContent });
  }

  getMainContent(): string {
    return this.state.mainContent;
  }

  setSecondaryContent(secondaryContent: string): void {
    this.setState({ secondaryContent });
  }

  getSecondaryContent(): string {
    return this.state.secondaryContent;
  }

  setActionsVisible(showActions: boolean): void {
    this.setState({ showActions });
  }

  isActionsVisible(): boolean {
    return this.state.showActions;
  }

}

export default HpmsButtonCardApi;