import { ComponentAPI, AwesomeEvent } from '@philips-emr/tws-core';
import { ClickEventListener } from '@philips-emr/tws-core/lib/component/types';

class HpmsSocialNetworksApi extends ComponentAPI {

  @AwesomeEvent
  onSocialNetworkClick = new ClickEventListener();

  setDark(value: boolean): void {
    this.setState({ isDark: value });
  }

  getDark(): void {
    return this.props.isDark;
  }

}

export default HpmsSocialNetworksApi;
