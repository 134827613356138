import { Option } from '@philips-emr/tws-components/lib/components';
import { HttpService } from '@philips-emr/tws-core/lib/services';
import React from 'react';
import { HpmsExpression } from '../constants';
import { Endpoint } from '../constants/endpoints';

export function getContextMenu(regras, ieSituacao, dsLinkNf, onSelect) {
  const { iePermiteSegundaVia, ieVisualizarLinkNotaFiscal } = regras;
  return (
    <>
      {
        iePermiteSegundaVia === 'S' && ieSituacao === '1'
          ? <Option value={`${HpmsExpression.MONTHLY_FEE_CONTEXT_MENU_PREFIX}.1`} onClick={() => onSelect('segunda_via')} />
          : null
      }
      < Option value={`${HpmsExpression.MONTHLY_FEE_CONTEXT_MENU_PREFIX}.2`} onClick={() => onSelect('detalhamento')} />
      {
        ieVisualizarLinkNotaFiscal === 'S' && dsLinkNf
          ? <Option value={`${HpmsExpression.MONTHLY_FEE_CONTEXT_MENU_PREFIX}.3`} onClick={() => onSelect('nota_fiscal')} />
          : null
      }
      < Option value={`${HpmsExpression.MONTHLY_FEE_CONTEXT_MENU_PREFIX}.4`} onClick={() => onSelect('observacoes')} />
    </>
  );
}

export function getRegrasContextMenu(nrSeqBeneficiario, nrSeqMensalidade, nrSeqRegra) {
  return HttpService.get(Endpoint.GET_REGRA_MENU_MENSALIDADE, { nrSeqRegra, nrSeqMensalidade, nrSeqBeneficiario });
}