import { Value } from "@philips-emr/tws-core";
import { Formatter, Validation } from "@philips-emr/tws-core/lib/services/model-service";
import { format, getPattern, parse, validate } from "@philips-emr/tws-model/lib/formatters/date/date";
import { DateFormatterOptions } from '@philips-emr/tws-model/lib/formatters/date/date-types';

function preventFutureDateValidate(value: Value): Validation[] {
  const currentDate = new Date();
  const minimumDate = new Date('1900-01-01');
  const valueDate = new Date(value as string);

  const isValidDate = validate(value)[0].valid;

  const valid = valueDate <= currentDate && valueDate >= minimumDate && isValidDate;

  return [{
    message: !valid ? 'tws.validator.invalid-date' : '',
    name: 'prevent-future-date',
    valid,
  }];
}

const PreventFutureDateFormatter: Formatter<string, DateFormatterOptions> = { format, parse, validate: preventFutureDateValidate, getPattern };

export default PreventFutureDateFormatter;