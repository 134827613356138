import Card from '@philips-emr/tws-components/lib/components/card/card';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { number, string } from '@philips-emr/tws-core/lib/component/prop-types';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import React, { PureComponent } from 'react';
import { ReactComponent as CancelSVG } from '../../assets/images/global/hpms-cancel.svg';
import { ReactComponent as CheckSVG } from '../../assets/images/global/hpms-check.svg';
import './hpms-carencias-card.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY })
class HpmsCarenciasCard extends PureComponent {

  static propTypes = {
    dsCarencia: string,
    dtLiberacao: string,
    ieCumprida: number
  }

  renderHeader() {
    const { dtLiberacao } = this.props;
    return (
      <div className='c-hpms-carencias-card__header'>
        <div className='c-hpms-carencias-card__header-left'>{format('date', dtLiberacao)}</div>
      </div>
    );
  }

  renderContent() {
    const { dsCarencia } = this.props;
    return (
      <div className='c-hpms-carencias-card__content'>{dsCarencia}</div>
    );
  }

  renderFooter() { 
    const { ieCumprida } = this.props;

    const msgStatus = ieCumprida ? msg('beneficiario.carencia-card.status.' + ieCumprida) : '';
    return (
      <div className='c-hpms-carencias-card__footer'>
        <div className='c-hpms-cpt-card__footer-left'>
          {this.getSvg()}{'\u00A0'}{msgStatus}
        </div>
      </div> 
    );
  }

  render() {
    return (
      <Card width="12" className='c-hpms-carencias-card'>
        <div className={`c-hpms-carencias-card__wrapper c-hpms-carencias-card__border-${this.getColor()}`}>
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderFooter()}
        </div>
      </Card>
    );
  }

  getColor() {
    const { ieCumprida } = this.props;

    switch (ieCumprida) {
      case 'N':
        return 'gray';
      case 'S':
        return 'green';
      default:
        return 'gray';
    }
  }

  getSvg() {
    const { ieCumprida } = this.props;

    switch (ieCumprida) {
      case 'N':
        return <CancelSVG className={`c-hpms-carencias-card__color-${this.getColor()}`} />;
      case 'S':
        return <CheckSVG className={`c-hpms-carencias-card__color-${this.getColor()}`} />;
      default:
        return '';
    }
  }
}

export default HpmsCarenciasCard;