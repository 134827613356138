import Button from '@philips-emr/tws-components/lib/components/button/button';
import Input from '@philips-emr/tws-components/lib/components/input/input';
import Link from '@philips-emr/tws-components/lib/components/link/link';
import { ButtonType, InputType, Size } from '@philips-emr/tws-constants';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { bool, eventListener, string } from '@philips-emr/tws-core/lib/component/prop-types';
import React, { PureComponent } from 'react';
import LoginAPI from './login-api';
import './login.scss';





@AwesomeComponent({ type: ComponentType.DATA_ENTRY, api: LoginAPI })
class Login extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: ''
    };
  }

  static propTypes = {
    uuid: string,
    createNewUserVisibility: bool,
    forgotPasswordVisibility: bool,
    onLogin: eventListener,
    onCreateNewUser: eventListener,
    onForgotPassword: eventListener,
  };

  static defaultProps = {
    createNewUserVisibility: true,
    forgotPasswordVisibility: true,
  };

  onLogin = () => {
    const { onLogin } = this.props;
    const username = this.state.username;
    const password = this.state.password;

    onLogin && onLogin({ username, password });
  };

  onCreateNewUser = () => {
    const { onCreateNewUser } = this.props;

    onCreateNewUser && onCreateNewUser();
  };

  onForgotPassword = () => {
    const { onForgotPassword } = this.props;

    onForgotPassword && onForgotPassword();
  };

  onChangePassword = (e) => {
    this.setState({ password: e.value });
  };

  onChangeUsername = (e) => {
    this.setState({ username: e.value });
  };

  renderCreateNewUser() {
    const { createNewUserVisibility } = this.props;

    if (!createNewUserVisibility) {
      return null;
    }

    return (
      <div className="c-login__actions-create-new-user">
        <Button size={Size.LARGE.value}
          theme="outline"
          value='Autenticar'
          type={ButtonType.SUBMIT.value}
          onClick={this.onCreateNewUser} />
      </div>
    );
  }

  renderForgotPassword() {
    const { forgotPasswordVisibility } = this.props;

    if (!forgotPasswordVisibility) {
      return null;
    }

    return (
      <div className="c-login__actions-forgot-password">
        <Link onClick={this.onForgotPassword} value='Esqueceu a senha?' />
      </div>
    );
  }

  render() {
    const { username, password } = this.state;

    return (
      <form className="c-login">

        <div className="c-login__user">
          <Input value={username}
            onChange={this.onChangeUsername}
            placeholder='Nome de usuário ou e-mail' />
        </div>

        <div className="c-login__password">
          <Input value={password}
            onChange={this.onChangePassword}
            type={InputType.PASSWORD.value} placeholder='Senha' />
        </div>

        <div className="c-login__actions">

          <div className="c-login__actions-submit">
            <Button size={Size.LARGE.value}
              background="#8EC200"
              value='teste'
              type={ButtonType.SUBMIT.value}
              onClick={this.onLogin} />
          </div>

          {this.renderForgotPassword()}

          {this.renderCreateNewUser()}

        </div>

      </form>

    );
  }
}

export default Login;
