import { ComponentAPI, AwesomeEvent } from '@philips-emr/tws-core';
import { ClickEventListener } from '@philips-emr/tws-core/lib/component/types';


class HpmsTermServiceApi extends ComponentAPI {

  @AwesomeEvent
  onSubmitTermoUso = new ClickEventListener();

  setEnabled(enabled: boolean): void {
    this.setState({ enabled });
  }

}

export default HpmsTermServiceApi;
