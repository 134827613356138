import Controller, { OnClose } from '@philips-emr/tws-core/lib/client';
import BaseController from '../../BaseController';

@Controller({ fragment: 'beneficiary-termination-modal', component: 'beneficiaryTerminationModalLayout' })
class BeneficiaryTerminationModalController extends BaseController {

  @OnClose({ target: ['beneficiaryTerminationModal'] })
  onClose(): void {
    this.historyService.back();
  }

}

export default BeneficiaryTerminationModalController;