import React, { PureComponent } from "react";
import { AwesomeComponent, ComponentType } from "@philips-emr/tws-core";
import { eventListener, object, bool } from '@philips-emr/tws-core/lib/component/prop-types';

import { Checkbox, Button } from "@philips-emr/tws-components/lib/components";
import { msg } from '@philips-emr/tws-core/lib/services/message-service';

import './hpms-term-service.scss';
import HpmsTermServiceApi from "../../api/hpms-term-service-api";
import { ButtonType, Size } from "@philips-emr/tws-constants";

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: HpmsTermServiceApi })
class HpmsTermService extends PureComponent {
  static DefaultProps = {
    showCheck: false
  }

  static propTypes = {
    data: object,
    showCheck: bool,
    enabled: bool,
    onSubmitTermoUso: eventListener,
  }

  state = {
    check: false
  }

  getTermo = () => {
    const { data } = this.props;

    return data && data.termo;
  }

  submitTermoUso() {
    const { onSubmitTermoUso } = this.props;

    onSubmitTermoUso && onSubmitTermoUso();
  }

  onCheck() {
    this.setState({ check: !this.state.check });
  }

  render() {
    const { showCheck, enabled } = this.props;

    return (
      <div className="c-term-service">
        <div className="c-hpms-term-service" dangerouslySetInnerHTML={{ __html: this.getTermo() }}>
        </div>
        {showCheck &&
          (<div className="c-term-service__check">
            <div className="c-term-service__check-checkbox">
              <Checkbox
                label={msg('hpms-components.hpms-term-service.label')}
                onChange={() => this.onCheck()}
                value={this.state.check}
                enabled={enabled}
              />
            </div>
            <div className="c-term-service__check-button">
              <Button size={Size.XLARGE.value}
                value='Confirmar'
                type={ButtonType.SUBMIT.value}
                enabled={this.state.check}
                onClick={() => this.submitTermoUso()} />
            </div>
          </div>)
        }
      </div>
    );
  }
}
export default HpmsTermService;
