import Polling from './polling';

interface PollingHandler {
  name: string;
  polling: Polling;
}

class PollingService {

  listPolling: PollingHandler[];

  constructor() {
    this.listPolling = [];
  }

  createPolling(name: string, handlerFunction: Function, timout: number = 0): void {
    if (!handlerFunction) {
      return;
    }

    const handler: PollingHandler | undefined = this.findPollingHandler(name);
    if (!handler) {
      this.listPolling.push({ name, polling: new Polling(handlerFunction, timout) });
      return;
    }
    handler.polling.startPolling();
  }

  stopAllPolling(): void {
    this.listPolling.forEach((handler: PollingHandler) => {
      handler.polling.stopPolling();
    })
  }

  startAllPolling(): void {
    this.listPolling.forEach((handler: PollingHandler) => {
      handler.polling.startPolling();
    })
  }

  removePolling(name: string): void {
    const handler: PollingHandler | undefined = this.findPollingHandler(name);
    if (!handler) return;

    handler.polling.stopPolling();
    this.listPolling = this.listPolling.filter(handler => handler.name !== name);
  }

  private findPollingHandler(name: string): PollingHandler | undefined {
    return this.listPolling.find(handler => handler.name === name);
  }

  stopPolling(name: string): void {
    const handler: PollingHandler | undefined = this.findPollingHandler(name);
    if (!handler) return;

    handler.polling.stopPolling();
  }

  startPolling(name: string): void {
    const handler: PollingHandler | undefined = this.findPollingHandler(name);
    if (!handler) return;

    handler.polling.startPolling();
  }
}

const pollingService = new PollingService();
export default pollingService;