import { ComponentAPI, AwesomeEvent } from '@philips-emr/tws-core';
import { ClickEventListener } from '@philips-emr/tws-core/lib/component/types';

class HpmsAttachmentsListApi extends ComponentAPI {

  @AwesomeEvent
  onOpenAttachment = new ClickEventListener();

}

export default HpmsAttachmentsListApi;
