import Controller, { OnClose, OnMount, OnAfterFetch } from '@philips-emr/tws-core/lib/client';
import BaseController from '../../BaseController';
import { Inject } from '@philips/odin';
import { FetchAPI, FormAPI } from '@philips-emr/tws-components-api';
import { FetchEvent } from '@philips-emr/tws-core/lib/component/types';

interface Registro {
  dsMaterial: string;
}

@Controller({ fragment: 'anexos-tiss-item-quimio', component: 'modal-tiss-quimio' })
class AnexosTissItemQuimioController extends BaseController {

  @Inject('fetch-tiss-quimio')
  fetchTissQuimio: FetchAPI;

  @Inject('form-tiss-quimio')
  formTissQuimio: FormAPI;

  @OnClose({ target: ['modal-tiss-quimio'] })
  onclose(): void {
    this.historyService.back({ scrollToTop: false });
  }

  @OnMount({ target: ['modal-tiss-quimio'] })
  onMount(): void {
    const { nrSeqBeneficiario, nrSeqGuia, nrSeqRequisicao, nrSeqMat } = this.historyService.getCurrentRouteParams();
    const nrSeqOrigem = nrSeqGuia || nrSeqRequisicao;
    const origem = nrSeqGuia ? 'autorizacao' : 'requisicao';

    this.context.set({ nrSeqBeneficiario, nrSeqOrigem, nrSeqMat, origem });
    this.fetchTissQuimio.forceFetch();
  }

  @OnAfterFetch({ target: ['fetch-tiss-quimio'] })
  onAfterFetch(event: FetchEvent): void {
    const data: Registro = event.data as Registro;
    if (!data) return;

    const { dsMaterial } = data;
    this.formTissQuimio.setTitle(dsMaterial || '');
  }
}
export default AnexosTissItemQuimioController;