import { Card, Option } from '@philips-emr/tws-components/lib/components';
import ContextMenu from '@philips-emr/tws-components/lib/components/context-menu/context-menu';
import Field from '@philips-emr/tws-components/lib/components/field/field';
import Text from '@philips-emr/tws-components/lib/components/text/text';
import { Alignment, Placement, Size } from '@philips-emr/tws-constants';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { array, eventListener, number, string } from '@philips-emr/tws-core/lib/component/prop-types';
import { HttpService } from '@philips-emr/tws-core/lib/services';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import React, { PureComponent } from 'react';
import HpmsRequisicaoAutorizacaoCardApi from '../../api/hpms-requisicao-autorizacao-card-api';
import { ReactComponent as CheckSVG } from '../../assets/images/global/hpms-check.svg';
/** SVGs */
import { ReactComponent as TimeSVG } from '../../assets/images/global/hpms-time.svg';
import { ReactComponent as WarningSVG } from '../../assets/images/global/hpms-warning.svg';
import { Endpoint } from '../../constants/endpoints';
import excerpts from '../../util/excerpts';
import './hpms-requisicao-autorizacao-card.scss';



@AwesomeComponent({ type: ComponentType.LIST, api: HpmsRequisicaoAutorizacaoCardApi })
class HpmsRequisicaoAutorizacaoCard extends PureComponent {

  static propTypes = {
    nrSequencia: number,
    ieTipoGuia: number,
    ieEstagio: number,
    dsEstagio: string,
    dtRequisicao: string,
    nrSeqBeneficiario: string,
    guias: array,
    onDetalhesClick: eventListener,
    onGuiaDetalhesClick: eventListener,
  }

  state = {
    guias: []
  }

  static defaultProps = {
    nrSequencia: '',
    dsEstagio: ''
  }

  componentDidMount() {
    const { nrSequencia, nrSeqBeneficiario } = this.props;

    HttpService.get(Endpoint.REQUISICOES_GUIAS, { nrSeqBeneficiario, nrSeqRequisicao: nrSequencia }).then(response => {
      this.setState({ guias: response.body });
    });
  }

  onDetalhes(nrSeqRequisicao) {
    const { onDetalhesClick } = this.props;

    onDetalhesClick && onDetalhesClick({ value: nrSeqRequisicao });
  }

  onGuiaDetalhes(nrSeqGuia, nrSeqRequisicao) {
    const { onGuiaDetalhesClick } = this.props;

    onGuiaDetalhesClick && onGuiaDetalhesClick({ value: { nrSeqGuia, nrSeqRequisicao } });
  }


  renderHeader() {
    const { nrSequencia, ieTipoGuia, dtRequisicao, dsEstagio } = this.props;

    return (
      <div className="c-hpms-requisicao-autorizacao-card__header">
        <div className="c-hpms-requisicao-autorizacao-card__header-left">
          <div className="c-hpms-requisicao-autorizacao-card__header-left-content">
            <div className="c-hpms-requisicao-autorizacao-card__header-left-content-tipo-guia">
              {msg('authorization.form-type.' + ieTipoGuia)}
            </div>
            <div className="c-hpms-requisicao-autorizacao-card__header-left-content-sequencia">
              {nrSequencia}
            </div>
            <div className="c-hpms-requisicao-autorizacao-card__header-left-footer">
              <div className="c-hpms-requisicao-autorizacao-card__header-left-content-estagio">
                {this.getSvg()} {excerpts(dsEstagio, 16, '...')}
              </div>
              <div className="c-hpms-requisicao-autorizacao-card__header-left-content-data">
                <Text value={format('date', dtRequisicao, { type: 'shortDate' })} alignment={Alignment.RIGHT}></Text>
              </div>
            </div>
          </div>
        </div>
        <div className="c-hpms-requisicao-autorizacao-card__header-rigth">
          <div className="c-hpms-requisicao-autorizacao-card__header-rigth-context-menu">
            <ContextMenu placement={Placement.RIGHT}>
              <Option onClick={() => this.onDetalhes(nrSequencia)} value={msg('hpms-components.details')} />
            </ContextMenu>
          </div>
        </div>
      </div>
    )
  }

  renderBody() {
    const nrSeqRequisicao = this.props.nrSequencia;
    const { guias } = this.state;

    if (guias.length == 0) {
      return null;
    }

    return (
      guias.map(guia => {
        const { nrSequencia, nmPrestador } = guia;

        return (
          <div key={nrSequencia} className="c-hpms-requisicao-autorizacao-card__body">
            <div className="c-hpms-requisicao-autorizacao-card__body-left">
              <div>
                <Field label={'Guia'} width={12}>
                  <Text value={nrSequencia} size={Size.SMALL}></Text>
                </Field>
              </div>
              <div>
                <Field label={'Prestador'} width={12} >
                  <Text value={nmPrestador} size={Size.SMALL}></Text>
                </Field>
              </div>
            </div>
            <div className="c-hpms-requisicao-autorizacao-card__body-rigth">
              <ContextMenu placement={Placement.RIGHT}>
                <Option value={msg('hpms-components.details')} onClick={() => this.onGuiaDetalhes(nrSequencia, nrSeqRequisicao)} />
              </ContextMenu>
            </div>
          </div>
        );
      })
    );
  }

  render() {
    return (
      <Card width={12}>
        <div className="c-hpms-requisicao-autorizacao-card">
          {this.renderHeader()}

          {this.renderBody()}
        </div>
      </Card>
    )
  }

  getColor() {
    const { ieEstagio } = this.props;
    switch (ieEstagio) {
      case 1:
        return 'gray';
      case 2:
      case 6:
        return 'green';
      case 7:
        return 'orange';
      default:
        return '';
    }
  }

  getSvg() {
    const { ieEstagio } = this.props;

    switch (ieEstagio) {
      case 7:
        return <WarningSVG className={`c-hpms-requisicao-autorizacao-card__color-${this.getColor()}`} />;
      case 1:
      case 2:
      case 6:
        return <CheckSVG className={`c-hpms-requisicao-autorizacao-card__color-${this.getColor()}`} />;
      default:
        return <TimeSVG />;
    }
  }

}

export default HpmsRequisicaoAutorizacaoCard;
