import { ComponentAPI, AwesomeEvent } from '@philips-emr/tws-core';
import { ClickEventListener } from '@philips-emr/tws-core/lib/component/types';

class HpmsMensagemCardApi extends ComponentAPI {

  @AwesomeEvent
  onOpenAttachment = new ClickEventListener();
  
  setSeqBeneficiario(nrSeqBeneficiario: number): void {
    this.setState({ nrSeqBeneficiario });
  }

}

export default HpmsMensagemCardApi;
