import NotFound from '@philips-emr/tws-components/lib/components/not-found/not-found';
import { AwesomeComponent, ComponentType } from "@philips-emr/tws-core";
import { bool, string } from "@philips-emr/tws-core/lib/component/prop-types";
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import React, { PureComponent } from "react";
import HpmsNoResultApi from "./hpms-no-result-api";

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: HpmsNoResultApi })
class HpmsNoResult extends PureComponent {

  static propTypes = {
    titleExpression: string,
    subtitleExpression: string,
    visible: bool
  }

  static defaultProps = {
    visible: false,
    titleExpression: "",
    subtitleExpression: ""
  };

  render() {
    const { titleExpression, subtitleExpression, visible } = this.props;

    if (!visible){
      return null;
    }

    return (
      <NotFound titleExpression={titleExpression && msg(titleExpression)} subtitleExpression={subtitleExpression && msg(subtitleExpression)} />
    );
  }
}

export default HpmsNoResult;