import { ToastAPI } from "@philips-emr/tws-components-api";
import Types from '@philips-emr/tws-constants/lib/type';
import { RequestResponse } from "@philips-emr/tws-core/lib/services/http-service";
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import ToastService from "../services/toast-service";
import { getBreakpoint } from '@philips-emr/tws-utils';
import { Breakpoint } from '@philips-emr/tws-constants';

interface Mensagem {
  [key: string]: string;
}

const NOT_RENDERED_COMPONENT_APIS = 'You should not access not rendered component apis.';

class Utils {

  static catchHandler(response: RequestResponse, toast: ToastAPI | undefined = undefined): void {

    const msg500 = msg('$expression.$956418');
    const isStatus500 = response.status === 500;
    const data = response.body as Mensagem;
    const message = (data && (typeof data === 'object' ? data[Object.keys(data)[0]] : data)) || '';

    if (toast) {
      if (isStatus500) {
        ToastService.showMessage(toast, Types.DANGER, msg500);
      } else {
        ToastService.showMessage(toast, Types.CAUTION, message);
      }
    } else {
      if (isStatus500) {
        ToastService.danger(msg500);
      } else {
        ToastService.caution(message);
      }
    }
  }

  static catchRenderException(arg: () => void): void {
    try {
      arg();
    } catch (e) {
      const ignoreErr = [NOT_RENDERED_COMPONENT_APIS];
      if (!ignoreErr.includes(e.message)) {
        console.error(e);
      }
    }
  }

  static shouldRenderMobile(): boolean {
    const breakpoint = getBreakpoint();
    return breakpoint === Breakpoint.SM || breakpoint === Breakpoint.XSM;
  }
}

export default Utils;