import '@philips-emr/hpms-components';
import { Route } from '@philips-emr/tws-components';
import { AuthenticationService, ConfigurationService } from '@philips-emr/tws-core/lib/services';
import HistoryService from '@philips-emr/tws-core/lib/services/history-service';
import Engine, { Application, MetadataStore, ReactRenderer } from '@philips-emr/tws-engine';
import { getViewportElement } from '@philips-emr/tws-utils';
import React, { ReactElement } from 'react';
import IdleTimer from 'react-idle-timer';
import { Redirect, RouteComponentProps } from 'react-router';
import { isFunction } from 'util';
import '../controllers';
import Audit from './audit';
import bootstrap from './environment';
import { ApplicationState } from './types';

function getRedirect(props: RouteComponentProps): string {
  const { location } = props;

  const result = /redirect=([\w|\d|/]+)&*/.exec(location.search || '')
  const url = result ? result[1] : '/login';
  return HistoryService.makeURL(url);
}

class App extends React.Component<RouteComponentProps, ApplicationState> {

  state = {
    engine: new Engine({
      renderer: new ReactRenderer(),
      metadataStore: new MetadataStore()
    }),
    loaded: false
  };

  unlisten: Function = () => { };

  componentDidMount(): void {
    bootstrap(this.state.engine)
      .then(() => {
        this.setState({ loaded: true });
        const audit = new Audit();
        audit.logTrocaDeRota(HistoryService.getCurrentRoute());
        this.unlisten = audit.addRouteListener();
      });
  }

  componentWillUnmount(): void {
    if (isFunction(this.unlisten)) {
      this.unlisten();
    }
  }

  isHome(): boolean {
    const pathname = this.props.location.pathname;
    return pathname === '' || pathname === '/';
  }

  render(): ReactElement | null {
    const { loaded, engine } = this.state;

    if (!loaded) {
      return null;
    } else if (this.isHome()) {
      const redirect = getRedirect(this.props);
      return <Redirect to={redirect} />
    }

    const onIdle = (): void => {
      if (AuthenticationService.isAuthenticated()) {
        AuthenticationService.logout().then(() => {
          AuthenticationService.redirectToInactivity();
        });
      }
    };

    const { inactivity } = ConfigurationService.getAuthenticationConfiguration() || { inactivity: { time: 15 * 60 * 1000 } };
    const maxIdleTime = inactivity.time;

    return (
      <Route route={'/'} >
        <Application fragment="app" engine={engine} />
        <IdleTimer
          element={getViewportElement()}
          onIdle={onIdle}
          timeout={maxIdleTime}
        />
      </Route>
    )
  }

}

export default App;
