import { FormAPI, FetchAPI, FieldsetAPI, FieldAPI } from '@philips-emr/tws-components-api';
import Controller, { OnClose, OnMount, OnAfterFetch } from '@philips-emr/tws-core/lib/client';
import { SessionStorageService } from '@philips-emr/tws-core/lib/services';
import { Inject } from '@philips/odin';
import BaseController from '../../BaseController';
import { FetchEvent, MountEvent } from '@philips-emr/tws-core/lib/component/types';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';

@Controller({ fragment: 'authorization-items-detalhe-modal', component: 'modal-item-detalhe' })
class AuthorizationItemsDetalheModalController extends BaseController {

  @Inject('form-item-detalhe')
  formItemDetalhe: FormAPI;
  @Inject('fetch-glosas-ocorrencias')
  fetchGlosasOcorrencias: FetchAPI;
  @Inject('fieldset-glosas-ocorrencias')
  fieldsetGlosasOcorrencias: FieldsetAPI;
  @Inject('fieldset-opme')
  fieldsetOpme: FieldsetAPI;
  @Inject('fieldset-quimio')
  fieldsetQuimio: FieldsetAPI;
  @Inject('fetch-tiss')
  fetchTiss: FetchAPI;

  @OnClose({ target: ['modal-item-detalhe'] })
  onclose(): void {
    SessionStorageService.removeItem('autorizacao_item_detalhe');
    this.historyService.back();
  }

  @OnMount({ target: ['modal-item-detalhe'] })
  onMount(): void {
    const { nrSeqBeneficiario, nrSeqGuia } = this.historyService.getCurrentRouteParams();
    const { data, endpoint, parametro } = SessionStorageService.getItem('autorizacao_item_detalhe');
    const { ieTipoItem, nrSeqItem, ieTipoAnexo } = data;
    const nrSeqMat = ieTipoItem === 'M' && nrSeqItem;

    this.context.set({ nrSeqBeneficiario, nrSeqGuia, ...parametro, ...(nrSeqMat && { nrSeqMat }) });

    this.formItemDetalhe.setData(data);
    this.fetchGlosasOcorrencias.setEndpoint(endpoint);
    this.fetchGlosasOcorrencias.forceFetch();

    if (nrSeqMat && ['OP', 'QU'].includes(ieTipoAnexo)) {
      this.fetchTiss.forceFetch();

      if (ieTipoAnexo == 'OP') {
        this.fieldsetQuimio.setVisible(false);
      } else {
        this.fieldsetOpme.setVisible(false);
      }
    } else {
      this.fieldsetOpme.setVisible(false);
      this.fieldsetQuimio.setVisible(false);
    }
  }

  @OnMount({ target: ['field-odonto'] })
  onMountFieldOdonto(event: MountEvent): void {
    const api = event.target as FieldAPI;
    const { parametro } = SessionStorageService.getItem('autorizacao_item_detalhe');
    const { ieTipoGuia } = parametro as { ieTipoGuia: string};
    api.setVisible(ieTipoGuia === '11');
    api.setLabel(`${msg('authorization.Region')} / ${msg('authorization.Tooth')} / ${msg('authorization.Tooth-faces')}`);
  }

  @OnAfterFetch({ target: ['fetch-glosas-ocorrencias'] })
  onAfterFetch(event: FetchEvent): void {
    if (Object.keys(event.data as []).length == 0) {
      this.fieldsetGlosasOcorrencias.setVisible(false);
    }
  }
}

export default AuthorizationItemsDetalheModalController;