import { Text } from '@philips-emr/tws-components/lib/components';
import Card from '@philips-emr/tws-components/lib/components/card/card';
import Link from '@philips-emr/tws-components/lib/components/link/link';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { eventListener, number } from '@philips-emr/tws-core/lib/component/prop-types';
import { HttpService } from '@philips-emr/tws-core/lib/services';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import React, { PureComponent } from 'react';
import HpmsMensagensApi from '../../../api/hpms-mensagens-api';
import { Endpoint } from '../../../constants/endpoints';
import HpmsNotificacaoCard from '../../hpms-notificacao-card/hpms-notificacao-card';
import './hpms-mensagens.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: HpmsMensagensApi })
class HpmsMensagens extends PureComponent {

  state = {};

  static defaultProps = {
    width: 6
  }

  static propTypes = {
    onMoreClick: eventListener,
    width: number
  }

  onMoreClick = () => {
    const { onMoreClick } = this.props;

    onMoreClick && onMoreClick();
  }

  getMensagens() {
    if (this.state.nrSeqBeneficiario) {
      HttpService.get(Endpoint.GET_MENSAGENS, { nrSeqBeneficiario: this.state.nrSeqBeneficiario, page: 0, limit: 2 })
        .then(response => this.setState({ mensagens: response.body }));
    }
  }

  render() {
    const { width } = this.props;
    if (!this.state.mensagens) {
      this.getMensagens();
    }

    return (
      <Card width={width} className="c-hpms-notifications">

        <div className="c-hpms-notifications__title-section">
          <div className="c-hpms-notifications__title">
            <Text>{msg('hpms-components.mensagens')}</Text>
          </div>
        </div>

        <div className="c-hpms-notifications__content-section">
          {
            this.state.mensagens && this.state.mensagens.map(mensagem => (
              <HpmsNotificacaoCard
                key={'hpms-notificacao-card-' + mensagem.nrSequencia}
                dsTexto={mensagem.dsTexto}
                limiteCaracteresDescricao={145}
                dsTitulo={mensagem.dsTitulo}
                limiteCaracteresTitulo={60}
                dtCriacao={mensagem.dtLiberacao}
                mostrarTitulo={true}
                qtLeitura={mensagem.qtLeitura}
                ieImportante={mensagem.ieImportante}
              />
            ))
          }
        </div>

        <div className="c-hpms-notifications__footer-section">
          <div className="c-hpms-notifications__footer">
            <Link value="beneficiary-messages.show-all" onClick={() => this.onMoreClick()} />
          </div>
        </div>

      </Card>
    );
  }
}

export default HpmsMensagens;
