import Controller, { OnClose, OnMount } from '@philips-emr/tws-core/lib/client';
import BaseController from '../../BaseController';
import { Route } from '../../../constants/routes';

@Controller({ fragment: 'termo-uso-detalhe', component: 'modal-termo-uso-detalhe' })
class TermoUsoDetalheController extends BaseController {


  @OnMount({ target: 'modal-termo-uso-detalhe' })
  onMountModalTermoUsoDetalhe(): void {
    const { nrSeqTermo } = this.historyService.getCurrentRouteParams();
    this.context.set({ nrSeqTermo });
  }

  @OnClose({ target: 'modal-termo-uso-detalhe' })
  onClose(): void {
    this.historyService.push(Route.LISTA_TERMOS_DE_USO);
  }

}

export default TermoUsoDetalheController;