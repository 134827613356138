import employerIdentificationNumberValidator from './validator/employer-identification-number-validator';
import { createAlphanumericFormatter } from '@philips-emr/tws-model/lib/generators/';

export const EMPLOYER_IDENTIFICATION = 'employee-identification-number';

const EmployerIdentificationNumberFormatter = createAlphanumericFormatter({
  name: EMPLOYER_IDENTIFICATION,
  mask: '00.000.000/0000-00',
  validate: employerIdentificationNumberValidator,
  invalidMessage: 'hpms-formatter.employee-identification-number.invalid',
});

export default EmployerIdentificationNumberFormatter;
