import BaseController from "../../BaseController";
import Controller, { OnMount } from "@philips-emr/tws-core/lib/client";
import { Inject } from '@philips/odin';
import { ImageAPI } from "@philips-emr/tws-components-api";
import ConfigurationService from "../../../services/configuration-service";

@Controller({ fragment: 'new-user-confirmation-fail', component: 'layout-confirmation-fail' })
class NewUserConfirmationFail extends BaseController {

  @Inject('login-image-header')
  image: ImageAPI;

  @OnMount({ target: ['layout-confirmation-fail'] })
  onLayoutMount(): void {
    ConfigurationService.getConfigurationData().then(layoutData => {
      this.image.setSrc(layoutData.dsUrlLogoHeaderLogin);
    });
  }
}

export default NewUserConfirmationFail;