import React, { PureComponent } from 'react';

import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { bool, object } from '@philips-emr/tws-core/lib/component/prop-types';

import { HpmsTextHtmlApi } from '../../api';

interface TextHtmlProps {
  overflow: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: HpmsTextHtmlApi })
export default class HpmsTextHtml extends PureComponent<TextHtmlProps> {

  static propTypes = {
    data: object.notEnumerable(),
    overflow: bool.group('text-html'),

  };

  static defaultProps = {
    overflow: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render(): any {

    const html = this.props.value;
    const element = document.createElement('div');
    element.innerHTML = html;
    const innerHTML = element.textContent?.split('\\n').join(' ');
    const overflow = innerHTML && innerHTML?.length > 100 ? innerHTML?.substring(0, 100).concat('...') : innerHTML;

    return (
      <div className="c-text-html__value">
        {!this.props.overflow ?
          <div className="c-text-html__value--width"
            dangerouslySetInnerHTML={{ __html: this.props.data ? this.props.data : this.props.value }}></div>
          : <div className="c-text-html__value--width"
            dangerouslySetInnerHTML={{ __html: overflow ? overflow : '' }}></div>}
      </div>
    );
  }

}
