import HpmsLoaderApi from "@philips-emr/hpms-components/src/api/hpms-loader-api";
import { ButtonAPI, FieldAPI, FormAPI, ImageAPI } from "@philips-emr/tws-components-api";
import Controller, { OnClick, OnMount, OnValidate } from "@philips-emr/tws-core/lib/client";
import { HttpService } from "@philips-emr/tws-core/lib/services";
import { Inject } from '@philips/odin';
import { Endpoint } from "../../../constants/endpoints";
import { Route } from "../../../constants/routes";
import ConfigurationService from "../../../services/configuration-service";
import { confirmationFieldValidator, CONFIRMATION_FIELD_VALID, passwordValidator, PASSWORD_VALIDATOR } from '../../../utils/user-util';
import BaseController from "../../BaseController";
import { ValidateEvent } from "@philips-emr/tws-core/lib/component/types";

@Controller({ fragment: 'nova-senha', component: 'layout-recuperar-senha' })
class CriarNovaSenha extends BaseController {

  @Inject('login-image-header')
  image: ImageAPI;

  @Inject('field-senha')
  passwordField: FieldAPI;

  @Inject('field-confirmar-senha')
  passwordConfirmationField: FieldAPI;

  @Inject('form-senha')
  form: FormAPI;

  @Inject('button-criar')
  btnCriar: ButtonAPI;

  @Inject('hpms-loader')
  loader: HpmsLoaderApi;

  @OnMount({ target: ['layout-recuperar-senha'] })
  onLayoutMount(): void {
    this.btnCriar.setEnabled(false);

    ConfigurationService.getConfigurationData().then(layoutData => {
      this.image.setSrc(layoutData.dsUrlLogoHeaderLogin);
    });

    const { hash } = this.historyService.getCurrentRouteParams();

    HttpService.get(Endpoint.POST_NOVA_SENHA, { hash })
      .catch(() => this.historyService.push(Route.NOVA_SENHA_FALHA));
  }

  @OnMount({ target: ['form-senha'] })
  onFormMount(): void {
    this.passwordConfirmationField.addLocalValidator(CONFIRMATION_FIELD_VALID, (value) => {
      const confirmationValue = this.form.getRecord()['newPassword'];
      return confirmationFieldValidator(value, confirmationValue);
    });
    this.passwordField.addLocalValidator(PASSWORD_VALIDATOR, passwordValidator);
  }

  @OnValidate({ target: ['form-senha'] })
  onValidateForm(event: ValidateEvent): void {
    this.btnCriar.setEnabled(event.valid);
  }

  @OnClick({ target: ['button-criar'] })
  onBtnClick(): void {
    const { hash } = this.historyService.getCurrentRouteParams();
    this.btnCriar.setEnabled(false);
    this.loader.setVisible(true);
    HttpService.post(Endpoint.POST_NOVA_SENHA, { hash }, { body: this.form.getRecord() })
      .then(() => this.historyService.push(Route.NOVA_SENHA_SUCESSO))
      .catch(() => this.historyService.push(Route.NOVA_SENHA_FALHA));
  }

}

export default CriarNovaSenha;