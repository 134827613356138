import { Route } from '@philips-emr/tws-components';
import { DeckAPI, PanelAPI, AutocompleteAPI, FilterAPI, FetchAPI } from '@philips-emr/tws-components-api';
import Controller, { OnAfterFetch, OnClick, OnMount, OnBeforeFetch, OnChange } from '@philips-emr/tws-core/lib/client';
import { ClickEvent, FetchEvent, ChangeEvent } from '@philips-emr/tws-core/lib/component/types';
import { Inject } from '@philips/odin';
import ReportService from '../../../services/report-service';
import BaseController from '../../BaseController';
import { Value } from '@philips-emr/tws-core';


interface CardValue {
  nrSeqMensPagadorIr: number;
  dsAnoBase: string;
  mainContent: string;
}

interface Pagador {
  nrSeqPagador: number;
}
@Controller({ fragment: 'income-tax', component: 'income-tax-layout' })
class IncomeTaxController extends BaseController {

  @Inject('panel-filter-pagador')
  panelFilterPagador: PanelAPI;

  @Inject('deck')
  deck: DeckAPI;

  reportService: ReportService;

  @Inject('autocomplete-pagador')
  autocompletePagador: AutocompleteAPI;

  @Inject('filter-pagador')
  filterPagador: FilterAPI;

  @Inject('fetch-lotes')
  fetchTermoUso: FetchAPI;

  @OnMount({ target: ['income-tax-layout'] })
  onMount(): void {
    this.reportService = new ReportService('7', this);
  }

  @OnAfterFetch({ target: ['fetch-pagadores'] })
  onAfterFetchPagadores(event: FetchEvent): void {

    if (event.data) {
      const pagadorData: Pagador[] = event.data as Pagador[]
      const pagador: Pagador = pagadorData[0];
      this.autocompletePagador.setValue(pagador as unknown as Record<string, Value>);
      this.context.set({ nrSeqPagador: pagador.nrSeqPagador });
      this.context.set({ nrSeqPagadorOK : ''});
    }
  }

  @OnBeforeFetch({ target: ['fetch-lotes'] })
  onBeforeFetchPagadoress(event: FetchEvent): void {
    const nrSeqPagadorSelected: number = this.context.get('nrSeqPagadorOK') as number;
    if (!event.data && !nrSeqPagadorSelected || event.data == [] && !nrSeqPagadorSelected) { 
      const nrSeqPagador: number = this.context.get('nrSeqPagador') as number;
      this.fetchTermoUso.setParameters({ 'nrSeqPagador': nrSeqPagador });
    } else {
      this.fetchTermoUso.setParameters({ 'nrSeqPagador': nrSeqPagadorSelected });
    }

  }

  @OnChange({ target: ['field-0'] })
  onChangeRelatorio(e: ChangeEvent<Record<string, Value>>): void {
    if (e.value && !e.oldValue) {
      this.fetchTermoUso.setParameters({ 'nrSeqPagador': e.value.nrSeqPagador });
      const value: number = e.value.nrSeqPagador as number;
      
      this.context.set({ nrSeqPagadorOK : value});
    }
    if (e.oldValue && !e.value) { 
      this.context.set({ nrSeqPagadorOK : '000'});
    }

    if (e.value && e.oldValue) {
      this.fetchTermoUso.setParameters({ 'nrSeqPagador': e.value.nrSeqPagador });
      const value: number = e.value.nrSeqPagador as number;
      
      this.context.set({ nrSeqPagadorOK : value});
    }
  }

  @OnClick({ target: ['deck'] })
  onCardClick(event: ClickEvent): void {
    const value = event.value as CardValue;
    const param = {
      NR_SEQ_PAGADOR_IR: value.nrSeqMensPagadorIr
    };
    this.reportService && this.reportService.abreListaRelatorios(param, Route.INCOME_TAX_REPORTS, '$expression.$969644');
  }

}
export default IncomeTaxController;
