import { FilterAPI } from '@philips-emr/tws-components-api';
import Controller, { OnMount, OnSelect, OnUnmount } from '@philips-emr/tws-core/lib/client';
import { MountEvent, SelectEvent } from '@philips-emr/tws-core/lib/component/types';
import { Endpoint } from '../../../constants/endpoints';
import CardMensalidade from '../../../constants/hpms-monthlyfee-interface';
import { Route } from '../../../constants/routes';
import obterLinks from '../../../services/external-links-service';
import ReportService from '../../../services/report-service';
import ToastService from '../../../services/toast-service';
import BaseController from '../../BaseController';

@Controller({ fragment: 'monthly-fees', component: 'monthly-fees-layout' })
class MonthlyFeesController extends BaseController {

  @OnSelect({ target: ['hpms-monthly-fee-card'] })
  onSelectMenuCardMensalidade(e: SelectEvent<CardMensalidade>): void {
    const { item, nrSeqMensalidade, nrTitulo } = e.value;

    this.context.set({ nrSeqMensalidade });

    if (item === 'nota_fiscal') {
      const params = {
        nrSeqBeneficiario: e.value.nrSeqBeneficiario,
        nrSeqMensalidade: e.value.nrSeqMensalidade,
      };

      obterLinks(this, Endpoint.GET_LINKS_NFS_MENSALIDADES, params, Route.NFS_MENSALIDADES_LISTA, "$expression.$688160");
    }

    if (item === 'observacoes') {
      this.historyService.push(Route.OBSERVACOES_MENSALIDADE_LISTA, { nrSeqMensalidade });
    }

    if (item === 'segunda_via') {
      const repService = new ReportService('6', this);
      this.emitirRelatorio(repService, { NR_TITULO: nrTitulo });
    }

    if (item === 'detalhamento') {
      const repService = new ReportService('10', this);
      this.emitirRelatorio(repService, { NR_SEQ_MENSALIDADE: nrSeqMensalidade });
    }
  }

  emitirRelatorio(repService: ReportService, param: {}): void {
    repService.getRelatoriosByCdGrupo()
      .then(reps => {
        if (reps.length <= 0) {
          ToastService.caution('$expression.$736330');
          return;
        }

        repService.abreListaRelatorios(param, Route.MONTHLY_FEES_REPORTS);
      });
  }

  @OnMount({ target: 'filter-monthly-fees' })
  onFilterMount(e: MountEvent): void {

    const filter = e.target as FilterAPI;
    const restored = filter.restoreState(true);

    if (restored) {
      return;
    }

    const currentDate = new Date();
    const lastYear = new Date();
    lastYear.setFullYear(currentDate.getFullYear() - 1);

    const record = {
      'dtInicio': lastYear.toISOString(),
      'dtFim': currentDate.toISOString()
    };

    filter.setData(record);
    filter.submit();
  }

  @OnUnmount({ target: 'filter-monthly-fees' })
  onFilterUnmount(e: MountEvent): void {

    const filter = e.target as FilterAPI;

    filter.persistState();
  }

}
export default MonthlyFeesController;