import { ComponentAPI, AwesomeEvent } from '@philips-emr/tws-core';

import { ClickEventListener } from '@philips-emr/tws-core/lib/component/types/clickable';

class LoginAPI extends ComponentAPI {

  @AwesomeEvent
  onLogin = new ClickEventListener();

  @AwesomeEvent
  onForgotPassword = new ClickEventListener();

  @AwesomeEvent
  onCreateNewUser = new ClickEventListener();

  isCreateNewUserVisible() {
    return this.state.createNewUserVisibility;
  }

  setCreateNewUserVisibility(createNewUserVisibility) {
    this.setState({ createNewUserVisibility });
  }

  isForgotPasswordVisible() {
    return this.state.forgotPasswordVisibility;
  }

  setForgotPasswordVisibility(forgotPasswordVisibility) {
    this.setState({ forgotPasswordVisibility });
  }

  getUserName() {
    return this.state.username;
  }

  setUserName(username) {
    this.setState({ username });
  }

  getPassword() {
    return this.state.password;
  }

  setPassword(password) {
    this.setState({ password });
  }

}

export default LoginAPI;