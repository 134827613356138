import { Text } from "@philips-emr/tws-components";
import { ChecklistAPI, FetchAPI, FieldAPI, FieldsetAPI, FormAPI, InputAPI, PanelAPI, RepeaterAPI, StepsAPI, TextAPI, ToastAPI } from "@philips-emr/tws-components-api";
import Controller, { OnAfterFetch, OnChange, OnClick, OnDynamicContentRender, OnMount, OnSubmit, OnValidate } from "@philips-emr/tws-core/lib/client";
import EventListener from '@philips-emr/tws-core/lib/client/event-listener';
import { ChangeEvent, FetchEvent, NavigableEvent, RenderEvent, ValidateEvent } from "@philips-emr/tws-core/lib/component/types";
import { AccessibilityService, HttpService } from "@philips-emr/tws-core/lib/services";
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { Inject } from '@philips/odin';
import React from 'react';
import { Endpoint } from "../../../constants/endpoints";
import ToastService from "../../../services/toast-service";
import { emailValidator, EMAIL_VALIDATOR } from "../../../utils/user-util";
import Utils from "../../../utils/utils";
import BaseController from '../../BaseController';

const { scrollToTop } = AccessibilityService;

interface Retorno {
  nrSeqTermo: number;
  checklist: boolean;
  list: [];
  label: string;
  nmBeneficiario: string;
  ieTitularidade: string;
  nrSeqSegurado: number;
  key: string;
  checked: boolean;
  fieldsRequired: boolean;
  message: string;
  value: boolean;
}

@Controller({ fragment: 'cancelamento-steps', component: 'layout-steps-cancelamento' })
class CancelamentoStepsController extends BaseController {

  identifier1 = 'A967128';
  identifier2 = 'B967130';
  identifier3 = 'C967132';
  identifier4 = 'D644623';

  @Inject('steps-cancelamento')
  stepsCancelamento: StepsAPI;

  @Inject('text-mensagem')
  textMensagem: TextAPI;

  @Inject('panel-steps')
  panelSteps: PanelAPI;

  @Inject('panel-retorno')
  panelRetorno: PanelAPI;

  @Inject('panel-loader')
  panelLoader: PanelAPI;

  @Inject('field-1')
  fieldEmail: FieldAPI;

  @Inject('fieldset-termo')
  fieldsetTermo: FieldsetAPI;

  @Inject('fieldset-list')
  fieldsetList: FieldsetAPI;

  @Inject('fieldset-checklist')
  fieldsetChecklist: FieldsetAPI;

  @Inject('fieldset-step-3-form')
  fieldsetStep3Form: FieldsetAPI;

  @Inject('fieldset-step-3-text')
  fieldsetStep3Text: FieldsetAPI;

  @Inject('fieldset-step-4-form')
  fieldsetStep4Form: FieldsetAPI;

  @Inject('fieldset-step-4-text')
  fieldsetStep4Text: FieldsetAPI;

  @Inject('form-step-1')
  formStep1: FormAPI;

  @Inject('form-step-2')
  formStep2: FormAPI;

  @Inject('form-step-3')
  formStep3: FormAPI;

  @Inject('form-step-4')
  formStep4: FormAPI;

  @Inject('form-retorno')
  formRetorno: FormAPI;

  @Inject('repeater-step-2')
  repeaterStep2: RepeaterAPI;

  @Inject('checklist-beneficiarios')
  checklistBeneficiarios: ChecklistAPI;

  @Inject('fetch-autocomplete-titular')
  fetchAutocompleteTitular: FetchAPI;

  @Inject('field-cpf')
  fieldCpf: FieldAPI;

  @Inject('field-step-4-nmpagador')
  fieldStep4Nmpagador: FieldAPI;

  @Inject('field-step-4-nrcpf')
  fieldStep4Nrcpf: FieldAPI;

  @Inject('field-step-4-banco')
  fieldStep4Banco: FieldAPI;

  @Inject('field-step-4-agencia')
  fieldStep4Agencia: FieldAPI;

  @Inject('field-step-4-agencia-digito')
  fieldStep4AgenciaDigito: FieldAPI;

  @Inject('field-step-4-conta')
  fieldStep4Conta: FieldAPI;

  @Inject('field-step-4-conta-digito')
  fieldStep4ContaDigito: FieldAPI;

  @Inject('field-message-step-3')
  fieldMessageStep3: FieldAPI;

  @Inject('input-cpf')
  inputCpf: InputAPI;

  @Inject('toast-yes-no')
  toastYesNo: ToastAPI;

  @Inject('text-3')
  textMessageTitular: TextAPI;

  @Inject('fetch-form-4')
  fetchForm4: FetchAPI;

  @Inject('fetch-checklist')
  fetchChecklist: FetchAPI;

  listBeneficiarios: [] = [];
  listBeneficiariosChecklist: Retorno[] = [];
  titular: number | undefined;

  @OnMount({ target: ['form-step-1'] })
  onMountForm1(): void {
    this.fieldEmail.addLocalValidator(EMAIL_VALIDATOR, emailValidator);
  }

  @OnAfterFetch({ target: ['fetch-steps'] })
  onAfterFetchFS(event: FetchEvent): void {
    const retorno = event.data;

    if (retorno && typeof retorno === 'object') {
      this.stepsCancelamento.setHiddenSteps(retorno as []);
    } else if (retorno) {
      this.textMensagem.setValue(retorno);
      this.panelSteps.setVisible(false);
    }
  }

  @OnAfterFetch({ target: ['fetch-form-1'] })
  onAfterFetchF1(event: FetchEvent): void {
    const { nrSeqTermo } = event.data as Retorno;
    if (!nrSeqTermo) {
      this.fieldsetTermo.setVisible(false);
    }
  }

  @OnAfterFetch({ target: ['fetch-form-2'] })
  onAfterFetchF2(event: FetchEvent): void {
    const { checklist, list, message } = event.data as Retorno;
    this.listBeneficiarios = list || [];
    this.fetchChecklist.forceFetch();
    if (checklist) {
      this.fieldsetList.setVisible(false);
      this.textMessageTitular.setValue(message);
    } else {
      this.fieldsetChecklist.setVisible(false);
      this.repeaterStep2.setData(list);
    }
  }

  @OnAfterFetch({ target: ['fetch-form-4'] })
  onAfterFetchF4(event: FetchEvent): void {
    const { fieldsRequired } = event.data as Retorno;
    this.validateFormInfoFinanceiras(fieldsRequired);
  }

  validateFormInfoFinanceiras(fieldsRequired: boolean): void {

    this.formStep4.setEditing(false);

    if (fieldsRequired) {
      const required = [{ name: 'required', otions: {} }] as unknown as string[];
      const fieldAPIs = [
        this.fieldStep4Nmpagador,
        this.fieldStep4Nrcpf,
        this.fieldStep4Banco,
        this.fieldStep4Agencia,
        this.fieldStep4AgenciaDigito,
        this.fieldStep4Conta,
        this.fieldStep4ContaDigito,
      ];

      fieldAPIs.forEach(api => {
        api.setValidators(required);
        api.validate();
      });
    }

    this.formStep4.setEditing(true);
    this.formStep4.validate();
  }

  @OnAfterFetch({ target: ['fetch-checklist'] })
  onAfterFetchFC(event: FetchEvent): void {
    const retorno = event.data as Retorno[];
    this.listBeneficiariosChecklist = retorno;
    const list = this.listBeneficiarios as Retorno[];
    this.titular = (list.find(item => item.ieTitularidade == 'T' && this.listBeneficiariosChecklist.find(cb => Number(cb.key) == item.nrSeqSegurado && cb.value)) || {} as unknown as Retorno).nrSeqSegurado;
    if (this.titular) {
      this.checklistBeneficiarios.setValue(this.listBeneficiariosChecklist as unknown as Record<string, boolean>);
      this.checklistBeneficiarios.setDisabledKeys(this.getKeysDependentes());
    }
  }

  @OnChange({ target: ['checklist-beneficiarios'] })
  onChangeCB(event: ChangeEvent<Record<string, string>>): void {
    if (this.titular) {
      const value = event.value as unknown as Retorno[];
      if (value.find(item => Number(item.key) === this.titular)) {
        this.checklistBeneficiarios.setValue(this.listBeneficiariosChecklist as unknown as Record<string, boolean>);
        this.checklistBeneficiarios.setDisabledKeys(this.getKeysDependentes());
      } else {
        this.checklistBeneficiarios.setDisabledKeys([]);
      }
    }
  }

  @OnChange({ target: ['autocomplete-titular'] })
  onChangeAT(event: ChangeEvent<Record<string, string>>): void {
    const nrCpf = (event.value && event.value["nrCpf"]) || '';
    this.inputCpf.setReadOnly(!!nrCpf);
    this.fieldCpf.setValue(nrCpf);
    this.formStep3.validate();
  }

  @OnValidate({ target: ['form-step-1'] })
  onValidateF1(event: ValidateEvent): void {
    const form = event.target as FormAPI;
    const data = form.getRecord();
    const phoneNumber = data["telefone"] || data["celular"];
    this.stepsCancelamento.setStepDone(this.identifier1, event.valid && !!phoneNumber && !!data["termo"]);
  }

  @OnChange({ target: ['form-step-2'] })
  onChangeF2(event: ChangeEvent<Record<string, string>>): void {
    this.stepsCancelamento.setStepDone(this.identifier2, !!event.value["beneficiarios"].length);
  }

  @OnValidate({ target: ['form-step-3'] })
  onValidadeF3(event: ValidateEvent): void {
    this.stepsCancelamento.setStepDone(this.identifier3, event.valid);
  }

  @OnValidate({ target: ['form-step-4'] })
  onValidateF4(event: ValidateEvent): void {
    this.stepsCancelamento.setStepDone(this.identifier4, event.valid || !this.fieldsetStep4Form.isVisible());
  }

  @OnDynamicContentRender({ target: ['steps-cancelamento'] })
  onRenderResultSteps(event: RenderEvent): void {
    if (event.entry === this.identifier1) {
      const { email } = this.formStep1.getRecord();
      event.render(() => <Text>{`${msg('$expression.$289120')}: ${email}`}</Text>);

    } else if (event.entry === this.identifier2) {

      this.renderNames(event.render);
      this.setSelecionadosRecord();
    } else if (event.entry === this.identifier3) {

      const { titular } = this.formStep3.getRecord();
      const nome = (titular && (titular as Retorno).nmBeneficiario) || '';

      event.render(() => <Text>{nome ? `${msg('$expression.$326195')}: ${nome}` : `${msg('$expression.$967152')}`}</Text>);
    }
  }

  //@ts-ignore
  @EventListener({ type: 'onAfterNavigate', target: ['steps-cancelamento'] })
  onNavigate(event: NavigableEvent<string>): void {

    if (event.from == this.identifier1 && event.to == this.identifier2) {

      Utils.catchRenderException(() => {
        if (this.checklistBeneficiarios.getValue().length) {
          this.stepsCancelamento.setStepDone(this.identifier2, true);
        }
      });

      Utils.catchRenderException(() => {
        if (this.repeaterStep2.getData().length) {
          this.stepsCancelamento.setStepDone(this.identifier2, true);
        }
      });

    } else if (event.from == this.identifier2 && event.to == this.identifier3) {

      const { selecionados } = this.formStep2.getRecord();
      const list = selecionados as Retorno[];

      const containsTitular = list.find(item => item.ieTitularidade === 'T' && item.checked);
      const containsUnchecked = list.find(item => !item.checked);
      const check = !containsTitular || !containsUnchecked;

      this.fieldsetStep3Form.setVisible(!check);
      this.fieldsetStep3Text.setVisible(check);
      this.fieldMessageStep3.setVisible(!check);

      if (check) {

        this.formStep3.setRecord({});
        setTimeout(() => this.stepsCancelamento.setStepDone(this.identifier3, true), 1);
      } else {

        const nrSeqSeguradoSelecionados = list.filter(item => item.checked).map(item => item.nrSeqSegurado);
        if (nrSeqSeguradoSelecionados.length) {

          this.context.set({ nrSeqSeguradoSelecionados: nrSeqSeguradoSelecionados.join('&nrSeqSeguradoSelecionados=') })
          this.fetchAutocompleteTitular.forceFetch();
        }
      }
    } else if (event.from == this.identifier3 && event.to == this.identifier4) {

      const { selecionados } = this.formStep2.getRecord();
      const list = selecionados as Retorno[];

      const containsTitular = list.find(item => item.ieTitularidade === 'T' && item.checked);

      const identifier3Hidden = this.stepsCancelamento.getHiddenSteps().includes(this.identifier3);

      this.fieldsetStep4Form.setVisible(!!containsTitular);
      this.fieldsetStep4Text.setVisible(!containsTitular && !identifier3Hidden);

      this.fetchForm4.forceFetch();

      if (!containsTitular || this.formStep4.isValid()) {
        this.stepsCancelamento.setStepDone(this.identifier4, true);
      }
    } else if (event.from == this.identifier2 && event.to == this.identifier4) {
      this.fetchForm4.forceFetch();
    }
  }

  @OnSubmit({ target: ['steps-cancelamento'] })
  onSubmit(): void {
    this.toastYesNo.open();
  }

  @OnClick({ target: ['button-toast-no'] })
  onbuttonToastNoClick(): void {
    this.toastYesNo.close();
  }

  @OnClick({ target: ['button-toast-yes'] })
  onbuttonToastYesClick(): void {
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();

    this.toastYesNo.close();
    scrollToTop();

    this.panelLoader.setVisible(true);
    this.panelSteps.setVisible(false);

    const { selecionados } = this.formStep2.getRecord();
    const marcados = (selecionados as Retorno[] || this.setSelecionadosRecord()).filter(item => item.checked);

    var recordStep3 = {};
    var recordStep4 = {};

    Utils.catchRenderException(() => recordStep3 = this.formStep3.getRecord());
    Utils.catchRenderException(() => recordStep4 = this.formStep4.getRecord());

    const body = {
      ...this.formStep1.getRecord(),
      beneficiariosRescisao: marcados,
      ...recordStep3,
      ...recordStep4,
    };

    HttpService.post(Endpoint.POST_CANCELAMENTO_CONFIRMAR, { nrSeqBeneficiario }, { body })
      .then(response => {
        const data = response.body as {};
        const { message } = data as Retorno;

        this.panelRetorno.setVisible(true);
        this.panelLoader.setVisible(false);
        this.formRetorno.setData(data);

        ToastService.safety(message);
      })
      .catch(response => {
        this.panelLoader.setVisible(false);
        this.panelSteps.setVisible(true);
        Utils.catchHandler(response);
      });
  }

  getListStep2(): Retorno[] {
    var list: Retorno[] = [];

    Utils.catchRenderException(() => list = this.checklistBeneficiarios.getValue() as unknown as Retorno[]);
    Utils.catchRenderException(() => list = this.repeaterStep2.getData() as unknown as Retorno[]);

    return list;
  }

  renderNames(eventRender: Function): void {
    const nomes = (this.getListStep2() || [])
      .map(item => {
        const str = item.label || item.nmBeneficiario;
        if (str) {
          const arr = str.split(' ');
          if (arr && arr.length) {
            return arr[0];
          }
        }
        return undefined;
      })
      .filter(item => !!item);
    if (nomes.length) {
      eventRender(() => <Text>{`${nomes.join(', ')}`}</Text>);
    }
  }

  setSelecionadosRecord(): Retorno[] {
    const list = this.listBeneficiarios as Retorno[];
    const selecionadosList = [] as Retorno[];

    list.forEach(item => {
      const opt = this.getListStep2().find(e => (e.nrSeqSegurado || Number(e.key)) == item.nrSeqSegurado);
      const select = { nrSeqSegurado: item.nrSeqSegurado, ieTitularidade: item.ieTitularidade, checked: !!opt } as unknown as Retorno;
      selecionadosList.push(select);
    });

    if (selecionadosList.length) {
      this.formStep2.setRecord({ ...this.formStep2.getRecord(), selecionados: selecionadosList });
    }

    const { selecionados } = this.formStep2.getRecord();
    return selecionados as Retorno[];
  }

  getKeysDependentes(): string[] {
    const list = this.listBeneficiarios as Retorno[];
    return this.listBeneficiariosChecklist
      .filter(item => item.value && list.find(benef => benef.nrSeqSegurado == Number(item.key) && benef.nrSeqSegurado !== this.titular))
      .map(item => item.key);
  }
}

export default CancelamentoStepsController;