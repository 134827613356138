import Controller  from '@philips-emr/tws-core/lib/client';
import BaseController from '../../BaseController';



@Controller({ fragment: 'lista-aditivo-contratual', component: 'lista-aditivo-contratual-layout' })
class ListaAditivoContratualController extends BaseController {

}

export default ListaAditivoContratualController;