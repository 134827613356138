import Controller, { OnClose, OnMount } from '@philips-emr/tws-core/lib/client';
import BaseController from '../../BaseController';
import { MountEvent } from '@philips-emr/tws-core/lib/component/types';
import { FieldAPI } from '@philips-emr/tws-components-api';

import './encounter-protocol-details-controller.scss';

@Controller({ fragment: 'encounter-protocol-details', component: 'layout-encounter-protocol-details' })
class EncounterProtocolDetailsController extends BaseController {

  @OnClose({ target: ['modal-encounter-protocol-details'] })
  onClose(): void { 
    this.historyService.back();   
  } 

  @OnMount({ target: ['field-protocol-number'] })
  onMount(e: MountEvent): void {
    const dom = e.target as FieldAPI; 
    dom.setData({ 'nrProtocolo': String(this.context.get('nrProtocolo') || '') });
  } 
} 
export default EncounterProtocolDetailsController;