import { Text } from '@philips-emr/tws-components/lib/components';
import Card from '@philips-emr/tws-components/lib/components/card/card';
import ContextMenu from '@philips-emr/tws-components/lib/components/context-menu/context-menu';
import { Placement } from '@philips-emr/tws-constants';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { bool, eventListener, number, string } from '@philips-emr/tws-core/lib/component/prop-types';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import { classNames } from '@philips-emr/tws-utils';
import React, { PureComponent } from 'react';
import { isFunction } from 'util';
import { ReactComponent as CancelSVG } from '../../assets/images/global/hpms-cancel.svg';
import { ReactComponent as CheckSVG } from '../../assets/images/global/hpms-check.svg';
import { ReactComponent as TimeSVG } from '../../assets/images/global/hpms-time.svg';
import { ReactComponent as WarningSVG } from '../../assets/images/global/hpms-warning.svg';
import HpmsExpression from '../../constants/expression';
import excerpts from '../../util/excerpts';
import { getContextMenu, getRegrasContextMenu } from '../../util/monthly-fee-popover-utils';
import HpmsMonthlyFeeCardApi from './hpms-monthlyfee-card-api';
import './hpms-monthlyfee-card.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: HpmsMonthlyFeeCardApi })
class HpmsMonthlyFeeCard extends PureComponent {

  static propTypes = {
    uuid: string,
    onClick: eventListener,
    onSelect: eventListener,
    width: number,
    allowsDuplicate: string,
    reportModalRoute: string,
    showDetailMenu: bool,
    ieVisualizaMensalidadeTotal: string,
    ieVisualizaNotaFiscal: string,
    ieGeraBoleto: string,
    nrNotaFiscal: string,
    nrSeqRegra: string,
    dsLinkNf: string,
    dsSituacao: string,
    ieSituacao: string,
    nrSeqMensalidade: number,
    vlMensalidade: number,
    vlMensalidadeTotal: number,
    vlCoparticipacao: number,
    vlCoparticipacaoTotal: number,
    nrTitulo: number,
    dtMesanoReferencia: string,
    dtVencimento: string,
    ieVencido: string,
    nmPagador: string,
    nrSeqBeneficiario: number,
    ieNegociado: string
  };

  static defaultProps = {
    showActions: true,
    actionText: HpmsExpression.VIEW,
    ieVencido: 'N',
    width: 12,
    showDetailMenu: true,
  };

  state = {
    regraMensalidade: {},
  }

  obterRegraMensalidade = () => {
    const { nrSeqBeneficiario, nrSeqMensalidade, nrSeqRegra } = this.props;
    getRegrasContextMenu(nrSeqBeneficiario, nrSeqMensalidade, nrSeqRegra)
      .then(resp => {
        const regraMensalidade = resp.body;
        this.setState({ regraMensalidade });
      });
  }

  onSelect = (item) => {
    const { onSelect } = this.props;
    isFunction(onSelect) && onSelect({ value: { ...this.props, item } });
  }

  onClick() {
    const { onClick } = this.props;
    isFunction(onClick) && onClick();
  }

  isVencido() {
    const { ieVencido } = this.props;
    return ieVencido === 'S';
  }

  getColor() {
    const { ieSituacao, ieVisualizaMensalidadeTotal } = this.props;
    const isStatusVisible = ieVisualizaMensalidadeTotal !== 'C';
    return classNames({
      'red': this.isVencido() && ieSituacao === '1' && isStatusVisible,
      'green': ['2', '4'].indexOf(ieSituacao) >= 0 && isStatusVisible,
      'grey': ['3', '5', '6'].indexOf(ieSituacao) >= 0 || !isStatusVisible,
      'orange': !this.isVencido() && ieSituacao === '1' && isStatusVisible,
    });
  }

  getWrapperColorClass() {
    return `c-hpms-monthlyfee-card__border-${this.getColor()}`;
  }

  getMainContentColorClass() {
    return `c-hpms-monthlyfee-card__content-main-color-${this.getColor()}`;
  }

  getIconComponent(className, type) {
    const icons = {
      'warning': <WarningSVG className={className} />,
      'time-grey': <TimeSVG className={className} />,
      'cancel-red': <CancelSVG className={className} />,
      'check': <CheckSVG className={className} />,
    }
    return icons[type];
  }

  getIconType() {
    const { ieSituacao } = this.props;
    return classNames({
      'check': ['2', '4'].indexOf(ieSituacao) >= 0,
      'time-grey': ['3', '5', '6'].indexOf(ieSituacao) >= 0,
      'cancel-red': this.isVencido() && ieSituacao === '1',
      'warning': !this.isVencido() && ieSituacao === '1',
    });
  }

  getIcon() {
    const classPrefix = 'c-hpms-monthlyfee-card__content-status-image-';
    let iconType = this.getIconType();
    return this.getIconComponent(classPrefix.concat(iconType), iconType);
  }

  getValorMensalidade() {
    const { vlMensalidade, vlMensalidadeTotal, ieVisualizaMensalidadeTotal } = this.props;
    if (ieVisualizaMensalidadeTotal === 'B') return vlMensalidade;
    if (ieVisualizaMensalidadeTotal === 'T') return vlMensalidadeTotal;
  }

  getValorCoparticipacao() {
    const { vlCoparticipacao, vlCoparticipacaoTotal, ieVisualizaMensalidadeTotal } = this.props;
    if (ieVisualizaMensalidadeTotal === 'T') return vlCoparticipacaoTotal;

    return vlCoparticipacao;
  }

  getValor() {
    const { ieVisualizaMensalidadeTotal } = this.props;
    return ieVisualizaMensalidadeTotal === 'C' ? this.getValorCoparticipacao() : this.getValorMensalidade();
  }

  getStatusExpression() {
    const { ieSituacao } = this.props;
    let statusExpression = `${HpmsExpression.MONTHLY_FEE_STATUS}.${this.isVencido() ? 'late' : ieSituacao}`;
    return msg(statusExpression);
  }

  getTextSizeTotal() {
    const { width } = this.props;
    return width <= 4 ? 'md' : 'lg';
  }

  getClassItemMargin(className) {
    const { width } = this.props;
    const classes = {
      [className]: true,
      'c-hpms-monthlyfee-card__item-margin-sm': width <= 4,
    }
    return classNames(classes);
  }

  renderTitle() {
    return (
      <div className='c-hpms-monthlyfee-card__header-section-itens-lg'>
        {this.renderDataVencimento()}
        {this.renderPagador()}
        {this.renderCoparticipacao()}
        {this.renderNotaFiscal()}
      </div>
    );
  }

  renderDataVencimento() {
    const { dtVencimento } = this.props;
    return (
      <div className='c-hpms-monthlyfee-card__item-vencimento'>
        <Text value={format('date', dtVencimento, { type: 'smallDate' })} bold={true} />
        <div className='c-label c-hpms-monthlyfee__label'>{msg(HpmsExpression.DUE_DATE)}</div>
      </div>
    );
  }

  renderPagador() {
    const { nmPagador } = this.props;
    return (
      <div className={this.getClassItemMargin('c-hpms-monthlyfee-card__item-pagador c-hpms-monthlyfee-card__item')}>
        <Text value={excerpts(nmPagador, 25, '...')} />
        <div className='c-label c-hpms-monthlyfee__label'>{msg('$expression.$927601')}</div>
      </div>
    );
  }

  renderTitulo() {
    const { nrTitulo, ieVisualizaMensalidadeTotal } = this.props;
    if (ieVisualizaMensalidadeTotal === 'T') {
      return (
        <div className={this.getClassItemMargin('c-hpms-monthlyfee-card__item-titulo c-hpms-monthlyfee-card__item')}>
          <Text value={nrTitulo} />
          <div className='c-label c-hpms-monthlyfee__label'>{msg('$expression.$737368')}</div>
        </div>
      );
    }
  }

  renderTotal() {
    const { ieVisualizaMensalidadeTotal } = this.props;
    const valor = this.getValor();
    const expressao = ieVisualizaMensalidadeTotal === 'C' ? msg('$expression.$286241') : msg('$expression.$300179');
    return (
      <div className='c-hpms-monthlyfee-card__item-total'>
        <Text value={format('currency', valor)} bold={true} size={this.getTextSizeTotal()} />
        <div className='c-label c-hpms-monthlyfee__label'>{expressao}</div>
      </div>
    );
  }

  renderCoparticipacao() {
    const { ieVisualizaMensalidadeTotal } = this.props;
    if (ieVisualizaMensalidadeTotal !== 'C') {
      return (
        <div className='c-hpms-monthlyfee-card__item-coparticipacao c-hpms-monthlyfee-card__item'>
          <Text value={format('currency', this.getValorCoparticipacao())} />
          <div className='c-label c-hpms-monthlyfee__label'>{msg('$expression.$286241')}</div>
        </div>
      );
    }
  }

  renderNotaFiscal() {
    const { width, ieVisualizaNotaFiscal, nrNotaFiscal } = this.props;
    const generalClassName = 'c-hpms-monthlyfee-card__item-nota-fiscal-';
    const className = `${generalClassName}${width <= 4 ? 'md' : 'lg'}`;

    if (ieVisualizaNotaFiscal === 'S' && nrNotaFiscal) {
      return (
        <div className={className}>
          <Text value={nrNotaFiscal} />
          <div className='c-label c-hpms-monthlyfee__label'>{msg('$expression.$688160')}</div>
        </div>
      );
    }
  }

  renderActions() {
    const { ieSituacao, dsLinkNf } = this.props;
    const { regraMensalidade } = this.state;
    return (
      < ContextMenu onOpen={this.obterRegraMensalidade} placement={Placement.LEFT} >
        {getContextMenu(regraMensalidade, ieSituacao, dsLinkNf, this.onSelect)}
      </ContextMenu >
    );
  }

  renderStatus() {
    const { ieVisualizaMensalidadeTotal, ieNegociado } = this.props;
    if (ieNegociado == "S"){
      return (
        <div className='c-hpms-monthlyfee-card__status'>

          <div className='c-hpms-monthlyfee-card__content-status-text c-hpms-monthlyfee-cardNegociated'>{msg('$expression.$294069')}</div>
        </div>
      );
    } else {
      if (ieVisualizaMensalidadeTotal !== 'C') {
        return (
          <div className='c-hpms-monthlyfee-card__status'>
            <div className='c-hpms-monthlyfee-card__content-status-image'>{this.getIcon()}</div>
            <div className='c-hpms-monthlyfee-card__content-status-text'>{this.getStatusExpression()}</div>
          </div>
        );
      }
     
    }
  }

  renderFooter() {
    return (
      <div className='c-hpms-monthlyfee-card__footer-section'>
        <div className='c-hpms-monthlyfee-card__footer'>
          {this.renderDataVencimento()}
          {this.renderNotaFiscal()}
        </div>
      </div>
    );
  }

  renderLarge() {
    return (
      <div className='c-hpms-monthlyfee-card__wrapper'>
        <div className='c-hpms-monthlyfee-card__header-section'>
          <div className='c-hpms-monthlyfee-card__left-header-section'>
            {this.renderTitle()}
            {this.renderStatus()}
          </div>
          <div className='c-hpms-monthlyfee-card__center-header-section'>
            {this.renderTotal()}
          </div>
          <div className='c-hpms-monthlyfee-card__right-header-section'>
            {this.renderActions()}
          </div>
        </div>
      </div>
    );
  }

  renderSmall() {
    const { ieVisualizaMensalidadeTotal } = this.props;
    return (
      <div className='c-hpms-monthlyfee-card__wrapper'>
        <div className='c-hpms-monthlyfee-card__header-section'>
          <div className='c-hpms-monthlyfee-card__top-header-section'>
            {this.renderTotal()}
            {ieVisualizaMensalidadeTotal === 'T' ? this.renderTitulo() : this.renderPagador()}
            {this.renderStatus()}
          </div>
          <div className='c-hpms-monthlyfee-card__right-header-section'>
            {this.renderActions()}
          </div>
        </div>
        {this.renderFooter()}
      </div>
    );
  }

  render() {
    const { width } = this.props;
    const borderColorClass = this.getWrapperColorClass();
    return (
      <Card width={width} className={`c-hpms-monthlyfee-card ${borderColorClass}`}>
        {width <= 4 ? this.renderSmall() : this.renderLarge()}
      </Card >
    );
  }
}

export default HpmsMonthlyFeeCard;