import { AwesomeEvent, ComponentAPI } from '@philips-emr/tws-core';
import { ClickEventListener } from '@philips-emr/tws-core/lib/component/types';

class HpmsPlanInfoApi extends ComponentAPI {

  @AwesomeEvent
  onRolProcedimentosClick = new ClickEventListener();

  @AwesomeEvent
  onTabelaPrecoClick = new ClickEventListener();

  @AwesomeEvent
  onExtratoUtilizacaoClick = new ClickEventListener();

  @AwesomeEvent
  onShowMoreClick = new ClickEventListener();

}

export default HpmsPlanInfoApi;