import { ButtonAPI, DynamicFieldsAPI, FieldAPI, FormAPI, PanelAPI } from "@philips-emr/tws-components-api";
import { Metadata } from "@philips-emr/tws-components-api/lib/api/dynamic-fields/dynamic-fields-api";
import Controller, { OnChange, OnClick } from "@philips-emr/tws-core/lib/client";
import { ChangeEvent } from "@philips-emr/tws-core/lib/component/types";
import { HttpService } from "@philips-emr/tws-core/lib/services";
import { format } from "@philips-emr/tws-core/lib/services/model-service";
import { Value } from "@philips-emr/tws-engine/lib/metadata";
import { Inject } from '@philips/odin';
import { Endpoint } from "../../../constants/endpoints";
import ReportService from "../../../services/report-service";
import BaseController from "../../BaseController";

interface Relatorio {
  cdRelatorio: string;
  cdClassifRelat: string;
}

@Controller({ fragment: 'informacoes-adicionais', component: 'layout-info-adicional' })
class InformacoesAdicionaisController extends BaseController {

  @Inject('field-relatorio')
  field: FieldAPI;

  @Inject('button-gerar')
  gerar: ButtonAPI;

  @Inject('dynamic-fields')
  dynamicFields: DynamicFieldsAPI;

  @Inject('form-dynamic')
  formDynamic: FormAPI;

  @Inject('panel-dynamic')
  panel: PanelAPI;

  @OnClick({ target: ['button-gerar'] })
  onClickGerar(): void {
    if (this.panel.isVisible()) {
      this.formDynamic.submit();

      if (!this.formDynamic.isValid()) {
        return;
      }
    }

    const relatorio = this.field.getValue() as Relatorio;
    this.gerar.setEnabled(false);
    const reportService = new ReportService('13', this);

    const params = this.getParams();
    reportService.gerarRelatorio(relatorio.cdRelatorio, relatorio.cdClassifRelat, params)
      .finally(() => {
        this.formDynamic.setEditing(true);
        this.formDynamic.setLocked(false);
        this.gerar.setEnabled(true);
      });
  }

  @OnChange({ target: ['field-relatorio'] })
  onChangeRelatorio(e: ChangeEvent<Record<string, Value>>): void {
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    
    if (!e.value) {
      this.formDynamic.setRecord({});
      this.panel.setVisible(false);
      this.gerar.setEnabled(false);
      return;
    }

    this.gerar.setEnabled(!!e.value.nrSequencia && e.value.nrSequencia !== '');
    const nrSeqRelatorio = e.value.nrSequencia as string;

    HttpService.get(Endpoint.GET_PARAMETROS_DINAMICOS, { nrSeqBeneficiario, nrSeqRelatorio })
      .then(resp => {
        const metadata = resp.body as Metadata;
        if (metadata) {
          this.panel.setVisible(true);
          this.formDynamic.setRecord({});
          this.dynamicFields.setMetadata(resp.body as Metadata);
        } else {
          this.panel.setVisible(false);
        }
      })
      .catch(() => {
        this.panel.setVisible(false);
      });
  }

  getParams(): {} {
    let params = {};

    if (this.panel.isVisible()) {
      const record = this.formDynamic.getRecord();
      Object.keys(record).forEach(k => {
        if (record[k]) {
          if (typeof record[k] === 'object') {
            const value = record[k] as Record<string, Value>;
            params = { ...params, [k]: value.value }
          } else {
            const value = format('date', record[k])
            params = { ...params, [k]: value }
          }
        }
      });
    }

    return params;
  }
}

export default InformacoesAdicionaisController;