import HpmsNoResultApi from '@philips-emr/hpms-components/src/components/hpms-no-result/hpms-no-result-api';
import { FieldsetAPI, MapperAPI } from '@philips-emr/tws-components-api';
import { Data } from '@philips-emr/tws-components-api/lib/api/mapper/mapper-api';
import Controller, { OnAfterFetch, OnMount } from '@philips-emr/tws-core/lib/client';
import { FetchEvent, MountEvent } from '@philips-emr/tws-core/lib/component/types';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import { Inject } from '@philips/odin';
import BaseController from '../../BaseController';
import { Value } from '@philips-emr/tws-core';

interface CptList {
  [key: string]: CptObject | undefined;
}

interface CptObject {
  id: number;
  beneficiaryId: number;
  dtLiberacao: string;
  dsCarencia: string;
}

@Controller({ fragment: 'cpt', component: 'cpt-layout' })
class CptsController extends BaseController {

  @Inject('hpms-no-result')
  noresult: HpmsNoResultApi;

  @Inject('field-set-repeater')
  fieldset: FieldsetAPI;

  @OnAfterFetch({ target: ['cpt-fetch'] })
  onAfterFetch(event: FetchEvent): void {
    const cptList: CptList[] = event.data as CptList[];
    this.resolveNoResult(cptList);
  }

  @OnMount({ target: 'mapper-cpt' })
  onMapperMount(e: MountEvent): void {
    const api = e.target as MapperAPI;
    api.addCustomMapping('cptDisplay', this.resolveCptDisplayText);
  }

  resolveCptDisplayText(cptObject: Data): Value {
    const date = format('date', cptObject && cptObject.dtLiberacao);
    const dsCarencia = cptObject && cptObject.dsCarencia;
    return `${msg("beneficiary.cpt.consultation.clearance.statement")}: ${date} - ${dsCarencia}`;
  }

  resolveNoResult(data: CptList[]): void {
    if (Array.isArray(data) && data.length <= 0) {
      this.noresult.setVisible(true);
      return;
    }
    this.fieldset.setVisible(true);
  }
}

export default CptsController;
