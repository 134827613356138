import { AvatarAPI, DropDownMenuAPI, ImageAPI, LinkAPI, PanelAPI, SplitPaneLayoutAPI, ToastAPI } from '@philips-emr/tws-components-api';
import Controller, { OnClick, OnMount, OnOpen } from '@philips-emr/tws-core/lib/client';
import EventListener from '@philips-emr/tws-core/lib/client/event-listener';
import { AccessibilityService, AuthenticationService, HttpService } from '@philips-emr/tws-core/lib/services';
import JWTToken from '@philips-emr/tws-core/lib/services/auth/JWTToken';
import { Inject } from '@philips/odin';
import { Endpoint } from '../../constants/endpoints';
import { Route } from '../../constants/routes';
import TokenConstants from '../../constants/token-constants';
import ConfigurationService from '../../services/configuration-service';
import ToastService from '../../services/toast-service';
import Utils from '../../utils/utils';
import { SessionStorageService } from '@philips-emr/tws-core/lib/services';
import BaseController from '../BaseController';
import { Data } from './../../interfaces/data';
import './beneficiary-controller.scss';
import Audit from '../../app/audit';
import HpmsAditivoServiceApi from '@philips-emr/hpms-components/src/api/hpms-aditivo-service-api';

const { scrollToTop } = AccessibilityService;

interface PersonData {
  nmPessoaUsuario: string;
  cdPessoaFisica: string;
}

interface State {
  isFotoPlaceholder: boolean;
  notPrivacit: boolean;
  notTerms: boolean;

}

interface TermoDeUso {
  nrSeqTermo: number;
}

interface CheckTermoUso {
  nrSeqTermoUso: number;
}

interface AditivoContratual {
  nrSeqSegTermo: number;
  nrSeqSegurado:  number;
  ieObrigatorio: string;
  termo: string;
  tipoConfirmacao: string;
}

@Controller({ fragment: 'beneficiary', component: 'beneficiary-layout' })
class BeneficiaryController extends BaseController {

  state: State = { 
    isFotoPlaceholder: false,
    notPrivacit: false,
    notTerms: false,
  
  };

  private themes = {
    DARK: 'force-dark-color',
    LIGHT: 'force-light-color'
  };

  private THEME_DARK = "2";

  @Inject('header-image')
  headerImage: ImageAPI;

  @Inject('beneficiary-avatar')
  avatar: AvatarAPI;

  @Inject('beneficiary-header')
  beneficiaryHeader: PanelAPI;

  @Inject('notificacao-link')
  notification: LinkAPI;

  @Inject('notificacao-link-modal')
  notificationModal: LinkAPI;

  @Inject('message-link')
  message: LinkAPI;

  @Inject('split-pane-layout')
  splitPaneLayoutAPI?: SplitPaneLayoutAPI;

  @Inject('panel-drop-down-menu')
  panelDropDownMenu: PanelAPI;

  @Inject('menu-button-panel')
  menuButtonPanel: PanelAPI;

  @Inject('hpms-drop-down-menu')
  hpmsDropDownMenu: DropDownMenuAPI;

  @Inject('toast-message')
  toastMessage: ToastAPI;

  @Inject('hpms-aditivo-service')
  checkTerm: HpmsAditivoServiceApi;

  @OnMount({ target: ['beneficiary-layout'] })
  onMount(): void {
 
    ToastService.setTarget(this.toastMessage);
    const token = AuthenticationService.getJWTToken() as JWTToken;
    this.alterarSenha(token);
    const nrSeqBeneficiarioFromToken = token && token.claims[TokenConstants.NR_SEQ_BENEFICIARIO];
    const cdPessoaFisica = token && token.claims[TokenConstants.CD_PESSOA_FISICA];
      
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();

    if (!token || (nrSeqBeneficiarioFromToken != nrSeqBeneficiario)) {
      AuthenticationService.isAuthenticated() && AuthenticationService.logout();
      this.historyService.push(Route.LOGIN);
    }
   
    this.checkTermoDeUso();

    HttpService.get(Endpoint.GET_PERSON_BY_BENEFICIARY_ID)
      .then(response => {
        const personData = response.body as PersonData;

        let personName: string = personData.nmPessoaUsuario;

        if (personName && personName.length > 30) {
          personName = `${personName.substr(0, 27)}...`;
        }

        if (screen.width < 768) {
          this.avatar && this.avatar.setTitle('');
        } else {
          this.avatar && this.avatar.setTitle(personName);
        }

        this.setUserImage(personData.cdPessoaFisica);
      })
      .catch(err => Utils.catchHandler(err));

    const data = { beneficiaryId: nrSeqBeneficiario, cdPessoaFisica, nrSeqBeneficiario };
    this.context.set(data);
    
    this.layoutSetup();
  }

  async checkTermoDeUso(): Promise<void> {
    const { reset } = SessionStorageService.getItem('resetFetch') ? SessionStorageService.getItem('resetFetch') : true;
    await this.checkAvisoPrivacidade();
    await this.checkTermosdeUso();
    if (reset == undefined || reset !== false) { 
      await this.checkAditivos();
    } 
    
  }

  async checkAvisoPrivacidade():  Promise<void> {
    
    await HttpService.get(Endpoint.CHECK_AVISO_DE_PRIVACIDADE).then(async respCheckAviso => {
      const checkDataAviso = respCheckAviso.body as CheckTermoUso;
      if (!checkDataAviso.nrSeqTermoUso) {
        await HttpService.get(Endpoint.AVISO_DE_PRIVACIDADE).then(respAviso => {
          const nrSeqAviso = (respAviso.body as TermoDeUso).nrSeqTermo;
          if (nrSeqAviso) {
            this.context.set({ nrSeqAviso });
            this.historyService.push(Route.ASSIGN_AVISO_DE_PRIVACIDADE, { nrSeqAviso });
          } 
        });
      }
    });
  }

  async checkTermosdeUso(): Promise<void> {
    await HttpService.get(Endpoint.CHECK_TERMOS_USO).then(async (result) => {
      const checkData = result.body as CheckTermoUso;
      if (!checkData.nrSeqTermoUso) {
        await HttpService.get(Endpoint.TERMOS_USO).then(resp => {
          const nrSeqTermo = (resp.body as TermoDeUso).nrSeqTermo;
          if (nrSeqTermo) {
            this.context.set({ nrSeqTermo });
            this.historyService.push(Route.ASSIGN_TERMO_DE_USO, { nrSeqTermo });
          }
        });
      }
    });
  }

  async checkAditivos(): Promise<void>  {
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    await HttpService.get(Endpoint.ADITIVO_CONTRATUAL, {nrSeqBeneficiario}).then(async  respAviso => {
      const array = (respAviso.body as [AditivoContratual])
      if (array.length > 0) {
        const listNrSeqTermo = array;
        const nrSeqSegTermo =  array && array[0] ? array[0].nrSeqSegTermo : '';
        this.state.notPrivacit = true;
        this.context.set({'listNrSeqTermo': listNrSeqTermo});
        this.historyService.push(Route.ASSIGN_ADITIVO_CONTRATUAL, {nrSeqBeneficiario, nrSeqSegTermo});
      }
    });
  }
  

  setUserImage(cdPessoaFisica: string): void {
    this.context.set({ cdPessoaFisica });

    HttpService.get(Endpoint.GET_PESSOA_FISICA_FOTO, { cdPessoaFisica }).then(response => {
      const dataAuditor = response.body as Data;
      if (dataAuditor && dataAuditor.foto) {
        const theme = this.beneficiaryHeader.isLightTheme() ? '' : this.themes.DARK;
        Utils.catchRenderException(() => this.panelDropDownMenu.setClassName(theme));
        this.avatar.setImage(dataAuditor.foto as string);

        if (dataAuditor.placeholder) {
          Utils.catchRenderException(() => this.panelDropDownMenu.setClassName(`overflow-hidden ${theme}`));
          this.state.isFotoPlaceholder = true;
        }
      }
    });
  }

  @OnClick({ target: ['desktop-menu-link'] })
  onDesktopMenuClick(): void {
    if (this.splitPaneLayoutAPI && this.splitPaneLayoutAPI.isLeftPaneVisible()) {
      this.splitPaneLayoutAPI.setLeftPaneVisible(false);
    } else {
      this.splitPaneLayoutAPI && this.splitPaneLayoutAPI.setLeftPaneVisible(true);
    }
  }

  @OnClick({ target: ['card-request-menu-option-desktop', 'card-request-menu-option-mobile'] })
  onCardRequestMenuClick(): void {
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();

    HttpService.get(Endpoint.CARTAO_STATUS, { nrSeqBeneficiario }).then(response => {
      const status = response.body as string;
      switch (status) {
        case 'N':
          this.historyService.push(Route.CARTAO_SOLICITAR);
          break;
        case 'A':
          this.historyService.push(Route.CARTAO_CONSULTAR);
          break
        case 'CR':
          this.historyService.push(Route.CARTAO_CONFIRMAR);
          break;
      }
    });
  }

  @OnClick({ target: ['beneficiary-data-link', 'dados-pessoais-menu-option-mobile', 'dados-pessoais-menu-option-desktop'] })
  onPersonDataClick(): void {
    const nrSeqBeneficiario: string = this.context.get('nrSeqBeneficiario') as string;
    const cdPessoaFisica: string = this.context.get('cdPessoaFisica') as string;
    this.historyService.push(Route.PERSON_DATA_DETAILS, { nrSeqBeneficiario, cdPessoaFisica });
  }

  @OnClick({ target: ['beneficiary-contract-link', 'info-menu-option-desktop', 'info-menu-option-mobile'] })
  onContractClick(): void {
    const nrSeqBeneficiario: string = this.context.get('nrSeqBeneficiario') as string;
    this.historyService.push(Route.BENEFICIARIO_DETALHES, { nrSeqBeneficiario });
  }

  @OnClick({ target: ['menu-solicitacao-rescisao'] })
  onMenuSolicitacaoRescisaoClick(): void {
    const nrSeqBeneficiario: string = this.context.get('nrSeqBeneficiario') as string;
    this.historyService.push(Route.CONTRATO_SOLICITACAO_RESCISAO, { nrSeqBeneficiario });
  }

  @OnClick({ target: ['option-exit'] })
  onExitClick(): void {
    SessionStorageService.setItem('resetFetch', {
      reset: true
    });
    AuthenticationService.logout();
    this.historyService.push(Route.LOGIN, {});
  }

  @OnClick({ target: ['accessibility'] })
  onAccessibilityClick(): void {
    this.historyService.push(Route.ACESSIBILIDADE, {});
  }

  //@ts-ignore
  @EventListener({ type: 'onScrollToMainMenu', target: ['accessibility'] })
  onScrollToMainMenu(): void {
    scrollToTop();
  }

  @OnOpen({ target: ['mobile-menu'] })
  onOpenMobileMenu(): void {
    scrollToTop();
  }

  layoutSetup(): void {
    ConfigurationService.getConfigurationData().then(layoutData => {
      const isDarkTheme: boolean = layoutData.ieFontColor === this.THEME_DARK;
      this.beneficiaryHeader.setLightTheme(isDarkTheme);
      this.beneficiaryHeader.setBackground(`linear-gradient(279deg, ${layoutData.dsFirstColor}, ${layoutData.dsSecondColor})`);
      this.beneficiaryHeader.setClassName('u-theme-background');

      const theme = this.beneficiaryHeader.isLightTheme() ? this.themes.LIGHT : this.themes.DARK;
      Utils.catchRenderException(() => this.notification.setClassName(theme));
      Utils.catchRenderException(() => this.notificationModal.setClassName(theme));
      Utils.catchRenderException(() => this.message.setClassName(theme));

      Utils.catchRenderException(() => this.panelDropDownMenu.setClassName(theme));
      this.state.isFotoPlaceholder && Utils.catchRenderException(() => this.panelDropDownMenu.setClassName(`overflow-hidden ${theme}`));
      this.headerImage.setSrc(layoutData.dsUrlLogoHeader);
    });
  }

  alterarSenha(token: JWTToken): void {
    if (token == null) {
      return;
    }

    const deveAlterarSenha = (token.claims[TokenConstants.ALTERAR_SENHA] as string) === 'true';
    if (deveAlterarSenha && !this.historyService.getCurrentRoute().includes(Route.LOGIN)) {
      const nrSeqBeneficiario: string = token.claims[TokenConstants.NR_SEQ_BENEFICIARIO] as string;
      this.historyService.push(Route.ALTERAR_SENHA_LOGIN, { nrSeqBeneficiario });
    }
  }

  @OnOpen({ target: 'drop-down-menu-mensagem' })
  onOpenDropDownMensagem(): void {
    const requestBody = {
      rota: '',
      nmFuncao: 'Mensagens',
      'refresh_token': ''
    };
    Audit.logAcessoFuncao(requestBody);
  }

  @OnOpen({ target: 'drop-down-menu-notificacao' })
  onOpenDropDownNotificacao(): void {
    const requestBody = {
      rota: '',
      nmFuncao: 'Notificações',
      'refresh_token': ''
    };
    Audit.logAcessoFuncao(requestBody);
  }

}

export default BeneficiaryController;
