import Link from '@philips-emr/tws-components/lib/components/link/link';
import { AwesomeComponent, ComponentType } from "@philips-emr/tws-core";
import { eventListener } from '@philips-emr/tws-core/lib/component/prop-types';
import { array } from 'prop-types';
import React, { Component } from 'react';
import AttachmentsListApi from '../../api/hpms-attachments-list-api';
import './hpms-attachments-list.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: AttachmentsListApi })
class HPMSAttachmentsList extends Component {

  static propTypes = {
    data: array,
    onOpenAttachment: eventListener
  }

  state = {
    nrSeqAtendimento: null
  }

  onOpenAttachment = (seq) => {
    const { onOpenAttachment } = this.props;

    onOpenAttachment && onOpenAttachment({ value: seq })
  }

  renderContent() {
    const { data } = this.props;

    if (!data) {
      return;
    }

    return data.map((element, index) => {
      return (
        <div key={`${index}`} className="c-attachments-list__item">
          <div className="iconDownload"></div>
          <Link onClick={() => this.onOpenAttachment(element)} value={element.nmArquivo} />
        </div>
      )
    });
  }

  render() {
    return (
      <div className="c-attachments-list__wrapper">
        {this.renderContent()}
      </div>
    );
  }

}

export default HPMSAttachmentsList;
