import { ToastAPI } from '@philips-emr/tws-components-api';
import { Type } from '@philips-emr/tws-constants';
import Types from '@philips-emr/tws-constants/lib/type';

class ToastService {

  private static mainToast: ToastAPI;
  private static time = 5000;

  public static showMessage(toast: ToastAPI, type: Type, message: string, timeout: number = this.time): void {
    toast.setType(type);
    timeout > 0 && toast.setTimeOut(timeout);
    toast.setValue(message);
    toast.open();
  }

  public static setTarget(toast: ToastAPI): void {
    this.mainToast = toast;
  }

  public static safety(message: string, timeout: number = this.time): void {
    this.showToast(Types.SAFETY, message, timeout);
  }

  public static danger(message: string, timeout: number = this.time): void {
    this.showToast(Types.DANGER, message, timeout);
  }

  public static caution(message: string, timeout: number = this.time): void {
    this.showToast(Types.CAUTION, message, timeout);
  }

  private static showToast(type: Types, message: string, timeout: number): void {
    timeout > 0 && this.mainToast.setTimeOut(timeout);
    this.mainToast.setType(type);
    this.mainToast.setValue(message);
    this.mainToast.open();
  }
}

export default ToastService;