import HpmsReportListApi, { Report } from "@philips-emr/hpms-components/src/components/hpms-report-list/hpms-report-list-api";
import { DateFieldAPI, FormAPI, PanelAPI, ToastAPI } from "@philips-emr/tws-components-api";
import Types from "@philips-emr/tws-constants/lib/type";
import Controller, { OnClick, OnClose, OnMount } from "@philips-emr/tws-core/lib/client";
import { ClickEvent } from "@philips-emr/tws-core/lib/component/types";
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import { Inject } from '@philips/odin';
import { Endpoint } from "../../constants/endpoints";
import { Route } from "../../constants/routes";
import ReportService from "../../services/report-service";
import ToastService from "../../services/toast-service";
import BaseController from "../BaseController";


interface ValidacaoRelatorio {
  success: boolean;
  message: string;
}

@Controller({ fragment: 'extrato-utilizacao-modal', component: 'extrato-utilizacao-modal-component' })
class ExtratoUtilizacaoModalController extends BaseController {

  @Inject('hpms-report-list')
  repList: HpmsReportListApi;

  @Inject('panel-unico-relatorio')
  panelUnicoRelatorio: PanelAPI;

  @Inject('panel-lista-relatorio')
  panelListaRelatorio: PanelAPI;

  @Inject('panel-loader')
  panelLoader: PanelAPI;

  @Inject('periodo-form')
  periodoForm: FormAPI;

  @Inject('data-inicio')
  dtInicio: DateFieldAPI;

  @Inject('data-fim')
  dtFim: DateFieldAPI;

  @Inject('toast')
  toast: ToastAPI;

  reportService: ReportService;

  reports: Report[];

  @OnClose({ target: 'extrato-utilizacao-modal-component' })
  onCloseExtratoUtilizacao(): void {
    this.historyService.push(Route.DASHBOARD);
  }

  @OnMount({ target: 'extrato-utilizacao-modal-component' })
  onMountExtratoUtilizacao(): void {
    this.reportService = new ReportService('8', this, Endpoint.GET_RELATORIOS_POR_GRUPO_AUTORIZACAO);
    this.reportService.getRelatoriosByCdGrupo()
      .then(response => {
        this.reports = response;
        const isVisible = response.length > 1;

        this.panelListaRelatorio.setVisible(isVisible);
        this.panelUnicoRelatorio.setVisible(!isVisible);
        isVisible && this.repList.setData(response);
      });
  }

  @OnClick({ target: 'gerar-relatorio-button' })
  gerarRelatorioButtonClick(): void {
    this.periodoForm.validate();
    if (this.periodoForm.isValid()) {
      this.setReportListLoader(true);
      const relat = this.reports[0];
      this.gerarRelatorio(relat);
    }
  }

  @OnClick({ target: ['hpms-report-list'] })
  onRepClick(e: ClickEvent): void {
    this.periodoForm.validate();
    if (this.periodoForm.isValid()) {
      this.setReportListLoader(true);
      const relat = e.value as Report;
      this.gerarRelatorio(relat);
    }
  }

  gerarRelatorio(relat: Report): void {
    this.setReportListLoader(true);

    this.reportService.gerarRelatorio(relat.cdRelatorio, relat.cdClassifRelat, this.getReportParams())
      .catch((resp) => {
        const msg = JSON.parse(resp.body) as ValidacaoRelatorio;
        if (msg) {
          ToastService.showMessage(this.toast, Types.CAUTION, msg.message, 5000);
          return;
        }
      })
      .finally(() => {
        this.setReportListLoader(false);
      });
  }

  setReportListLoader(value: boolean): void {
    if (this.panelListaRelatorio.isVisible()) {
      this.repList.setLoading(value);
      return;
    }
    this.panelLoader.setVisible(value);
  }

  getReportParams(): object {
    return {
      DT_INICIO: format('date', this.periodoForm.getValue().dtInicial),
      DT_FIM: format('date', this.periodoForm.getValue().dtFinal),
    }
  }

}

export default ExtratoUtilizacaoModalController;