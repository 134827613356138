import React, { Component } from 'react';
import { array, arrayOf, bool, element, eventListener, number, oneOf, oneOfType, string } from '@philips-emr/tws-core/lib/component/prop-types';


/** External Components */
import { CardList, Loader } from '@philips-emr/tws-components/lib/components';

import { isFunction, isMobile } from '@philips-emr/tws-utils';

import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { Expression } from '@philips-emr/tws-constants';
import { LayoutGap } from '@philips-emr/tws-components-api';

import './hpms-notificacao-deck.scss';
import { enumValues } from '@philips-emr/tws-utils';

//This component should be removed as soon as possible, we should ask TEC to create an option on the DECK to hide the "show more" button
@AwesomeComponent({ type: ComponentType.LIST })
class HpmsNotificacaoDeck extends Component {
  static propTypes = {
    children: oneOfType([element, arrayOf(element)]).notEnumerable(),
    columnGap: oneOf(enumValues(LayoutGap)).group('layout'),
    data: array.notEnumerable(),
    done: bool.notEnumerable(),
    emptyResultSubtitleExpression: string,
    emptyResultTitleExpression: string,
    limit: number,
    loadMoreExpression: string,
    loading: bool.notEnumerable(),
    onClick: eventListener,
    onContextMenuClick: eventListener,
    onDoubleClick: eventListener,
    onNextPage: eventListener,
    onPrevPage: eventListener,
    page: number.notEnumerable(),
    reset: bool.notEnumerable(),
    rowGap: oneOf(enumValues(LayoutGap)).group('layout'),
    uuid: string,
  };

  static defaultProps = {
    columnGap: LayoutGap.LARGE,
    done: true,
    limit: 12,
    loadMoreExpression: Expression.LOAD_MORE,
    page: 0,
    rowGap: LayoutGap.LARGE,
  };

  onLoadMoreClick = () => {
    const { limit, onNextPage, page } = this.props;

    isFunction(onNextPage) &&
      onNextPage({ limit, page: page + 1, keepData: true });
  };

  onItemClick = (item) => {
    const { onClick } = this.props;

    isFunction(onClick) && onClick({ value: item });
  };

  onDoubleClick = (event) => {
    const { onDoubleClick } = this.props;

    isFunction(onDoubleClick) && onDoubleClick(event);
  };

  onContextMenuClick = (event) => {
    const { onContextMenuClick } = this.props;

    isFunction(onContextMenuClick) && onContextMenuClick(event);
  };

  fetchFirstPage() {
    const { limit, onNextPage } = this.props;

    isFunction(onNextPage) && onNextPage({ limit, page: 0, keepData: false });
  }

  componentDidMount() {
    this.fetchFirstPage();
  }

  componentDidUpdate({ reset: prevReset }) {
    const { reset } = this.props;

    if (reset && prevReset !== reset) {
      this.fetchFirstPage();
    }
  }

  renderCards() {
    const { children, columnGap, data, emptyResultSubtitleExpression, emptyResultTitleExpression, rowGap, uuid } = this.props;

    return (
      <CardList
        columnGap={columnGap}
        data={data}
        emptyResultSubtitleExpression={emptyResultSubtitleExpression}
        emptyResultTitleExpression={emptyResultTitleExpression}
        onClick={this.onItemClick}
        onContextMenuClick={this.onContextMenuClick}
        onDoubleClick={this.onDoubleClick}
        rowGap={rowGap}
        showAddNew={false}
        uuid={uuid}
      >
        {children}
      </CardList>
    );
  }

  renderLoader() {
    const { loading } = this.props;

    if (!loading) {
      return null;
    }

    return (
      <Loader className="c-deck__loader" size={isMobile() ? 32 : 64} />
    );
  }

  render() {
    return (
      <div className="c-deck">
        {this.renderCards()}
        {this.renderLoader()}
      </div>
    );
  }
}

export default HpmsNotificacaoDeck;
