import { ComponentAPI, AwesomeEvent } from '@philips-emr/tws-core';
import { ClickEventListener } from '@philips-emr/tws-core/lib/component/types';

class HpmsLatestAuthorizationsApi extends ComponentAPI {

  @AwesomeEvent
  onVisualizarAutorizacao = new ClickEventListener();

  @AwesomeEvent
  onVerMais = new ClickEventListener();

}

export default HpmsLatestAuthorizationsApi;
