import { MenuOptionType } from '@philips-emr/tws-components-api';
import { MenuOption } from '@philips-emr/tws-components/lib/components';
import { Direction } from '@philips-emr/tws-constants';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { bool, oneOf, string } from '@philips-emr/tws-core/lib/component/prop-types';
import { HttpService } from '@philips-emr/tws-core/lib/services';
import { enumValues } from '@philips-emr/tws-utils';
import React, { Component } from 'react';
import { Endpoint } from './../../constants/endpoints';

@AwesomeComponent({ type: ComponentType.NAVIGATION })
class HpmsDynamicMenuOption extends Component {

  static propTypes = {
    label: string.readOnly(),
    uuid: string,
    route: string,
    alias: string,
    direction: oneOf(enumValues(Direction)).readOnly(),
    type: oneOf(enumValues(MenuOptionType)).readOnly(),
    visible: bool,
    baseName: string.readOnly(),
    dockedSubmenu: bool.readOnly(),
  }

  state = {
    menu: '',
    showSubmenu: false,
    isLoaded: false
  }

  render() {
    if (!this.state.isLoaded) {
      HttpService.get(Endpoint.GET_MENU_DINAMICO).then(response => this.setState({ menu: response.body && response.body.title, isLoaded: true }));
    }

    return (this.state.menu ?
      <MenuOption
        label={this.state.menu}
        alias={this.props.alias}
        uuid={this.props.uuid}
        route={this.props.route}
        direction={Direction.HORIZONTAL}
        type={MenuOptionType.PRIMARY}
        visible={true}
        dockedSubmenu={false}
        baseName=""
      ></MenuOption> : null
    );
  }
}
export default HpmsDynamicMenuOption;