import { Link } from '@philips-emr/tws-components';
import EventListener from '@philips-emr/tws-core/lib/client/event-listener';
import Controller, { OnDynamicContentRender} from '@philips-emr/tws-core/lib/client';
import { RenderEvent, ClickEvent} from '@philips-emr/tws-core/lib/component/types';
import React from 'react';
import BaseController from '../../BaseController';
import { Route } from '../../../constants/routes';


const DETAIL_COLUMN_NAME = 'detailColumn';

interface Value {
  nrSeqTermo: number;
}

interface Data {
  nrSeqTermo: number;
  value: Value;
}

@Controller({ fragment: 'historico-consentimentos', component: 'layout-historico-consentimentos' })
class HistoryConsentsListController extends BaseController {

  
  @OnDynamicContentRender({ target: ['data-table-history-consents'] })
  onDynamicContentRender(event: RenderEvent): void {
    const { entry, data, render } = event;

    const nrSeqTermo = (data as Data).nrSeqTermo;

    if (entry === DETAIL_COLUMN_NAME) {

      const renderLink: Function = () => {
        return (
          <Link icon='/static/assets/view-details.svg' className='hpms-custom-icon-size' onClick={() => this.onDetailClick(nrSeqTermo)} value=' '/>
        );
      }

      render(renderLink);
    }
  }

  //@ts-ignore
  @EventListener({ type: 'onDetailClickCard', target: ['hpms-aditivo-contratual-card'] })
  onDetailClickCard(event: ClickEvent): void {
    this.onDetailClick(event.value.nrSeqTermo);
  }

  onDetailClick = (nrSeqTermo: number) => {
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    this.context.set({ nrSeqBeneficiario, nrSeqTermo });

    this.historyService.push(Route.DETAILS_ADITIVO_CONTRATUAL, { nrSeqBeneficiario, nrSeqTermo });
  }

}

export default HistoryConsentsListController;