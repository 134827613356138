import { Value } from "@philips-emr/tws-core";
import { validate } from "@philips-emr/tws-model/lib/formatters/date/date";
import { Validation } from "@philips-emr/tws-core/lib/services/model-service";

const CONFIRMATION_FIELD_VALID = 'confirmationField';
const PASSWORD_VALIDATOR = 'passwordValidator';
const EMAIL_VALIDATOR = 'emailValidator';
const SENHA_NOVA_DIFERENTE_ATUAL = 'senhaNovaDiferenteDaAtual';
const DT_NASCIMENTO_VALIDATOR = 'dtNascimentoValidator';


const EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
const PASSWORD = /^(?=.*?[A-Z])(?=(.*[a-z]))(?=(.*[\d]))(?=(.*[\W_])).{8,}$/;

function confirmationFieldValidator(value: Value, confirmationValue: Value): Validation[] {
  const valid = Boolean(value) && Boolean(confirmationValue) && confirmationValue === value;

  return [{
    message: !valid ? 'beneficiary.confirmation-field-invalid' : '',
    name: CONFIRMATION_FIELD_VALID,
    valid,
  }];
}

function senhaNovaDiferenteDaAtual(value: Value, comparisonField: Value): Validation[] {
  const valid = value !== comparisonField;

  return [{
    message: !valid ? '$expression.$965257' : '',
    name: SENHA_NOVA_DIFERENTE_ATUAL,
    valid,
  }];
}

function passwordValidator(value: Value): Validation[] {
  const pass = value as string || '';
  const valid = Boolean(pass.match(PASSWORD)) && pass.length >= 8 && pass.length <= 16;

  return [{
    message: !valid ? '$expression.$1082772' : '',
    name: PASSWORD_VALIDATOR,
    valid,
  }];
}

function emailValidator(value: Value): Validation[] {
  const valid = Boolean((value as string).match(EMAIL));

  return [{
    message: !valid ? 'beneficiary.novo-usuario.email-validator' : '',
    name: EMAIL_VALIDATOR,
    valid,
  }];
}

function dtNascimentoValidator(value: Value): Validation[] {
  const currentDate = new Date();
  const minimumDate = new Date('1900-01-01');
  const valueDate = new Date(value as string);

  const isValidDate = validate(value)[0].valid;
  
  const valid = valueDate <= currentDate && valueDate >= minimumDate && isValidDate;
  
  return [{
    message: !valid ? '$expression.$515046' : '',
    name: DT_NASCIMENTO_VALIDATOR,
    valid,
  }];
}


export {
  confirmationFieldValidator,
  passwordValidator,
  emailValidator,
  PASSWORD_VALIDATOR,
  senhaNovaDiferenteDaAtual,
  CONFIRMATION_FIELD_VALID, EMAIL_VALIDATOR,
  SENHA_NOVA_DIFERENTE_ATUAL,
  dtNascimentoValidator,
  DT_NASCIMENTO_VALIDATOR
};

