import { HttpService, SessionStorageService } from "@philips-emr/tws-core/lib/services";
import BaseController from "../controllers/BaseController";

interface Lista {
  [key: string]: string;
}


export default function obterLinks(owner: BaseController, endpoint: string, endpointParams: {}, modalRoute: string, title: string = ''): void {
  HttpService.get(endpoint, endpointParams)
    .then(response => {
      const { body } = response;
      const lista = body as Lista[];

      if (lista.length > 1) {
        SessionStorageService.setItem('dados_links', { fetchEndPoint: endpoint, fetchParams: endpointParams, title });
        owner.historyService.push(modalRoute, endpointParams);
      } else {
        var win = window.open(lista[0][Object.keys(lista[0])[0]], '_blank');
        win && win.focus();
      }
    });
}