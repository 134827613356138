
import Link from '@philips-emr/tws-components/lib/components/link/link';
import Loader from '@philips-emr/tws-components/lib/components/loader/loader';
import Form from '@philips-emr/tws-components/lib/components/form/form';
import { AwesomeComponent, ComponentType } from "@philips-emr/tws-core";
import { arrayOf, eventListener, object } from '@philips-emr/tws-core/lib/component/prop-types';
import React, { PureComponent } from "react";
import { isFunction } from 'util';
import { ReactComponent as PrintSVG } from '../../assets/images/global/hpms-print.svg';
import './hpms-report-list.scss';
import HpmsReportListApi from './hpms-report-list-api';
import { Field } from '@philips-emr/tws-components/lib/components';

@AwesomeComponent({ type: ComponentType.LIST, api: HpmsReportListApi })
class HpmsReportList extends PureComponent {

  static propTypes = {
    data: arrayOf(object).notEnumerable(),
    onClick: eventListener,
  };

  static defaultProps = {
    data: [],
  }

  state = {
    loading: false,
  }

  renderList() {
    const { data } = this.props;
    const { loading } = this.state;

    return (
      <Form locked={true} loading={loading} disabled={true} >
        {data.map((rep, index) =>
          <Field key={`${rep['nrSequencia']}${index}`} width={12} hideLabel={true}>
            <div className="c-hpms-report-list__item" onClick={() => this.onRepClick(rep)} >
              <div className="c-hpms-report-list__image svg-hpms"><PrintSVG /></div>
              <div className="c-hpms-report-list__link" disabled={true}><Link className="c-hpms-report-list__item" value={rep['dsTitulo']} /></div>
            </div>
          </Field>
        )
        }
      </Form>)
  }

  onRepClick = (rep) => {
    const { onClick } = this.props;

    isFunction(onClick) && onClick({ value: rep })
  };

  render() {
    const { data } = this.props;
    if (data.length <= 0) {
      return <Loader></Loader>;
    }

    return (
      <div className="c-hpms-report-list__card-interior" >
        {this.renderList()}
      </div>
    );
  }
}

export default HpmsReportList;