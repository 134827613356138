import { ConfigurationService as ConfigurationServiceFW, LocaleService } from '@philips-emr/tws-core/lib/services';
import { AuthenticationConfiguration } from '@philips-emr/tws-core/lib/services/auth/authentication-service';
import { HttpMethod } from '@philips-emr/tws-core/lib/services/http-service';
import MessageService, { MessageServiceConfig } from '@philips-emr/tws-core/lib/services/message-service';
import { Information } from '@philips-emr/tws-foundation';
import { Endpoint } from '../constants/endpoints';
import { Route } from '../constants/routes';
import ConfigurationService from '../services/configuration-service';
import { Engine, Metadata } from './types';

//@ts-ignore
const CONTEXT = env.CONTEXT; //eslint-disable-line

//@ts-ignore
const METADATAS: Metadata[] = env.METADATA; //eslint-disable-line

//@ts-ignore ['tws', 'authorization', 'person', 'beneficiary']
const MESSAGES_MAP: MessageServiceConfig = env.MESSAGES; //eslint-disable-line


// TODO: can I remove it?
let inited = false;

function getApplicationContext(): string {
  return CONTEXT;
}

function bootstrap(engine: Engine): Promise<void> {
  if (inited) return Promise.resolve();

  // fill engine with metadatas
  METADATAS.forEach(meta => {
    const { name, metadata } = meta;

    engine.registerMetadata(name, metadata);
  });

  function map(obj: object): Information {
    // @ts-ignore
    return obj.body;
  }

  const config: AuthenticationConfiguration = {
    clientId: 'beneficiary',
    endpoints: {
      refresh: {
        method: HttpMethod.POST,
        url: Endpoint.AUTHENTICATION
      },
      revoke: {
        method: HttpMethod.POST,
        url: Endpoint.REVOKE
      },
      information: {
        method: HttpMethod.GET,
        url: ''
      }
    },
    routes: {
      forgotPassword: Route.RECUPERAR_SENHA,
      home: Route.DASHBOARD,
      inactivity: Route.LOGOUT_POR_INATIVIDADE,
      login: Route.LOGIN
    },
    inactivity: {
      time: 15 * 60 * 1000,
    },
    endpointMappers: {
      //@ts-ignore
      information: map,
    }
  };
  ConfigurationServiceFW.setAuthenticationConfiguration(config);
  LocaleService.init({ defaultLocale: "BR", supportedLocales: ["BR"] });
  ConfigurationService.setFileuploaderConfig();

  // init message
  return MessageService.init(MESSAGES_MAP)
    .then(() => MessageService.changeLanguage('pt-br'));
}

export { bootstrap as default, getApplicationContext };

