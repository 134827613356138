import Button from '@philips-emr/tws-components/lib/components/button/button';
import Card from '@philips-emr/tws-components/lib/components/card/card';
import { Size } from '@philips-emr/tws-constants';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { bool, eventListener, string } from '@philips-emr/tws-core/lib/component/prop-types';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import React, { PureComponent } from 'react';
import HpmsExpression from '../../constants/expression';
import HpmsButtonCardApi from './hpms-button-card-api';
import './hpms-button-card.scss';




@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: HpmsButtonCardApi })
class HpmsButtonCard extends PureComponent {

  static propTypes = {
    uuid: string,
    titleExpression: string,
    subTitleExpression: string,
    mainContent: string,
    secondaryContent: string,
    showActions: bool,
    showDividers: bool,
    actionExpression: string,
    onClick: eventListener,
    width: string,
  };

  static defaultProps = {
    showActions: true,
    showDividers: true,
    actionExpression: HpmsExpression.VIEW,
    width: 3,
  };

  onActionClick = (event) => {
    const { onClick } = this.props;

    onClick && onClick(event);
  }

  renderTitle() {
    const { titleExpression, subTitleExpression } = this.props;

    return (
      <div className={"c-hpms-button-card__title-section ".concat(this.getDivider('bottom'))}>
        <div className="c-hpms-button-card__title">{msg(titleExpression)}</div>
        <div className="c-hpms-button-card__sub-title">{subTitleExpression && msg(subTitleExpression)}</div>
      </div>
    );
  }

  renderContent() {
    const { mainContent } = this.props;

    return (
      <div className="c-hpms-button-card__content-section">
        <div className="c-hpms-button-card__content-main">{this.props[mainContent] || mainContent}</div>
        {this.renderSecondaryContent()}
      </div>
    );
  }

  renderSecondaryContent() {
    const { secondaryContent } = this.props;

    if (!secondaryContent) {
      return;
    }

    return (
      <div className="c-hpms-button-card__content-secondary">{this.props[secondaryContent] || msg(secondaryContent) || secondaryContent}</div>
    );
  }

  renderActions() {
    const { showActions, actionExpression } = this.props;

    if (!showActions) {
      return;
    }

    return (
      <div className={"c-hpms-button-card__actions-section ".concat(this.getDivider('top'))} >
        <div className="c-hpms-button-card__actions-button">
          <Button value={msg(actionExpression)}
            size={Size.SMALL.value}
            onClick={this.onActionClick} />
        </div>
      </div>
    );
  }

  render() {

    return (
      <Card width={this.props.width}>
        <div className="c-hpms-button-card__wrapper">

          {this.renderTitle()}

          {this.renderContent()}

          {this.renderActions()}

        </div>
      </Card>
    );
  }

  getDivider(topOrBottom){
    const { showDividers } = this.props;
    if (showDividers){
      return 'c-hpms-button-divider__'.concat(topOrBottom);
    }
    return " ";
  }
}

export default HpmsButtonCard;
