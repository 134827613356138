import { Accordion, Link, Text } from '@philips-emr/tws-components/lib/components';
import { Size } from '@philips-emr/tws-constants';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { arrayOf, object, string } from '@philips-emr/tws-core/lib/component/prop-types';
import React, { PureComponent } from 'react';
import './hpms-dynamic-screen.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY })
class HpmsDynamicScreen extends PureComponent {

  static propTypes = {
    uuid: string,
    alias: string,
    data: arrayOf(object).notEnumerable(),
  };

  render() {
    const { data } = this.props;
    return this.mount(data);
  }

  mount(data) {
    if (!data) {
      return null;
    }

    return (<div className='c-hpms-dynamic-screen'>{
      data.map(item => {
        if (item.type == 'ACCORDION') {
          return this.getAccordion(item.label, this.mount(item.data));
        } else {
          return this.getLink(item.label, item.link);
        }
      })
    }</div>);
  }

  getAccordion(value, content) {
    return (
      <Accordion initialCollapsed={false}>
        <div slot='header'><Text value={value} size={Size.MEDIUM} ></Text></div>
        <div slot='content' style={{ width: '100%' }}>{content}</div>
      </Accordion>
    );
  }

  getLink(value, link) {
    return (<div style={{ padding: '5px' }}>
      <Link onClick={() => window.open(link)} value={value}></Link>
    </div>)
  }
}

export default HpmsDynamicScreen;