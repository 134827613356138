import { ImageAPI } from "@philips-emr/tws-components-api";
import Controller, { OnClick, OnMount } from "@philips-emr/tws-core/lib/client";
import { Inject } from '@philips/odin';
import { Route } from "../../../constants/routes";
import ConfigurationService from "../../../services/configuration-service";
import BaseController from "../../BaseController";

@Controller({ fragment: 'nova-senha-falha', component: 'layout-confirmation-fail' })
class RecuperarSenhaFalha extends BaseController {

  @Inject('login-image-header')
  image: ImageAPI;

  @OnMount({ target: ['layout-confirmation-fail']})
  onLayoutMount(): void {
    ConfigurationService.getConfigurationData().then(layoutData => {
      this.image.setSrc(layoutData.dsUrlLogoHeaderLogin);
    });
  }

  @OnClick({ target: ['button-ok']})
  onBtnClick(): void{
    this.historyService.push(Route.LOGIN);
  }

}

export default RecuperarSenhaFalha;