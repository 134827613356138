import { ComponentAPI, AwesomeEvent } from '@philips-emr/tws-core';
import { ClickEventListener } from '@philips-emr/tws-core/lib/component/types';

export interface Report {
  cdClassifRelat: string;
  cdRelatorio: string;
  dsTitulo: string;
  nrSequencia: string;
  cdGrupo: string;
}

class HpmsReportListApi extends ComponentAPI {
  @AwesomeEvent
  onClick = new ClickEventListener();

  setData(data: Report[]): void {
    this.setState({ data });
  }

  setLoading(value: boolean): void {
    this.setState({ loading: value });
  }
}

export default HpmsReportListApi;