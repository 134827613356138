import { ComponentAPI, AwesomeEvent } from '@philips-emr/tws-core';
import { ClickEventListener } from '@philips-emr/tws-core/lib/component/types';

class HpmsRequisicaoAutorizacaoCardApi extends ComponentAPI {

  @AwesomeEvent
  onDetalhesClick = new ClickEventListener();

  @AwesomeEvent
  onGuiaDetalhesClick = new ClickEventListener();
}

export default HpmsRequisicaoAutorizacaoCardApi;
