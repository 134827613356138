import { FetchAPI, FieldsetAPI, PanelAPI, RepeaterAPI } from '@philips-emr/tws-components-api';
import { Data } from '@philips-emr/tws-components-api/lib/api/repeater/repeater-api';
import Controller, { OnAfterFetch, OnClick, OnMount } from '@philips-emr/tws-core/lib/client';
import { FetchEvent } from '@philips-emr/tws-core/lib/component/types';
import { Inject } from '@philips/odin';
import { Route } from '../../../constants/routes';
import ReportService from '../../../services/report-service';
import BaseController from '../../BaseController';
import { Endpoint } from '../../../constants/endpoints';

interface ListaDiagnostico {
  [key: string]: Diagnostico | undefined;
}

interface Diagnostico {
  cdDoenca: string;
  dsDoenca: string;
}

interface AutorizacaoData {
  cdSenha: string;
  dtValidadeSenha: string;
  nmRecemNascido: string;
  dtNascRecemNascido: string;
  ieTipoGuia: string;
  ieStatus: string;
  cdGuia: string;
}

const GRUPO_RELATORIO_AUTORIZACAO_GUIA = '11';
@Controller({ fragment: 'authorization-data', component: 'formDataLayout' })
class FormDataController extends BaseController {

  @Inject('repeater-authorization-diagnosis')
  cptDiagnosis: RepeaterAPI;

  listaDiagnostico?: ListaDiagnostico;

  @Inject('fieldset-externa')
  fieldsetExterna: FieldsetAPI;

  @Inject('fieldset-recem-nascido')
  fieldsetRecemNascido: FieldsetAPI;

  @Inject('fieldset-internacao')
  fieldsetInternacao: FieldsetAPI;

  @Inject('fieldset-cid')
  fieldsetCid: FieldsetAPI;

  @Inject('authorization-diagnosis-fetch')
  authorizationDiagnosisFetch: FetchAPI;

  @Inject('fetch-authorization-data')
  fetchAuthorizationData: FetchAPI;

  @Inject('panel-relatorio')
  panelRelatorio: PanelAPI;

  reportService: ReportService;

  @OnMount({ target: ['formDataLayout'] })
  onMount(): void {
    const { nrSeqBeneficiario, nrSeqGuia } = this.historyService.getCurrentRouteParams();
    this.context.set({ nrSeqBeneficiario, nrSeqGuia });

    this.reportService = new ReportService(GRUPO_RELATORIO_AUTORIZACAO_GUIA, this, Endpoint.GET_RELATORIOS_POR_GRUPO_AUTORIZACAO);

    this.authorizationDiagnosisFetch.forceFetch();
    this.fetchAuthorizationData.forceFetch();
  }

  @OnAfterFetch({ target: ['fetch-authorization-data'] })
  onAfterFetchData(event: FetchEvent): void {
    const data = event.data as AutorizacaoData;

    if (!data) {
      return;
    }

    if (!(data.cdSenha || '').trim() && !(data.dtValidadeSenha || '').trim()) {
      this.fieldsetExterna.setVisible(false);
    }

    if (!(data.nmRecemNascido || '').trim() && !(data.dtNascRecemNascido || '').trim()) {
      this.fieldsetRecemNascido.setVisible(false);
    }

    if ((data.ieTipoGuia || '').trim() !== '1' && (data.ieTipoGuia || '').trim() !== '8') {
      this.fieldsetInternacao.setVisible(false);
    }

    if (data.ieStatus === '1') {
      const { nrSeqGuia } = this.historyService.getCurrentRouteParams();
      this.reportService.getRelatoriosByCdGrupo({ nrSeqGuia, ieTipoGuia: data.ieTipoGuia })
        .then(reps => {
          this.panelRelatorio.setVisible(reps.length > 0);
        });
    }
  }

  @OnAfterFetch({ target: ['authorization-diagnosis-fetch'] })
  onAfterFetch(event: FetchEvent): void {

    this.listaDiagnostico = event.data as ListaDiagnostico;

    if (!this.listaDiagnostico) {
      return;
    }

    const diagnosticos: Data[] = [];

    Object.keys(this.listaDiagnostico).forEach(
      key => {
        const diagnostico: Diagnostico | undefined = this.listaDiagnostico && this.listaDiagnostico[key];
        const textoFormatado: Data = (diagnostico && this.resolveDisplayText(diagnostico)) || {};
        if (textoFormatado.diagnosticosAutorizados) {
          diagnosticos.push(textoFormatado);
        }
      }
    );

    this.fieldsetCid.setVisible(!!diagnosticos.length);

    this.cptDiagnosis.setData(diagnosticos);
  }

  resolveDisplayText(diagnostico: Diagnostico): Data {
    const code = diagnostico.cdDoenca;
    const description = diagnostico.dsDoenca;

    const displayObj: Data = { diagnosticosAutorizados: code ? ` ${code} - ${description} ` : `` };

    return displayObj;
  }

  @OnClick({ target: ['button-imprimir'] })
  onClickImprimir(): void {
    const { nrSeqGuia } = this.historyService.getCurrentRouteParams();
    const rota = this.historyService.getCurrentRoute().concat(Route.RELATORIOS).replace(/\/\//g, '/');
    const param = { NR_SEQUENCIA_AUTOR: nrSeqGuia };
    this.reportService && this.reportService.abreListaRelatorios(param, rota);
  }

}

export default FormDataController;