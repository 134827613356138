import HpmsTermServiceApi from '@philips-emr/hpms-components/src/api/hpms-term-service-api';
import { FetchAPI } from '@philips-emr/tws-components-api';
import Controller, { OnClose, OnMount } from '@philips-emr/tws-core/lib/client';
import EventListener from '@philips-emr/tws-core/lib/client/event-listener';
import { AuthenticationService, HttpService } from '@philips-emr/tws-core/lib/services';
import { Inject } from '@philips/odin';
import { Route } from "../../../constants/routes";
import BaseController from '../../BaseController';
import JWTToken from '@philips-emr/tws-core/lib/services/auth/JWTToken';
import TokenConstants from '../../../constants/token-constants';
import { Endpoint } from './../../../constants/endpoints';

@Controller({ fragment: 'assign-termo-uso-modal', component: 'modal-assign-termo-uso' })
class AssignTermUsoModalController extends BaseController {

  @Inject('fetch-hpms-term-uso')
  fetchTermoUso: FetchAPI;

  @Inject('hpms-term-service')
  checkTerm: HpmsTermServiceApi;

  @OnMount({ target: ['modal-assign-termo-uso'] })
  onMount(): void {
    const { nrSeqTermo } = this.historyService.getCurrentRouteParams();
    this.context.set({ nrSeqTermo });
    const nrSeqValid = nrSeqTermo !== 'undefined';
    this.checkTerm.setEnabled(nrSeqValid);
    if (nrSeqValid) {
      this.fetchTermoUso.forceFetch();
    }
  }

  @OnClose({ target: ['modal-assign-termo-uso'] })
  onclose(): void {
    AuthenticationService.logout().then(() => {
      this.historyService.push(Route.LOGIN);
    });
  }

  //@ts-ignore
  @EventListener({ type: 'onSubmitTermoUso', target: ['hpms-term-service'] })
  onSubmitTermoUso(): void {
    const { nrSeqTermo } = this.historyService.getCurrentRouteParams();
    const token = AuthenticationService.getJWTToken() as JWTToken;
    const nrSeqBeneficiario = token.claims[TokenConstants.NR_SEQ_BENEFICIARIO] as string;

    if (nrSeqTermo !== 'undefined') {
      HttpService.put(Endpoint.UPDATE_TERMOS_USO, { nrSeqTermo }).then(() => {
        this.historyService.push(Route.DASHBOARD, { nrSeqBeneficiario });
      });
    }
  }

}

export default AssignTermUsoModalController;
