import { ClickEventListener } from '@philips-emr/tws-core/lib/component/types';
import { ComponentAPI, AwesomeEvent } from '@philips-emr/tws-core';

class HpmsInfoBeneficiarioApi extends ComponentAPI {

  @AwesomeEvent
  onShowMoreClick = new ClickEventListener();

  setImage(foto: string): void {
    this.setState({ foto });
  }

  setLoading(value: boolean): void {
    this.setState({ loading: value });
  }

  setExtend(value: boolean): void {
    this.setState({ extend: value });
  }

  setWidth(width: number): void {
    this.setState({ width });
  }

}

export default HpmsInfoBeneficiarioApi;
