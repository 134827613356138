import { DropDownMenuAPI, LinkAPI } from '@philips-emr/tws-components-api';
import Controller, { OnAfterFetch, OnClick, OnMount, OnUnmount } from '@philips-emr/tws-core/lib/client';
import { FetchEvent } from '@philips-emr/tws-core/lib/component/types';
import { HttpService } from '@philips-emr/tws-core/lib/services';
import { listenHistoryChange } from '@philips-emr/tws-core/lib/services/history-service';
import { Inject } from '@philips/odin';
import { Endpoint } from '../../../constants/endpoints';
import { Route } from '../../../constants/routes';
import { Notificacao } from '../../../interfaces/notificacao';
import pollingService from '../../../services/polling/polling-service';
import Utils from '../../../utils/utils';
import BaseController from '../../BaseController';

interface UrlParameters {
  pathname: string;
}

@Controller({ fragment: 'beneficiary', component: 'layout-icones' })
class NotificacaoController extends BaseController {

  private TEMPO_NOTIFICACAO = 60000;
  private TEMPO_MENSAGEM = 60000;

  @Inject('notificacao-link')
  notificacao: LinkAPI;

  @Inject('notificacao-link-modal')
  notificacaoModal: LinkAPI;

  @Inject('message-link')
  mensagem: LinkAPI;

  @Inject('drop-down-menu-notificacao')
  hpmsDropDownMenuNotificacao: DropDownMenuAPI;

  @Inject('drop-down-menu-mensagem')
  hpmsDropDownMenuMensagem: DropDownMenuAPI;

  @Inject('hpms-drop-down-menu')
  hpmsDropDownMenu: DropDownMenuAPI;

  removeListener: Function;

  @OnMount({ target: 'layout-icones' })
  onMount(): void {
    this.setQtNotificacao();
    this.setQtMensagem();

    pollingService.createPolling('notificacao', this.setQtNotificacao.bind(this), this.TEMPO_NOTIFICACAO);
    pollingService.createPolling('mensagem', this.setQtMensagem.bind(this), this.TEMPO_MENSAGEM);

    this.removeListener = listenHistoryChange((url: UrlParameters) => {
      if(url.pathname.indexOf('/login') != -1) return;

      this.setQtNotificacao.call(this)
      this.setQtMensagem.call(this)
    });
  }

  @OnUnmount({ target: 'layout-icones' })
  onUnmount(): void {
    this.removeListener();
    this.stopPollings();
  }

  @OnClick({ target: 'notificacao-link-modal' })
  onNotificacaoLinkModalClick(): void {
    const beneficiaryId = this.context.get('beneficiaryId') as string;
    this.setQtNotificacao(true);
    this.historyService.push(Route.NOTIFICACAO, { beneficiaryId });
  }

  @OnClick({ target: 'option-message' })
  onOptionMessageClick(): void {
    this.hpmsDropDownMenu.close();
    const beneficiaryId = this.context.get('beneficiaryId') as string;
    this.historyService.push(Route.MENSAGEM, { beneficiaryId });
  }

  @OnClick({ target: 'visualizar-todos-notificacao-link' })
  onVisualizarTodosNotificacaoLinkClick(): void {
    this.hpmsDropDownMenuNotificacao.close();
    this.setQtNotificacao(true);
    const beneficiaryId = this.context.get('beneficiaryId') as string;
    this.historyService.push(Route.NOTIFICACAO, { beneficiaryId });
  }

  @OnClick({ target: 'visualizar-todas-mensagens-link' })
  onVisualizarTodasMensagensLinkClick(): void {
    this.hpmsDropDownMenuMensagem.close();
    const beneficiaryId = this.context.get('beneficiaryId') as string;
    this.historyService.push(Route.MENSAGEM, { beneficiaryId });
  }

  @OnAfterFetch({ target: 'fetch-notification' })
  onAfterFetchNotificacao(event: FetchEvent): void {
    const params = event.data as Notificacao[];
    const notificacoes = params && params.filter(_notificacao => _notificacao.qtLeitura == "0");

    //@ts-ignore body deveria aceitar receber um array de dados
    HttpService.post(Endpoint.POST_CONFIRMAR_LEITURA_NOTIFICACAO, {}, { body: notificacoes }).then(() => {
      this.setQtNotificacao();
    });
  }

  @OnUnmount({ target: 'fragment-lista-notificacao-modal' })
  onNotificacaoUnmount(): void {
    this.setQtNotificacao(true);
  }

  setQtNotificacao(clear: boolean = false): void {
    if (clear) {
      Utils.catchRenderException(() => this.notificacao.setBadge(null));
      Utils.catchRenderException(() => this.notificacaoModal.setBadge(null));
      return;
    }

    HttpService.get(Endpoint.GET_NOTIFICACAO).then(response => {
      const notificacoes = response.body as Notificacao[] || [];
      const qtNaoLido = notificacoes.filter((notificacao: Notificacao) => parseInt(notificacao.qtLeitura) === 0).length;
      Utils.catchRenderException(() => this.notificacao.setBadge(qtNaoLido ? String(qtNaoLido) : null));
      Utils.catchRenderException(() => this.notificacaoModal.setBadge(qtNaoLido ? String(qtNaoLido) : null));
    });
  }

  setQtMensagem(clear: boolean = false): void {
    if (clear) {
      Utils.catchRenderException(() => this.mensagem.setBadge(null));
      return;
    }

    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();

    HttpService.get(Endpoint.GET_COUNT_MENSAGENS, { nrSeqBeneficiario }).then(response => {
      const qtNaoLido = response.body as number;
      Utils.catchRenderException(() => this.mensagem.setBadge(qtNaoLido ? String(qtNaoLido) : null));
    });
  }

  stopPollings(): void {
    pollingService.removePolling('notificacao');
    pollingService.removePolling('mensagem');
  }
}

export default NotificacaoController;