
const DOMAINS: Record<string, string> = {
  'domain-gender': 'person.gender', // 4
  'domain-hiring-type': 'authorization.hiring-type', // 1666
  'domain-request-item-status': 'authorization.request-item-status', // 3453
  'domain-form-type': 'authorization.form-type', // 1746
  'domain-segmentation': 'beneficiary.support-segmentation',// 1665
  'domain-regulation': 'beneficiary.regulation',
  'domain-accomodation': 'beneficiary.accomodation',
  'domain-scope': 'beneficiary.scope', // 1669
  'domain-origin': 'beneficiary.origin',
  'domain-surgery-nature': 'authorization.surgery-nature', // 1016
  'domain-admittance-regime': 'authorization.admittance-regime', // 1757
  'domain-consultation-types': 'authorization.consultation-types', // 3435
  'domain-encounter-type': 'authorization.encounter-type', // 1761
  'domain-treatment-purpose': 'authorization.treatment-purpose-terminology', // 5673
  'domain-tumor-staging': 'authorization.tumor-staging-terminology', // 7017
  'domain-chemotherapy-terminology': 'authorization.chemotherapy-terminology-type', // 5672
  'domain-functional-capacity': 'authorization.functional-capacity-terminology', // 5671
  'domain-tumor-terminology': 'authorization.tumor-terminology', // 8111
  'domain-nodule-terminology': 'authorization.nodule-terminology', // 8110
  'domain-metastasis-terminology': 'authorization.metastasis-terminology', // 8109
  'domain-diagnosis-imaging': 'authorization.diagnosis-by-imaging-terminology', // 5888
  'domain-authorization-form-status': 'authorization.authorization-form-status', // 1832
  'domain-manufacturer-option': 'authorization.manufacturer-option', // 7024
  'domain-administration-terminology': 'authorization.administration-mean-terminology', // 5674
  'domain-measurement-terminology': 'authorization.measurement-unit-terminology', // 8132
  'domain-admission-nature': 'authorization.admission-nature', // 2819
  'domain-estado-civil': '$domain.$5', // 5
  'domain-status-guia': 'authorization.form-status' // 1747
};

export default DOMAINS;