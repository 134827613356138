import Controller, { OnMount } from "@philips-emr/tws-core/lib/client";
import { HttpService } from "@philips-emr/tws-core/lib/services";
import { Endpoint } from "../../../constants/endpoints";
import { Route } from "../../../constants/routes";
import BaseController from "../../BaseController";

@Controller({ fragment: 'new-user-confirmation', component: 'panel-confirmation' })
class NewUserConfirmation extends BaseController {

  @OnMount({ target: ['panel-confirmation'] })
  onPanelMount(): void {
    const { hash } = this.historyService.getCurrentRouteParams();
    if ( hash ) {
      const currentRoute = this.historyService.getCurrentRoute();
      HttpService.post(Endpoint.CONFIRMACAO_NOVO_USUARIO, { hash })
        .then(() => {
          this.historyService.push(currentRoute.concat(Route.CONFIRMACAO_USUARIO_SUCCESS));
        }).catch(() => {
          this.historyService.push(currentRoute.concat(Route.CONFIRMACAO_USUARIO_FAIL));
        });
    }
  }

}

export default NewUserConfirmation;