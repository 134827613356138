import { ComponentAPI } from '@philips-emr/tws-core';

class HpmsLoaderApi extends ComponentAPI {

  setVisible(visible: boolean): void {
    this.setState({ visible });
  }

}

export default HpmsLoaderApi;
