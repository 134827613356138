import { Loader } from "@philips-emr/tws-components/lib/components";
import { AwesomeComponent, ComponentType } from "@philips-emr/tws-core";
import { bool, number } from "@philips-emr/tws-core/lib/component/prop-types";
import React, { PureComponent } from "react";
import HpmsLoaderApi from "../../api/hpms-loader-api";

@AwesomeComponent({ type: ComponentType.FEEDBACK, api: HpmsLoaderApi })
class HpmsLoader extends PureComponent {

  static propTypes = {
    visible: bool,
    centered: bool,
    size: number,
  }

  static defaultProps = {
    visible: false,
    centered: false,
    size: 32,
  };

  render() {
    const { visible, centered, size } = this.props;

    if (!visible){
      return null;
    }

    return (
      <Loader centered={centered} size={size} />
    );
  }
}

export default HpmsLoader;