import Card from '@philips-emr/tws-components/lib/components/card/card';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { eventListener, string } from '@philips-emr/tws-core/lib/component/prop-types';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import { trigger } from '@philips-emr/tws-utils';
import React, { PureComponent } from 'react';
import { ReactComponent as CancelSVG } from '../../assets/images/global/hpms-cancel.svg';
import { ReactComponent as CheckSVG } from '../../assets/images/global/hpms-check.svg';
import { ReactComponent as TimeSVG } from '../../assets/images/global/hpms-time.svg';

import './hpms-alteracao-cadastro-card.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY })
class HpmsAlteracaoCadastroCard extends PureComponent {

  static propTypes = {
    onClick: eventListener,
    dsAtributo: string,
    dtCriacao: string,
    ieStatus: string,
    dsValorNew: string,
  }

  renderHeader() {
    const { dtCriacao } = this.props;

    return (
      <div className='c-hpms-alteracao-cadastro-card__header'>
        <div className='c-hpms-alteracao-cadastro-card__header-left'>{format('date', dtCriacao, { type: 'timestamp' })}</div>
      </div>
    );
  }

  renderContent() {
    const { dsAtributo } = this.props;
    const { dsValorNew } = this.props;
    return (
      <div className='c-hpms-alteracao-cadastro-card_content-wrapper'>
        <div className='c-hpms-alteracao-cadastro-card__content'>{dsValorNew}</div>
        <div className='c-label'>{dsAtributo}</div>
      </div>
    );
  }

  renderFooter() {
    const { ieStatus } = this.props;

    const msgStatus = ieStatus ? msg('beneficiario.solicitacao-alteracao.' + ieStatus) : '';
    return (
      <div className='c-hpms-alteracao-cadastro-card__footer'>
        <div className='c-hpms-alteracao-cadastro-card__footer-left'>
          {this.getSvg()}{'\u00A0'}{msgStatus}
        </div>
      </div>
    );
  }

  render() {
    const { onClick } = this.props;
    return (
      <Card width="12" className='c-hpms-alteracao-cadastro-card' onClick={trigger(onClick)}>
        <div className={`c-hpms-alteracao-cadastro-card__wrapper c-hpms-alteracao-cadastro-card__border-${this.getColor()}`}>
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderFooter()}
        </div>
      </Card>
    );
  }

  getColor() {
    const { ieStatus } = this.props;

    switch (ieStatus) {

      case 'P':
        return 'gray';
      case 'A':
        return 'green';
      case 'R':
        return 'red';
    }
  }

  getSvg() {
    const { ieStatus } = this.props;

    switch (ieStatus) {
      case 'P':
        return <TimeSVG width="24" height="24" />;
      case 'A':
        return <CheckSVG className={`c-hpms-protocolo-atendimento-card__color-${this.getColor()}`} />;
      case 'R':
        return <CancelSVG className={`c-hpms-protocolo-atendimento-card__color-${this.getColor()}`} />;
      default:
        return '';
    }
  }
}

export default HpmsAlteracaoCadastroCard;