import { object, string } from '@philips-emr/tws-core/lib/component/prop-types';
import { HttpService } from '@philips-emr/tws-core/lib/services';
import React, { Component } from 'react';
import './hpms-expansible-row.scss';

class HPMSExpansibleRow extends Component {

  static propTypes = {
    params: object,
    endpoint: string,
  }

  static defaultProps = {
    params: {}
  };

  state = {
    data: null
  };

  componentDidMount() {
    const { params, endpoint } = this.props;

    HttpService.get(endpoint, params).then(response => this.setState({ data: response.body }));
  }

  render() {
    const { data } = this.state;
    if (!data) {
      return null
    }

    return (
      <>
        {
          data.map(data => {
            return data.dsMotivoRejeicao ? (
              <span key={data.nrSequencia}>{data.dsMotivoRejeicao}<br/>{data.descricao}</span>
            ) : (
              <span key={data.nrSequencia}>{data.descricao}</span>
            )
          })
        }
      </>
    );
  }

}
export default HPMSExpansibleRow;
