import Controller, { OnMount } from "@philips-emr/tws-core/lib/client";
import { HttpService } from "@philips-emr/tws-core/lib/services";
import { Endpoint } from "../../../constants/endpoints";
import { Route } from '../../../constants/routes';
import BaseController from '../../BaseController';

@Controller({ fragment: 'cartao-consultar', component: 'layout-cartao-consultar' })
class CartaoConsultarController extends BaseController {

  @OnMount({ target: ['layout-cartao-consultar'] })
  onMount(): void {
    this.checkStatus().then((route) => {
      if (route) {
        this.historyService.push(route);
      }
    });
  }

  checkStatus(): Promise<Route | undefined> {
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();

    const status: Promise<Route | undefined> = HttpService.get(Endpoint.CARTAO_STATUS, { nrSeqBeneficiario }).then(response => {
      const status = response.body as string;
      switch (status) {
        case 'CR':
          return Route.CARTAO_CONFIRMAR;
        case 'N':
          return Route.CARTAO_SOLICITAR;
        default:
          return undefined;
      }
    });

    return status;
  }
}

export default CartaoConsultarController;