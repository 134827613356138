function phoneNumberFormatter(origenPhone: string, isFree: boolean): string {
  let phoneFormatted: string = origenPhone;
  if (isFree) {
    phoneFormatted = phoneFormatted.replace(/\D/g, "");
    phoneFormatted = phoneFormatted.replace(/^(\d)/, "$1");
    phoneFormatted = phoneFormatted.replace(/(.{3})(\d)/, "$1$2 ");
    if (phoneFormatted.length == 9) {
      phoneFormatted = phoneFormatted.replace(/(.{1})$/, " $1");
    } else if (phoneFormatted.length == 10) {
      phoneFormatted = phoneFormatted.replace(/(.{2})$/, " $1");
    } else if (phoneFormatted.length == 11) {
      phoneFormatted = phoneFormatted.replace(/(.{3})$/, " $1");
    } else if (phoneFormatted.length == 12) {
      phoneFormatted = phoneFormatted.replace(/(.{4})$/, " $1");
    }
    return phoneFormatted;
  } else {
    phoneFormatted = phoneFormatted.replace(/\D/g, "");
    phoneFormatted = phoneFormatted.replace(/^(\d)/, "($1");
    phoneFormatted = phoneFormatted.replace(/(.{3})(\d)/, "$1) $2");
    if (phoneFormatted.length == 9) {
      phoneFormatted = phoneFormatted.replace(/(.{1})$/, "-$1");
    } else if (phoneFormatted.length == 10) {
      phoneFormatted = phoneFormatted.replace(/(.{2})$/, "-$1");
    } else if (phoneFormatted.length == 11) {
      phoneFormatted = phoneFormatted.replace(/(.{3})$/, "-$1");
    } else if (phoneFormatted.length == 12) {
      phoneFormatted = phoneFormatted.replace(/(.{4})$/, "-$1");
    } else if (phoneFormatted.length > 12) {
      phoneFormatted = phoneFormatted.replace(/(.{4})$/, "-$1");
    }
    return phoneFormatted;
  }
 
}

export default phoneNumberFormatter;