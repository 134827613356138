import Link from '@philips-emr/tws-components/lib/components/link/link';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { eventListener } from '@philips-emr/tws-core/lib/component/prop-types';
import { array } from 'prop-types';
import React, { Component } from 'react';
import './hpms-link-list.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY })
class HPMSLinkList extends Component {

  static propTypes = {
    data: array,
    onOpenAttachment: eventListener
  }

  state = {
    nrSeqAtendimento: null
  }

  onOpen = (link) => {
    var win = window.open(link, '_blank');
    win && win.focus();
  }

  renderContent() {
    const { data } = this.props;

    if (!data) {
      return;
    }

    return data.map((element, index) => {
      return (
        <div key={`${index}`} className='c-link-list__item'>
          <Link className='beneficiary-blue-link' value='$expression.$969218' onClick={() => this.onOpen(element.dsLink)} />
        </div>
      )
    });
  }

  render() {
    return (
      <div className='c-link-list__wrapper'>
        {this.renderContent()}
      </div>
    );
  }

}

export default HPMSLinkList;
