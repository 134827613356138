import { FetchAPI, FieldsetAPI, FormAPI, PanelAPI, RepeaterAPI } from '@philips-emr/tws-components-api';
import { Data } from '@philips-emr/tws-components-api/lib/api/repeater/repeater-api';
import Controller, { OnAfterFetch, OnClick, OnMount } from '@philips-emr/tws-core/lib/client';
import EventListener from '@philips-emr/tws-core/lib/client/event-listener';
import { ClickEvent, FetchEvent } from '@philips-emr/tws-core/lib/component/types';
import { FileService } from '@philips-emr/tws-core/lib/services';
import { HttpMethod } from '@philips-emr/tws-core/lib/services/http-service';
import { msg } from '@philips-emr/tws-core/lib/services/message-service';
import { Inject } from '@philips/odin';
import ReportService from '../../../services/report-service';
import ToastService from '../../../services/toast-service';
import BaseController from '../../BaseController';
import { Endpoint } from './../../../constants/endpoints';
import { Route } from './../../../constants/routes';

interface Diagnosticos {
  [key: string]: Diagnostico | undefined;
}

interface Diagnostico {
  dsDoenca: string;
  cdDoenca: string;
}

interface RequestData {
  ieRecemNascido: string;
  ieTipoGuia: string;
  ieEstagio: number;
}

interface Anexos {
  dsAnexo: string;
  nmArquivo: string;
  nrSeqAnexo: number;
}

interface GlosaOcorrencia {
  dsTipo: string;
  dsDescricaoGlosaOcor: string;
  nrCodigo: string;
  nrSequencia: number;
  descricao: string;
}

const GRUPO_RELATORIO_REQUISICAO = '12';
@Controller({ fragment: 'request-form', component: 'layout-form' })
class RequestDataController extends BaseController {

  @Inject('panel-icd')
  panelICD: PanelAPI;

  @Inject('repeater-request-diagnosis')
  cptDiagnosis: RepeaterAPI;

  @Inject('fetch-request-data')
  fetchRequestData: FetchAPI;

  diagnosticos?: Diagnosticos;

  @Inject('fieldset-recem-nascido')
  fieldsetRecemNascido: FieldsetAPI;

  @Inject('fieldset-guia-internacao')
  fieldsetGuiaInternacao: FieldsetAPI;

  @Inject('panel-request-attachments')
  panelAnexos: PanelAPI;

  @Inject('request-diagnosis-fetch')
  requestDiagnosisFetch: FetchAPI;

  @Inject('panel-danials-incidents')
  painelDanialsIncidents: PanelAPI;

  @Inject('panel-relatorio')
  panelRelatorio: PanelAPI;

  @Inject('requestDataForm')
  form: FormAPI;

  reportService: ReportService;

  @OnMount({ target: ['layout-form'] })
  onMountRequestData(): void {
    const { nrSeqRequisicao } = this.historyService.getCurrentRouteParams();
    this.context.set({ nrSeqRequisicao });

    this.reportService = new ReportService(GRUPO_RELATORIO_REQUISICAO, this, Endpoint.GET_RELATORIOS_POR_GRUPO_REQUISICAO);

    this.fetchRequestData.forceFetch();
    this.requestDiagnosisFetch.forceFetch();
  }

  @OnAfterFetch({ target: ['fetch-request-data'] })
  OnAfterRequestDataFetch(event: FetchEvent): void {
    const requestDetailData = event.data as RequestData;

    if (requestDetailData) {
      this.fieldsetRecemNascido.setVisible(requestDetailData.ieRecemNascido == 'S');
      this.fieldsetGuiaInternacao.setVisible(requestDetailData.ieTipoGuia == '1');

      if (requestDetailData.ieEstagio !== 2) {
        this.panelRelatorio.setVisible(false);
        return;
      }

      const { nrSeqRequisicao } = this.historyService.getCurrentRouteParams();
      this.reportService.getRelatoriosByCdGrupo({ nrSeqRequisicao, ieTipoGuia: requestDetailData.ieTipoGuia })
        .then(reps => this.panelRelatorio.setVisible(reps.length > 0));
    }
  }

  @OnAfterFetch({ target: ['request-diagnosis-fetch'] })
  onAfterFetchRequestDiagnosis(event: FetchEvent): void {
    this.diagnosticos = event.data as Diagnosticos;

    if (this.diagnosticos && Object.keys(this.diagnosticos).length == 0) {
      this.panelICD.setVisible(false);
      return;
    }

    const displayTexts: Data[] = [];
    this.diagnosticos && Object.keys(this.diagnosticos).forEach(
      key => {
        let displayObj: Data = this.resolveDisplayText(this.diagnosticos && this.diagnosticos[key]);
        if (displayObj.diagnosticoRequisicaoAutorizacao) {
          displayTexts.push(displayObj);
        }
      }
    );
    this.cptDiagnosis.setData(displayTexts);
  }

  resolveDisplayText(diagnostico: Diagnostico | undefined): Data {
    if (!diagnostico) {
      return { diagnosticoRequisicaoAutorizacao: '' };
    }

    const { cdDoenca, dsDoenca } = diagnostico;
    return { diagnosticoRequisicaoAutorizacao: ` ${cdDoenca} - ${dsDoenca} ` };
  }

  @OnAfterFetch({ target: ['denialsIncidentsFetch'] })
  onAfterFetchDenialsIncidents(event: FetchEvent): void {
    const data = event.data as GlosaOcorrencia;

    if (Object.keys(data).length == 0) {
      this.painelDanialsIncidents.setVisible(false);
      return
    }
  }

  @OnAfterFetch({ target: ['fetch-request-attachments'] })
  onAfterFetchRequestAttachments(event: FetchEvent): void {
    const data = event.data as Anexos;

    if (Object.keys(data).length == 0) {
      this.panelAnexos.setVisible(false);
      return;
    }
  }

  //@ts-ignore
  @EventListener({ type: 'onOpenAttachment', target: ['hpms-attachments-list'] })
  onDownloadClick(event: ClickEvent): void {
    const value = event.value;
    const { nrSeqBeneficiario, nrSeqRequisicao } = this.historyService.getCurrentRouteParams();
    FileService.download(
      Endpoint.DOWNLOAD_REQUISICAO_AUTORIZACAO_ANEXO,
      value.nmArquivo,
      { nrSeqAnexo: value.nrSeqAnexo, nrSeqBeneficiario, nrSeqRequisicao },
      HttpMethod.GET
    ).catch(() => ToastService.caution(msg('$expression.$508286')));
  }

  @OnClick({ target: ['button-imprimir'] })
  onClickImprimir(): void {
    const { nrSeqRequisicao } = this.historyService.getCurrentRouteParams();
    const rota = this.historyService.getCurrentRoute().concat(Route.RELATORIOS).replace(/\/\//g, '/');
    const param = { NR_SEQ_REQUISICAO: nrSeqRequisicao, NR_SEQUENCIA_AUTOR: nrSeqRequisicao };
    this.reportService && this.reportService.abreListaRelatorios(param, rota);
  }

}

export default RequestDataController;