import React from 'react';
import Controller, { OnDynamicContentRender } from "@philips-emr/tws-core/lib/client";
import BaseController from "../../BaseController";
import { RenderEvent } from "@philips-emr/tws-core/lib/component/types";
import { Link } from "@philips-emr/tws-components";
import { Route } from '../../../constants/routes';


interface Chamado {
  nrSequencia: number;
}

@Controller({ fragment: 'historico-chamados' })
class ListaChamadoController extends BaseController {

  @OnDynamicContentRender({ target: ['data-table-hist'] })
  onDynamicContentRender(event: RenderEvent): void {
    const { entry, data, render } = event;
    const nrSeqChamado = (data as Chamado).nrSequencia;

    if (entry === 'detailColumn') {

      const renderLink: Function = () => {
        return (
          <Link icon='/static/assets/view-details.svg' className='hpms-custom-icon-size' onClick={() => this.onDetailClick(nrSeqChamado)} value=' ' />
        );
      }

      render(renderLink);
    }
  }

  private onDetailClick(nrSeqChamado: number): void {
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();
    this.historyService.push(Route.CHAMADO_DETALHE, { nrSeqBeneficiario, nrSeqChamado });
  }

} export default ListaChamadoController
