import { PanelAPI, ToastAPI } from "@philips-emr/tws-components-api";
import Controller, { OnClick, OnMount } from "@philips-emr/tws-core/lib/client";
import { HttpService } from "@philips-emr/tws-core/lib/services";
import { Inject } from '@philips/odin';
import { Endpoint } from "../../../constants/endpoints";
import { Route } from '../../../constants/routes';
import ToastService from "../../../services/toast-service";
import Utils from "../../../utils/utils";
import BaseController from '../../BaseController';

@Controller({ fragment: 'cartao-confirmar', component: 'layout-cartao-confirmar' })
class CartaoConfirmarController extends BaseController {

  @Inject('toast-yes-no')
  toastYesNo: ToastAPI;

  @Inject('panel-loader')
  panelLoader: PanelAPI;

  @Inject('panel-button')
  panelButton: PanelAPI;

  @OnMount({ target: ['layout-cartao-confirmar'] })
  onMount(): void {
    this.checkStatus().then((route) => {
      if (route) {
        this.historyService.push(route);
      }
    });
  }

  @OnClick({ target: ['button-confirmar'] })
  onConfirmarClick(): void {
    this.checkStatus().then((route) => {
      if (route) {
        this.historyService.push(route);
      } else {
        this.toastYesNo.open();
        this.panelButton.setVisible(false);
        this.panelLoader.setVisible(true);
      }
    });
  }

  @OnClick({ target: ['button-toast-yes'] })
  onYesClick(): void {
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();

    this.toastYesNo.close();

    HttpService.post(Endpoint.POST_CARTAO_CONFIRMAR, { nrSeqBeneficiario })
      .then(response => {
        const mensagem = response.body as string;
        ToastService.safety(mensagem);
        this.panelLoader.setVisible(false);
      })
      .catch(response => {
        Utils.catchHandler(response);
        this.panelButton.setVisible(true);
        this.panelLoader.setVisible(false);
      });
  }

  @OnClick({ target: ['button-toast-no'] })
  onNoClick(): void {
    this.toastYesNo.close();
    this.panelButton.setVisible(true);
    this.panelLoader.setVisible(false);
  }

  checkStatus(): Promise<Route | undefined> {
    const { nrSeqBeneficiario } = this.historyService.getCurrentRouteParams();

    const status: Promise<Route | undefined> = HttpService.get(Endpoint.CARTAO_STATUS, { nrSeqBeneficiario }).then(response => {
      const status = response.body as string;
      switch (status) {
        case 'A':
          return Route.CARTAO_CONSULTAR;
        case 'N':
          return Route.CARTAO_SOLICITAR;
        default:
          return undefined;
      }
    });

    return status;
  }
}

export default CartaoConfirmarController;