import Controller, { OnClose } from "@philips-emr/tws-core/lib/client";
import { Route } from "../../../constants/routes";
import BaseController from '../../BaseController';

@Controller({ fragment: 'invoice-request-term-modal', component: 'modal-boleto-termo' })
class InvoiceRequestTermModalController extends BaseController {

  @OnClose({ target: ['modal-boleto-termo'] })
  onclose(): void {
    this.historyService.push(Route.BOLETOS_POR_EMAIL);
  }
}

export default InvoiceRequestTermModalController;