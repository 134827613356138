import React, { PureComponent } from 'react';
import { eventListener, string, object, bool } from '@philips-emr/tws-core/lib/component/prop-types';
import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import './hpms-social-networks.scss';
import Link from '@philips-emr/tws-components/lib/components/link/link';
import HpmsSocialNetworksApi from '../../api/hpms-social-networks-api';
@AwesomeComponent({ type: ComponentType.DATA_DISPLAY, api: HpmsSocialNetworksApi })
class HpmsSocialNetworks extends PureComponent {

  constructor(props) {
    super(props);
  }

  state = {
    isDark: false
  }

  static propTypes = {
    uuid: string,
    data: object,
    isDark: bool,
    onSocialNetworkClick: eventListener
  };

  static defaultProps = {
    data: {},
    isDark: false
  };

  socialNetworkClick(link) {
    const { onSocialNetworkClick } = this.props;

    onSocialNetworkClick && onSocialNetworkClick({ value: link });
  }

  resolveLink(link) {
    if (link.indexOf('http') === -1) {
      link = `https://${link}`;
    }

    return link;
  }

  renderFacebookLogo() {
    const { data } = this.props;

    if (!data.dsLinkFacebook) {
      return;
    }

    const link = this.resolveLink(data.dsLinkFacebook);
    const styles = this.props.isDark ? 'facebook-logo-dark' : '';

    return (
      <div className="c-social-networks__logos">
        <Link onClick={() => this.socialNetworkClick(link)} rel="noopener noreferrer" className={`c-social-networks-logo facebook-logo ${styles}`} />
      </div>
    );
  }

  renderInstagramLogo() {
    const { data } = this.props;

    if (!data.dsLinkInstagram) {
      return;
    }

    const link = this.resolveLink(data.dsLinkInstagram);
    const styles = this.props.isDark ? 'instagram-logo-dark' : '';
    return (
      <div className="c-social-networks__logos">
        <Link onClick={() => this.socialNetworkClick(link)} rel="noopener noreferrer" className={`c-social-networks-logo instagram-logo ${styles}`} />
      </div>
    );
  }

  renderTwitterLogo() {
    const { data } = this.props;

    if (!data.dsLinkTwitter) {
      return;
    }

    const link = this.resolveLink(data.dsLinkTwitter);
    const styles = this.props.isDark ? 'twitter-logo-dark' : '';
    return (
      <div className="c-social-networks__logos">
        <Link onClick={() => this.socialNetworkClick(link)} rel="noopener noreferrer" className={`c-social-networks-logo twitter-logo ${styles}`} />
      </div>
    );
  }

  renderYoutubeLogo() {
    const { data } = this.props;

    if (!data.dsLinkYoutube) {
      return;
    }

    const link = this.resolveLink(data.dsLinkYoutube);
    const styles = this.props.isDark ? 'youtube-logo-dark' : '';
    return (
      <div className="c-social-networks__logos">
        <Link onClick={() => this.socialNetworkClick(link)} rel="noopener noreferrer" className={`c-social-networks-logo youtube-logo ${styles}`} />
      </div>
    );
  }

  renderLinkedinLogo() {
    const { data } = this.props;

    if (!data.dsLinkLinkedin) {
      return;
    }

    const link = this.resolveLink(data.dsLinkLinkedin);
    const styles = this.props.isDark ? 'linkedin-logo-dark' : '';
    return (
      <div className="c-social-networks__logos">
        <Link onClick={() => this.socialNetworkClick(link)} rel="noopener noreferrer" className={`c-social-networks-logo linkedin-logo ${styles}`} />
      </div>
    );
  }

  render() {

    return (
      <div className="c-social-networks">
        {this.renderFacebookLogo()}

        {this.renderInstagramLogo()}

        {this.renderTwitterLogo()}

        {this.renderYoutubeLogo()}

        {this.renderLinkedinLogo()}
      </div>

    );
  }
}

export default HpmsSocialNetworks;
