import { FetchAPI, PanelAPI } from '@philips-emr/tws-components-api';
import Controller, { OnAfterFetch, OnClick, OnMount } from '@philips-emr/tws-core/lib/client';
import { FetchEvent } from '@philips-emr/tws-core/lib/component/types';
import { AuthenticationService } from '@philips-emr/tws-core/lib/services';
import JWTToken from '@philips-emr/tws-core/lib/services/auth/JWTToken';
import { Inject } from '@philips/odin';
import { Route } from '../../../constants/routes';
import TokenConstants from '../../../constants/token-constants';
import ReportService from '../../../services/report-service';
import BaseController from '../../BaseController';

@Controller({ fragment: 'waiting-period', component: 'waiting-period-layout' })
class CarenciaController extends BaseController {

  @Inject('fetch-msg-operadora')
  fetchMsgOperadora: FetchAPI;

  @Inject('panel-relatorio')
  panelRelatorio: PanelAPI;

  reportService: ReportService;

  @OnMount({ target: ['waiting-period-layout'] })
  onMount(): void {
    this.reportService = new ReportService('5', this);
    this.reportService.getRelatoriosByCdGrupo()
      .then(reps => {
        this.panelRelatorio.setVisible(reps.length > 0);
      });
  }

  @OnAfterFetch({ target: ['fetch-carencia-card', 'fetch-carencia-datatable'] })
  onAfterFetch(event: FetchEvent): void {
    if (event.data && Object.keys(event.data).length > 0) {
      this.fetchMsgOperadora.forceFetch();
    }
  }

  @OnClick({ target: ['imprimir-relatorio-carencia-link'] })
  onClickPrintReportLink(): void {
    const token = AuthenticationService.getJWTToken() as JWTToken;
    const param = {
      DS_LOGIN: token && token.claims[TokenConstants.DS_LOGIN]
    }
    this.reportService && this.reportService.abreListaRelatorios(param, Route.CARENCIA_RELATORIOS, '$expression.$601003');
  }
}

export default CarenciaController;