import BaseController from "../../BaseController";
import Controller, { OnMount, OnClick } from "@philips-emr/tws-core/lib/client";
import { Inject } from '@philips/odin';
import { ImageAPI } from "@philips-emr/tws-components-api";
import ConfigurationService from "../../../services/configuration-service";
import { Route } from '../../../constants/routes';

@Controller({ fragment: 'new-user-confirmation-success', component: 'layout-confirmation-success' })
class NewUserConfirmationSuccess extends BaseController {

  @Inject('login-image-header')
  image: ImageAPI;

  @OnMount({ target: ['layout-confirmation-success'] })
  onLayoutMount(): void {
    ConfigurationService.getConfigurationData().then(layoutData => {
      this.image.setSrc(layoutData.dsUrlLogoHeaderLogin);
    });
  }

  @OnClick({ target: ['button-login'] })
  onLoginBtnClick(): void {
    this.historyService.push(Route.LOGIN);
  }

}

export default NewUserConfirmationSuccess;