import { ImageAPI, LinkAPI } from "@philips-emr/tws-components-api";
import Controller, { OnClick, OnMount } from "@philips-emr/tws-core/lib/client";
import { Inject } from '@philips/odin';
import { Route } from "../../../constants/routes";
import ConfigurationService from "../../../services/configuration-service";
import BaseController from "../../BaseController";

@Controller({ fragment: 'nova-senha-sucesso', component: 'layout-success' })
class NovaSenhaSucesso extends BaseController {

  @Inject('login-image-header')
  image: ImageAPI;

  @Inject('link-login')
  link: LinkAPI;

  @OnMount({ target: ['layout-success'] })
  onLayoutMount(): void {
    ConfigurationService.getConfigurationData().then(layoutData => {
      this.image.setSrc(layoutData.dsUrlLogoHeaderLogin);
      this.link.setValue(this.getUrl(layoutData.dsLinkWebSuite));
    });
  }

  getUrl(url: string = ""): string {
    if (url.endsWith("/")) {
      return `${url.slice(0, -1)}${Route.LOGIN}`;
    }
    return `${url}${Route.LOGIN}`;
  }

  @OnClick({ target: ['link-login'] })
  onBtnClick(): void {
    this.historyService.push(Route.LOGIN);
  }

}

export default NovaSenhaSucesso;