import Controller, { OnMount } from '@philips-emr/tws-core/lib/client';
import BaseController from '../../BaseController';

@Controller({ fragment: 'request-details', component: 'requestDetailsLayout' })
class RequestDetailsController extends BaseController {

  @OnMount({ target: ['requestDetailsLayout'] })
  onMount(): void {
    const { nrSeqBeneficiario, nrSeqRequisicao } = this.historyService.getCurrentRouteParams();
    this.context.set({ nrSeqBeneficiario, nrSeqRequisicao, origem: 'requisicao', nrSeq: nrSeqRequisicao });
  }
}

export default RequestDetailsController;
