import { ComponentAPI} from '@philips-emr/tws-core';

class HpmsIconApi extends ComponentAPI {

  setLabel(label: string): void {
    this.setState({ label });
  }
}

export default HpmsIconApi;
