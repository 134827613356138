import { AwesomeComponent, ComponentType } from '@philips-emr/tws-core';
import { string } from '@philips-emr/tws-core/lib/component/prop-types';
import { format } from '@philips-emr/tws-core/lib/services/model-service';
import React, { PureComponent } from 'react';
import './hpms-encounter-protocol.scss';

@AwesomeComponent({ type: ComponentType.DATA_DISPLAY })
class HpmsEncounterProtocol extends PureComponent {

  static propTypes = {
    date: string,
    description: string
  }

  renderContent() {
    const { date, description } = this.props;

    return (
      <div className='c-hpms-encounter-protocol__content'>
        <div className='c-hpms-encounter-protocol__content-top'>{format('date', date, {type: 'timestamp'})}</div>
        <div className='c-hpms-encounter-protocol__content-bottom'>{description}</div>
      </div>
    );
  }

  render() {
    return (
      <div className='u-grid-col-12 c-hpms-encounter-protocol'>{this.renderContent()}</div>
    );
  }
}

export default HpmsEncounterProtocol;