import { Inject } from '@philips/odin';

import { Value } from '@philips-emr/tws-core';
import { HistoryService } from '@philips-emr/tws-core/lib/services/history-service';

interface DataContext {
  get(key: string): string|number|boolean|object;
  getBulkData(): Record<string, Value>;
  set(data: { [key: string]: string|number|boolean|object }): void;
  readOnly(): {};
}

class BaseController {

  @Inject('HistoryService')
  historyService: HistoryService;

  @Inject('Context')
  context: DataContext;

}

export default BaseController;