import { AutocompleteAPI, FetchAPI, FieldAPI, FieldsetAPI, FormAPI, PanelAPI, TextAPI } from '@philips-emr/tws-components-api';
import Controller, { OnBeforeSubmit, OnBlur, OnChange, OnClick, OnEdit } from '@philips-emr/tws-core/lib/client';
import { EditEvent, SubmitEvent } from '@philips-emr/tws-core/lib/component/types';
import { HttpService } from '@philips-emr/tws-core/lib/services';
import { Inject } from '@philips/odin';
import { Endpoint } from '../../constants/endpoints';
import { Route } from '../../constants/routes';
import ToastService from "../../services/toast-service";
import Utils from '../../utils/utils';
import BaseController from '../BaseController';

interface Target {
  state: State;
}

interface State {
  record: Record<string, Value>;
  oldValues: Record<string, Value>;
}

interface Value {
  dsMunicipio: string;
  vlDominio: string;
  nrSequencia: string;
}

@Controller({ fragment: 'person-data-details-form-editable', component: 'person-data-details-layout-editable' })
class PersonDataController extends BaseController {

  state: State = { oldValues: {}, record: {} };

  @Inject('field-nr-termo-alt')
  fieldNrTermoAlt: FieldAPI;

  @Inject('fieldset-termo')
  fieldsetTermo: FieldsetAPI;

  @Inject('fieldset-anexos')
  fieldsetAnexos: FieldsetAPI;

  @Inject('person-data-form-editable')
  personDataFormEditable: FormAPI;

  @Inject('municipio-fetch')
  municipioFetch: FetchAPI;

  @Inject('panel-loader')
  panelLoader: PanelAPI;

  @Inject('text-obrigatorio')
  textObrigatorio: TextAPI;

  @OnEdit({ target: ['person-data-form-editable'] })
  onEdit(event: EditEvent): void {
    const target = event.target as unknown as Target;
    this.state.oldValues = target.state.record;

    this.fieldsetTermo.setVisible(!!this.fieldNrTermoAlt.getValue());

    HttpService.get(Endpoint.CHECK_TIPO_ANEXOS)
      .then(response => {
        this.fieldsetAnexos.setVisible(true);
        const retorno = response.body as string;
        if (retorno) {
          this.textObrigatorio.setValue(retorno);
        }
      }).catch(() => {
        this.fieldsetAnexos.setVisible(false);
      });
  }

  @OnBeforeSubmit({ target: ['person-data-form-editable'] })
  onBeforeSubmitEvent(event: SubmitEvent): void {
    this.panelLoader.setVisible(true);
    this.personDataFormEditable.setDisabled(true);

    const { abort } = event;
    const nrSeqBeneficiario = this.context.get('nrSeqBeneficiario');
    const cdPessoaFisica = this.context.get('cdPessoaFisica');

    const newValues = event.record as Record<string, Value>;
    const oldValues = this.state.oldValues;

    const campos: object[] = [];
    const nrSeqTermo = newValues.termo ? this.fieldNrTermoAlt.getValue() : '';
    const body = { nrSeqBeneficiario, cdPessoaFisica, nrSeqTermo, campos, dadosBeneficiario: event.record };

    if (!body.nrSeqTermo) {
      delete body.nrSeqTermo;
    }

    Object.keys(newValues).forEach(key => {
      if (oldValues[key] !== newValues[key]) {
        const oldV = oldValues[key];
        const newV = newValues[key];
        if (key !== 'termo' && key !== 'anexos') {
          body.campos.push({
            nmAtributo: key,
            dsValorOld: oldV ? oldV.vlDominio || oldV.nrSequencia || oldV.dsMunicipio || oldV : '',
            dsValorNew: newV ? newV.vlDominio || newV.nrSequencia || newV.dsMunicipio || newV : ''
          });
        }
      }
    });

    const canSubmit: Promise<boolean> = HttpService.post(Endpoint.POST_SOLIC_ALT_CADASTRO, { cdPessoaFisica }, { body })
      .then((response) => {
        const data = response.body as Mensagem;
        ToastService.safety(data[Object.keys(data)[0]]);
        this.panelLoader.setVisible(false);
        return false;
      })
      .catch(response => {
        Utils.catchHandler(response);
        this.panelLoader.setVisible(false);
        this.personDataFormEditable.setDisabled(false);
        return true;
      });

    abort(canSubmit);
  }

  @OnClick({ target: ['link-termo-responsabilidade'] })
  onTermoResponsabilidade(): void {
    const nrSeqBeneficiario = this.context.get('nrSeqBeneficiario') as string;
    const cdPessoaFisica = this.context.get('cdPessoaFisica') as string;
    const nrSeqTermo = this.fieldNrTermoAlt.getValue() as string;
    this.historyService.push(Route.PERSON_DATA_DETAILS_TERMO, { nrSeqBeneficiario, cdPessoaFisica, nrSeqTermo }, { scrollToTop: false });
  }

  @OnBlur({ target: ['cep'] })
  onBlurCep(e: FocusEvent): void {
    const elem = e.target as unknown as FieldAPI;
    const cdCep = (elem.getValue && elem.getValue()) || elem.state.value;

    if (cdCep) {
      HttpService.get(Endpoint.GET_ENDERECO, { cdCep })
        .then((response) => {
          const resp = response.body as object;
          const r = { ...this.personDataFormEditable.getData(), ...resp };
          this.personDataFormEditable.setData(r as Record<string, Value>);
        });
    }
  }

  @OnChange({ target: ['autocomplete-estado'] })
  onChangeEstado(e: AutocompleteAPI): void {
    this.context.set({ dsUnidadeFederacao: e.value ? e.value.vlDominio : null });
    this.municipioFetch.forceFetch();
  }

}
export default PersonDataController;
